const versions = [
  {
    id: "v1.2.1",
    name: "Version 1.2.1",
    released: "March 12, 2024",
    changes: [
      "Added the scorecard page where users can input the result of their CAAP examination and claim their Achievement Box reward.",
      "Fixed the progress bar under Subtopic practice, which was not working properly.",
      "Fixed the issue of inaccurate answers showing when reviewing the mock history.",
      "Updated the GCash QR code when purchasing the CAAP Lite."
    ]
  },
  {
    id: "v1.2.0",
    name: "Version 1.2.0",
    released: "July 01, 2023",
    changes: [
      "AI Tutor: Users now have the option to delve into dynamic explanations that unveil the reasoning behind why an answer is correct for a specific question.",
      "Dynamic Practice: Practice items are now shuffled per subtopic and questions can now be skipped.",
      "Question Explorer: During Practice, you can now jump to any question you want to answer.",
      "Practice now has sound effects. You have the option to turn it on or off.",
      "Mock Exam Review: You can now review the mock exam results. See where you got right or wrong.",
      "Time Management Mastery: Discover how much time you spent on each question, relative to the allowed time for passing the exam.",
    ]
  },
  {
    id: "v0.2.4",
    name: "Version 0.2.4",
    released: "March 01, 2023",
    changes: [
      "Added 'Season 2' feature to give live updates and more information of the ongoing Leaderboard Challenge.",
      "Adjusted 'Skillpoints' reward value to get a more balanced point system for Season 2.",
      "Time requirements for achieving 'Speedrunner' and 'Master' have been shortened for all subjects to add a more challenging experience in unlocking these Milestones.",
      "Issues with uploading CAAP Test Results (stuck on loading page) have been fixed. ",
    ]
  },
  {
    id: "v0.2.3",
    name: "Version 0.2.3",
    released: "August 02, 2022",
    changes: [
      "Updated Payment mechanism to include Reference Number of the receipt.",
      "Added new feature called \"CAAP Info\" where Users need to set a CAAP exam date.",
    ]
  },
  {
    id: "v0.2.0",
    name: "Version 0.2.0",
    released: "April 15, 2022",
    changes: [
      "For improved security, multiple user sessions are now disallowed and will be detected by the system.",
      "Users can now login/signup using one-click \"Single Sign On\" (SSO) via their Google and Yahoo email accounts. User-Password logins are dropped.",
    ]
  },
  {
    id: "v0.1.2",
    name: "Version 0.1.2",
    released: "March 15, 2022",
    changes: [
      "The subject \"Airframe Rating\" has been updated as a result of our latest research findings.",
      "Endorser Code is now required for account creation.",
      "New default schools for AMT, AVT and Engineering if user's school is not found in the options.",
      "Bug Fix: Leaderboard to show only top 20.",
      "Bug Fix: Dashboard menu \"Mock History\" and \"Achievements\" not working properly for newly created users.",
      "Bug Fix: Mock Exam History page not working properly when a User hasn't taken any mock exams yet.",
      "Users can now view the app's changelog.",
    ]
  },
  {
    id: "v0.1.1",
    name: "Version 0.1.1",
    released: "March 5, 2022",
    changes: [
      "School info and logo are now available for each users.",
      "Users can now upload profile pictures. Please upload image files only with 2MB maximum file size.",
      "School Leaderboard is now available. Each school features its own user leaderboard.",
      "Clicking the Skilltech logo will redirect users to the Dashboard page.",
      "CAAP Mock Exam history now shows all results instead of just 20.",
    ]
  }, 
]

exports.changelogData = {
  versions
}
<script setup>
import { ref, computed } from "vue"
import firebase from "@/firebase/config"
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon } from "@heroicons/vue/solid"
import PreExamModal from "../components/PreExamModal.vue"

const isWaiting = ref(true)
const user = ref(null)
const school = ref(null)
const subjects = ref([])
const selectedSubject = ref(null)
const isPreExamModalOpen = ref(false)

let reviewerId = 'lite';

const bestRecord = computed( () => {
    return user.value.subjects.filter( e => e.id == 'air-law-and-human-performance')[0]
})

const fetchSubjects = () => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc(reviewerId).collection("subjects").get()
            .then((querySnapshot) => {
                var subjects = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subjects.push(data)
                });
                resolve(subjects)
                console.log(subjects)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
                console.log(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const fetchSchool = (schoolId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("schools").doc(schoolId).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

const closePreExamModal = () => {
    isPreExamModalOpen.value = false
    // selectedSubject.value = null
}

const openPreExamModal = () => {
    isPreExamModalOpen.value = true
    selectedSubject.value = subjects.value[0]
}

const initialize = async () => {

    // puting this code to switch bet. reviewer ID
    // Reviewer id in production is different in development
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('skilltech.team')) {
      reviewerId = 'tByhLcFSn6tAWLBt79GY';
    }

    isWaiting.value = true
    user.value = await fetchUser()
    school.value = await fetchSchool(user.value.school)
    subjects.value = await fetchSubjects()
    isWaiting.value = false
}
initialize()

</script>
<template>

    <div class="max-w-md mx-auto py-6">
        <div v-if="!isWaiting" class="space-y-6 mb-20 px-4">
            <!--  -->
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/reviewers" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">CAAP Lite</span>
                    <span class="block md:hidden">CAAP Lite</span>
                </div>
                <!-- FAQ Menu -->
                <a href="#">
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
                </a>
            </nav>
            <!--  -->
            <div class="flex flex-col">
                <div class="w-full flex justify-center z-10 -mb-2">
                    <div class="relative h-30 w-24">
                        <div class="flex-shrink-0 h-24 w-24 rounded-full bg-white">
                            <img class="object-cover h-24 w-24 border-4 border-white rounded-full" :src="user.photo" rel="preload" alt="">
                        </div>
                        <div class="absolute flex-shrink-0 rounded-full bg-white h-12 w-12 -bottom-2 -right-4">
                            <img class="object-cover border-4 h-12 w-12 border-white rounded-full" :src="school.logoUrl" rel="preload" alt="">
                        </div>
                    </div>
                </div>
                <div v-if="user" class="profile-card -mt-10">
                    <div class="flex flex-col items-center mt-8 pt-6 text-center px-3">
                        <h1 class="text-warm-gray-400 text-md font-semibold my-2 flex items-center space-x-1">
                            <span>{{ user.firstname }} {{ user.lastname }}</span>
                            <svg v-if="user.isLicensed && user.isVerified" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-4 h-4 text-blue-500"><path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                        </h1>
                        <div class="flex items-start space-x-1 text-warm-gray-400 text-sm font-normal">
                            <span>📚 {{ user.course }}</span>
                        </div>
                        <div class="flex items-start space-x-1 text-warm-gray-400 text-sm font-normal">
                            <span>🏫 {{ school.name }} ({{ school.shorthand }})</span>
                        </div>
                        <div class="flex flex-inline space-x-2 mt-4 -mb-3">
                            <!-- Milestones -->
                            
                            <div class="flex w-full justify-between space-x-2">
                                <div class="w-28 flex flex-col items-center">
                                <div :class="[(bestRecord && bestRecord.milestones.includes('passer')) ? 'bg-blue-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                    <PaperClipIcon :class="[(bestRecord && bestRecord.milestones.includes('passer')) ? 'text-blue-500' : 'text-true-gray-600', 'w-6 h-6']" />
                                </div>
                                </div>
                                <div class="w-28 flex flex-col items-center">
                                <div :class="[(bestRecord && bestRecord.milestones.includes('speedrunner')) ? 'bg-purple-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                    <LightningBoltIcon :class="[(bestRecord && bestRecord.milestones.includes('speedrunner')) ? 'text-purple-500' : 'text-true-gray-600', 'w-6 h-6']" />
                                </div>
                                </div>
                                <div class="w-28 flex flex-col items-center">
                                <div :class="[(bestRecord && bestRecord.milestones.includes('master')) ? 'bg-yellow-200' : 'bg-true-gray-700', 'mx-auto flex-grow-0 rounded-full w-12 h-12 p-3']">
                                    <FireIcon :class="[(bestRecord && bestRecord.milestones.includes('master')) ? 'text-yellow-500' : 'text-true-gray-600', 'w-6 h-6']" />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  -->

                    <div class="grid grid-cols-3 mt-8">
                        <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6">
                            <p class="text-sm font-medium leading-6 text-gray-400">High Score</p>
                            <p v-if="bestRecord" class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">{{ round(bestRecord.score, 1) }}</span>
                                <span class="text-sm text-warm-gray-400">%</span>
                            </p>
                            <p v-else class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">0</span>
                                <span class="text-sm text-warm-gray-400">%</span>
                            </p>
                        </div>
                        <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6 border-l">
                            <p class=" text-sm font-medium leading-6 text-gray-400">Best Time</p>
                            <p v-if="bestRecord" class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">{{ (bestRecord.time > 60) ? round(bestRecord.time/60, 1) : bestRecord.time }}</span>
                                <span v-if="bestRecord.time > 60" class="text-sm text-warm-gray-400">mins</span>
                                <span v-else="bestRecord.time > 60" class="text-sm text-warm-gray-400">secs</span>
                            </p>
                            <p v-else class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">0</span>
                                <span class="text-sm text-warm-gray-400">secs</span>
                            </p>
                        </div>
                        <!-- <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6 border-l">
                            <p class="text-sm font-medium leading-6 text-gray-400">Most Streak</p>
                            <p class="mt-2 flex items-baseline gap-x-2">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">15</span>
                                <span class="text-sm text-gray-400">days</span>
                            </p>
                        </div> -->
                        <div class="border-t border-true-gray-700 py-3 px-4 sm:px-6 border-l">
                            <p class=" text-sm font-medium leading-6 text-gray-400">Skillpoints</p>
                            <p v-if="user.skillpoints > 1000000" class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                    {{  round(user.skillpoints/1000000, 1) }}
                                </span>
                                <span class="text-sm text-warm-gray-400">M</span>
                            </p>
                            <p v-else-if="user.skillpoints > 1000" class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                    {{  round(user.skillpoints/1000, 1) }}
                                </span>
                                <span class="text-sm text-warm-gray-400">k</span>
                            </p>
                            <p v-else class="flex items-baseline gap-x-1">
                                <span class="text-2xl sm:text-3xl font-semibold tracking-tight text-white">
                                    {{  user.skillpoints }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- practice -->
            <div class="card">
                <!-- <div class="flex justify-between">
                    <h1>🚀 Master the topics!</h1>
                    <div class="relative">
                        <span class="absolute bottom-11 -right-0 animate-pulse items-center font-semibold rounded-full bg-yellow-400 bg-opacity-10 px-2 py-1 text-xs text-yellow-400 ring-1 ring-inset ring-yellow-400 ring-opacity-30">
                            UPDATED
                        </span>
                    </div>
                </div> -->
                <h1>🚀 Master the topics!</h1>
                <p>
                    Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT renewal exam. Browse related subtopics that test your knowledge level
                    for Air Law and Human Performance.
                </p>
                <router-link to="/lite/subtopics">
                    <button type="button" class="btn">
                        <span>Begin Practice</span>
                        <ArrowRightIcon class="w-4 h-4 ml-1" />
                    </button>
                </router-link>
            </div>
            <!-- mock exam -->
            <div class="card">
                <h1>✏️ Perfect the exam!</h1>
                <p>
                    Test your knowledge by doing a CAAP licensure mock exam. This is built with time and score criteria that exactly replicates the real one.
                </p>
                <!-- this will open a modal before user can start mock exam -->
                <button @click="openPreExamModal()" type="button" class="btn">
                    <span>Start Mock Exam</span>
                    <ArrowRightIcon class="w-4 h-4 ml-1" />
                </button>
            </div>
            <!-- progress -->
            <div class="card">
                <h1>🔎 Review your progress!</h1>
                <p>
                    See the extensive record of your learning progress so far by looking into all your Mock Exam results. Easily identify which areas you need to practice more.
                </p>
                <router-link to="/lite/history">
                    <button type="button" class="btn">
                        <span>View Exam History</span>
                        <ArrowRightIcon class="w-4 h-4 ml-1" />
                    </button>
                </router-link>
            </div>
            <!-- achievements -->
            <div class="card">
                <h1>🏅 Collect these badges!</h1>
                <p>
                    View all Achievements that can be obtained by reaching different Milestones in our Mock Exams. Boost your exam skills and collect big Skillpoints in the process.
                </p>
                <!-- <router-link to="/lite/achievements"> -->
                    <!-- <button type="button" class="btn">
                        <span>See Achievements List</span>
                        <ArrowRightIcon class="w-4 h-4 ml-1" />
                    </button> -->
                    <button type="button" class="mt-4 cursor-default rounded-md bg-warm-gray-700 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm w-full flex items-center justify-center">
                        <span>Coming Soon</span>
                        <!-- <ArrowRightIcon class="w-4 h-4 ml-1" /> -->
                    </button>
                <!-- </router-link> -->
            </div>
        </div>
        <div v-else>
            <div class="flex justify-center mt-8">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>
    </div>
    
    <PreExamModal :isOpen="isPreExamModalOpen" :subject="selectedSubject" @closeModal="closePreExamModal()" />
    
</template>

<style scoped>
.btn{
    @apply mt-4 rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm hover:bg-yellow-500 w-full flex items-center justify-center
}
h1{
    @apply font-bold text-warm-gray-200 mt-2
}
.card p{
    @apply text-sm text-warm-gray-400 my-2
}
.card{
    @apply text-base rounded-2xl px-6 py-6 bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}
.profile-card{
    @apply text-base rounded-2xl bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}
</style>
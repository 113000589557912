import firebase from "@/firebase/config";
import router from '../router';

const collectionName = 'usersLoggedIn';
const localStorageSetName = 'udid';
let unsubscribe = null;

const listenForNewLogin = (uid) => {
    const db = firebase.firestore();
    if (!unsubscribe) {
        console.log('start listening..')
        unsubscribe = db.collection(collectionName).doc(uid)
            .onSnapshot((doc) => {
                // check local storage udid and compare it to the the saved data in firestore.
                // if not logout user.
                if (localStorage.getItem(localStorageSetName) != doc.data().userDeviceID) {
                    alert('You will be logged out because someone logged in using your account in another device.\n\nDevice: ' + doc.data().deviceInfo)
                    logOutUser(true);
                }

            });
    }

}

export const logOutUser = async(isForceLogout) => {
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;


    if (unsubscribe) {
        console.log('stop listening..')
        unsubscribe();
    }

    if (!isForceLogout) {
        // remove the user from the collection
        await db.collection(collectionName).doc(user.uid).delete();
    }
    // remove the udid from the localstorage
    localStorage.removeItem(localStorageSetName);
    // signout the user
    firebase.auth().signOut().then(() => router.replace('/login'));

}

export const limitedDeviceCheck = (user) => {
    const db = firebase.firestore();
    // 1 Login Policy: for this changes I updated the firestore rules also
    // if the user logged in
    if (user) {

        // set the user device logged in session
        // check if udid is not yet set
        if (!localStorage.getItem(localStorageSetName)) {
            // if not yet then meaning it's the first time user's login
            const data = {
                userDeviceID: Date.now(),
                deviceInfo: navigator.userAgent
            };

            db.collection(collectionName).doc(user.uid).set(data)
                .then(() => {
                    localStorage.setItem(localStorageSetName, data.userDeviceID) // set local storage
                    listenForNewLogin(user.uid)
                })
                .catch((err) => {
                    console.error("Error setting user login session: ", err);
                });

        } else {
            // if udid in local already set meaning the user already logged in
            // get the usersLoggedIn information from the firestore
            db.collection(collectionName).doc(user.uid).get()
                .then((doc) => {
                    if (doc.exists) {
                        // if the users already exist from the collection,
                        listenForNewLogin(user.uid);
                    } else {
                        // If the user is found in the collection, let the user log in again.
                        // Logout the user
                        alert('Invalid Session! Please login again.')
                        logOutUser(true)
                    }
                }).catch((err) => {
                    console.log("Error getting document:", err);
                });
        }

    }
}
<template>
    <!-- Mobile -->
    <TransitionRoot as="template" :show="isMobileMenuOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="this.closeMobileMenu()">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full dark:bg-true-gray-700 bg-white focus:outline-none">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-4">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="this.closeMobileMenu()"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>

            <!-- Nav Section -->
            <div class="pt-5 pb-4">
              <div class="flex-shrink-0 flex items-center px-4">
                <Logo class="h-8 w-auto dark:text-true-gray-400 text-warm-gray-800" />
              </div>
              <nav aria-label="Sidebar" class="mt-5">
                <div class="px-2 space-y-1">
                  <router-link
                    v-for="item in navigation"
                    :key="item.name"
                    :to="item.href"
                    @click="this.closeMobileMenu()"
                    :class="[
                      item.name == $route.name
                        ? 'bg-warm-gray-50 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-400'
                        : 'hover:bg-warm-gray-50 hover:text-warm-gray-900 dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400 ',
                      'group p-2 rounded-md flex items-center text-base font-medium dark:text-true-gray-400 text-warm-gray-600',
                    ]"
                  >
                    <div v-if="item.new" class="absolute right-6 flex h-3 w-3">
                      <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                      <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                    </div>
                    <component
                      :is="item.icon"
                      class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500 dark:group-hover:text-true-gray-400"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </router-link>

                  <!-- Season 2 Menu Option -->
                  <!-- <router-link 
                    to="/season2"
                    @click="this.closeMobileMenu()"
                    :class="[
                      'Season 2' == $route.name
                      ? 'bg-warm-gray-50 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-400'
                        : 'hover:bg-warm-gray-50 hover:text-warm-gray-900 dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400 ',
                      'group p-2 rounded-md flex items-center text-base font-medium dark:text-true-gray-400 text-warm-gray-600',
                    ]">
                    <div class="absolute right-6 flex h-3 w-3">
                      <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                      <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                    </div>
                    <svg class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500 dark:group-hover:text-true-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                    </svg>  
                    Season 2
                  </router-link> -->

                  <!-- Dark/Light Mode Toggle -->
                  <button
                    @click="(this.closeMobileMenu()), this.toggleDarkMode()"
                    class="w-full hover:bg-warm-gray-50 hover:text-warm-gray-900 dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400 group p-2 rounded-md flex items-center text-base font-medium dark:text-true-gray-400 text-warm-gray-600"
                  >
                    <SunIcon
                      v-if="isDarkMode"
                      class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500 dark:group-hover:text-true-gray-400"
                      aria-hidden="true"
                    />
                    <MoonIcon
                      v-else
                      class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500 dark:group-hover:text-true-gray-400"
                      aria-hidden="true"
                    />
                    <span v-if="isDarkMode">Light Mode</span>
                    <span v-else>Dark Mode</span>
                  </button>

                  <!-- Logout -->
                  <router-link
                    to="/logout"
                    @click="this.closeMobileMenu()"
                    class="hover:bg-warm-gray-50 hover:text-warm-gray-900 dark:hover:bg-true-gray-600 dark:hover:text-true-gray-400 group p-2 rounded-md flex items-center text-base font-medium dark:text-true-gray-400 text-warm-gray-600"
                  >
                    <LogoutIcon
                      class="mr-4 h-6 w-6 text-warm-gray-400 group-hover:text-warm-gray-500 dark:group-hover:text-true-gray-400"
                      aria-hidden="true"
                    />
                    Logout
                  </router-link>
                </div>
              </nav>
              <footer class="text-warm-gray-400 text-xs p-4">
                Version 0.2.5
              </footer>
            </div>

            <!-- Account Section -->
            <div class="flex-shrink-0 flex border-t dark:border-true-gray-800 border-warm-gray-200 p-4">
              <router-link 
                to="/account"
                @click="this.closeMobileMenu()"
                href="#" class="flex-shrink-0 group block"
              >
                <div class="flex items-center">
                  <div>
                    <img v-if="user.photo"
                      class="block mx-auto h-10 w-10 rounded-full object-cover"
                      :src="user.photo"
                      alt=""
                    />
                    <DefaultUser v-else class="inline-block mx-auto h-10 w-10"/>
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-warm-gray-700 dark:text-true-gray-200 group-hover:text-warm-gray-900 dark:group-hover:text-true-gray-400">
                      {{ user.email }}
                    </p>
                    <p class="text-sm font-medium text-warm-gray-500 dark:text-true-gray-400 group-hover:text-warm-gray-700 dark:group-hover:text-warm-gray-500">
                      Account Settings
                    </p>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
</template>

<script>

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  MenuIcon, XIcon, 
  HomeIcon, BookmarkAltIcon, PresentationChartLineIcon, LogoutIcon,
  SunIcon, MoonIcon, 
} from "@heroicons/vue/outline";

import Logo from "@/components/Logo";
import DefaultUser from "@/components/DefaultUser";

export default {
  props: [
    "isMobileMenuOpen", "isDarkMode", 
    "navigation", "user"
  ],
  components: {
    MenuIcon, XIcon, // Main Menu Toggle
    HomeIcon, BookmarkAltIcon, PresentationChartLineIcon, LogoutIcon, // Main Menu Items
    SunIcon, MoonIcon, // Dark/Light Mode Toggle
    Dialog, DialogOverlay, TransitionChild, TransitionRoot, // Headless UI for Slide Over Menu
    Logo, DefaultUser
  },
  methods: {
    
    closeMobileMenu() {
      this.$emit("closeMobileMenu");
    },
    toggleDarkMode(){
        this.$emit("toggleDarkMode");
    }
  },
};
</script>
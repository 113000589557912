/**
 * sort the users via skillpoints for the leaderboard
 */
function sortLeaderboard(users){
    let sorted_users = users.sort(function (a, b) {
        if( a.skillpoints === b.skillpoints){ return a.date_started - b.date_started } // if they have equal points, give priority to people who signed up earlier
        else{ return b.skillpoints - a.skillpoints; } // sort via skillpoints
    });
    sorted_users.forEach( (user, i) => { user.rank = i+1; });
    return sorted_users
}

/**
 * NOTE: Connects To Firebase
 * fetch the leaderboard
 */
function fetchLeaderboard(db){
    return db.collection('s2leaderboard').orderBy("date_created", "asc").get()
      .then((querySnapshot) => {
        var leaderboard = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          data.id = doc.id
          leaderboard.push(data)
        });
        return leaderboard;
      })
      .catch((error) => {
        console.error("Error getting Season 2 Leaderboard data: ", error);
        throw "Error getting Season 2 Leaderboard data"
      })
}

function initializeUserToS2LeaderboardV2(user, db){

  let lboard_data = {
    skillpoints: 0,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    date_created: user.date_created,
    school: user.school,
    course: user.course,
    photo: user.photo,
    degree: user.degree,
  }

  return db.collection("s2leaderboard").doc(user.id).set(lboard_data)
      .then(() => {
          console.log("User data has been updated!");
          return { success: true }
      })
      .catch((error) => {
          console.error("Error updating user data: ", error);
          throw new functions.https.HttpsError(
              'not found',
              'Error updating user data'
          )
      });
}

async function initializeUserToS2Leaderboard(user, initUserToS2Leaderboard){
  // Initialize the user in the leaderboard
  let lboard_data = {
    skillpoints: 0,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    date_created: user.date_created,
    school: user.school,
    course: user.course,
    photo: user.photo,
    degree: user.degree,
  }

  console.log('NEW S2 LEADERBOARD DATA: ', lboard_data)
  let res = await initUserToS2Leaderboard(lboard_data)

  if(res.data.success){
    return true
  }else{
    console.error("[functions > initUserToS2Leaderboard] => User update to s2 leaderboard was unsuccessful!")
    alert("User update to s2 leaderboard was unsuccessful!")
  }
}


function adjustLeaderboardForUser(user, leaderboard){
    // console.log(leaderboard)
    // console.log(user.id)
    let i = leaderboard.findIndex( e => e.id == user.id )
    if( i < 0){
      // User is not in the leaderboard. Put him at the last part.
      // console.log("User is in the leaderboard")
      leaderboard.push(user)
      return leaderboard
    }else{
      // User is in the leaderboard. Do nothing
      // console.log("User is in the leaderboard")
      return leaderboard
    }
}

exports.leaderboardService = {
    fetchLeaderboard, sortLeaderboard, adjustLeaderboardForUser, initializeUserToS2Leaderboard, initializeUserToS2LeaderboardV2
}
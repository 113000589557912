<template>
  <LoadingScreen v-if="isLoading" />
  <div v-else class="dark:bg-true-gray-900 bg-gray-50 min-h-screen w-full py-8">
    <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-8">
      
      <!-- First and Last Name -->
      <div v-if="this.slide_index == 0" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Welcome To Skilltech</span>
        <!-- <img src="https://i.pinimg.com/originals/99/6e/47/996e47166a6ffe11ff6d4b23ecdd7cea.jpg" class="rounded-xl w-84 my-4" alt=""> -->
        <h1 class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
                <span class="block xl:inline">Let's jump start your</span>
                {{ ' ' }}
                <span class="block dark:text-yellow-200 text-yellow-400 xl:inline">Aviation career!</span>
              </h1>
        <p class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          Here at Skilltech, we believe proper guidance after you graduate is the key to your future success. Let's get to know each other, tell us your name!
        </p>
        
        <div class="my-8 text-gray-800">
          <label for="firstname" class="block text-xl dark:text-gray-400 text-gray-600">My first name is...</label>
          <div class="mt-2">
            <input v-model="this.user.firstname" type="firstname" name="firstname" id="firstname" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4"  />
          </div>
          <label for="lastname" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">My last name is...</label>
          <div class="mt-2">
            <input v-model="this.user.lastname" type="lastname" name="lastname" id="lastname" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4"  />
          </div>

          <p class="mt-6 text-red-400 text-sm">
            <strong class="text-sm mr-2 text-red-900 bg-red-500 px-2 py-0.5">IMPORTANT</strong>Please make sure that your name is correct, as you <strong>can't change</strong> this later on within the App.
          </p>
        </div>
        <button :disabled="!readyToProceedToTwo" @click="this.nextStep(1)" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
          Next
        </button>
      </div>

      <!-- Academic Info -->
      <div v-if="this.slide_index == 1" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Welcome To Skilltech</span>
        <!-- <img src="https://cdn.dribbble.com/users/508588/screenshots/7722239/media/7dca70b78e345b10a17bd3e34d22a9d9.jpg?compress=1&resize=1200x900&vertical=top" class="rounded-xl w-84 my-4" alt=""> -->
        <h1 class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
                <span class="inline">{{user.firstname}}, are you a</span>{{ ' ' }}
                <span class="dark:text-yellow-200 text-yellow-400">student</span>{{ ' ' }}<span class="">right now?</span>
              </h1>
        <p class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          Regardless if you're still a student, Skilltech is the right platform for you to start establishing your professional portfolio and we're here to help you out! 
        </p>
        <div class="flex items-center h-5 my-8">
          <input v-model="isStudent" id="isStudent" name="isStudent" type="checkbox" class="cursor-pointer border-2 focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" />
          <p class="ml-3 text-xl dark:text-gray-400 text-gray-600 leading-8">Yes, I'm still a student</p>
        </div>
        
        <div class="my-8 text-gray-800">
          
          <label for="course" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">{{(!isStudent) ? "I studied..." : "I'm currently studying..."}}</label>
          <div class="mt-2">
            <select v-model="this.user.course" id="course" name="course" autocomplete="course" class="mt-1 block w-full bg-white border border-gray-200 border-2 rounded-md shadow-sm py-4 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option>Aircraft Maintenance Technology (AMT)</option>
              <option>Aircraft Avionics/Electrical (AVT)</option>
              <option>Aircraft/Aerospace Engineering (AE)</option>
            </select>
          </div>

          <label for="school" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">over at...</label>
          <div class="mt-2">
            <select v-model="this.user.school" id="school" name="school" autocomplete="school" class="mt-1 block w-full bg-white border border-gray-200 border-2 rounded-md shadow-sm py-4 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option v-for="school in schools" :key="school.id" :value="school.id" :selected="(school.id == user.school) ? 'true' : 'false'">{{school.name}}</option>
            </select>
          </div>
          
        </div>
        <button :disabled="!readyToProceedToThree" @click="this.nextStep(2)" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
          Next
        </button>
      </div>

      <!-- TOC -->
      <div v-if="this.slide_index == 2" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Welcome To Skilltech</span>
        <h1 class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
          <span class="">Skilltech will be tailored to suit your </span><span class="dark:text-yellow-200 text-yellow-400">career path.</span>
        </h1>
        <p class="mt-6 text-xl dark:text-gray-400 text-gray-600 leading-8">
          Before you finally proceed, you must agree to Skilltech's Terms and Conditions which can be found <a href="/terms" class="text-indigo-500 hover:text-indigo-400" target="_blank">here</a> simply by checking the box below. 
          Lastly, we need to know we can reach out to you in case of important updates or concerns.
        </p>
        <div >
          <label for="phone" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">My phone number is:</label>
          <div class="mt-4">
            <input v-model="user.phone" type="phone" name="phone" id="phone" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input 11 Digit PH Phone Number (i.e. 09*********)" />
          </div>
        </div>

        <div class="flex items-center h-5 my-8">
          <input v-model="hasAcceptedTerms" id="isStudent" name="isStudent" type="checkbox" class="border-2 cursor-pointer focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" />
          <p class="ml-3 text-xl dark:text-gray-400 text-gray-600">I Accept Skilltech's Terms and Conditions</p>
        </div>      
        
        <button :disabled="!readyToComplete" @click="prepareToOnboard()" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
          Complete
        </button>
      </div>

      <!-- Final -->
      <div v-if="this.slide_index == 3" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Welcome To Skilltech</span>
        <h1 v-if="readyToExit" class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
          <span class="dark:text-yellow-200 text-yellow-400">{{user.firstname}}</span><span>, we're glad to have you onboard! 🎉</span>
        </h1>
        <h1 v-else class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
          <span class="block xl:inline">Awesome! We're a <span class="dark:text-yellow-200 text-yellow-400">few seconds</span> away now.</span>
        </h1>
        <p v-if="!readyToExit" class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          Please sit tight while we initialize your account. If there was a mistake, you can always modify your personal and academic info within your Account Settings. 
        </p>
        <p v-else class="mt-6 text-xl dark:text-gray-400 text-gray-600 leading-8">
          You're now ready to use Skilltech! You can now proceed to the Dashboard by clicking the button below or you'll automatically get redirected in a few seconds. Feel free to browse our Reviewers!
        </p>

        <div v-if="!readyToExit" class="relative pt-1 mt-8">
          <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
              <div
              :style="`width: ${progress}%`"
              class="transition-all duration-500 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"
              ></div>
          </div>
        </div>
        
        <div v-if="!readyToExit" class="bg-yellow-50 overflow-hidden rounded-lg text-xl text-warm-gray-500 text-left mt-8 max-w-full">
          <div class="px-4 py-5 sm:py-8 sm:px-9 flex space-x-2 items-start">
            <div>
              <LightBulbIcon class="text-yellow-400 w-5 h-5" />
            </div>
            <p class="text-yellow-700">
              <strong>Tip:</strong> {{tips[current]}}
            </p>
          </div>
        </div>
        
        <button v-if="readyToExit" :disabled="!readyToExit" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
          {{(readyToExit) ? 'Go to Dashboard' : 'Please wait...' }}
        </button>
      </div>

    </div>
    </div>
  
  
</template>

<script>

import firebase from "@/firebase/config";
// import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import moment from "moment";
const db = firebase.firestore();

import { subscribe } from '@/utils/convertkit'

import LoadingScreen from "@/components/appshell/LoadingScreen"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"
import { LightBulbIcon } from "@heroicons/vue/solid";
import { homeData } from "@/data/home.data"

export default {
  components: { LoadingScreen, LightBulbIcon },
  data() {
    return {
      // user data
      user: {
        email: '',
        date_created: '',
        phone: null,
        //
        firstname: '',
        lastname: '',
        //
        course: '',
        school: '',
        level: '',
        //
        endorser: '',
        license: {
          exam_date: null,
          test_results: null,
          isLicensed: false,
          isVerified: false,
          isRevoked: false,
        }
      },
      // ui data
      isLoading: false,
      isStudent: true,
      slide_index: 0,
      hasAcceptedTerms: false,
      progress: 0,
      readyToExit: false,
      tips: homeData.tips,
      current: 0,
      moment: moment,
      tmpFile: null
    };
  },
  computed: {
    readyToProceedToTwo() { 
      if( this.user.firstname == '' ) return
      if( this.user.firstname.length < 2 ) return
      if( this.user.lastname == '' ) return
      if( this.user.lastname.length < 2 ) return
      return true
    },
    readyToProceedToThree() { 
      if( this.user.course == '' ) return
      if( this.user.school == '' ) return
      return true
    },
    // readyToProceedToFour(){
    //   if(this.user.license.exam_date == null ) return // exam_date must always have a value
    //   if(this.user.license.isLicensed){
    //     // If user already has CAAP license
    //     if(moment(this.user.license.exam_date).isAfter(moment())) return // date must be today or before today
    //     if( this.user.license.test_results == null ) return
    //   }else{
    //     // If user doesn't have a CAAP license yet
    //     if(moment(this.user.license.exam_date).isBefore(moment(-1,"days"))) return // date must be today or after today
    //     if( this.user.phone == null ) return
    //     if( this.user.phone.length < 11 ) return
    //     if( this.user.phone.length > 11 ) return
    //     if(!/^09(\d{9})$/.test(this.user.phone)) return // i.e. 09062738475
    //   }
    //   return true
    // },
    readyToComplete(){
      if(!this.hasAcceptedTerms) return
      // Make Endorser Code optional
      // if( this.user.endorser == '' ) return
      // if( this.user.endorser.length < 8 ) return
      // if( this.user.endorser.length > 9 ) return

      // phone number should be filled up
      if( this.user.phone == null ) return
      if( this.user.phone.length < 11 ) return
      if( this.user.phone.length > 11 ) return
      if(!/^09(\d{9})$/.test(this.user.phone)) return // i.e. 09062738475

      return true
    },
  },

  methods: {
    someHandler(event){
        console.log("User updared the file")
        // tempFileName
        // console.log('Selected file: ' + event.target.files.item(0).name);
        console.log('Selected file: ' + event.target.files.item(0).size);
        // console.log('Selected file: ' + event.target.files.item(0).type);
        
        var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
        
        console.log(`File size: ${filesize}MB`)
        
        if(filesize > 2){
            alert("File size of 2MB exceeded")
            // this.emptyFile()
            this.license.test_results = null 
        }else{
            this.user.license.test_results = event.target.files.item(0).name
            this.tmpFile = event.target.files.item(0)
            // console.log(event)
            console.log(this.tmpFile)
        }
    },
    clearEverything(){
      this.user.phone = null
      this.user.license.exam_date = null
      this.user.license.test_results = null 
      console.log("Clearing everything...")
    },
    nextStep(index){
      this.slide_index = index;
      document.querySelector("body").scrollTop;
    },
    
    async prepareToOnboard(){
      // let isValidEndorserCode = await userService.isValidEndorserCode(db, this.user.endorser)
      // if(!isValidEndorserCode){
      //   alert("Endorser code not found")
      // }else{
        this.user.level = (this.isStudent) ? 'Undergraduate' : '';
        // console.log("user: ", this.user)
        
        this.nextStep(3) // navigate to the final slide
        this.onboardUser(); // run loaders and tips
      // }    
    },
    
    onboardUser(){
            
      var current_progress = 0;
      var step = 0.05; // the smaller this is the slower the progress bar

      // convert the exam_date into moment milliseconds  
      this.user.license.exam_date =  this.moment(this.user.license.exam_date).format("x");

      // run interval for progress bar
      const interval = setInterval( () => {
          current_progress += step;
          this.progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
          if (this.progress >= 100){
              clearInterval(interval);
          }else if(this.progress >= 20) {
              step = 0.1
          }
      }, 100);

      // run interval for tips
      const tipsInterval = setInterval(() => {
        this.current = (this.current < this.tips.length-1) ? this.current+1 : 0;
        // console.log(this.current)
      }, 4000);


      if(this.user.license.isLicensed){
        this.uploadImage(interval, tipsInterval)
      }else{
        this.updateData(interval, tipsInterval)
      }

     
    },
    
    updateData(interval, tipsInterval){
       

      // @TODO: CONVERT THIS CALLBACK HELL TO PROMISE ALL
      const updateUserData = firebase.functions().httpsCallable('updateUserData');
      // console.log("Updating user data.")
      // console.log("User data: ", this.user)
      updateUserData(this.user)
        .then((res) => {
          if(res.data.success){
            // this.user.date_created = firebase.auth().currentUser.metadata.a;

            // Initialize the user in the leaderboard
            let lboard_data = {
              skillpoints: 0,
              firstname: this.user.firstname,
              lastname: this.user.lastname,
              email: this.user.email,
              date_created: this.user.date_created,
              school: this.user.school,
            //   degree: this.user.degree,
              course: this.user.course,
            }

            const initUserToLeaderboard = firebase.functions().httpsCallable('initUserToLeaderboard');
            // console.log("Initializing user to leaderboard.")
            // console.log("Leaderboard data: ", lboard_data)
            initUserToLeaderboard(lboard_data)
                .then((res) => {
                  if(res.data.success){

                    // User has been updated. Update the onboarding flag
                    const completeOnboarding = firebase.functions().httpsCallable('completeOnboarding');
                    // console.log("Completing onboarding by setting user claims.")
                    completeOnboarding()
                      .then((res) => {
                        if(res.data.success){

                          // subscribe user to convert kit
                          console.log("Subscribing to Newsletter")
                          // subscribe({ 
                          //   email: this.user.email,
                          //   firstname: this.user.firstname,
                          //  }, "signup")
                          //   .then( res => {

                          //     console.log(res)
                              
                              // console.log("Onboarding complete: ", res)
                              // clear timers
                              clearInterval(tipsInterval); 
                              clearInterval(interval);
                              
                              // enable buttons and everything
                              this.progress = 100; // set progress to 100
                              this.readyToExit = true;
                              
                              // redirect to dashboard in 5 seconds
                              setInterval(() => {
                                // console.log("Redirecting to Dashboard now...")
                                location.reload()
                              }, 5000);

                          //   })
                          //   .catch( err => {
                          //     console.error("An error occurred during subscription.", err)
                          //   })

                        }else{
                          console.error("[functions > completeOnboarding] => User claim update to 'onboarded' was unsuccessful!")
                          alert("User claim update to 'onboarded' was unsuccessful!")
                        }
                      })
                      .catch(error => {
                        console.error("[functions > completeOnboarding] => ",error)
                        alert("An Error occurred when trying to onboard user to leaderboard: ", error)
                      })
                      /* end of completeOnboarding */

                  }else{
                    console.error("[functions > initUserToLeaderboard] => User update to leaderboard was unsuccessful!")
                    alert("User update to leaderboard was unsuccessful!")
                  }
                })
                .catch(error => {
                  console.error("[functions > initUserToLeaderboard] => ",error)
                  alert("An Error occurred when trying to onboard user to leaderboard: ", error)
                })
                /* end of initUserToLeaderboard */

          }else{
            console.error("[functions > updateUserData] => User update was unsuccessful!")
            alert("User update was unsuccessful!")
          }
        })
        .catch(error => {
          console.error("[functions > updateUserData] => ",error)
          alert("An Error occurred when trying to onboard user: ", error)
        })
        /* end of updateUserData */

    },

    uploadImage(interval, tipsInterval){
      
      var storageRef = firebase.storage().ref();
      var fileExt = this.user.license.test_results.split('.').pop();
      var userPicImagesRef = storageRef.child(`testresults/${firebase.auth().currentUser.uid}.${fileExt}`);
      // const selectedFile = document.getElementById('test_results').files[0];
      const selectedFile = this.tmpFile

      console.log('selectedFile: ', selectedFile)
      
      if(selectedFile){
        // 'file' comes from the Blob or File API
        userPicImagesRef.put(selectedFile).then((snapshot) => {
          snapshot.ref.getDownloadURL().then( (downloadURL) => {
            console.log("User image updated");
            console.log("File available at", downloadURL);
            
            this.user.license.test_results = downloadURL
            this.updateData(interval, tipsInterval)

          })
        })
      }else{
        console.error("No file added");
        alert("An error occurred while uploading your test results")
      }      
      
    }
  },

  async created(){
    this.isLoading = true

    /* CLAIMS */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(claims.isOnboarded) this.$router.push({ path: '/' })  // reroute onboarded users to Dashboard  

    this.user.email = firebase.auth().currentUser.email;
    this.user.date_created = firebase.auth().currentUser.metadata.a; // get the user's creation date

    /* SCHOOLS */
    this.schools = await schoolService.fetchAllSchools(firebase.firestore())
    this.schools = schoolService.sortSchoolsAlpha(this.schools)
    // console.log("this.schools", this.schools)

    // If there is a referrer code, populate it in the endorser code by default
    if(this.$route.query.referrer){
      this.user.endorser = this.$route.query.referrer
    }

    this.isLoading = false

  },
};
</script>
<script setup>
import { computed, onMounted } from "vue"
import ROUTER from "@/router"
import { ChevronDoubleLeftIcon } from "@heroicons/vue/solid"
import SpellingTips from "../components/tips/SpellingTips.vue"
import WordMeaningTips from "../components/tips/WordMeaningTips.vue"
import WordRelationshipTips from "../components/tips/WordRelationshipTips.vue"
import ComprehensionTips from "../components/tips/ComprehensionTips.vue"
import CriticalThinkingTips from "../components/tips/CriticalThinkingTips.vue"
import ComputationTips from "../components/tips/ComputationTips.vue"
import EstimationTips from "../components/tips/EstimationTips.vue"
import NumericalReasoningTips from "../components/tips/NumericalReasoningTips.vue"
import DataInterpretationTips from "../components/tips/DataInterpretationTips.vue"
import AbstractAbilityTips from "../components/tips/AbstractAbilityTips.vue"
import DiagrammaticAbilityTips from "../components/tips/DiagrammaticAbilityTips.vue"
import ConcentrationTips from "../components/tips/ConcentrationTips.vue"
import SpatialAbilityTips from "../components/tips/SpatialAbilityTips.vue"
import MechanicalReasoningTips from "../components/tips/MechanicalReasoningTips.vue"
import FaultDiagnosisTips from "../components/tips/FaultDiagnosisTips.vue"
import DataCheckingTips from "../components/tips/DataCheckingTips.vue"
import AviationBasicsTips from "../components/tips/AviationBasicsTips.vue"

const subjectId = computed( () => ROUTER.currentRoute.value.query.subjectId )
const subjectName = computed( () => ROUTER.currentRoute.value.query.subjectName )
const subtopicId = computed( () => ROUTER.currentRoute.value.query.subtopicId )
const subtopicName = computed( () => ROUTER.currentRoute.value.query.subtopicName )

const goBack = () => {
    ROUTER.push({ path: `/mtp/subtopics`, query: { subjectId: subjectId.value, subjectName: subjectName.value } })
}

onMounted(()=>{
  console.log('Load to the top you!!')
  scrollBackToTop('target-id')
})

const scrollBackToTop = (elementID) => {
    const element = document.getElementById(elementID);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

</script>
<template>
  <div class="max-w-3xl mx-auto">

    <div id="target-id" class="space-y-6 mb-20 px-4 py-6">
      <nav class="flex justify-between items-center" aria-label="Breadcrumb">
          <div class="flex items-center">
            <button @click="goBack()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
              <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
            </button>
          </div>
          <div class="text-sm font-medium text-warm-gray-500">
              <span class="hidden md:block">{{ subtopicName }}</span>
              <span class="block md:hidden">{{ subtopicName }}</span>
          </div>
          <a href="#">
              <!-- <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/> -->
          </a>
      </nav>
      <div>
        <h1 class="ml-1 text-lg font-medium dark:text-true-gray-200 text-true-gray-800">
          Tips For Answering Subtopic: {{ subtopicName }}
        </h1>
        <SpellingTips v-if="subtopicId == 'mtp-spelling'" />
        <WordMeaningTips v-if="subtopicId == 'mtp-word-meaning'" />
        <WordRelationshipTips v-if="subtopicId == 'mtp-word-relationship'" />
        <ComprehensionTips v-if="subtopicId == 'mtp-comprehension'" />
        <CriticalThinkingTips v-if="subtopicId == 'mtp-critical-thinking'" />
        <ComputationTips v-if="subtopicId == 'mtp-computation'" />
        <EstimationTips v-if="subtopicId == 'mtp-estimation'" />
        <NumericalReasoningTips v-if="subtopicId == 'mtp-numerical-reasoning'" />
        <DataInterpretationTips v-if="subtopicId == 'mtp-data-interpretation'" />
        <AbstractAbilityTips v-if="subtopicId == 'mtp-abstract-ability'" />
        <DiagrammaticAbilityTips v-if="subtopicId == 'mtp-diagrammatic-ability'" />
        <ConcentrationTips v-if="subtopicId == 'mtp-concentration'" />
        <SpatialAbilityTips v-if="subtopicId == 'mtp-spatial-ability'" />
        <MechanicalReasoningTips v-if="subtopicId == 'mtp-mechanical-reasoning'" />
        <FaultDiagnosisTips v-if="subtopicId == 'mtp-fault-diagnosis'" />
        <DataCheckingTips v-if="subtopicId == 'mtp-data-checking'" />
        <AviationBasicsTips v-if="subtopicId == 'mtp-aviation-basics'" />
        
        
        <button @click="goBack()" class="btn">
          All good. Let's practice!
        </button>
      </div>
    </div>
  </div>
</template>
<style>
.btn{
  @apply mt-4 rounded-md bg-yellow-400 px-3 py-2 text-sm font-semibold text-warm-gray-900 shadow-sm hover:bg-yellow-500 w-full flex items-center justify-center
}
</style>
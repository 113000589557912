
/**
 * If the user achieved "passer" on all subjects under AVT
 * returns 
 *  result { 
 *      isAchieved: BOOL,   // If the user achieved this achievement
 *      progress: INT,      // The progress so far (i.e. `progress` out of `total`)
 *      total: INT          // Size of the AVT subjects array
 *  }
 */
function evaluateAchievementA1(subjects, user){
    
    // console.log('%c [A1C]: If the user achieved "passer" on all subjects under AMT', 'background: #222; color: #bada55');
    // console.log("[A1C] => subjects: ", subjects)
    // console.log("[A1C] => subjects_under_avt: ", subjects.filter(e => e.course.includes("avt")) )
    // console.log("[A1C] => user: ", user)

    var progress = 0;
    var avt_subjects = subjects.filter(e => e.course.includes("avt"));
    avt_subjects.forEach( subject => {  // loop through all of the AVT subjects
        
        // Check if the user already has this subject in its records. 
        // If yes, the array will be thrown in filterred array: _f
        var _f = user.subjects.filter(e => e.id == subject.id );

        // If the user already has a record for this subject, 
        // check the milestones if they have "passer" in it.
        // If user has 'passer' in this subject, increment progress
        if(_f.length > 0 && _f[0].milestones.includes('passer')){
            progress++;   
        }
    });

    // Formulate the result data and return it
    var result = {
        id:'full-passer-avt',
        name: 'The Surefire Licensee AVT',
        course: 'avt',
        description: 'Achieve Passer on all AVT CAAP Knowledge Test subjects.',
        isAchieved: (progress == avt_subjects.length) ? true : false,
        progress: progress,
        total: avt_subjects.length,
        percent: (progress/avt_subjects.length)*100,
        skillpoints: 2500,
        
    };
    // console.log("[A1C] result => ", result)
    // console.log('%c END OF A1_CHECK', 'background: #222; color: #bada55');
    return result;
}

/**
 * If the user achieved "passer" on all subjects under AMT
 * returns result { isAchieved: BOOL, progress: INT, total: INT }
 */
 function evaluateAchievementA2(subjects, user){
    var progress = 0;
    var amt_subjects = subjects.filter(e => e.course.includes("amt"));
    amt_subjects.forEach( subject => {
        var _f = user.subjects.filter(e => e.id == subject.id );
        if(_f.length > 0 && _f[0].milestones.includes('passer')){
            progress++;
        }
    });
    var result = {
        id:'full-passer-amt',
        name: 'The Surefire Licensee AMT',
        course: 'amt',
        description: 'Achieve Passer on all AMT CAAP Knowledge Test subjects.',
        isAchieved: (progress == amt_subjects.length) ? true : false,
        progress: progress,
        total: amt_subjects.length,
        percent: (progress/amt_subjects.length)*100,
        skillpoints: 2500,
    };
    return result;
}

/**
 * If the user achieved "speedrunner" on all subjects under AVT
 * returns result { isAchieved: BOOL, progress: INT, total: INT }
 */
 function evaluateAchievementA3(subjects, user){
    var progress = 0;
    var avt_subjects = subjects.filter(e => e.course.includes("avt"));
    avt_subjects.forEach( subject => {
        var _f = user.subjects.filter(e => e.id == subject.id );
        if(_f.length > 0 && _f[0].milestones.includes('speedrunner')){
            progress++;
        }
    });
    var result = {
        id:'full-speedrunner-avt',
        name: 'The Quick Examinee AVT',
        course: 'avt',
        description: 'Achieve Speedrunner on all AVT CAAP Knowledge Test subjects.',
        isAchieved: (progress == avt_subjects.length) ? true : false,
        progress: progress,
        total: avt_subjects.length,
        percent: (progress/avt_subjects.length)*100,
        skillpoints: 3000,
    };
    return result;
}

/**
 * If the user achieved "speedrunner" on all subjects under AMT
 * returns result { isAchieved: BOOL, progress: INT, total: INT }
 */
 function evaluateAchievementA4(subjects, user){
    var progress = 0;
    var amt_subjects = subjects.filter(e => e.course.includes("amt"));
    amt_subjects.forEach( subject => {
        var _f = user.subjects.filter(e => e.id == subject.id );
        if(_f.length > 0 && _f[0].milestones.includes('speedrunner')){
            progress++;
        }
    });
    var result = {
        id:'full-speedrunner-amt',
        name: 'The Quick Examinee AMT',
        course: 'amt',
        description: 'Achieve Speedrunner on all AMT CAAP Knowledge Test subjects.',
        isAchieved: (progress == amt_subjects.length) ? true : false,
        progress: progress,
        total: amt_subjects.length,
        percent: (progress/amt_subjects.length)*100,
        skillpoints: 3000,
    };
    return result;
}

/**
 * If the user achieved "master" on all subjects under AVT
 * returns result { isAchieved: BOOL, progress: INT, total: INT }
 */
 function evaluateAchievementA5(subjects, user){
    var progress = 0;
    var avt_subjects = subjects.filter(e => e.course.includes("avt"));
    avt_subjects.forEach( subject => {
        var _f = user.subjects.filter(e => e.id == subject.id );
        if(_f.length > 0 && _f[0].milestones.includes('master')){
            progress++;
        }
    });
    var result = {
        id:'full-master-avt',
        name: 'The Certified Airman AVT',
        course: 'avt',
        description: 'Achieve Master on all AVT CAAP Knowledge Test subjects.',
        isAchieved: (progress == avt_subjects.length) ? true : false,
        progress: progress,
        total: avt_subjects.length,
        percent: (progress/avt_subjects.length)*100,
        skillpoints: 8000,
    };
    return result;
}

/**
 * If the user achieved "master" on all subjects under AMT
 * returns result { isAchieved: BOOL, progress: INT, total: INT }
 */
 function evaluateAchievementA6(subjects, user){
    var progress = 0;
    var amt_subjects = subjects.filter(e => e.course.includes("amt"));
    amt_subjects.forEach( subject => {
        var _f = user.subjects.filter(e => e.id == subject.id );
        if(_f.length > 0 && _f[0].milestones.includes('master')){
            progress++;
        }
    });
    var result = {
        id:'full-master-amt',
        name: 'The Certified Airman AMT',
        course: 'amt',
        description: 'Achieve Master on all AMT CAAP Knowledge Test subjects.',
        isAchieved: (progress == amt_subjects.length) ? true : false,
        progress: progress,
        total: amt_subjects.length,
        percent: (progress/amt_subjects.length)*100,
        skillpoints: 8000,
    };
    return result;
}

exports.achievementsService = {
    evaluateAchievementA1, evaluateAchievementA2, evaluateAchievementA3, 
    evaluateAchievementA4, evaluateAchievementA5, evaluateAchievementA6,  
}
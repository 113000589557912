<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 486.724 486.724"
    fill="currentColor"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M427.846,219.809v-28.238v-14.795v-19.769h-15.701v19.147l-39.253-1.537V139.65c0-7.758-6.283-14.046-14.042-14.046
			h-3.313c-7.759,0-14.043,6.288-14.043,14.046v33.732l-29.795-1.17c3.206-4.673,5.094-10.307,5.094-16.398
			c0-8.786-3.972-16.566-10.135-21.887L414.293,26.276L397.642,9.624l-98.27,98.264l-1.784-23.638l0.237-7.065
			c0-4.256-0.773-8.211-2.192-11.852c-3.787-13.7-18.21-29.44-31.358-37.103L261.854,0h-36.987l-2.427,28.314
			c-10.277,6.039-21.313,16.981-27.431,28.002c-4.781,5.758-7.59,12.773-7.59,20.868l0.691,20.082l-1.484,17.757L81.229,9.624
			L64.579,26.276l107.846,107.846c-6.021,5.324-9.896,13.019-9.896,21.685c0,6.203,1.978,11.918,5.282,16.633l-22.578,0.974v-33.754
			c0-7.767-6.286-14.054-14.044-14.054h-3.312c-7.759,0-14.044,6.288-14.044,14.046v35.104l-39.253,1.683v-19.43H58.878v62.802
			h15.701v-27.902l39.253,1.684v35.726c0,7.758,6.285,14.044,14.044,14.044h3.312c7.758,0,14.044-6.286,14.044-14.044v-34.376
			l46.318,1.986l0.627,18.172L64.579,342.685l16.651,16.655l112.047-112.048l2.631,76.365c0,19.817,10.425,38.692,30.883,43.87
			l3.094,79.943h25.481l3.082-79.943c20.453-5.186,30.881-24.057,30.881-43.87l2.424-70.198L397.642,359.34l16.651-16.655
			L292.872,221.264l0.837-24.421l47.784-1.88v34.354c0,7.758,6.284,14.044,14.043,14.044h3.313c7.759,0,14.042-6.286,14.042-14.044
			v-35.588l39.253-1.537v27.617H427.846z"
        ></path>
        <rect x="262.988" y="408.219" width="23.552" height="78.505"></rect>
      </g>
    </g>
  </svg>
</template>
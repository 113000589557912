<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>These questions are designed to test your ability to take a series of facts expressed in words and to
            understand and manipulate the information to solve a specific problem. They are not so concerned with
            measuring your facility with English.</p>
        <p>This involves a critical understanding of what you're reading and how to use it to satisfy the required
            conditions. Deducing information, quick comprehension, and even calculations are parts of the skill you need
            to practice in order to achieve success in these types of assessment.</p>

        <h2>Sample Questions</h2>
        <p><strong>Strategy: </strong>Critical thinking is one of the hardest types of Verbal Reasoning tests due to the
            high level of concentration it requires. To counter this, don't feel overwhelmed with the information in
            front of you and find a way to simplify everything as fast as you can before trying to answer the questions.
        </p>
        <p>
            Also, if an item requires you to make calculations, change the words into numbers first (e.g. word: half---
            number: 1/2 or 0.5) to avoid confusion.
        </p>
        <hr />

        <div class="tip-wrapper">
            <p>
                1. Pedro goes either hunting or fishing every day. If it is snowing & windy then Pedro goes hunting. If
                it is
                sunny and not windy then Pedro goes fishing. Sometimes it can be snowing and sunny.
            </p>
            <p>
                <strong>Which of the following statements must be true:</strong>
            </p>
            <ul>
                <li>A.] If it is not sunny and it is snowing then Pedro goes hunting.</li>
                <li>B.] If it is windy and Pedro does not go hunting then it is not snowing.</li>
                <li>C.] If it is windy and not sunny then Pedro goes hunting.</li>
                <li>D.] If it is windy and sunny then Pedro goes hunting.</li>
                <li>E.] If it is snowing and sunny then Pedro goes hunting.</li>
            </ul>
            <p>💡 To answer this, the information given can be first simplified to:</p>
            <p>
            <ul>
                <li>Snowing + Windy = Hunting</li>
                <li>Sunny + Not Windy = Fishing</li>
            </ul>
            </p>
            <p>Then simplify the choices this way:</p>
            <p>
            <ul>
                <li>A — Need not be true because it is not stated that it is windy.</li>
                <li>B — Must be true because when it is snowing and windy Pedro goes hunting.</li>
                <li>C — Need not be true because it is not stated that it is snowing.</li>
                <li>D — Need not be true because it is not stated that it is snowing.</li>
                <li>E — Need not be true because it is not stated that it is windy.</li>
            </ul>

            </p>
            <p class="font-bold">Option B is the correct answer.</p>
        </div>

        <div class="tip-wrapper">
            <p>
                2. The cost of manufacturing tractors in Korea is twenty percent less than the cost of
                manufacturing tractors in Germany. Even after transportation fees and import taxes are added, it is
                still cheaper to import tractors from Korea to Germany than to produce tractors in Germany.

            </p>
            <p>
                <strong>Which of the following assertions is <span class="underline">best supported</span> by this
                    information?</strong>
            </p>
            <ul>
                <li>A.] Labor costs in Korea are twenty percent below those in Germany.</li>
                <li>B.] Importing tractors into Germany will eliminate twenty percent of the manufacturing jobs in
                    Germany.</li>
                <li>C.] The costs of transporting a tractor from Korea to Germany is more than twenty percent of the
                    cost of manufacturing the tractor in Korea.</li>
                <li>D.] The import taxes on a tractor imported from Korea to Germany is less than twenty percent of the
                    cost of manufacturing the tractor in Germany.</li>
                <li>E.] It takes twenty percent less time to make a tractor in Korea than it does in Germany.</li>
            </ul>
            <p>💡 The question asks 'Which of the following assertions is <span class="underline">best supported</span>
                by this information?'</p>
            <p>After clarifying what you need to look for, you can format the choices this way:</p>
            <p>
            <ul>
                <li>A — This assertion is not supported because the twenty percent difference may be due to costs other
                    than labour costs.</li>
                <li>B — This assertion is not supported by the information given.</li>
                <li>C — This assertion is not supported by the information given.</li>
                <li>D — The import taxes must be less than twenty percent because even after transport costs and import
                    taxes it is still cheaper to manufacture tractors in Korea.</li>
                <li>E — This assertion is not supported by the information given.</li>
            </ul>
            </p>
            <p class="font-bold">Option D is the correct answer.</p>
        </div>

        <div class="tip-wrapper">
            <p>
                3. Of the teams competing in the world archery championships, twenty percent are
                from Europe. Half as many are from the United States and one twentieth are from
                Africa. What fraction of teams are from neither Europe, the US or Africa.
            </p>
            <ul>
                <li>A.] 13/20</li>
                <li>B.] 15/20</li>
                <li>C.] 13/15</li>
                <li>D.] 17/20</li>
                <li>E.] 13/17</li>
            </ul>
            <p>💡 The fractions are calculated as follows:</p>
            <p>
            <ul>
                <li>Europe 20% = 4/20</li>
                <li>United States 10% = 2/20</li>
                <li>Africa= 1/20 (indicating that there are 20 teams total in the competition)</li>
                <li>4/20 + 2/20 + 1/20 = 7/20 teams are from Europe, the US or Africa.</li>
            </ul>
            </p>
            <p>Therefore 13/20 must be from other countries.</p>
            <p class="font-bold">Option A is the correct answer.</p>
        </div>


    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

.tip-wrapper {
    @apply my-4 py-2 px-4 border border-warm-gray-200 dark:border-warm-gray-700 rounded-lg
}

.tip-wrapper p {
    @apply my-4
}

.question-grid {
    @apply grid grid-cols-3
}

ul{
    @apply space-y-1.5
}

.correct-answer {
    @apply font-bold text-warm-gray-800 dark:text-warm-gray-300
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}

p {
    @apply my-4
}
</style>
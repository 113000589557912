<script setup>

import ROUTER from "@/router"
import { ref, computed, onMounted } from "vue"
import firebase from "@/firebase/config"
import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid"
import LoadingCircle from "../components/LoadingCircle.vue";
import Announcement from "../components/Announcement.vue";

const isInitializing = ref(true);
const subtopics = ref([]);

const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId);
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName);
const sortedSubtopics = computed(() => subtopics.value.sort((a, b) => a.order - b.order));

// import PreExamModal from "../components/PreExamModal.vue"
// const isPreExamModalOpen = ref(false)
// const selectedSubtopic = ref(null)
// const closePreExamModal = () => { isPreExamModalOpen.value = fals }
// const openPreExamModal = (subtopic) => {
//     selectedSubtopic.value = subtopic;
//     isPreExamModalOpen.value = true
// }

const addOrderToSubtopics = (subjectId) => {
    var order = []
    switch (subjectId) {
        case 'caap-101-general-information':
            order = [
                'caap-101-history-of-caap',
                'caap-101-structure-and-organization',
                'caap-101-powers-and-responsibilities',
                'caap-101-services-provided',
                'caap-101-key-personnel',
            ]
            break;
        case 'caap-101-the-license-exam':
            order = [
                'caap-101-caap-license-exam-overview',
                'caap-101-requirements-for-application',
                'caap-101-exam-frequency-and-scheduling',
                'caap-101-application-process',
                'caap-101-exam-centers-and-locations',
                'caap-101-exam-format-and-content',
            ]
            break;
        case 'caap-101-exam-study-tips':
            order = [
                'caap-101-recommended-study-materials',
                'caap-101-effective-study-strategies',
                'caap-101-preparing-for-exam-day',
                'caap-101-maintaining-motivation',
                'caap-101-utilizing-reviewers-effectively'
            ]
            break;
        default:
            break;
    }

    order.forEach((subtopicId, orderIndex) => {
        let foundIndex = subtopics.value.findIndex(e => e.id == subtopicId)
        subtopics.value[foundIndex].order = orderIndex

        /**
         * @hack @todo @junrey
         * explicitly marking a highlighted subtopics and subtopics prone to change.
         */
        let isHighlightedSubtopics = [
            'caap-101-application-process',
            'caap-101-requirements-for-application',
            'caap-101-utilizing-reviewers-effectively'
        ]
        if (isHighlightedSubtopics.includes(subtopicId)) {
            subtopics.value[foundIndex].isHighlighted = true;
        }

        let isProneToChangeSubtopics = [
            'caap-101-key-personnel',
            'caap-101-exam-frequency-and-scheduling',
            'caap-101-requirements-for-application',
            'caap-101-application-process',
            'caap-101-exam-centers-and-locations',
            'caap-101-exam-format-and-content',
        ]
        if (isProneToChangeSubtopics.includes(subtopicId)) {
            subtopics.value[foundIndex].isProneToChange = true;
        }

        let isNotComingSoon = [
            'caap-101-history-of-caap',
            'caap-101-requirements-for-application',
            'caap-101-application-process',
            'caap-101-exam-centers-and-locations',
            'caap-101-exam-format-and-content',
        ]
        if (!isNotComingSoon.includes(subtopicId)) {
            subtopics.value[foundIndex].isComingSoon = true;
        }

    });
}

const fetchSubtopicsBySubject = (subjectId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("caap-101").collection("subtopics").where("subjects", "array-contains", subjectId).get()
            .then((querySnapshot) => {
                var subtopics = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subtopics.push(data)
                });
                resolve(subtopics)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })
}

const handlePracticeClick = (subtopic) => {
    ROUTER.push({ path: `/caap-101/practice`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name, subjectId: subjectId.value, subjectName: subjectName.value } })
}

/**
 * When the component or view is mounted, we fetch the caap 101 subjects
 * and create order on how they will be displayed in the UI.
 */
onMounted(async () => {
    if (subjectId.value) {
        subtopics.value = await fetchSubtopicsBySubject(subjectId.value)
        if (subtopics.value.length > 0) {
            addOrderToSubtopics(subjectId.value);
            isInitializing.value = false;
        } else {
            console.error("No results found. Please make sure you have correct Subject Id.")
        }
    } else {
        console.error("There is no subject being passed. You will be redirected")
    }
})

</script>
<template>
    <div class="max-w-md mx-auto py-2">
        <Announcement />
        <div class="space-y-6 mb-20 px-4">
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/caap-101"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">{{ subjectName }}</span>
                    <span class="block md:hidden">{{ subjectName }}</span>
                </div>
                <div class="w-6 h-6"></div>
            </nav>
            <div v-if="!isInitializing" class="space-y-6">
                <div v-for="subtopic in sortedSubtopics" :key="subtopic.id"
                    class="text-base rounded-2xl border border-true-gray-400 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white border-opacity-50 dark:border-opacity-50">
                    <div class="grid grid-cols-1 gap-4 p-6">
                        <div class="focus:outline-none">
                            <div class="text-base font-medium text-warm-gray-400 mb-4">
                                <div class="flex justify-between items-center">
                                    <!-- <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" /> -->
                                    <h1 class="text-xl font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                        {{ subtopic.name }}
                                    </h1>
                                    <button @click="handleTipsClick(subtopic)">
                                        <QuestionMarkCircleIcon
                                            class="flex-shrink-0 h-5 w-5 text-warm-gray-600 hover:text-warm-gray-700"
                                            aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <p class="text-sm text-warm-gray-500 mt-2">{{ subtopic.description }}</p>
                            <p class="mt-3 space-x-2">
                                <span v-if="subtopic.isProneToChange" class="badge-base badge-base--blue">
                                    🌀 Prone To Changes
                                </span>
                                <span v-if="subtopic.isHighlighted" class="badge-base badge-base--yellow">
                                    🔥 Must Read
                                </span>
                            </p>
                            <!-- <p class="text-sm text-warm-gray-500 mt-2">This subtopic contains {{ getNumberOfSetsBySubtopic(subtopic.id) }} sets.</p> -->
                        </div>
                    </div>

                    <div
                        class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl">
                        <div class="text-sm flex justify-between">
                            <button v-if="!subtopic.isComingSoon" @click="handlePracticeClick(subtopic)"
                                class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                Begin Reading
                            </button>
                            <button v-else disabled
                                class="font-medium dark:text-true-gray-400 dark:hover:text-true-gray-300 text-true-gray-600 hover:text-true-gray-700 pointer-events-none cursor-pointer">
                                Coming Soon
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <LoadingCircle />
            </div>
        </div>
        <!-- <PreExamModal :isOpen="isPreExamModalOpen" :subtopic="selectedSubtopic" @closeModal="closePreExamModal()" /> -->
    </div>
</template>
<style scoped>
.badge-base {
    @apply inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset
}

.badge-base--yellow {
    @apply bg-yellow-400 bg-opacity-10 text-yellow-500 ring-yellow-400 ring-opacity-30 dark:text-yellow-400
}

.badge-base--blue {
    @apply bg-blue-400 bg-opacity-10 text-blue-400 ring-blue-400 ring-opacity-30
}
</style>
<script setup>
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

import moment from "moment";

import {ref, onMounted, computed} from 'vue';
import { ArrowLeftIcon, PlusIcon, XIcon, StarIcon } from '@heroicons/vue/outline';

const sites = [
    {id:'patts', name: 'PATTS'},
    {id:'omni', name: 'OMNI'},
    {id:'philsca', name: 'PHILSCA'},
    {id:'feati', name: 'FEATI'},
    {id:'others', name: 'Others'}
];

const SubjectId = Object.freeze({
    IDENTIFICATION_GENERAL: 'identification-general',
    IDENTIFICATION_AIRCRAFT: 'identification-aircraft',
    HANDS_ON_PERFORMANCE: 'hands-on-performance',
    EXPLANATION_OF_PROCESS: 'explanation-of-process',
});

const identificationGeneralTopics = ref([]);
const identificationAircraftTopics = ref([]);
const handsOnPerformanceTopics = ref([]);
const explanationOfProcessTopics = ref([]);

const isWaiting = ref(false);
const isSubmitting = ref(false);
const page = ref(null)
const slide = ref(1);
const hoverRating = ref(0);
const toAddTopic = ref('');

const authUserId = ref(null);
const authUserEmail = ref(null);
const plusSurveyInfo = ref(null);

const surveyForm = ref({
    date: null,
    site: null,
    siteSpecificName: null,
    identificationGeneralTopics: [],
    identificationGeneralTopicsAdded: [],
    identificationAircraftTopics: [],
    identificationAircraftTopicsAdded: [],  
    handsOnPerformanceTopics: [],
    handsOnPerformanceTopicsAdded: [],
    explanationOfProcessTopics: [],
    explanationOfProcessTopicsAdded: [],
    rating: 0,
    comments: ''
})

const canNext = computed(()=>{
    switch (slide.value) {
        case 1:
            if (!surveyForm.value.date || !surveyForm.value.site || (surveyForm.value.site == 'others' && !surveyForm.value.siteSpecificName)) {
                return false;
            }

            // validate the date, should be today or previous day
            const selectedDate = new Date(surveyForm.value.date);
            const currentDate = new Date();
            // Set current date to midnight to avoid time comparison issues
            currentDate.setHours(0, 0, 0, 0);
            if (selectedDate > currentDate) {return false;}

            break;
        case 2:
            if (surveyForm.value.identificationGeneralTopics.length == 0) {
                return false;
            }
            break;
        case 3:
            if (surveyForm.value.identificationAircraftTopics.length == 0) {
                return false;
            }
            break;
        case 4:
            if (surveyForm.value.handsOnPerformanceTopics.length == 0) {
                return false;
            }
            break;
        case 5:
            if (surveyForm.value.explanationOfProcessTopics.length == 0) {
                return false;
            }
            break;
        case 6:
            if (surveyForm.value.rating == 0 || !surveyForm.value.comments) {
                return false;
            }
            break;
    }

    return true;
})

const addTopic = (subject) => {

    if (!toAddTopic.value) {
        return false;
    }

    switch (subject) {
        case SubjectId.IDENTIFICATION_GENERAL:
            surveyForm.value.identificationGeneralTopicsAdded.push(toAddTopic.value)
            break;
        case SubjectId.IDENTIFICATION_AIRCRAFT:
            surveyForm.value.identificationAircraftTopicsAdded.push(toAddTopic.value)
            break;
        case SubjectId.HANDS_ON_PERFORMANCE:
            surveyForm.value.handsOnPerformanceTopicsAdded.push(toAddTopic.value)
            break;
        case SubjectId.EXPLANATION_OF_PROCESS:
            surveyForm.value.explanationOfProcessTopicsAdded.push(toAddTopic.value)
            break;
    }

    toAddTopic.value = '';
}

const removeTopic = (subject, i) => {
    switch (subject) {
        case SubjectId.IDENTIFICATION_GENERAL:
            surveyForm.value.identificationGeneralTopicsAdded.splice(i, 1);
            break;
        case SubjectId.IDENTIFICATION_AIRCRAFT:
            surveyForm.value.identificationAircraftTopicsAdded.splice(i, 1)
            break;
        case SubjectId.HANDS_ON_PERFORMANCE:
            surveyForm.value.handsOnPerformanceTopicsAdded.splice(i, 1)
            break;
        case SubjectId.EXPLANATION_OF_PROCESS:
            surveyForm.value.explanationOfProcessTopicsAdded.splice(i, 1)
            break;
    }
}

const submitSurvey = async () => {

    // convert date from string to date object
    surveyForm.value.date = new Date(surveyForm.value.date); 

    isSubmitting.value = true;
    // save post exam survey
    await db.collection("plus_exam_survey").doc(authUserId.value).set({
        ...surveyForm.value,
        userId: authUserId.value,
        userEmail: authUserEmail.value,
        status: 'submitted',
        metadata:{
            submittedOn: moment().valueOf().toString(),
            updatedOn: moment().valueOf().toString(),
        },
    })
    .then(() => {
        console.log("Plus Survey document successfully saved!");
    })
    .catch((error) => {
        console.error("Error writing plus survey document: ", error);
    });

    page.value = 'thankYou';
    isSubmitting.value = false;
}

const nextPage = () => {
    slide.value++;
    toAddTopic.value = '';
    scrollBackToTop();
}

const prevPage = () => {
    slide.value--;
    toAddTopic.value = '';
    scrollBackToTop();
}

const scrollBackToTop = () => {
    const element = document.getElementById('scroll-target-div');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

const fetchPlusSurveyInfo = async () => {

    await db.collection("plus_exam_survey").doc(authUserId.value).get()
      .then((doc) => {
        if (doc.exists) {
            plusSurveyInfo.value = doc.data();
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });

}

const fetchSubtopics = async () => {
    await db.collection("reviewers").doc('plus').collection('subtopics').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

            let _id = doc.id;
            let _data = doc.data();

            // For Identification General Subject
            if (_data.subjects.includes(SubjectId.IDENTIFICATION_GENERAL)) {
                identificationGeneralTopics.value.push({
                    id: _id,
                    name: _data.name
                })
            }

            // For Identification Aircraft Subject
            if (_data.subjects.includes(SubjectId.IDENTIFICATION_AIRCRAFT)) {
                identificationAircraftTopics.value.push({
                    id: _id,
                    name: _data.name
                })
            }

            // For Hands on Performance Subject
            if (_data.subjects.includes(SubjectId.HANDS_ON_PERFORMANCE)) {
                handsOnPerformanceTopics.value.push({
                    id: _id,
                    name: _data.name
                })
            }

            // For Explanation of Process Subject
            if (_data.subjects.includes(SubjectId.EXPLANATION_OF_PROCESS)) {
                explanationOfProcessTopics.value.push({
                    id: _id,
                    name: _data.name
                })
            }

        });
    });
}

onMounted(async () => {

    isWaiting.value = true;

    const element = document.getElementById('target-div');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    authUserId.value = firebase.auth().currentUser.uid;
    authUserEmail.value = firebase.auth().currentUser.email;
    
    await fetchPlusSurveyInfo();
    await fetchSubtopics();


    if (plusSurveyInfo.value == null) {
        page.value = 'form';
    }else{
        page.value = 'completed';
    }


    isWaiting.value = false;
});


</script>
<template>
    <div id="target-div" style="min-height: 100dvh;" :class="isWaiting || isSubmitting ? 'opacity-40 pointer-events-none' : ''" class=" text-warm-gray-600 dark:text-warm-gray-400 flex justify-center items-start">

        <div class="w-full max-w-3xl">

            <div class=" flex justify-between items-center">
                <router-link to="/plus" class="flex justify-center items-center text-sm gap-1 text-warm-gray-500">
                    <ArrowLeftIcon class="w-5 h-4"/>
                    <span class="whitespace-nowrap">
                        Go Back
                    </span>
                </router-link>
            </div>

            <div class=" mt-6">
                <h2 id="scroll-target-div" class=" text-2xl lg:text-3xl font-semibold tracking-wide text-warm-gray-800 dark:text-warm-gray-200">
                    CAAP Practical Exam Survey
                </h2>
            </div>
    
            <div>

                <!-- Complete page -->
                <div v-if="page == 'completed'">
                    <div class="dark:bg-true-gray-800 shadow-md rounded-lg p-8 text-center mt-10">
                        <h1 class="text-3xl font-bold mb-4 text-warm-gray-800 dark:text-white">🙌 You've Already Taken This Survey!</h1>
                        <p class="text-lg mb-6">
                            It looks like you've already submitted your feedback. We really appreciate your response!
                        </p>
                        <p class="">If you have any additional thoughts or questions, feel free to reach out to us at <a href="mailto:support@skilltech.ph" class=" text-yellow-500">support@skilltech.ph</a>.</p>
                        <br>
                        <router-link to="/plus" class=" text-white bg-yellow-600 py-2 px-4 rounded-lg shadow hover:bg-yellow-700 transition-colors">
                            <span class="whitespace-nowrap">
                                Exit Survey
                            </span>
                        </router-link>
                    </div>
                </div>

                <!-- Thank you page -->
                <div v-if="page == 'thankYou'">
                    <div class="dark:bg-true-gray-800 shadow-md rounded-lg p-8 text-center mt-10">
                        <h1 class="text-3xl font-bold mb-4 text-warm-gray-800 dark:text-white">🎉 Thank You! 🎉</h1>
                        <p class="text-lg mb-6">
                            We appreciate you taking the time to complete our survey. Your feedback helps us improve our reviewer, and your input means a lot to us! 🌟
                        </p>
                        <p class="">Best of luck on your journey to becoming a certified Aircraft Mechanic! Keep aiming high! ✈️</p>
                        <br>
                        <router-link to="/plus" class=" text-white bg-yellow-600 py-2 px-4 rounded-lg shadow hover:bg-yellow-700 transition-colors">
                            <span class="whitespace-nowrap">
                                Exit Survey
                            </span>
                        </router-link>
                    </div>
                </div>

                <!-- Form slides Page -->
                <div v-if="page == 'form'">
                    <!-- Page 1 (Date and Site) -->
                    <div v-if="slide == 1">
                        
                        <div class=" flex flex-col mt-8 space-y-2">
                            <label for="date">Date of Skill Test</label>
                            <input v-model="surveyForm.date" type="date" name="date" id="date" class=" text-warm-gray-600 rounded-md p-4 w-full">
                        </div>
    
                        <div class="mt-8">
                            <label>Skill Test Site</label>
                            <div class="grid gap-4 mb-6 mt-2">
    
                                <div v-for="site,i in sites" :key="i">
                                    <input type="radio"  v-model="surveyForm.site" :id="'site-'+i" :value="site.id" class="hidden" />
                                    <label :for="'site-'+i" :class="['block w-full text-center cursor-pointer p-4 rounded-lg border-2 transition-colors duration-300',
                                        surveyForm.site && surveyForm.site==site.id
                                        ? 'bg-yellow-50 text-yellow-600 font-semibold border-yellow-600'
                                        : 'border-warm-gray-300 dark:border-warm-gray-600 hover:text-warm-gray-900 dark:hover:text-warm-gray-200 hover:font-semibold hover:border-warm-gray-400 dark:hover:border-warm-gray-500'
                                        ]">
                                        {{ site.name }}
                                    </label>
                                </div>
    
                            </div>
                        </div>
    
                        <div class="mt-4" :class="surveyForm.site != 'others' ? 'opacity-40 pointer-events-none': ''">
                            <label for="siteSpecificName">If other, specify the site name</label>
                            <div class="grid gap-4 mb-6 mt-2">
                                <input v-model="surveyForm.siteSpecificName" type="text" id="siteSpecificName" class="p-4 rounded-md placeholder-warm-gray-400 text-warm-gray-700" placeholder="Enter site name">
                            </div>
                        </div>
        
                    </div>
    
                    <!-- Page 2 (Identification (General))-->
                    <div v-if="slide == 2">
                        <div class="mt-8">
                            <p>From your review of the subject <b class="text-warm-gray-300">Identification (General)</b>, what topics were included in your actual Skill Test?</p>
                            <p class=" mt-4">Please click on the subtopics below to select.</p>
                            <div class="grid gap-4 mb-6 mt-4">
                                <div v-for="(topic, i) in identificationGeneralTopics" :key="i">
                                    <input type="checkbox" v-model="surveyForm.identificationGeneralTopics" :id="'topic-'+i" :value="topic.id" class="hidden" />
                                    <label :for="'topic-'+i" :class="['block w-full text-center cursor-pointer p-4 rounded-lg border-2 transition-colors duration-300',
                                        surveyForm.identificationGeneralTopics.includes(topic.id)
                                        ? 'bg-yellow-50 text-yellow-600 font-semibold border-yellow-600'
                                        : 'border-warm-gray-300 dark:border-warm-gray-600 hover:text-warm-gray-900 dark:hover:text-warm-gray-200 hover:font-semibold hover:border-warm-gray-400 dark:hover:border-warm-gray-500'
                                        ]">
                                        {{ topic.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div class="mt-8 flex flex-col">
                            <label>Any topics we need to add?</label>
                            <div class="flex justify-between items-center gap-2 mt-2">
                                <input v-model="toAddTopic" type="text" class=" p-4 rounded-md w-full text-warm-gray-700 placeholder-warm-gray-300">
                                <button @click="addTopic(SubjectId.IDENTIFICATION_GENERAL)" class="p-3 rounded-md bg-warm-gray-400 dark:bg-warm-gray-700">
                                    <PlusIcon class="w-8 h-8 text-warm-gray-100"/>
                                </button>
                            </div>
                        </div>
    
                        <div class="mt-4 flex flex-col">
                            <ul class=" space-y-2">
                                <li v-for="addedTopic,i in surveyForm.identificationGeneralTopicsAdded" class=" border-b dark:border-warm-gray-700 p-2 rounded flex justify-between items-center">
                                    <span>{{ addedTopic }}</span>
                                    <button @click="removeTopic(SubjectId.IDENTIFICATION_GENERAL, i)">
                                        <XIcon class="w-4 h-4 text-red-700"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
    
                    </div>
    
                    <!-- Page 3 (Identification (Aircraft))-->
                    <div v-if="slide == 3">
                        <div class="mt-8">
                            <p>From your review of the subject <b class="text-warm-gray-300">Identification (Aircraft)</b>, what topics were included in your actual Skill Test?</p>
                            <p class=" mt-4">Please click on the subtopics below to select.</p>
                            <div class="grid gap-4 mb-6 mt-4">
                                <div v-for="(topic, i) in identificationAircraftTopics" :key="i">
                                    <input type="checkbox" v-model="surveyForm.identificationAircraftTopics" :id="'topic-'+i" :value="topic.id" class="hidden" />
                                    <label :for="'topic-'+i" :class="['block w-full text-center cursor-pointer p-4 rounded-lg border-2 transition-colors duration-300',
                                        surveyForm.identificationAircraftTopics.includes(topic.id)
                                        ? 'bg-yellow-50 text-yellow-600 font-semibold border-yellow-600'
                                        : 'border-warm-gray-300 dark:border-warm-gray-600 hover:text-warm-gray-900 dark:hover:text-warm-gray-200 hover:font-semibold hover:border-warm-gray-400 dark:hover:border-warm-gray-500'
                                        ]">
                                        {{ topic.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div class="mt-8 flex flex-col">
                            <label>Any topics we need to add?</label>
                            <div class="flex justify-between items-center gap-2 mt-2">
                                <input v-model="toAddTopic" type="text" class=" p-4 rounded-md w-full text-warm-gray-700 placeholder-warm-gray-300">
                                <button @click="addTopic(SubjectId.IDENTIFICATION_AIRCRAFT)" class="p-3 rounded-md bg-warm-gray-400 dark:bg-warm-gray-700">
                                    <PlusIcon class="w-8 h-8 text-warm-gray-100"/>
                                </button>
                            </div>
                        </div>
    
                        <div class="mt-4 flex flex-col">
                            <ul class=" space-y-2">
                                <li v-for="addedTopic,i in surveyForm.identificationAircraftTopicsAdded" class=" border-b dark:border-warm-gray-700 p-2 rounded flex justify-between items-center">
                                    <span>{{ addedTopic }}</span>
                                    <button @click="removeTopic(SubjectId.IDENTIFICATION_AIRCRAFT, i)">
                                        <XIcon class="w-4 h-4 text-red-700"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
    
                    </div>
    
                    <!-- Page 4 (Hands-on Performance)-->
                    <div v-if="slide == 4">
                        <div class="mt-8">
                            <p>From your review of the subject <b class="text-warm-gray-300">Hands-on Performance</b>, what topics were included in your actual Skill Test?</p>
                            <p class=" mt-4">Please click on the subtopics below to select.</p>
                            <div class="grid gap-4 mb-6 mt-4">
                                <div v-for="(topic, i) in handsOnPerformanceTopics" :key="i">
                                    <input type="checkbox" v-model="surveyForm.handsOnPerformanceTopics" :id="'topic-'+i" :value="topic.id" class="hidden" />
                                    <label :for="'topic-'+i" :class="['block w-full text-center cursor-pointer p-4 rounded-lg border-2 transition-colors duration-300',
                                        surveyForm.handsOnPerformanceTopics.includes(topic.id)
                                        ? 'bg-yellow-50 text-yellow-600 font-semibold border-yellow-600'
                                        : 'border-warm-gray-300 dark:border-warm-gray-600 hover:text-warm-gray-900 dark:hover:text-warm-gray-200 hover:font-semibold hover:border-warm-gray-400 dark:hover:border-warm-gray-500'
                                        ]">
                                        {{ topic.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div class="mt-8 flex flex-col">
                            <label>Any topics we need to add?</label>
                            <div class="flex justify-between items-center gap-2 mt-2">
                                <input v-model="toAddTopic" type="text" class=" p-4 rounded-md w-full text-warm-gray-700 placeholder-warm-gray-300">
                                <button @click="addTopic(SubjectId.HANDS_ON_PERFORMANCE)" class="p-3 rounded-md bg-warm-gray-400 dark:bg-warm-gray-700">
                                    <PlusIcon class="w-8 h-8 text-warm-gray-100"/>
                                </button>
                            </div>
                        </div>
    
                        <div class="mt-4 flex flex-col">
                            <ul class=" space-y-2">
                                <li v-for="addedTopic,i in surveyForm.handsOnPerformanceTopicsAdded" class=" border-b dark:border-warm-gray-700 p-2 rounded flex justify-between items-center">
                                    <span>{{ addedTopic }}</span>
                                    <button @click="removeTopic(SubjectId.HANDS_ON_PERFORMANCE, i)">
                                        <XIcon class="w-4 h-4 text-red-700"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
    
                    </div>
    
                    <!-- Page 5 (Explanation of Process)-->
                    <div v-if="slide == 5">
                        <div class="mt-8">
                            <p>From your review of the subject <b class="text-warm-gray-300">Explanation of Process</b>, what topics were included in your actual Skill Test?</p>
                            <p class=" mt-4">Please click on the subtopics below to select.</p>
                            <div class="grid gap-4 mb-6 mt-4">
                                <div v-for="(topic, i) in explanationOfProcessTopics" :key="i">
                                    <input type="checkbox" v-model="surveyForm.explanationOfProcessTopics" :id="'topic-'+i" :value="topic.id" class="hidden" />
                                    <label :for="'topic-'+i" :class="['block w-full text-center cursor-pointer p-4 rounded-lg border-2 transition-colors duration-300',
                                        surveyForm.explanationOfProcessTopics.includes(topic.id)
                                        ? 'bg-yellow-50 text-yellow-600 font-semibold border-yellow-600'
                                        : 'border-warm-gray-300 dark:border-warm-gray-600 hover:text-warm-gray-900 dark:hover:text-warm-gray-200 hover:font-semibold hover:border-warm-gray-400 dark:hover:border-warm-gray-500'
                                        ]">
                                        {{ topic.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
    
                        <div class="mt-8 flex flex-col">
                            <label>Any topics we need to add?</label>
                            <div class="flex justify-between items-center gap-2 mt-2">
                                <input v-model="toAddTopic" type="text" class=" p-4 rounded-md w-full text-warm-gray-700 placeholder-warm-gray-300">
                                <button @click="addTopic(SubjectId.EXPLANATION_OF_PROCESS)" class="p-3 rounded-md bg-warm-gray-400 dark:bg-warm-gray-700">
                                    <PlusIcon class="w-8 h-8 text-warm-gray-100"/>
                                </button>
                            </div>
                        </div>
    
                        <div class="mt-4 flex flex-col">
                            <ul class=" space-y-2">
                                <li v-for="addedTopic,i in surveyForm.explanationOfProcessTopicsAdded" class=" border-b dark:border-warm-gray-700 p-2 rounded flex justify-between items-center">
                                    <span>{{ addedTopic }}</span>
                                    <button @click="removeTopic(SubjectId.EXPLANATION_OF_PROCESS, i)">
                                        <XIcon class="w-4 h-4 text-red-700"/>
                                    </button>
                                </li>
                            </ul>
                        </div>
    
                    </div>
    
                    <!-- Page 6 (Rate and Comment)-->
                    <div v-if="slide == 6">
    
                        <div class="mt-8">
                            <label>Overall, how would you rate our CAAP Practical reviewer?</label>
                            <div class="mb-6 mt-4 flex justify-start items-center gap-3">
                            <button
                                v-for="index in 5"
                                :key="index"
                                @mouseenter="hoverRating = index"
                                @mouseleave="hoverRating = 0"
                                @click="surveyForm.rating = index"
                                class="focus:outline-none"
                            >
                                <StarIcon
                                class="w-8 h-8"
                                :class="{
                                    'text-yellow-500': index <= hoverRating || index <= surveyForm.rating,
                                    'text-warm-gray-600': index > hoverRating && index > surveyForm.rating
                                }"
                                />
                            </button>
                            </div>
                        </div>
    
                        <div class="mt-8 flex flex-col">
                            <label>Any comments or suggestions? </label>
                            <div class="flex justify-between items-center gap-2 mt-2">
                                <textarea v-model="surveyForm.comments" class="w-full rounded-xl p-4 text-warm-gray-700" rows="10"></textarea>
                            </div>
                        </div>
    
                    </div>   

                    <div class=" mt-14">
                        <button v-if="slide < 6" @click="nextPage" type="button"
                            class=" border dark:border-yellow-700 p-4 bg-yellow-600 text-white font-semibold mt-4n rounded-md disabled:opacity-30 w-full"
                            :disabled="!canNext">
                            Next
                        </button>
                        <button v-else @click="submitSurvey()" type="button"
                            class=" border dark:border-purple-700 p-4 bg-purple-600 text-white font-semibold mt-4n rounded-md disabled:opacity-30 w-full"
                            :disabled="!canNext">
                            {{ isSubmitting ? 'Submitting survey...' : 'Submit' }}
                        </button>
                        <button v-if="slide > 1" @click="prevPage" type="button"
                            class=" p-4 font-semibold mt-4n rounded-md disabled:opacity-30 w-full">
                            Previous
                        </button>
                    </div>
                </div>

    
            </div>

        </div>


    </div>
</template>

<style scoped>
html {
  scroll-behavior: smooth;
}
</style>
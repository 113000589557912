import { createStore } from 'vuex'

export default createStore({
    state: {
        user: {
            credentials: null,
            info: null,
        },
    },
    mutations: {
        SET_USER(state, user) {
            state.user.credentials = user ? user.credentials : null;
            state.user.info = user ? user.info : null;
        },
        SET_USER_INFO(state, user) {
            state.user.info = user ? user.info : null;
        },
        updateUserExamResultInfo(state, data) {
            state.user.info.isExamResultCompleted = data.isExamResultCompleted;
            state.user.info.examTaken = data.examTaken;
        },
    },
    actions: {
        async setUser({ commit }, user) {
            await commit('SET_USER', user);
            console.log('============ (Set user to store completed!)');
        },
        async setUserInfoOnly({ commit }, user) {
            await commit('SET_USER_INFO', user);
            console.log('============ (Set user infor to store completed!)');
        },
        async updateUserExamResultInfo({ commit }, data) {
            await commit('updateUserExamResultInfo', data);
        },
    },
    getters: {
        user: state => state.user,
    },
    modules: {},
})
<template>
  <!-- display for users that are using the fb built-in browser -->
  <template v-if="isUsingFBBrowser">
    <div class=" flex flex-col items-center justify-start min-h-screen pt-10 px-4">
      <!-- Headline -->
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="https://skilltech.ph/">
          <Logo class="text-warm-gray-900 w-12 h-12 mx-auto" />
        </a>
        <h2 class="mt-6 text-center text-2xl font-extrabold text-warm-gray-900">
          Welcome to skilltech.ph!
        </h2>
        <p class="text-center py-4 max-w-xs mx-auto text-warm-gray-600">
          You are few moments away to jump starting your Aviation career. 
        </p>
      </div>

      <!-- warning sign -->
      <div class="bg-blue-100 py-5 px-6 mb-3 text-base text-blue-700 items-center w-full border-l-4 border-blue-700" role="alert">
        <div class="inline-flex items-center">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="w-4 h-4 mr-2 fill-current" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
          </svg>
          <h2 class="font-semibold">Access using default browser</h2>
        </div>

        <hr class="border-blue-600 opacity-30 mb-2">

        <p>For better user experience we're requiring our users to access the application using the default browser. Please type <span class="underline">https://app.skilltech.ph</span> to your favorite browser.</p>
        <p class="mt-2">Currently Using: {{ browserType }}</p>
      </div>

      <div class="w-full mt-4 mb-8 px-2">
        <!-- steps -->
        <div>
          <div class=" font-semibold text-blue-700 border-b border-blue-400">
            Or, you can follow these very simple steps below.
          </div>
          <div class="ml-4 mt-2 text-warm-gray-600">
            <ol class="list-decimal space-y-2">
              <li> Click the 3 dots at the upper-right corner of your screen. <img src="images/step-1.JPG" alt="Step 1 image" class=" shadow-lg"></li>
              <li> Click the <b>Open in (what ever is your default browser...)</b>  <img src="images/step-2.JPG" alt="Step 1 image" class=" shadow-lg"></li>
              <li> Done! </li>
            </ol>
          </div>
        </div>
      
      </div>

    </div>
  </template>
  <!-- display for those not using the fb built-in browser -->
  <template v-else>
    <div v-if="!isLogged || $route.name == 'Logout' || $route.name == 'Login' || $route.name == 'Onboarding' || $route.name == 'Terms' || $route.name == 'Privacy'">
      <router-view></router-view>
    </div>
    <div v-else :class="[($route.name == 'Season 2') ? 'relative isolate overflow-hidden bg-gradient-to-r from-yellow-500 to-yellow-800 shadow-2xl' : 'dark:bg-true-gray-900 bg-warm-gray-50', 'relative h-screen flex overflow-hidden']">

      <!-- Mobile Menu -->
      <MobileMenu 
        :isMobileMenuOpen="isMobileMenuOpen"
        :isDarkMode="isDarkMode"
        :navigation="navigation"
        :user="user"
        @closeMobileMenu="closeMobileMenu()"
        @toggleDarkMode="toggleDarkMode()"
      />

      <!-- Static sidebar for desktop -->
      <SidebarMenu 
        :isDarkMode="isDarkMode"
        :navigation="navigation"
        :user="user"
        @closeMobileMenu="closeMobileMenu()"
        @toggleDarkMode="toggleDarkMode()"
      />

      <section class="flex-1 min-w-0 flex flex-col overflow-hidden">
        
        <!-- Header -->
        <Header 
          @openMobileMenu="openMobileMenu()"
        />

        <main class="flex-1 flex overflow-hidden">
          <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
            <div class="flex-1 flex xl:overflow-hidden">
              <!-- Main content -->
              <div class="w-full flex-1 max-h-screen xl:overflow-y-auto">
                <!-- 
                  Creating this wrapper for the new reviewers.
                  That way, we have full control of the main frame.
                  i.e. This will only display dark mode.
                -->
                <div v-if="$route.name && $route.name.includes('CAAP Lite') && $route.name.includes('Mtp') && $route.name.includes('Practical')" class="min-h-screen w-full bg-true-gray-900">
                  <!-- <div :class="[
                    ($route.name && ($route.name.includes('Practice') || $route.name.includes('Exam Review'))) ? 'w-full mx-auto py-6 px-3' : 'max-w-md mx-auto py-6',
                    ]"> -->
                    <router-view></router-view>
                  <!-- </div> -->
                </div>
                <!--
                  Else, display the legacy view
                -->           
                <div v-else 
                  :class="[($route.name && $route.name.includes('Coupon')) ? 'dark:bg-true-gray-900 bg-gray-200 px-3 py-4 sm:py-8' : 'py-6 px-4 sm:px-6 lg:py-6 lg:px-6', 'max-w-full mx-auto']">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </main>

      </section>
      
    </div>
  </template>
</template>

<script>
import firebase from "@/firebase/config";
import "firebase/auth";
import { ref } from "vue";

// App Shell Components
import MobileMenu from "@/components/appshell/MobileMenu";
import SidebarMenu from "@/components/appshell/SidebarMenu";
import Header from "@/components/appshell/Header";

import Logo from '@/components/Logo'

// Navigation Icons
import { HomeIcon, BookmarkAltIcon, PresentationChartLineIcon, LibraryIcon, GiftIcon } from "@heroicons/vue/outline";

import { userService } from "@/utils/user"





const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon },
  { name: "Reviewers", href: "/reviewers", icon: BookmarkAltIcon },
  { name: "Leaderboard", href: "/leaderboard", icon: PresentationChartLineIcon },
  { name: "Schools", href: "/schools", icon: LibraryIcon },
  { name: "Reward", href: "/reward", icon: GiftIcon, new: true },
];

export default {
  components: { MobileMenu, SidebarMenu, Header, Logo },
  setup() {
    const isMobileMenuOpen = ref(false);
    return {
      navigation,
      isMobileMenuOpen,
      closeMobileMenu() { isMobileMenuOpen.value = false },
      openMobileMenu() { isMobileMenuOpen.value = true },
    };
  },
  data() {
    return {
      isLogged: false,
      isDarkMode: false,
      isUsingFBBrowser: false,
      browserType: '',
      user: {
        photo: null,
        email: null,
      },
    };
  },
  methods: {
    toggleDarkMode() {
      if(!this.isDarkMode) localStorage.theme = "dark";
      (this.isDarkMode) ? document.documentElement.classList.remove("dark") : document.documentElement.classList.add("dark");
      this.isDarkMode = (this.isDarkMode) ? false : true;
    },
  },
  async created() {

    // checks if the user open the app using the built-in FB browser...
    // var ua = navigator.userAgent || navigator.vendor || window.opera;
    // if(ua.match(/FBAV/i) || ua.match(/FBAN/i)){
    //   this.isUsingFBBrowser = true;
    // }

    /**
     * IMPORTANT:
     * Update as of May 29, 2023. Detect if the user is using Webview.
     * If true, make sure we don't let them SSO as this is not allowed by Google.
     */
    console.log(navigator.userAgent)
    // Check if the user is accessing using Android WebView
    if (navigator.userAgent.indexOf('wv') > -1) {
      console.log('User is accessing using Android WebView');
      this.browserType='android-webview';
      this.isUsingFBBrowser = true;
      // Do something here
    } else {
      console.log('User is not using Android WebView');
      // Do something else here
    }

    var userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test( userAgent ),
    ios = /iphone|ipod|ipad/.test( userAgent );

    if( ios ) {
        if ( safari ) {
            //browser
        } else if ( !safari ) {
            //webview
            console.log('User is accessing using iOS WebView');
            this.browserType='ios-webview';
            this.isUsingFBBrowser = true;
        };
    } else {
        //not iOS
    };
    /** END OF IMPORTANT */


    // Initiate user if logged in
    var user = firebase.auth().currentUser;
    this.isLogged = (user) ? true : false;

    // console.log(user)
    // console.log(this.isLogged)

    /* CLAIMS */
    if(this.isLogged){
      let claims = await userService.fetchUserClaims(firebase.auth())
      if(claims.isOnboarded){
        this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
      }else{
        // moving the onboarding guard here
        this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
      }
    }

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC (flash of unstyled content)
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches) ){
      document.documentElement.classList.add("dark");
      this.isDarkMode = true;
    } else {
      document.documentElement.classList.remove("dark");
      this.isDarkMode = false;
    }

    // Remove body scroll if user is not onboarding
    // Onboarding page with overflow-hidden causes bug: cannot scroll
    // if(this.$router.currentRoute.value.name == "Onboarding" || this.$router.currentRoute.value.name == "Signup" ){
    // }

    // console.log("process.env.VUE_APP_FIREBASE_API_KEY: ", process.env.VUE_APP_FIREBASE_API_KEY)
    // console.log("process.env.VUE_APP_FIREBASE_AUTH_DOMAIN: ", process.env.VUE_APP_FIREBASE_AUTH_DOMAIN)
    // console.log("process.env.VUE_APP_FIREBASE_PROJECT_ID: ", process.env.VUE_APP_FIREBASE_PROJECT_ID)
    // console.log("process.env.VUE_APP_FIREBASE_STORAGE_BUCKET: ", process.env.VUE_APP_FIREBASE_STORAGE_BUCKET)
    // console.log("process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID: ", process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID)
    // console.log("process.env.VUE_APP_FIREBASE_APP_ID: ", process.env.VUE_APP_FIREBASE_APP_ID)
    // console.log("process.env.VUE_APP_FIREBASE_RTDB_URL: ", process.env.VUE_APP_FIREBASE_RTDB_URL)

    // firebase auth:import userdata2.json --project skilltech-team --hash-algo=scrypt --rounds=8 --hash-key="3tV/tj5s96MBdMPCplDVJsRk01yE3YmptixI8tIFqYYnlft6HzouFKYymKl/DXofweL+ns0/ATg4n7Re5dwQxA==" --salt-separator="Bw==" --mem-cost=14

  // console.log(user)

  },
};
</script>


<template>
  <LoadingScreen v-if="isLoading" />
  <main v-else>
    <header class="mt-0 sm:mt-4">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 sm:flex sm:items-center sm:justify-between align-middle">
        <div class="my-5 sm:my-0">
          <h1 class="text-xl font-bold leading-7 text-warm-gray-900 dark:text-warm-gray-200 sm:text-2xl sm:truncate">
            School Leaderboard 🏛
          </h1>
          <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl">
            Here are the highest performing schools in our platform. Click a specific school to see their top users.
          </p>
        </div>
      </div>
    </header>
    <div class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
              <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    School
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs hidden lg:block font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Top Users
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Skillpoints
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                <tr v-for="(school, index) in schools" :key="school.id" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                    {{ this.getOrdinal(index+1) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <router-link :to="`/school/${school.shorthand.toLowerCase()}`" class="hover:text-md">
                          <img class="object-cover mx-auto h-10 w-10 rounded-md" :src="school.logoUrl" alt="" />
                        </router-link>
                      </div>
                      <div class="ml-4">
                        <router-link :to="`/school/${school.shorthand.toLowerCase()}`">
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100 hidden lg:inline ">{{ school.name }} ({{ school.shorthand}})</div>
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100 inline lg:hidden">{{ school.shorthand}}</div>
                        </router-link>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400 flex flex-inline">
                          <UserGroupIcon class="text-true-gray-500 dark:text-true-gray-400 h-4 w-4 mr-2 mt-0.5"/>
                          <span>{{school.users.length}} Users</span>
                          </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-3 text-sm text-gray-500 font-medium hidden lg:inline">
                    <div v-if="school.users.length > 0" class="flex items-center space-x-2">
                      <div class="flex flex-shrink-0 -space-x-1">

                        <div v-for="(user, index) in school.users.slice(0,5)" :key="index">
                          <img v-if="user.photo" class="max-w-none h-7 w-7 object-cover rounded-full ring-2 ring-warm-gray-100 dark:ring-true-gray-600" :src="user.photo" alt="" />
                          <DefaultUser v-else class="block mx-auto h-7 w-7 max-w-none h-6 w-6 rounded-full ring-2 ring-warm-gray-100 dark:ring-true-gray-600" />
                        </div>
                      </div>
                      <span v-if="school.users.length > 5" class="flex-shrink-0 text-xs leading-5 font-medium text-true-gray-500 dark:text-true-gray-400">+{{school.users.length - 5}}</span>
                    </div>
                    <div v-else class="flex items-center space-x-2 text-xs leading-5 font-medium text-true-gray-500 dark:text-true-gray-400">
                      This school has no users yet.
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ school.skillpoints }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>

  
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";

import Logo from '@/components/Logo'
import DefaultUser from '@/components/DefaultUser'
import LoadingScreen from "@/components/appshell/LoadingScreen"

import { userService } from "@/utils/user"
import { leaderboardService } from "@/utils/leaderboard"
import { schoolService } from "@/utils/school"

import { UserGroupIcon } from '@heroicons/vue/outline';

export default {
  components: { 
    Logo,
    DefaultUser,
    LoadingScreen,
    UserGroupIcon
  },
  data(){
    return {
      isLoading: true,
      user: null,
      leaderboard: [],
      schools: [],
    }
  },
  
  methods: {

    getSchoolById: schoolService.getSchoolById,
    
    /**
     * Get the ordinal of a number for ranking system
     * i.e. getOrdinal(1) returns String "1st"
     * @NOTE: Duplicate over /dashboard
     */
    getOrdinal(i) { 
      var j = i % 10, k = i % 100;
      if (j == 1 && k != 11) { return i + "st"; }
      if (j == 2 && k != 12) { return i + "nd"; }
      if (j == 3 && k != 13) { return i + "rd"; }
      return i + "th";
    },
  },
  async created(){
    this.isLoading = true

    // var specificSchool = this.$route.params.school // or filter
    // console.log(specificSchool)

    /* CLAIMS */
    // let claims = await userService.fetchUserClaims(firebase.auth())
    // if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    
    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    
    /* LEADERBOARD */
    this.leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());
    
    /* SCHOOLS */
    this.schools = await schoolService.fetchAllSchools(firebase.firestore())
    this.schools = schoolService.sortSchoolLeaderboard(this.schools, this.leaderboard)

    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false

  }
}
</script>
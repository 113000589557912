<template>
  <div>
    <div id="faq-container" class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-12 divide-warm-gray-200 dark:divide-warm-gray-700">
        <!-- FAQs: How to Purchase -->
        <h2 class="my-6 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: Reviewer Subscriptions</h2>
        <dl class="space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsPurchase" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
        <!-- FAQs: Leaderboard Challenge -->
        <h2 class="my-6 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: Leaderboard Challenge</h2>
        <dl class="space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsLeaderboardChallenge" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
        <!-- FAQs: CAAP License Tracker -->
        <!-- <h2 class="my-6 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: CAAP License Tracker</h2>
        <dl class="space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsCaapLicenseTracker" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl> -->
        <!-- FAQs: Reviewers -->
        <h2 class="my-6 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: Reviewers</h2>
        <dl class="space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsReviewers" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
        <!-- Milestones & Achievements -->
        <h2 class="mt-12 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: Milestones & Achievements</h2>
        <dl class="mt-6 space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsMilestonesAchievements" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
        <!-- Leaderboards -->
        <h2 class="mt-12 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">FAQs: Leaderboards</h2>
        <dl class="mt-6 space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 mb-16">
          <Disclosure as="div" v-for="faq in faqsLeaderboards" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-warm-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

import { faqData } from "@/data/faq.data"

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  setup() {
    return {
      faqsPurchase: faqData.faqsPurchase,
      faqsReviewers: faqData.faqsReviewers,
      faqsMilestonesAchievements: faqData.faqsMilestonesAchievements,
      faqsLeaderboards: faqData.faqsLeaderboards,
      faqsLeaderboardChallenge: faqData.faqsLeaderboardChallenge,
      faqsCaapLicenseTracker: faqData.faqsCaapLicenseTracker
    }
  },
  created(){
    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");
  },
  mounted(){
    document.getElementById("faq-container").scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the scroll container
        inline: 'nearest' // Align to the nearest edge (in case of horizontal scrolling)
    });
  }
}
</script>
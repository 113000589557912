import firebase from "@/firebase/config"
import LiteLanding from "../views/LiteLanding.vue"
import LiteSubtopics from "../views/LiteSubtopics.vue"
import LitePractice from "../views/LitePractice.vue"
import LiteExam from "../views/LiteExam.vue"
import LiteExamHistory from "../views/LiteExamHistory.vue"
import LiteExamReview from "../views/LiteExamReview.vue"
import Default from "../views/Default.vue"

async function authGuard(to, from, next) {
    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } })
    } else {
        
        let claims = await fetchUserClaims()
        console.log(claims)
        if(claims.caap_lite){
            next()
        }else{
            next({ path: '/', query: { error: 'unauthorized' } })
        }
        
        /**
         * @TODO
         * Add feature that enables CAAP Lite purchase. Make sure to add
         * the necessary claims. Once done, uncomment the section above
         * and remove the line below (i.e. next();)
         */
        // next();
    }
}

async function fetchUserClaims() {
    return new Promise(async (resolve, reject) => {
        firebase.auth().currentUser.getIdTokenResult(true)
            .then(result => {
                resolve(result.claims)
            })
            .catch(error => {
                console.error("Error getting user claims", error);
                reject(error)
            })
    })
}


export const liteRoutes = [
    {
        path: '/lite',
        name: 'CAAP Lite',
        component: LiteLanding,
        beforeEnter: authGuard
    },
    {
        path: '/lite/subtopics',
        name: 'CAAP Lite Subtopics',
        component: LiteSubtopics,
        beforeEnter: authGuard
    },
    {
        path: '/lite/practice',
        name: 'CAAP Lite Practice',
        component: LitePractice,
        beforeEnter: authGuard
    },
    {
        path: '/lite/exam',
        name: 'CAAP Lite Exam',
        component: LiteExam,
        beforeEnter: authGuard
    },
    {
        path: '/lite/history',
        name: 'CAAP Lite Exam History',
        component: LiteExamHistory,
        beforeEnter: authGuard
    },
    {
        path: '/lite/review',
        name: 'CAAP Lite Exam Review',
        component: LiteExamReview,
        beforeEnter: authGuard
    },
]
/**
 * NOTE: Connects To Firebase
 * fetch the user's token and then the user claims
 */
function fetchUserClaims(auth){
  return auth.currentUser.getIdTokenResult(true)
    .then(tokenResult => {
      return tokenResult.claims
    })
    .catch((error) => {
      console.error("Error getting user claims", error);
      throw `Error getting user claims: ${error}`;
    });
}

/**
 * NOTE: Connects To Firebase
 * fetch the current logged in user's data 
 */
function fetchUserData(db, user_id){
  return db.collection("users").doc(user_id).get()
    .then(doc => {
      let data = doc.data()
      data.id = doc.id
      return data
    })
    .catch((error) => {
      console.error("Error getting user document:", error);
      throw `Error getting user document: ${error}`;
    });
}

/**
 * NOTE: Connects To Firebase
 * fetch the current logged in user's most recent mock exams
 */
function fetchUserExams(db, user_id){
  
  return db.collection("mock")
    .where("user", "==", user_id)
    // .where("status", "==", "completed")
    .orderBy("time.start", "desc")
    // .limit(20)
    .get()
    .then((querySnapshot) => { 
      if(querySnapshot.size > 0){ // only check if there are existing practice exams started
        var mock_exams = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          data.id = doc.id
          mock_exams.push(data)
        });
        return mock_exams;
      }else{
        // console.log("No previous mock exams were found!")
      }
    })
    .catch((error) => {
      console.error("Error getting document => ", error);
      return "Error getting document => " + error;
    })
}

/**
 * NOTE: Connects To Firebase
 * verifies if the endorser code passed by user is valid/present
 */
function isValidEndorserCode(db, endorser_code){
  return db.collection("_endorser_codes")
    .where("code", "==", endorser_code).get()
    .then((querySnapshot) => { 
        if(querySnapshot.size > 0){           
          return true
        }else{
          return false
        }
    })
    .catch((error) => {
      console.error("Cannot communicate with server: "+error)
      return false
    })
}

exports.userService = {
    fetchUserData, fetchUserClaims, fetchUserExams, isValidEndorserCode
}
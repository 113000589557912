<template>
  <div class="caap">
    <!-- Breadcrumbs -->
    <nav class="flex px-2 lg:px-12 justify-between items-center" aria-label="Breadcrumb">
      <div class="flex items-center">
        <router-link to="/caap" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-400" aria-current="page">
          <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
        </router-link>
      </div>
      <div class="text-sm font-medium text-warm-gray-500">
        <span class="hidden md:block">CAAP Achievements</span>
        <span class="block md:hidden">Achievements</span>
      </div>
      <!-- FAQ Menu -->
      <Menu as="div" class="ml-3 relative inline-block text-left">
        <MenuButton class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          <span class="sr-only">Open options</span>
          <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button type="button" :class="[ active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What am I seeing in this page?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What is a Practice/Mock Exam?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What are Achievements?</span>
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </nav>

    <!-- Main Content -->
    <main class="max-w-full mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8 pb-5">
      <h2 class="text-2xl font-extrabold tracking-tight text-warm-gray-900 dark:text-true-gray-300">
        CAAP Licensure Exam Achievements List
      </h2>
      <p class="mt-4 text-base text-warm-gray-500">
        These are all the Achievements that can be obtained by reaching different Milestones in our Mock Exams. Boost your exam skills and collect big Skillpoints in the process.
      </p>
      <!-- Display waiting screen if app is loading -->
      <div v-if="isLoading" class="grid grid-cols-1 gap-4 lg:grid-cols-3  mt-4">
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
        <div class="h-64 sm:h-50 md:h-82 relative rounded-lg overflow-hidden dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse shadow-sm"></div>
      </div>
      <div v-else class="grid grid-cols-1 gap-4 lg:grid-cols-3 mt-4">
        <div v-for="achievement in achievements.filter(e => e.course == 'amt')" :key="achievement.id" class="relative rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500 my-5">
          <div class="p-8">
            <div class="flex flex-col justify-center content-center">
              <div :class="[(achievement.isAchieved) ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 ' : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 ', 'rounded-full w-40 h-40 p-6 my-4 mx-auto']">
                <AllPasserLogo v-if="achievement.id == 'full-passer-amt'" />
                <AllSpeedrunnerLogo v-else-if="achievement.id == 'full-speedrunner-amt'"/>
                <AllMasterLogo v-else-if="achievement.id == 'full-master-amt'"/>
              </div>
              <!-- Subject Data -->
              <div class="focus:outline-none text-left">
                <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                  <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                      {{achievement.name}}
                  </h1>
                </div>
                <p class="text-sm w-full text-center text-warm-gray-500 mt-2 max-w-sm">
                  {{achievement.description}}
                </p>
                <div class="relative pt-1 mt-6">
                  <div class="flex mb-2 items-center justify-between">
                    <div>
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                      <!-- <span class="bg-yellow-200 px-2 py-1 uppercase rounded-fulltext-warm-gray-800 dark:text-warm-gray-200 text-xs font-medium text-center flex flex-inline items-center justify-center space-x-1"> -->
                        <Logo class="w-4 h-4" />
                        <span>+{{ achievement.skillpoints }}</span>
                      </span>
                    </div>
                    <div class="text-right">
                      <span v-if="!achievement.isAchieved" class="text-xs font-semibold inline-block text-indigo-600">
                        {{achievement.progress}} out of {{achievement.total}}
                      </span>
                      <span v-else class="text-xs font-semibold inline-block text-yellow-600">
                        Completed
                      </span>
                    </div>
                  </div>
                  <div :class="[(achievement.isAchieved) ? 'bg-yellow-200' : 'bg-indigo-200', 'overflow-hidden h-2 text-xs flex rounded']">
                    <div :style="`width:${achievement.percent}%`" :class="[(achievement.isAchieved) ? 'bg-yellow-400' : 'bg-indigo-500', 'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center']"></div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div v-for="achievement in achievements.filter(e => e.course == 'avt')" :key="achievement.id" class="relative rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500 my-5">
          <div class="p-8">
            <div class="flex flex-col justify-center content-center">
              <div :class="[(achievement.isAchieved) ? 'bg-yellow-200 text-yellow-500 dark:bg-yellow-300 dark:text-yellow-500 ' : 'bg-true-gray-200 text-true-gray-300 dark:bg-true-gray-700 dark:text-true-gray-600 ', 'rounded-full w-40 h-40 p-6 my-4 mx-auto']">
                <AllPasserLogoHeli v-if="achievement.id == 'full-passer-avt'" />
                <AllSpeedrunnerLogoHeli v-else-if="achievement.id == 'full-speedrunner-avt'"/>
                <AllMasterLogoHeli v-else-if="achievement.id == 'full-master-avt'"/>
              </div>
              <!-- Subject Data -->
              <div class="focus:outline-none text-left">
                <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                  <h1 class="text-lg w-full text-center mt-2 font-medium dark:text-warm-gray-400 text-warm-gray-800">
                      {{achievement.name}}
                  </h1>
                </div>
                <p class="text-sm w-full text-center text-warm-gray-500 mt-2 max-w-sm">
                  {{achievement.description}}
                </p>
                <div class="relative pt-1 mt-6">
                  <div class="flex mb-2 items-center justify-between">
                    <div>
                      
                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-yellow-600 bg-yellow-200 flex flex-inline items-center justify-center space-x-1">
                      <!-- <span class="bg-yellow-200 px-2 py-1 uppercase rounded-fulltext-warm-gray-800 dark:text-warm-gray-200 text-xs font-medium text-center flex flex-inline items-center justify-center space-x-1"> -->
                        <Logo class="w-4 h-4" />
                        <span>+{{ achievement.skillpoints }}</span>
                      </span>
                    </div>
                    <div class="text-right">
                      <span v-if="!achievement.isAchieved" class="text-xs font-semibold inline-block text-indigo-600">
                        {{achievement.progress}} out of {{achievement.total}}
                      </span>
                      <span v-else class="text-xs font-semibold inline-block text-yellow-600">
                        Completed
                      </span>
                    </div>
                  </div>
                  <div :class="[(achievement.isAchieved) ? 'bg-yellow-200' : 'bg-indigo-200', 'overflow-hidden h-2 text-xs flex rounded']">
                    <div :style="`width:${achievement.percent}%`" :class="[(achievement.isAchieved) ? 'bg-yellow-400' : 'bg-indigo-500', 'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center']"></div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
    </main>
  </div>
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import Logo from "@/components/Logo"
import AllPasserLogo from "@/components/AllPasserLogo"
import AllSpeedrunnerLogo from "@/components/AllSpeedrunnerLogo"
import AllMasterLogo from "@/components/AllMasterLogo"
import AllPasserLogoHeli from "@/components/AllPasserLogoHeli"
import AllSpeedrunnerLogoHeli from "@/components/AllSpeedrunnerLogoHeli"
import AllMasterLogoHeli from "@/components/AllMasterLogoHeli"
const db = firebase.firestore();

// Import UI dependencies
import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import { achievementsService } from '@/utils/achievements'
import { userService } from '@/utils/user'
import { reviewerService } from '@/utils/reviewer'
import { expirationGuard } from "@/utils/helper"

export default {
  components: {
    ChevronDoubleLeftIcon, QuestionMarkCircleIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    Logo, AllPasserLogo, AllSpeedrunnerLogo, AllMasterLogo,
    AllPasserLogoHeli, AllSpeedrunnerLogoHeli, AllMasterLogoHeli,
  },
  data(){
    return{
      caapPurchased: false
    }
  },
  name: "Caap Achievements",
  data(){
    return {
      isLoading: false,
      achievements: null,
      subjects: null,
      user: null
    }
  },
  async created(){
    this.isLoading = true

    /* CLAIMS - Guard Clause */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    if(!claims.caap) this.$router.push({ path: '/reviewers' })  // reroute non-caap members

    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    expirationGuard(this.user, this.$router) // Check if access already expired. Added November 17, 2023
    this.subjects = await reviewerService.fetchAllSubjects(db, "caap")
    
    var a1Data = achievementsService.evaluateAchievementA1(this.subjects, this.user)
    var a2Data = achievementsService.evaluateAchievementA2(this.subjects, this.user)
    var a3Data = achievementsService.evaluateAchievementA3(this.subjects, this.user)
    var a4Data = achievementsService.evaluateAchievementA4(this.subjects, this.user)
    var a5Data = achievementsService.evaluateAchievementA5(this.subjects, this.user)
    var a6Data = achievementsService.evaluateAchievementA6(this.subjects, this.user)
    
    this.achievements = [a1Data, a2Data, a3Data, a4Data, a5Data, a6Data]

    this.isLoading = false
  }
};
</script>

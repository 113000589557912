const faqsPurchase = [
  {
    question: "How to buy a reviewer subscription?",
    answer: "You can buy a reviewer subscription by clicking the 'Purchase Subscription' button on any reviewer in the Reviewers page. The payment instructions will be shown afterwards.",
  },
  {
    question: "What is your mode of payment?",
    answer: "You can only purchase via GCash. A GCash QR code will be shown and you can scan it to send your payment. Once you successfully sent the payment, you have to take a screenshot as proof of transaction. However, we only need the 13 digit Reference Code from you in order to proceed. If there are issues with your payment, that's the time we will ask for the payment screenshot via email.",
  },
  {
    question: "How long until my payment be confirmed?",
    answer: "Expect the payment confirmation process within 24 hours. You can reach us via support@skilltech.ph if you have any questions or concerns.",
  },
  {
    question: "What happens after my payment is confirmed?",
    answer: "Upon payment confirmation, your reviewer will now become ready for activation. This means you can decide to start using the reviewer now or at a later date. The expiration will only start once you activate it.",
  },
  {
    question: "Can I buy the reviewer now and activate it later?",
    answer: "Yes. This is a feature of our platform wherein you can buy the reviewers to take advantage of a sale but you can activate it later whenever you are ready. The duration (i.e. expiration) will only start from the date you activated the reviewer.",
  },
  {
    question: "Do you give out refunds?",
    answer: "Refunds are subject to evaluation. We do not give out refunds if the reviewer has already been activated.",
  },
  {
    question: "Do you grant free extensions?",
    answer: "No we do not grant free extensions. Simply subscribe again if you think you need more time to review or if you haven't completed your exams.",
  },
  {
    question: "Can I get a discount if I renew my subscription?",
    answer: "Yes, this is possible. Contact our support team at support@skilltech.ph for assistance.",
  },
  {
    question: "Can I purchase multiple reviewers?",
    answer: "Yes. For now, you can buy each one of them individually. We also offer bundled reviewers at a much lesser price.",
  },
  {
    question: "Can I sell my account or have it rented after I finish my exams early?",
    answer: "No. This is in violation of our terms of service. However, we understand that you want to help out your friends and family, or you want to earn through our platform. With that in mind, we highly suggest you apply as a Skilltech Endorser. That way, you can earn even more and not get tied up to just one account.",
  },
  {
    question: "What is a Skilltech Endorser?",
    answer: "A Skilltech Endorser (or Endorsement) is an affiliate program by Skilltech to help us grow and reach more users. Skilltech Endorsers enjoy commissions on each sale they make as well as other perks like bonuses, exclusive merch, sponsorships, etc. To learn more about this, reach out to us via support@skilltech.ph.",
  },
]

const faqsLeaderboardChallenge = [
  {
    question: "What is a Leaderboard Challenge?",
    answer: "This is an event where all Skilltech users compete against each other to reach the Top 10 of the Season’s leaderboard. The winners of the challenge win various prizes which include fully-paid technical training, CAAP exam expense allowances, cash reward, refunds, and more. ",
  },
  {
    question: "What is a Season?",
    answer: "Various challenges will be released at a different time, with different themes, and with different types of mechanics. Each of these events will be introduced in the form of a Season.",
  },
  {
    question: "How do I win in these challenges?",
    answer: "All you have to do is to collect as much Skillpoints as you can before the Season period ends. The one that collects the most wins the grand prize.",
  },
  {
    question: "What is the goal of the Leaderboard Challenge?",
    answer: "Skilltech is always finding ways to help our young Filipino AMTs and AVTs become more successful in their fields. By launching events like these, we are hoping to encourage our users to master their exams more. ",
  },
  {
    question: "Why should I join the challenge?",
    answer: "We mostly reward our users with aviation-related prizes. By doing so, they will gain the much-needed advantage in kick-starting their careers – while steadily mastering your Licensure and MTP exams at the same time."
  },
]

const faqsCaapLicenseTracker = [
  {
    question: "What is a CAAP License Tracker?",
    answer: "This is a feature of the app wherein you can see the planned date and the current status of your CAAP Licensure journey.",
  },
  {
    question: "How can this feature benefit me?",
    answer: "This will help you remember your goals, particularly the time factor involved in getting your AMT or AVT license. In our research, users who constantly see their target dates most likely finish their exams on time. In some instances, even earlier.",
  },
  {
    question: "Why should I update my CAAP Info?",
    answer: "If you want to change the planned date of your exams, or finally give us the good news that you’ve successfully passed all the subjects, you can easily do that by updating your CAAP Info. Regularly updating this helps us understand our users more. The more info we get, the more we can improve the overall experience of our users.",
  },
  {
    question: "After finishing my exam, why should I choose to upload my test results?",
    answer: "Aside from getting a large amount of Skillpoints, uploading your CAAP Test Results actually helps us keep our reviewers more accurate. By giving us the percentages of your ratings, and the times it took you to pass each subject for the actual CAAP exam, we can pinpoint which part of the reviewer needs immediate improvement.",
  },
]

const faqsReviewers = [
  {
    question: "What are Skilltech Reviewers?",
    answer: "These are strategically created reviewers that will help you pass the required exams to become successful Aircraft Mechanics.",
  },
  {
    question: "What type of reviewers do you have?",
    answer: "Currently, we have the CAAP Licensure Exam reviewer already available, and the MTP Entrance Exam reviewer to be released soon.",
  },
  {
    question: "What is a Practice Exam?",
    answer: "This is the first part of the reviewer where you can freely choose a specific subtopic you want to study. Score counters are also included to help you monitor your progress. You can also save your progress at any point of your review and just come back anytime you like.",
  },
  {
    question: "What are Subtopics?",
    answer: "An exam (i.e CAAP Knowledge Test) is usually divided into major parts called Subjects. Each of these Subjects contain different coverages which are further divided into what we call Subtopics.",
  },
  {
    question: "What are progress bars for?",
    answer: "The progress bars will show you the percentage of items you have already covered in a subtopic."
  },
  {
    question: "What are Key Subtopics?",
    answer: "Based on our research, the actual exam includes more items from a particular subtopic than the others. Because of this, we tagged them as ‘Key Subtopics’ to help improve your review experience."
  },
  {
    question: "What are Duplicate Subtopics?",
    answer: "To give you an example, the subtopic ‘Landing Gears’ can be both found in subjects ‘Airframe Rating’ and ‘Aircraft Maintenance.’ We tag these subtopics as ‘Duplicate Subtopics.’ This is to help you review faster and avoid studying the same subtopic twice as their progress bars are programmed to automatically sync. "
  },
  {
    question: "What is a Mock Exam?",
    answer: "This is the second part of the reviewer where you can test and determine your mastery level of the topics you have studied. The mock exam is equipped with time limits, passing scores, and other features to simulate a real exam."
  },
  {
    question: "Where did you base the time limits and passing scores?",
    answer: "These are all based from our research of the most recent standards being followed by examination bodies (i.e. CAAP AEB)."
  },
  {
    question: "Do scores and time limits change?",
    answer: "To keep the standards always relevant, these numbers may change when new updates are available from our research team. Make sure to read our announcements for your reference."
  },
  {
    question: "Do the Mock Exams work like the real exams?",
    answer: "Yes, items for our Mock Exams are randomly picked by our system from a standard coverage of a particular subject, just like how the real exams are being programmed. Our randomization algorithms are also derived from our research of the most common items that examinees encountered during the real exam."
  },
  {
    question: "If I pass a Mock Exam once, does it mean I will pass the real exam too?",
    answer: "Passing our Mock Exams, especially if done repeatedly, will increase your chances of getting better scores since we modeled our Passer Milestones from real exam standards. However, we do not guarantee anything since passing the real test depends on a lot of factors."
  },
  {
    question: "How will I maximize the benefits of the Mock Exams?",
    answer: "We strongly encourage you to constantly take the Mock Exams, regardless of the result, to make sure you can practice with as many sets of combinations as possible."
  },
]

const faqsMilestonesAchievements = [
  {
    question: "What are Milestones?",
    answer: "Milestones are sets of conditions placed in every Mock Exam subject (i.e. Air Law) that can be unlocked by beating specific scores and time limits.",
  },
  {
    question: "What are Achievements?",
    answer: "Achievements are sets of conditions placed in every Reviewer Category (i.e. CAAP License Knowledge Test) that can be unlocked by achieving specific sets of Milestones.",
  },
  {
    question: "What happens when I unlock a Milestone?",
    answer: "You will be granted Skillpoints which will improve your Overall Ranking. Achieving a specified set will also unlock corresponding Achievements with bigger Skillpoint values.",
  },
  {
    question: "What happens when I unlock an Achievement?",
    answer: "You will earn an Achievement Badge which will be added to your personal dashboard. You will also receive bigger Skillpoints which will exponentially boost your Overall Ranking.",
  },
  {
    question: "What are Skillpoints?",
    answer: "It’s a point system implemented by Skilltech to rank the users for the Nationwide Overall Ranking.",
  },
  {
    question: "How can I earn Skillpoints?",
    answer: "By unlocking different Milestones and Achievements found in our Mock Exams. ",
  },
  {
    question: "What are the basis for the passing rates and time limits (Passer Milestone)?",
    answer: "Criteria for all Passer Milestones are based on our research of the aviation industry’s standards being implemented today.",
  },
  {
    question: "What are Speedrunner and Master Milestones?",
    answer: "Speedrunner and Master milestones are designed by Skilltech to help you improve your exam skills. Getting a higher score and finishing the exams faster are two key elements of our simulated mock exam.",
  },
]

const faqsLeaderboards = [
  {
    question: "What are Leaderboards?",
    answer: "This is a ranking system created by Skilltech to give you the ability to determine your current rank amongst the competition. Its core purpose is to develop a sense of competitiveness and connection for all our users by giving them better access to other people who are also in the same stage of their career progression."
  },
  {
    question: "Do other users see my rank?",
    answer: "All users can see your rank as long as you are in the Top 20 of any category."
  },
  {
    question: "How do I improve my Overall Ranking?",
    answer: "You can improve it by earning Skillpoints through accomplishing various Milestones and Achievements found in our Mock Exams."
  },
  {
    question: "What do I get for topping the Overall Ranking?",
    answer: "You will be included in our weekly posts of the top rankers in all our social media accounts. Your name will also be highlighted in your school’s social media page and/or website (if allowed by your school’s admin)."
  },
  {
    question: "Are there real-life rewards for topping the Overall Ranking?",
    answer: "Besides the ultimate bragging rights, we are currently developing a reward system that will give our top rankers real-life aviation-related perks. It includes prizes like sponsored Aviation Training Programs, all-expense paid CAAP License acquisition, full refunds for Skilltech Reviewers, and a lot more. This is a part of our mission in creating more successful careers in the aviation community. Watch out for our announcements for more details."
  },
  {
    question: "Do I earn Skillpoints for ranking higher?",
    answer: "No, you can’t. For now, you can only earn Skillpoints by accomplishing Milestones and Achievements. But we are already in the process of creating a reward system for the Leaderboards which we will announce in the coming months."
  },
  {
    question: "How are ratings being computed?",
    answer: "Ratings are computed by following a 50:50 ratio using your recorded Score Percentage and Time Left (not time consumed) Percentage."
  },
  {
    question: "How do Leaderboards help me in my review?",
    answer: "We found out that most of the exam takers tend to stop reviewing for their tests because of the lack of motivation and the absence of reference for their progress. Leaderboards are incredible tools that will help you become more engaged in what you're trying to accomplish since you now have the best determiner of your current learning status based on other active users just like you."
  },
]

exports.faqData = {
  faqsPurchase, faqsReviewers, faqsMilestonesAchievements, faqsLeaderboards, faqsLeaderboardChallenge, faqsCaapLicenseTracker
}
// import { ref, onValue, onDisconnect, set, serverTimestamp, getDatabase } from "firebase/database";
import firebase from '@/firebase/config'
import "firebase/database";

const rtdb = firebase.database();

import store from '@/store';

export const setPresence = async(presenceStatus, user) => {


    // check if user is logged in
    console.log('Setting user presence.... ')
    if (user) {
        rtdb.ref('users-app/' + user.uid).set({
            status: presenceStatus,
            // lastOnline: firebase.firestore.Timestamp
        });
    }

}


export const infoConnected = (authUser) => {

    var connectedRef = rtdb.ref(".info/connected");
    connectedRef.on("value", (snap) => {

        if (authUser) {

            const userRef = rtdb.ref('users-app/' + authUser.uid)

            if (snap.val() === true) {
                userRef.set({
                    status: 'online',
                    // lastOnline: firebase.firestore.Timestamp
                });
                var onDisconnectRef = userRef.onDisconnect();
                onDisconnectRef.set({
                    status: 'offline',
                    // lastOnline: serverTimestamp()
                });
            }
        }

    });
}


export const updateOnAway = (user) => {

    window.onblur = () => {
        setPresence('away', user)
    };

    window.onfocus = () => {
        setPresence('online', user)
    };
}
<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p class="my-4">
            These questions consist of a short passage of text and some related questions. They
            will often be about a topic which is unfamiliar to you and the job. This is an advantage,
            rather than a disadvantage, because you need to answer the questions <strong>based only on
                the information that you are given</strong> – not using any knowledge that you already have.
        </p>

        <h2>Sample Questions</h2>
        <p><strong>Strategy: </strong>Due to time limits associated with entrance exams, you can't afford to spend too
            much time reading and comprehending the whole passage. One way to solve this is to do the 'opposite reading'
            style. Instead of reading the passage/essay, scan through all the questions first, so that you'll know what
            information you're going to search for to answer those questions.</p>
        <hr />
        <div class="my-4">
            <p><strong>Instructions:</strong> Read through each passage and evaluate the statements which follow it
                according to the rules
                below.</p>
        </div>
        <div class="my-4">
            <p class="my-4"><strong>True</strong> - The statement is true given the information in the passage.</p>
            <p class="my-4"><strong>False</strong> - The statement is false given the information in the passage.</p>
            <p class="my-4"><strong>Can't Say</strong> - There is insufficient information to say whether the statement
                is true or
                false.
            </p>
        </div>

        <div class="my-4">
            <p class="my-4"><strong>Passage:</strong></p>
            <p class="my-4">Metallurgy is concerned with the production of metallic components for use in consumer or
                engineering products. This involves the production of alloys, the shaping, the heat treatment and the
                surface treatment of the product. Common engineering metals are aluminium, chromium, copper, iron,
                magnesium, nickel, titanium and zinc. These are most often used as alloys. Much effort has been placed
                on understanding one very important alloy system, that of purified iron, which has carbon dissolved in
                it, better known as steel. Normal steel is used in low cost, high strength applications where weight and
                corrosion are not a problem.</p>
        </div>

        <div class="tip-wrapper">
            <p>1. Iron is purified steel which has carbon dissolved in it.</p>
            <div class="question-grid">
                <span>a] True</span>
                <span class="correct-answer">b] False ✅</span>
                <span>c] Can't Say</span>
            </div>
            <p>💡 From the part of the passage, <em>“...that of <strong>purified iron, which has carbon dissolved in
                        it</strong>, better
                    known as <strong>steel</strong>.”</em> Steel is purified iron, not the opposite. Therefore, it's
                false.</p>
        </div>

        <div class="tip-wrapper">
            <p>2. Aluminium is lighter than iron but not as strong.</p>
            <div class="question-grid">
                <span>a] True</span>
                <span>b] False</span>
                <span class="correct-answer">c] Can't Say ✅</span>
            </div>
            <p>💡 No information in the passage can confirm or disprove this statement.</p>
        </div>

        <div class="tip-wrapper">
            <p>3. Steel is relatively cheap but can suffer from corrosion.</p>
            <div class="question-grid">
                <span class="correct-answer">a] True ✅</span>
                <span>b] False</span>
                <span>c] Can't Say</span>
            </div>
            <p>💡 In the passage, it was mentioned that <em>“Normal <strong>steel</strong> is used in <strong>low
                        cost</strong>, high strength applications
                    <strong>where weight and corrosion are not a problem</strong>.”</em> Using this info, we can say
                that steel is cheap and is
                only used when corrosion is not a concern (meaning, it's prone to corrosion). Therefore, the statement
                is true.</p>
        </div>

        <div class="tip-wrapper">
            <p>4. Metallurgy involves producing alloys for use in engineering products.</p>
            <div class="question-grid">
                <span class="correct-answer">a] True ✅</span>
                <span>b] False</span>
                <span>c] Can't Say</span>
            </div>
            <p>💡 This was mentioned in the first part of the passage <em>“<strong>Metallurgy</strong> is concerned with the production of
                metallic components <strong>for use</strong> in consumer or <strong>engineering products</strong>. This <strong>involves the production of
                    alloys</strong>....”</em> Therefore, it's true.</p>
        </div>

        <div class="tip-wrapper">
            <p>5. Using stainless steel avoids problems due to corrosion.</p>
            <div class="question-grid">
                <span>a] True</span>
                <span>b] False</span>
                <span class="correct-answer">c] Can't Say ✅</span>
            </div>
            <p>💡 No information in the passage can confirm or disprove this statement. </p>
        </div>

    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

.tip-wrapper {
    @apply my-4 py-2 px-4 border border-warm-gray-200 dark:border-warm-gray-700 rounded-lg
}

.tip-wrapper p {
    @apply my-4
}

.question-grid {
    @apply grid grid-cols-3
}

.correct-answer {
    @apply font-bold text-warm-gray-800 dark:text-warm-gray-300
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}
</style>
<script setup>

import ROUTER from "@/router"
import { ref, computed, onMounted } from "vue"
import firebase from "@/firebase/config"
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon } from "@heroicons/vue/solid"
import Thinking1 from "../assets/Thinking1.vue"
import Thinking2 from "../assets/Thinking2.vue"
import Thinking3 from "../assets/Thinking3.vue"
import Thinking4 from "../assets/Thinking4.vue"

/**
 * Component for the "This is Beta" announcement.
 */
 import Announcement from "@/modules/caap-101/components/Announcement.vue";
 

const isWaiting = ref(true)
const subjects = ref([])
const sortedSubjects = computed( () => { 
    const order = [
        'identification-general',
        'identification-aircraft',
        'hands-on-performance',
        'explanation-of-process',
    ]
    return subjects.value.sort((a, b) => {
                console.log(a.id)
                console.log(b.id)
                let indexA = order.indexOf(a.id);
                let indexB = order.indexOf(b.id);
                return indexA - indexB;
            });

})

const icons = [Thinking1, Thinking2, Thinking3, Thinking4]

const fetchSubjects = () => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("plus").collection("subjects").get()
            .then((querySnapshot) => {
                var subjects = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subjects.push(data)
                });
                resolve(subjects)
                // console.log(subjects)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

const handleStudyClick = (subject) => {
    ROUTER.push({ path: `/plus/subtopics/study`, query: { subjectId: subject.id, subjectName: subject.name } })
}


onMounted(async () => {
    isWaiting.value = true
    subjects.value = await fetchSubjects()
    // addOrderToSubjects()
    isWaiting.value = false
});
</script>
<template>
    <div class="max-w-md mx-auto py-2">
        <div class="space-y-6 mb-20 px-4">
            <Announcement />
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/plus" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">CAAP Plus Subjects</span>
                    <span class="block md:hidden">CAAP Plus Subjects</span>
                </div>
                <button class="opacity-20 disabled:cursor-default" disabled>
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
                </button>
            </nav>
            <div v-if="!isWaiting" class="space-y-6">
                <!-- <ul>
                    <li v-for="subject in sortedSubjects" :key="subject.id" class="my-8 border border-white rounded-md px-8 py-4">
                        <h1 class="my-2 text-lg font-bold">{{ subject.name }}</h1>
                        <p>{{ subject }}</p>
                        <div class="flex justify-between text-yellow-400 my-2">
                            <button>Begin Practice</button>
                            <button>Start Mock Exam</button>
                        </div>
                    </li>
                </ul> -->
                <div v-for="(subject, index) in sortedSubjects" :key="subject.id" class="relative rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                    <div class="flex-1 min-w-0 col-span-1 p-6">
                        <!-- Subject Data -->
                        <div class="focus:outline-none text-left">
                        <div class="flex dark:text-warm-gray-400 text-warm-gray-800 items-center">
                            <!-- <LightningBoltIcon class="text-text-gray-500 w-6 h-6" /> -->
                            <component :is="icons[index]" class="text-text-gray-500 w-7 h-7" />
                            <h1 class="ml-2 text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                {{subject.name}}
                            </h1>
                        </div>
                        <p class="text-sm text-warm-gray-500 mt-2 max-w-sm">
                            {{subject.description}}
                        </p>
                        </div>
                    </div>
                    <!-- Action Buttons -->
                    <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3">
                    <div class="text-sm flex justify-between">
                        <button @click="handleStudyClick(subject)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                            View Subtopics
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else>
            <div class="flex justify-center mt-8">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
            </div>
        </div>
    </div>
</template>
// const reviewers = [
//   {
//     id: "caap",
//     name: "CAAP Licensure Exam Reviewer",
//     description: "Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT licensure exam. Complete coverage for all Knowledge Test subjects in practice or mock exam modes.",
//     image: 'https://images.unsplash.com/photo-1577412729580-eed54dd4e5c8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2900&q=80',
//     isPublished: true,
//     purchaseLink: "#",
//     price: 2499,
//     topics: 12,
//     skillpoints: 120000,
//     users: 250,
    
//   },
//   {
//     id: "mtp",
//     name: "MTP Entrance Exam Reviewer",
//     description: "Prepare for the latest topics used by aviation companies today for their Maintenance Training Program (MTP) entrance exams. Improve your exam skills with categories like Abstract Reasoning and more.",
//     image: 'https://images.unsplash.com/photo-1598981457915-aea220950616?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2870&q=80',
//     isPublished: false,
//     purchaseLink: "#",
//     price: 2499,
//     topics: 12,
//     skillpoints: 120000,
//     users: 0,
//   }
// ]


const reviewers = [
  {
    id: "caap",
    name: "CAAP Licensure Exam",
    description: "Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT licensure exam. Complete coverage for all Knowledge Test subjects in practice or mock exam modes.",
    image: 'https://images.unsplash.com/photo-1577412729580-eed54dd4e5c8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2900&q=80',
    isPublished: true,
    purchaseLink: "#",
    price: 2499,
    topics: 12,
    skillpoints: 120000,
    users: 250,
    
  },
]

const caap_headline = [
  {
    title: "Master Your CAAP Exam",
    description: "Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT licensure exam."
  },
  { 
    title: "Check Your Progress",
    description: "Monitor your status in your Mock Exam results and become better by breaking new records."
  }
]

const caap_menu = [
{
  title: "Knowledge Test for Aircraft Maintenance Technician (AMT)",
  href: "/caap/course/amt",
  image: "https://cdn-icons-png.flaticon.com/128/655/655846.png",
  description: "Exam subjects and related subtopics that test your knowledge level for Airframe and/or Powerplant rating.",
},
{
  title: "Knowledge Test for Aircraft Avionics Technician (AVT)",
  href: "/caap/course/avt",
  image:"https://cdn-icons-png.flaticon.com/128/6550/6550421.png",
  description:"These are exam subjects and related subtopics that test your knowledge level for AMS Avionics rating.",
},
{
  title: "View Mock Exam History",
  href: "/caap/history",
  image: "https://cdn-icons-png.flaticon.com/128/2782/2782058.png",
  description: "See the extensive record of your learning progress so far by looking into all your Mock Exam results. Easily identify which areas you need to practice more.",
},
{
  title: "View Achievements List", 
  href: "/caap/achievements",
  image:"https://cdn-icons-png.flaticon.com/128/1642/1642120.png",
  description:"View all Achievements that can be obtained by reaching different Milestones in our Mock Exams. Boost your exam skills and collect big Skillpoints in the process.",
},
];

const caap_tips = [
  "Since the questions for one subject in a CAAP Knowledge Test is made out of different sets, you might want to refrain asking anyone for pointers. Yours could be totally different from theirs so just review them all.  ",
  "Our Mock Exams are designed to create a simulation of the real exam. But passing them once or twice doesn’t mean your work is done. Your goal is to do it regularly until it’s easy to get a passing score, or even a perfect one.",
  "Based on our interviews of previous CAAP exam takers, one of the best ways to review for the test is to master a subject first before moving to the next one.",
  "Our survey shows that 60% of those who failed the exam skipped some of the coverage and instead, followed a short list of unverified pointers. Avoid this. Reviewing the whole thing might take more effort but it’s gonna be worth it.",
  "If you’re feeling that the coverage is too heavy for you, try setting specific goals for each study session rather than finishing them all at once. Forcing it won’t help you at all.",
  "CAAP exams are given thru a computer and not thru smartphones or tablets. If you want to get the full experience, try opening this reviewer in a laptop or PC and go practice like it’s the real thing.",
  "As much as possible, try to study alone. You have to get used to answering these questions on your own just like in the actual exam. So when you’re using this reviewer, try to get some solo time.",
  "Keywords are helpful in trying to remember the correct answers. But you should always read the whole question. According to our survey, around 40% failed the CAAP exam because they only tried to memorize a specific keyword found in the choices.",
  "In your CAAP exam, you can constantly see your score displayed in your screen. It starts at zero and will only increase if you got the correct answer. Don’t get distracted by it though. Instead, focus on the question in front of you. ",
  "If you’re planning to take more than one subject in a single day for your CAAP exam, try choosing subjects with matching Duplicate Subtopics. It will greatly lessen the number of items you have to cover for your review.",
];

exports.reviewerData = {
  reviewers, caap_headline, caap_menu, caap_tips
}
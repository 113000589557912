<script setup>
import { onMounted, ref, computed } from 'vue';
import ROUTER from '@/router';
import firebase from "@/firebase/config";
import LoadingCircle from '../components/LoadingCircle.vue';
import { LogoutIcon, ChevronDoubleRightIcon, ChevronDoubleLeftIcon } from "@heroicons/vue/outline";

const isInitializing = ref(true);
const isError = ref(false);

// computed properties 
const reviewerId = 'caap-101' // constant reviewer id
const subtopicId = computed(() => ROUTER.currentRoute.value.query.subtopicId) // subtopic id taken from the query params
const subtopicName = computed(() => ROUTER.currentRoute.value.query.subtopicName) // subtopic name taken from the query params
const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId) // subject id taken from the query params
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName) // subject name taken from the query params


// practice session refs
const questionnaire = ref(null) // the questionnaire that is fetched from firestore
const index = ref(0) // the questionnaire that is fetched from firestore

onMounted(async () => {

    console.log(subtopicId.value)
    console.log(subtopicName.value)
    console.log(subjectId.value)
    console.log(subjectName.value)

    if (subtopicId.value) {
        try {
            questionnaire.value = await fetchQuestionnaireBySubtopicId(subtopicId.value);
            console.log(questionnaire.value);
            isInitializing.value = false;
        } catch (error) {
            console.error('Something went wrong while fetching the questionnaire. Please make sure that the questionnaire exists.');
            isError.value = true
        }
    } else {
        console.error("There is no subtopic being passed. You will be redirected")
        ROUTER.push({ path: `/reviewers` })
    }
})

// CONNECTS TO FIRESTORE: retreive the questionnaire collection of this specific subtopic via subtopic id
const fetchQuestionnaireBySubtopicId = (subtopicId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("questionnaires").doc(subtopicId).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })
}

const handleCompleteClick = () => {
    ROUTER.push({ path: `/caap-101/subtopics`, query: { subtopicName: subtopicName.value, subtopicId: subtopicId.value, subjectName: subjectName.value, subjectId: subjectId.value } })
}


const handlePrevClick = () => {
    if (index.value > 0) {
        index.value--;
        scrollToTop();
    }
}

const handleNextClick = () => {
    if (index.value < questionnaire.value.questions.length) {
        index.value++;
        scrollToTop();
    }
}

const scrollToTop = () => {
    document.getElementById("caap-101-container").scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the scroll container
        inline: 'nearest' // Align to the nearest edge (in case of horizontal scrolling)
    });
}
</script>
<template>
    <div id="caap-101-container" class="caap-101-container">
        <div v-if="isError" class="text-red-500">
            Something went wrong. Please return home.
        </div>
        <div v-else-if="isInitializing">
            <LoadingCircle />
        </div>
        <div v-else>
            <nav id='practice-nav' class="flex px-1 lg:px-7 justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <div @click="handleCompleteClick()"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer"
                        aria-current="page">
                        <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </div>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span>Reading Session for {{ subtopicName }}</span>
                </div>
                <div class="w-6 h-6"></div>
            </nav>
            <main class="reading-container">
                <div v-if="questionnaire.questions.length > 0" class="space-y-4">

                    <!-- We need to render the questions and add a v-if in order for transition to kick in -->
                    <!-- <div v-for="(topic, i) in questionnaire.questions" >
                        <transition name="fade" mode="out-in" >
                            <div v-if="index == i" :key="topic.id" class="reading-topic" >
                                <div v-html="topic.study" />
                            </div>
                        </transition>
                    </div> -->

                        <transition name="fade" mode="out-in" >
                            <div :key="index" v-html="questionnaire.questions[index].study" class="reading-topic" />
                        </transition>

                    <!-- Footer Nav -->
                    <nav class="reading-nav-container">
                        <button :disabled="index == 0" @click="handlePrevClick()" class="button--prev">
                            <ChevronDoubleLeftIcon class="icon mr-1" />
                            Previous
                        </button>

                        <button v-if="index < questionnaire.questions.length - 1" @click="handleNextClick()"
                            class="button--next">
                            Next
                            <ChevronDoubleRightIcon class="icon ml-1" />
                        </button>
                        <button v-else @click="handleCompleteClick()"
                            class="button--complete">
                            Complete Reading Session
                        </button>
                    </nav>
                </div>
                <div v-else class="text-yellow-500">
                    There are no reading materials set for this topic yet.
                </div>
            </main>
        </div>
    </div>
</template>

<style>
.caap-101-container {
    @apply w-full mx-auto py-6 px-1 lg:px-8 text-warm-gray-700 dark:text-warm-gray-400
}

.reading-container {
    @apply max-w-full lg:max-w-7xl mt-8 mx-auto
}

.reading-topic {
    @apply border border-true-gray-200 dark:border-true-gray-700 px-6 py-8
}

.reading-topic h2 {
    @apply text-2xl font-bold
}

.reading-topic h3 {
    @apply text-xl font-bold mt-4
}

.reading-topic a {
    @apply underline
}

.reading-topic ul {
    @apply list-disc pl-4 space-y-2
}

.reading-topic ol {
    @apply list-decimal pl-4 space-y-2
}

.icon {
    @apply h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500
}

.reading-nav-container {
    @apply mt-6 flex flex-col sm:flex-row justify-between
}

.button--next {
    @apply order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}

.button--prev {
    @apply order-last sm:order-first my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}

.button--complete{ 
    @apply order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.fade-enter-active,
.fade-leave-active {
    /** animation delay: 300ms */
    transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
<template>
  <div class="reviewers">
    <!-- Header -->
    <header class="mt-0 sm:mt-4">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 sm:flex sm:items-center sm:justify-between align-middle"
      >
        <div class="my-5 sm:my-0">
          <h1
            class="text-xl font-bold leading-7 text-warm-gray-900 dark:text-warm-gray-200 sm:text-2xl sm:truncate"
          >
            Skilltech Reviewers 📚
          </h1>
          <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-3xl">
            Get ahead with our latest aviation exam reviewers for all aspiring
            Aircraft Mechanics.<br />
            Complete with features and pointers developed from actual exam
            standards and practices.
          </p>
        </div>
      </div>
    </header>

    <div class="mt-4 max-w-full mx-auto py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl space-y-8 mb-20">

      <!-- CAAP Complete -->
      <div class="relative dark:bg-true-gray-800 bg-white shadow-md border border-warm-gray-300 dark:border-true-gray-700">
        <div class="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-2/5">
          <img :src="caapPhoto" class="w-full h-full object-cover" alt="Reviewer Cover Photo" />
        </div>
        <div class="relative max-w-7xl mx-auto px-8 py-8 sm:py-10 sm:px-10 lg:py-10">
          <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-3/5 lg:pl-10">
            <h2 class="mt-6 text-2xl font-extrabold dark:text-warm-gray-300 text-warm-gray-800 sm:text-3xl flex items-center space-x-4">
              <span>CAAP Licensure Exam</span>
            </h2>
            <p class="mt-6 text-md dark:text-warm-gray-400 text-warm-gray-800">
              Review for your Civil Aviation Authority of the Philippines (CAAP) AMT or AVT licensure exam. Complete coverage for all Knowledge Test subjects in practice or mock exam modes.
            </p>
            
            <!-- Notification! Will be shown once the user submitted the appeal -->
            <div v-if="this.user && this.user.reviewers.caap == 'expired'" class="my-4">
              <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-8" role="alert">
                <div class="flex items-center">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                  <div>
                    <p class="font-bold text-sm">Approved!</p>
                    <p class="text-sm">Click <router-link to="/assessment" class=" text-yellow-500">here</router-link> to view the shipment status of your achievement box.</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="this.user && this.user.reviewers.caap == 'inactive' && user.isCaapBlocked" class="mt-6 text-md text-yellow-900 py-4 px-6 bg-yellow-50">
              <p>🏆 Your subscription has expired. There are no free extensions; please subscribe again if needed. Click the purchase button below for payment instructions.</p>
              <p>Did you already took the exam and passed? Claim your FREE reward <router-link to="/reward" class="text-yellow-600 hover:text-yellow-500 font-bold underline">here</router-link>.</p>
            </div>
            <p v-if="this.user && this.user.caap_data && this.user.reviewers.caap == 'active' && this.user.caap_data.expiration_date && this.user.caap_data.expiration_date.seconds != null" class="mt-6 text-md text-blue-900 py-4 px-6 bg-blue-100">
              ⌛️ Your reviewer access will expire on {{ convertTimestampToDate(this.user.caap_data.expiration_date) }}.
            </p>
            <p v-if="this.user && this.user.reviewers.caap == 'inactive'" class="mt-6 text-md text-yellow-900 py-4 px-6 bg-yellow-50">
              🎫 Do you have a discount coupon? Click <router-link :to="`/coupon`" class="text-yellow-600 hover:text-yellow-500 font-bold underline">here</router-link> or simply scan the QR code at the back of your coupon.
            </p>
            <p v-else-if="this.user && this.user.reviewers.caap == 'ready'" class="mt-6 text-md bg-indigo-200 text-indigo-600 py-4 px-5 rounded-md border-2 border-indigo-500">
              🎁 You can now activate your reviewer access anytime by clicking the button below. Please note that the 6 month access duration <strong>will only start</strong> right after you activate.
            </p>
            
            <div>
              <p v-if="this.user && this.user.reviewers.caap == 'paid'" class="mt-4 text-xs text-warm-gray-500">
                If you have any issues/concerns, just click the button below.
              </p>
              <button v-if="this.user == null" class="disabled flex-shrink-0 flex bg-gray-600 disabled:cursor-auto cursor-auto py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full">
                Please wait...
              </button>
              <button
                v-else-if="this.user && ( this.user.reviewers.caap == 'ready' || isActivating)"
                :disabled="isActivating"
                @click="openActivateModal"
                class="flex-shrink-0 flex bg-indigo-500 hover:bg-indigo-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full disabled:opacity-25 disabled:cursor-default"
              >
                {{ isActivating ? 'Please wait for activation...' : 'Activate Reviewer Now' }}
              </button>
              <button
                v-else-if="this.user && this.user.reviewers.caap == 'active'"
                @click="startReview()"
                :disabled="isStartReview"
                class=" disabled:opacity-40 flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Start Review
              </button>

              <!-- <router-link
                v-else-if="this.user && this.user.reviewers.caap == 'active'"
                :to="`/caap/`"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Start Review
              </router-link> -->

              <router-link
                v-else-if="this.user && this.user.reviewers.caap == 'expired'"
                :to="`/caap/history`"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                View Mock Exam History
              </router-link>
              <button
                v-else-if="this.user && this.user.reviewers.caap == 'paid'"
                class="flex-shrink-0 flex bg-warm-gray-500 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Payment Sent and Waiting for Confirmation
              </button>
              <button
                v-else-if="this.user"
                @click="purchaseReviewer"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Purchase Reviewer for only 4,999.00 PHP
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- CAAP Lite -->
      <div class="relative dark:bg-true-gray-800 bg-white shadow-md border border-warm-gray-300 dark:border-true-gray-700">
        <div class="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-2/5">
          <img :src="litePhoto" class="w-full h-full object-cover" alt="Reviewer Cover Photo" />
        </div>
        <div class="relative max-w-7xl mx-auto px-8 py-8 sm:py-10 sm:px-10 lg:py-10">
          <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-3/5 lg:pl-10">
            <h2 class="mt-6 text-2xl font-extrabold dark:text-warm-gray-300 text-warm-gray-800 sm:text-3xl flex items-center space-x-4">
              <span>CAAP Renewal Exam (Lite)</span>
            </h2>
            <p class="mt-6 text-md dark:text-warm-gray-400 text-warm-gray-800">
              For airmen who got their CAAP licenses expired, get it easily activated again by passing the exam using this specially designed reviewer. Covers topics that are essential for license renewal only.
            </p>
            <div>
              <p v-if="this.user && this.user.reviewers.caap_lite == 'paid'" class="mt-4 text-xs text-warm-gray-500">
                If you have any issues/concerns, just click the button below.
              </p>
              <button v-if="this.user == null" class="disabled flex-shrink-0 flex bg-gray-600 disabled:cursor-auto cursor-auto py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full">
                Please wait...
              </button>
              <router-link
                v-else-if="this.user && this.user.reviewers.caap_lite == 'active'"
                :to="`/lite/`"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Start Review
              </router-link>
              <button
                v-else-if="this.user && this.user.reviewers.caap_lite == 'paid'"
                @click="openPurchasedHelpModal"
                class="flex-shrink-0 flex bg-warm-gray-500 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Payment Sent and Waiting for Confirmation
              </button>
              <button
                v-else-if="this.user"
                @click="purchaseLiteReviewer"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Purchase Reviewer for only 2,999.00 PHP
              </button>
            </div>
            <!-- <button
              disabled
              class="cursor-default flex-shrink-0 flex bg-warm-gray-600 py-3 border border-warm-gray-300 border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
            >
              Coming Soon
            </button> -->
          </div>
        </div>
      </div>

      <!-- CAAP Plus -->
      <div class="relative dark:bg-true-gray-800 bg-white shadow-md border border-warm-gray-300 dark:border-true-gray-700">
        <div class="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-2/5">
          <img :src="plusPhoto" class="w-full h-full object-cover" alt="Reviewer Cover Photo" />
        </div>
        <div class="relative max-w-7xl mx-auto px-8 py-8 sm:py-10 sm:px-10 lg:py-10">
          <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-3/5 lg:pl-10">
            <h2 class="mt-6 text-2xl font-extrabold dark:text-warm-gray-300 text-warm-gray-800 sm:text-3xl flex items-center space-x-4">
              <span>CAAP Practical Exam (Plus)</span>
              <span class="inline-flex items-center rounded-md bg-yellow-400 bg-opacity-10 px-2 py-1 text-xs font-medium text-yellow-500 border border-yellow-400">
                BETA
              </span>
            </h2>
            <p class="mt-6 text-md dark:text-warm-gray-400 text-warm-gray-800">
              Become more ready on your CAAP Skill Test by learning aircraft maintenance basic principles, terminologies, toolings, aircraft parts, and more by using this supplementary reviewer.
            </p>


            <p v-if="this.user && this.user.reviewers.caap_plus == 'active' && this.user.caap_plus_data && this.user.caap_plus_data.expiration_date && this.user.caap_plus_data.expiration_date.seconds != null" class="mt-6 text-md text-blue-900 py-4 px-6 bg-blue-100">
              ⌛️ Your reviewer access will expire on {{ convertTimestampToDate(this.user.caap_plus_data.expiration_date) }}.
            </p>
            <!-- <p v-if="this.user && this.user.reviewers.caap == 'inactive'" class="mt-6 text-md text-yellow-900 py-4 px-6 bg-yellow-50">
              🎫 Do you have a discount coupon? Click <router-link :to="`/coupon`" class="text-yellow-600 hover:text-yellow-500 font-bold underline">here</router-link> or simply scan the QR code at the back of your coupon.
            </p> -->
            <p v-else-if="this.user && this.user.reviewers.caap_plus == 'ready'" class="mt-6 text-md bg-indigo-200 text-indigo-600 py-4 px-5 rounded-md border-2 border-indigo-500">
              🎁 You can now activate your reviewer access anytime by clicking the button below. Please note that the 3 month access duration <strong>will only start</strong> right after you activate.
            </p>

            <!-- <div>
              <p v-if="this.user && this.user.reviewers.caap_plus == 'paid'" class="mt-4 text-xs text-warm-gray-500">
                If you have any issues/concerns, send us an email at support@skilltech.ph
              </p>
              <button v-if="this.user == null" class="disabled flex-shrink-0 flex bg-gray-600 disabled:cursor-auto cursor-auto py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full">
                Please wait...
              </button>

              <button
                v-else-if="this.user && (this.user.reviewers.caap_plus == 'ready' || isActivatingPlus)"
                :disabled="isActivatingPlus"
                @click="openActivatePlusModal"
                class="flex-shrink-0 flex bg-indigo-500 hover:bg-indigo-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full disabled:opacity-25 disabled:cursor-default"
              >
                {{ isActivatingPlus ? 'Please wait for activation...' : 'Activate Reviewer Now' }}
              </button>

              <router-link
                v-else-if="this.user && this.user.reviewers.caap_plus == 'active'"
                :to="`/plus/`"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Start Review
              </router-link>
              <button
                v-else-if="this.user && this.user.reviewers.caap_plus == 'paid'"
                disabled
                class="disabled:cursor-default flex-shrink-0 flex bg-warm-gray-500 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Payment Sent and Waiting for Confirmation
              </button>
              <button
                v-else-if="this.user"
                @click="purchasePlusReviewer"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Purchase Reviewer for only 2,999.00 PHP
              </button>
            </div> -->

            <button
              disabled
              class="cursor-default flex-shrink-0 flex bg-warm-gray-600 py-3 border border-warm-gray-300 border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
            >
              Coming Soon
            </button>
          </div>
        </div>
      </div>

      <!-- MTP Lite -->
      <div class="relative dark:bg-true-gray-800 bg-white shadow-md border border-warm-gray-300 dark:border-true-gray-700">
        <div class="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-2/5">
          <img :src="mtpPhoto" class="w-full h-full object-cover" alt="Reviewer Cover Photo" />
        </div>
        <div class="relative max-w-7xl mx-auto px-8 py-8 sm:py-10 sm:px-10 lg:py-10">
          <div class="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-3/5 lg:pl-10">
            <h2 class="mt-6 text-2xl font-extrabold dark:text-warm-gray-300 text-warm-gray-800 sm:text-3xl flex items-center space-x-4">
              <span>Entrance Exam</span>
              <span class="inline-flex items-center rounded-md bg-yellow-400 bg-opacity-10 px-2 py-1 text-xs font-medium text-yellow-500 border border-yellow-400">
                BETA
              </span>
            </h2>
            <p class="mt-6 text-md dark:text-warm-gray-400 text-warm-gray-800">
              Prepare for the latest topics used by aviation companies today for their Maintenance Training Program (MTP) entrance exams. Improve your exam skills with categories like Abstract Reasoning and more.
            </p>

            <!-- <p v-if="this.user && this.user.reviewers.mtp == 'active' && this.user.mtp_data && this.user.mtp_data.expiration_date && this.user.mtp_data.expiration_date.seconds != null" class="mt-6 text-md text-blue-900 py-4 px-6 bg-blue-100">
              ⌛️ Your reviewer access will expire on {{ convertTimestampToDate(this.user.mtp_data.expiration_date) }}.
            </p>
            <p v-else-if="this.user && this.user.reviewers.mtp == 'ready'" class="mt-6 text-md bg-indigo-200 text-indigo-600 py-4 px-5 rounded-md border-2 border-indigo-500">
              🎁 You can now activate your reviewer access anytime by clicking the button below. Please note that the 3 month access duration <strong>will only start</strong> right after you activate.
            </p>

            <div>
              <p v-if="this.user && this.user.reviewers.mtp == 'paid'" class="mt-4 text-xs text-warm-gray-500">
                If you have any issues/concerns, send us an email at support@skilltech.ph
              </p>
              <button v-if="this.user == null" class="disabled flex-shrink-0 flex bg-gray-600 disabled:cursor-auto cursor-auto py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full">
                Please wait...
              </button>

              <button
                v-else-if="this.user && (this.user.reviewers.mtp == 'ready' || isActivatingMtp)"
                :disabled="isActivatingMtp"
                @click="openActivateMtpModal"
                class="flex-shrink-0 flex bg-indigo-500 hover:bg-indigo-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full disabled:opacity-25 disabled:cursor-default"
              >
                {{ isActivatingMtp ? 'Please wait for activation...' : 'Activate Reviewer Now' }}
              </button>

              <router-link
                v-else-if="this.user && this.user.reviewers.mtp == 'active'"
                :to="`/mtp/`"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Start Review
              </router-link>
              <button
                v-else-if="this.user && this.user.reviewers.mtp == 'paid'"
                disabled
                class="disabled:cursor-default flex-shrink-0 flex bg-warm-gray-500 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Payment Sent and Waiting for Confirmation
              </button>
              <button
                v-else-if="this.user"
                @click="purchaseMtpReviewer"
                class="flex-shrink-0 flex bg-yellow-500 hover:bg-yellow-400 py-3 border border-white border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
              >
                Purchase Reviewer for only 999.00 PHP
              </button>
            </div> -->
            <button
              disabled
              class="cursor-default flex-shrink-0 flex bg-warm-gray-600 py-3 border border-warm-gray-300 border-opacity-25 rounded-md items-center justify-center text-base font-medium text-white mt-6 w-full"
            >
              Coming Soon
            </button>
          </div>
        </div>
      </div>

      <PurchaseMtpModal
        :isPurchaseModalOpen="isPurchaseMtpModalOpen"
        :user="this.user"
        @closePurchaseModal="isPurchaseMtpModalOpen = false"
      />

      <PurchaseLiteModal
        :isPurchaseModalOpen="isPurchaseLiteModalOpen"
        :user="this.user"
        @closePurchaseModal="isPurchaseLiteModalOpen = false"
      />

      <PurchasePlusModal
        :isPurchaseModalOpen="isPurchasePlusModalOpen"
        :user="this.user"
        @closePurchaseModal="isPurchasePlusModalOpen = false"
      />

      <PurchaseModal
        :isPurchaseModalOpen="isPurchaseModalOpen"
        :user="this.user"
        @closePurchaseModal="isPurchaseModalOpen = false"
      />

      <PurchasedHelpModal
        :isPurchasedHelpModalOpen="isPurchasedHelpModalOpen"
        @closePurchasedHelpModal="isPurchasedHelpModalOpen = false"
      />
      <ActivateModal
        :isActivateModalOpen="isActivateModalOpen"
        @closeActivateModal="isActivateModalOpen = false"
        @initiateActivation="initiateActivation"
      />
      <ActivatePlusModal
        :isActivatePlusModalOpen="isActivatePlusModalOpen"
        @closeActivatePlusModal="isActivatePlusModalOpen = false"
        @initiatePlusActivation="initiatePlusActivation"
      />
      <ActivateMtpModal
        :isActivateMtpModalOpen="isActivateMtpModalOpen"
        @closeActivateMtpModal="isActivateMtpModalOpen = false"
        @initiateMtpActivation="initiateMtpActivation"
      />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";

import caapPhoto from '@/assets/images/caap-primary-photo.jpeg'
import litePhoto from '@/assets/images/lite-primary-photo.jpeg'
import plusPhoto from '@/assets/images/plus-primary-photo.jpeg'
import mtpPhoto from '@/assets/images/mtp-primary-photo.jpeg'

import { UsersIcon } from "@heroicons/vue/outline";
import { reviewerData } from "@/data/reviewer.data";

import { userService } from "@/utils/user";

// Modals
import PurchaseModal from "@/components/modals/PurchaseModal";
import PurchaseMtpModal from "@/modules/mtp/components/PurchaseMtpModal";
import PurchaseLiteModal from "@/modules/lite/components/PurchaseLiteModal";
import PurchasePlusModal from "@/modules/plus/components/PurchasePlusModal";
import PurchasedHelpModal from "@/components/modals/PurchasedHelpModal";
import ActivateModal from "@/components/modals/ActivateModal";
import ActivatePlusModal from "@/modules/plus/components/ActivatePlusModal";
import ActivateMtpModal from "@/modules/mtp/components/ActivateMtpModal";

import { convertTimestampToDate } from '@/views/coupon/couponService'

const db = firebase.firestore();

// In here, we define the allowed routes: i.e. caap, etc.
export default {
  components: { UsersIcon, PurchaseModal, PurchasedHelpModal, PurchaseMtpModal, PurchaseLiteModal, PurchasePlusModal, ActivateModal, ActivateMtpModal, ActivatePlusModal },
  setup() {
    const isPurchaseModalOpen = ref(false);
    const isPurchaseMtpModalOpen = ref(false);
    const isPurchaseLiteModalOpen = ref(false);
    const isPurchasePlusModalOpen = ref(false);
    const isPurchasedHelpModalOpen = ref(false);
    const isActivateModalOpen = ref(false);
    const isActivatePlusModalOpen = ref(false);
    const isActivateMtpModalOpen = ref(false);

    return {
      reviewers: reviewerData.reviewers,
      isPurchaseModalOpen,
      closePurchaseModal() {
        isPurchaseModalOpen.value = false;
      },
      openPurchaseModal() {
        isPurchaseModalOpen.value = true;
      },
      ///
      isPurchaseMtpModalOpen,
      closePurchaseMtpModal() {
        isPurchaseMtpModalOpen.value = false;
      },
      openPurchaseMtpModal() {
        isPurchaseMtpModalOpen.value = true;
      },
      ///
      isPurchaseLiteModalOpen,
      closePurchaseLiteModal() {
        isPurchaseLiteModalOpen.value = false;
      },
      openPurchaseLiteModal() {
        isPurchaseLiteModalOpen.value = true;
      },
      ///
      isPurchasePlusModalOpen,
      closePurchasePlusModal() {
        isPurchasePlusModalOpen.value = false;
      },
      openPurchasePlusModal() {
        isPurchasePlusModalOpen.value = true;
      },
      ///
      isPurchasedHelpModalOpen,
      closePurchasedHelpModal() {
        isPurchasedHelpModalOpen.value = false;
      },
      openPurchasedHelpModal() {
        isPurchasedHelpModalOpen.value = true;
      },
      ///
      isActivateModalOpen,
      closeActivateModal() {
        isActivateModalOpen.value = false;
      },
      openActivateModal() {
        isActivateModalOpen.value = true;
      },
      ///
      isActivatePlusModalOpen,
      closeActivatePlusModal() {
        isActivatePlusModalOpen.value = false;
      },
      openActivatePlusModal() {
        isActivatePlusModalOpen.value = true;
      },
      ///
      isActivateMtpModalOpen,
      closeActivateMtpModal() {
        isActivateMtpModalOpen.value = false;
      },
      openActivateMtpModal() {
        isActivateMtpModalOpen.value = true;
      },
    };
  },
  data() {
    return {
      isCaapPurchased: false,
      isMtpPurchased: true,
      isLoading: false,
      isStartReview: false,
      user: null,
      caapPhoto: caapPhoto,
      mtpPhoto: mtpPhoto,
      litePhoto: litePhoto,
      plusPhoto: plusPhoto,
      isActivating: false,
      isActivatingPlus: false,
      isActivatingMtp: false,
      convertTimestampToDate: convertTimestampToDate
    };
  },

  methods: {
    purchaseReviewer() {
      // console.log("Purchasing reviewer...")
      this.openPurchaseModal();
    },
    purchaseLiteReviewer() {
      this.openPurchaseLiteModal();
    },
    purchasePlusReviewer() {
      this.openPurchasePlusModal();
    },
    purchaseMtpReviewer() {
      this.openPurchaseMtpModal();
    },

    async initiateActivation(){
      this.isActivating = true;
      try {
        let result = await this.activateReviewerAccess()
        if(result){
          alert('Activation complete. Good luck on your review!')  
          // this.user.reviewers.caap = 'active';
        }
      } catch (error) {
        console.error(error)
        alert(error)
      } finally {
        this.isActivating = false;
      }
    },
    activateReviewerAccess() { 
      return new Promise(async (resolve, reject) => {
        // Update User Data
        this.user.reviewers.caap = 'active';

        // set the old caap data and update it
        let _caap_data = (this.user.caap_data != null) ? this.user.caap_data : {}
        _caap_data.activation_date = new Date(),
        _caap_data.expiration_date = this.getReviewerExpiry()
        

        console.log("pushed data to db:", {
          reviewers: this.user.reviewers,
          caap_data: _caap_data
        })
        
        // Push to database
        db.collection("users").doc(this.user.id).update({ 
          reviewers: this.user.reviewers,
          caap_data: _caap_data
        })
        .then(async () => { 
          console.log("Successfully updated user")

          // TODO: update user.info field in store

          // Also update the CAAP claim to have access
          let claimActivationResult = await this.activateClaims()
          if(claimActivationResult){
            resolve(true)
          }
        })
        .catch((error) => { 
          console.error("Error updating user data: ", error)  
          reject(error)
        });
        
        
      })
    },
    // Also update the CAAP claim to have access
    async activateClaims(){
      return new Promise(async (resolve, reject) => {
        try {
          let caapExpAt = this.getReviewerExpiry();
          console.log("Updating claims")
          const activateReviewerFbFunc = firebase.functions().httpsCallable("activateReviewer");
          let activateResponse = await activateReviewerFbFunc({ email: this.user.email, caapExpAt: caapExpAt })
          resolve(activateResponse)
        } catch (error) {
          console.error('Error happened during claims activation.')
          reject(error)
        }
      })
      
    },

    async initiatePlusActivation(){
      this.isActivatingPlus = true;
      try {
        let result = await this.activatePlusReviewerAccess()
        if(result){
          alert('Activation for CAAP Plus complete. Good luck on your review!')  
          // this.user.reviewers.mtp = 'active';
        }
      } catch (error) {
        console.error(error)
        alert(error)
      } finally {
        this.isActivatingPlus = false;
      }
    },
    activatePlusReviewerAccess() { 
      return new Promise(async (resolve, reject) => {
        // Update User Data
        this.user.reviewers.caap_plus = 'active';

        // set the old caap plus data and update it
        let _caap_plus_data = (this.user.caap_plus_data != null) ? this.user.caap_plus_data : {}
        _caap_plus_data.activation_date = new Date(),
        _caap_plus_data.expiration_date = this.getReviewerExpiry(90)
        
        console.log("pushed data to db:", {
          reviewers: this.user.reviewers,
          caap_plus_data: _caap_plus_data
        })
        
        // Push to database
        db.collection("users").doc(this.user.id).update({ 
          reviewers: this.user.reviewers,
          caap_plus_data: _caap_plus_data
        })
        .then(async () => { 
          console.log("Successfully updated user")

          // TODO: update user.info field in store

          // Also update the CAAP Plus claim to have access
          let claimActivationResult = await this.activatePlusClaims()
          if(claimActivationResult){
            resolve(true)
          }
        })
        .catch((error) => { 
          console.error("Error updating user data: ", error)  
          reject(error)
        });
        
      })
    },
    async activatePlusClaims(){
      return new Promise(async (resolve, reject) => {
        try {
          let plusExpAt = this.getReviewerExpiry(90);
          console.log("Updating claims")
          const activatePlusReviewerFbFunc = firebase.functions().httpsCallable("activateReviewer");
          let activateResponse = await activatePlusReviewerFbFunc({ email: this.user.email, plusExpAt: plusExpAt })
          resolve(activateResponse)
        } catch (error) {
          console.error('Error happened during claims activation.')
          reject(error)
        }
      })
      
    },

    async initiateMtpActivation(){
      this.isActivatingMtp = true;
      try {
        let result = await this.activateMtpReviewerAccess()
        if(result){
          alert('Activation for MTP complete. Good luck on your review!')  
          // this.user.reviewers.mtp = 'active';
        }
      } catch (error) {
        console.error(error)
        alert(error)
      } finally {
        this.isActivatingMtp = false;
      }
    },
    activateMtpReviewerAccess() { 
      return new Promise(async (resolve, reject) => {
        // Update User Data
        this.user.reviewers.mtp = 'active';

        // set the old mtp data and update it
        let _mtp_data = (this.user.mtp_data != null) ? this.user.mtp_data : {}
        _mtp_data.activation_date = new Date(),
        _mtp_data.expiration_date = this.getReviewerExpiry(90)
        
        console.log("pushed data to db:", {
          reviewers: this.user.reviewers,
          mtp_data: _mtp_data
        })
        
        // Push to database
        db.collection("users").doc(this.user.id).update({ 
          reviewers: this.user.reviewers,
          mtp_data: _mtp_data
        })
        .then(async () => { 
          console.log("Successfully updated user")

          // TODO: update user.info field in store

          // Also update the CAAP claim to have access
          let claimActivationResult = await this.activateMtpClaims()
          if(claimActivationResult){
            resolve(true)
          }
        })
        .catch((error) => { 
          console.error("Error updating user data: ", error)  
          reject(error)
        });
        
      })
    },
    async activateMtpClaims(){
      return new Promise(async (resolve, reject) => {
        try {
          let mtpExpAt = this.getReviewerExpiry(90);
          console.log("Updating claims")
          const activateMtpReviewerFbFunc = firebase.functions().httpsCallable("activateReviewer");
          let activateResponse = await activateMtpReviewerFbFunc({ email: this.user.email, mtpExpAt: mtpExpAt })
          resolve(activateResponse)
        } catch (error) {
          console.error('Error happened during claims activation.')
          reject(error)
        }
      })
      
    },

    //  180 days default or around 6 months. Ideally, it should be set by user
    getReviewerExpiry(days = 180) {
      let durationInDays = days;
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + durationInDays)
      // const expiryTimestamp = Timestamp.fromDate(currentDate)
      return currentDate
    },
    startReview(){
      this.isStartReview = true;
      this.$router.push({ path: '/caap' })
    }
  },

  async created() {
    this.isLoading = true;
    this.isStartReview = false;

    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    // this.user = {
    //   reviewers: {
    //     caap: 'inactive'
    //   }
    // }

    /* CLAIMS */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    this.isCaapPurchased = (claims.caap) ? true : false;  // store isCaapPurchased claims
    // console.log(this.isCaapPurchased)

    // console.log(claims)

    // @TODO: FETCH USER REVIEWER DATA HERE
    /* USER */
    this.user = await userService.fetchUserData(
      firebase.firestore(),
      firebase.auth().currentUser.uid
    );
    // console.log(this.user)

    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false

    // console.log(this.user)

    // console.log(this.user);
  },
  
};
</script>
<script setup>
import { ref } from "vue"
import firebase from "@/firebase/config"
import { TIPS } from "../utilities/constants"
import { KeyIcon, CheckCircleIcon, ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid"
import PrePracticeModal from "../components/PrePracticeModal.vue"

const subtopics = ref([])
const selectedSubtopic = ref(null)
const isPrePracticeModalOpen = ref(false)
const currentTip = ref(null)
const isWaiting = ref(true)

const fetchSubtopics = () => {

    // puting this code to switch bet. reviewer ID
    // Reviewer id in production is different in development
    let reviewerId = 'lite';
    if (window.location.hostname.includes('localhost') || window.location.hostname.includes('skilltech.team')) {
        reviewerId = 'tByhLcFSn6tAWLBt79GY';
    }


    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc(reviewerId).collection("subtopics").get()
            .then((querySnapshot) => {
                var subtopics = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subtopics.push(data)
                });
                resolve(subtopics)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })   
}

const closePrePracticeModal = () => {
    isPrePracticeModalOpen.value = false
    
    /**
     * @TODO
     * The values below can't be set to null immediately.
     * This is because we need to account with the closing animation
     * of the dialog box. If the values are nulled while the component
     * is not yet removed, it will throw an error.
     * 
     * A workaround for this, is to always set a new subtopic and
     * new tip every time the user opens the modal. This will overwrite
     * the old values - instead of setting it to null everytime.
     */
    // selectedSubtopic.value = null
    // currentTip.value = null
    
}

const openPrePracticeModal = (subtopic) => {
    isPrePracticeModalOpen.value = true
    selectedSubtopic.value = subtopic
    currentTip.value = TIPS[Math.floor(Math.random() * TIPS.length)]
}

/**
     * Get all Subtopics Practice Data
     * Check if a subtopic is a Key subtopic
     * Check if a subtopic has been completed
     * Check if a subtopic has a paused practice exam
     */
const getSubtopicsPracticeData = (uid) => {
  return new Promise(async (resolve, reject) => {
    subtopics.value.forEach( async (subtopic, index) => {
      await firebase.firestore().collection("lite_practice")
        .where("user", "==", uid)
        .where("subtopic", "==", subtopic.id)
        .where("status", "in", ["started","completed"])
        .get()
        .then((querySnapshot) => {

          var subtopics_data = [];
          if(querySnapshot.size > 0){

            // this subtopic already has practices that are started.
            subtopics.value[index].isStarted = true
            
            querySnapshot.forEach( doc => {
              // console.log(doc.data());
              let data = doc.data()
              data.id = doc.id
              subtopics_data.push(data)
            })

            // Check if this subtopic is already completed. 
            // If there are any existing practice that is "status==completed",
            // this means the user already went through it.
            var subtopic_practices_completed = subtopics_data.filter( e => e.status == "completed")
            subtopics.value[index].isCompleted = (subtopic_practices_completed.length > 0) ? true : false

            // Even if a subtopic practice is completed, 
            // a user can still start a practice session
            // and there might be open practices (i.e. "status==started") 
            // which is in progress. Fetch them so user can allow to continue
            var subtopic_practices_started = subtopics_data.filter( e => e.status == "started")
            subtopics.value[index].isInProgress = (subtopic_practices_started.length > 0) ? true : false

            // If there's a paused exam, store it for reference later
            var most_recent_practice = null;
            if(subtopic_practices_started.length > 0){
              // Reduce the array to the most recently taken exam
              most_recent_practice = subtopic_practices_started.reduce( (previousValue, currentValue) => {
                return (currentValue.time.start > previousValue.time.start) ? currentValue : previousValue
              });
            }
            subtopics.value[index].pausedPractice = most_recent_practice // will return null if none

          }else{
            subtopics.value[index].isStarted = false
          }

          resolve(true)
          
        })
        .catch((error) => {
          console.error("Error getting document => ", error);
          reject(error)
        })

    });
  })
}

const initialize = async () => {
  isWaiting.value = true
  subtopics.value = await fetchSubtopics()
  if(subtopics.value){
    await getSubtopicsPracticeData(firebase.auth().currentUser.uid)

    isWaiting.value = false
  }
    
}

initialize()

</script>
<template>
  <div class="max-w-md mx-auto py-6">
    <div class="space-y-6 mb-20 px-4">
        <!--  -->
        <nav class="flex justify-between items-center" aria-label="Breadcrumb">
            <div class="flex items-center">
                <router-link to="/lite" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                    <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                </router-link>
            </div>
            <div class="text-sm font-medium text-warm-gray-500">
                <span class="hidden md:block">CAAP Lite Topics</span>
                <span class="block md:hidden">CAAP Lite Topics</span>
            </div>
            <!-- FAQ Menu -->
            <a href="#">
                <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
            </a>
        </nav>
        <!--  -->

        <div v-if="!isWaiting" class="space-y-6">
            <div @click="openPrePracticeModal(subtopic)" v-for="subtopic in subtopics" :key="subtopic.id" class="card cursor-pointer">
                <div class="focus:outline-none ">
                    <div class="text-base font-medium text-warm-gray-400 flex justify-between items-start">
                        <div class="flex flex-inline items-start">
                        <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" />
                        <span>{{subtopic.name}}</span>
                        </div>
                        <KeyIcon v-if="subtopic.keys.includes(true)" class="w-6 h-6 text-yellow-400" />
                    </div>
                    <p class="text-sm text-warm-gray-500 mt-2">
                        {{subtopic.items}} Items
                    </p>
                </div>

                <!--  -->
                <!-- Progress Bar -->
            <!-- <div v-if="subtopic.isStarted == null"> -->
            
            <div>
              <!-- <div v-if="!subtopic.isStarted" class="relative pt-1 mt-4">  -->
              <div v-if="!subtopic.isStarted" class="relative pt-1 mt-4"> 
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700">
                      Not Yet Started
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-warm-gray-600 dark:text-true-gray-400">
                      0%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                  <div style="width:0%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500"></div>
                </div>
              </div>
              <div v-else-if="subtopic.isInProgress" class="relative pt-1 mt-4">
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                      In progress
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-indigo-600">
                      {{subtopic.pausedPractice.progress}}%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded bg-indigo-200">
                  <div :style="`width:${subtopic.pausedPractice.progress}%`" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                </div>
              </div>
              <div v-else-if="subtopic.isCompleted" class="relative pt-1 mt-4">
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                      Completed
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-green-600">
                      100%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                  <div style="width:100%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                </div>
              </div>
            </div>
            </div>
        </div>
        <div v-else>
          <div class="flex justify-center mt-8">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
            
    <PrePracticeModal :isOpen="isPrePracticeModalOpen" :subtopic="selectedSubtopic" :tip="currentTip" @closeModal="closePrePracticeModal()" />

    </div>
  </div>
    
</template>
<style scoped>
.card{
    @apply text-base rounded-2xl px-6 py-6 bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}
</style>
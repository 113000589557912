// HOME > ACTIONS MENU
const actions = [
    {
      id: "resume-practice",
      img: "https://cdn-icons-png.flaticon.com/512/5807/5807373.png",
      name: 'Resume your saved Practice Exam',
      href: '#',
      iconForeground: 'text-black-300',
      iconBackground: 'bg-yellow-400',
      description: "Continue where you left off the last time. Start on the part that you previously saved and carry on with your progress in preparing yourself for the Mock Exam."
    },
    {
      id: "caap-achievements",
      img: 'https://cdn-icons-png.flaticon.com/512/3211/3211424.png',
      name: 'See what Achievements you can unlock',
      href: '/caap-achievements',
      iconForeground: 'text-black-300',
      iconBackground: 'bg-yellow-400',
      description: "View all Achievements that can be obtained by reaching different Milestones in our Mock Exams. Boost your exam skills and collect big Skillpoints in the process."
    },
    {
      id: "leaderboard",
      img: "https://cdn-icons-png.flaticon.com/512/5854/5854784.png",
      name: 'Check the Skilltech Leaderboard',
      href: '/leaderboard',
      iconForeground: 'text-black-300',
      iconBackground: 'bg-yellow-400',
      description: "Know your rank among the competition. Beat records and be on top of the nationwide leaderboard. "
    },
  
    {
      id: "caap-history",
      img: "https://cdn-icons-png.flaticon.com/512/5807/5807566.png",
      name: 'View your Mock Exam history',
      href: '/caap-history',
      iconForeground: 'text-black-500',
      iconBackground: 'bg-yellow-400',
      description: "See the extensive record of your learning progress so far by looking into all your Mock Exam results. Easily identify which areas you need to practice more."
    },
    { 
      id: "faqs",
      img: "https://cdn-icons-png.flaticon.com/512/5854/5854718.png",
      name: 'Frequently Asked Questions',
      href: '/faqs', 
      iconForeground: 'text-black-300',
      iconBackground: 'bg-yellow-400',
      description: "Our platform provides several ways for you to improve your skills while enjoying it at the same time. If you have any questions, simply browse this FAQ page."
    },
    {
      id: "ticket",
      img: "https://cdn-icons-png.flaticon.com/512/5807/5807634.png",
      name: 'Submit a suggestion or bug report',
      isNewTab: true,
      href: 'https://forms.gle/TdQmRv48Xy9ZKutH7',
      iconForeground: 'text-black-300',
      iconBackground: 'bg-yellow-400',
      description: "Let us know how to improve our features and services. Complete the form and we'll get back to you as soon as possible."
    },
]
  
// HOME > BLOGS
const blogs = [
    {
      id: 'blog-1',
      title: 'How to Find Aircraft Mechanic Jobs Online',
      href: 'https://blog.skilltech.ph/how-to-find-aircraft-mechanic-jobs-online/',
      preview:
        'Finding jobs these days has never been easier because of this thing called the Internet. Many aviation companies are now posting their jobs online which makes it easier for everyone to look for opportunities.',
    },
    {
      id: 'blog-2',
      title: 'Top 10 Expectations vs Reality for AMT New Grads',
      href: 'https://blog.skilltech.ph/top-10-expectations-vs-reality-for-amt-new-grads/',
      preview:
        'No doubt, it’s a challenging chapter in your life. Yesterday, your world was only inside the four corners of your campus. Then suddenly, you realized, it’s an open field now. School is over. Now what?',
    },
    {
      id: 'blog-3',
      title: 'How to Create Resumes for Aircraft Mechanics',
      href: 'https://blog.skilltech.ph/how-to-create-resumes-for-aircraft-mechanics/',
      preview:
        'It has been one of the most common challenges especially for those who are still starting out (A.K.A new graduates) to make a resume that will stand out from the rest.',
    },
]

// TIPS
const tips = [
  "The CAAP Knowledge Test are given in various exam sets. Meaning, your exam may differ from what your friend is taking, even if you’re taking the test for the same subject.",
  "CAAP exams have time limits. Typically, the number of items in a subject is also the number of minutes allotted to complete the exam. For example, 30 items means 30 minutes. ",
  "One of the creators of this app has managed to pass all the CAAP Knowledge Test for AMT subjects in only one day. 7 subjects in 1 day. It’s possible.",
  "The passing score for the actual exam is 70% across all subjects. Want to know if you’re ready? Our Mock Exams follows the same passing rates so always give it a try.",
  "Skilltech Reviewer’s concepts and features are the results of in-depth research into real life experiences of Filipino AMT and AVT exam takers. ",
  "This reviewer has been created with the inputs of actual CAAP exam passers. Through hundreds of interviews, surveys, and statistics, we’ve managed to collect the best ideas from the Filipino aviation community. ",
  "In the Philippines, your CAAP Airmen License will be required first before a company promotes you to a Certifying Staff. No license, no promotion. That’s why it’s always better to have it ready.",
  "The CAAP Airmen License is one of the most frequently required credentials when applying for jobs abroad. Job agencies in the Philippines usually include your license as part of their requirements.",
  "Our survey shows that the most exam takers consider Air Law and Airworthiness to be the most difficult subject and Human Performance to be the easiest.",
  "We graduated as BSAMT but we started our OJT right after getting our Associate’s degree. After completing our OJT, we immediately took the CAAP exams. By the time we’re in 3rd year college, we already got our CAAP AMT licenses.",
]

exports.homeData = {
  actions, blogs, tips
}
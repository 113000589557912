<template>
    <LoadingScreen v-if="isLoading" />
    <main v-else class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
            <header class="mt-0 sm:mt-4">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 sm:flex sm:items-center sm:justify-between align-middle">
                    <div class="my-3 sm:my-0">
                        <h1 class="text-xl font-bold leading-7 text-warm-gray-900 dark:text-warm-gray-200 sm:text-2xl sm:truncate">
                        Account Settings
                        </h1>
                        <!-- <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa voluptate consectetur, esse accusantium, deleniti eos excepturi cum eaque ipsam illo aliquid deserunt ut? Nobis delectus et quod, totam ad amet.
                        </p> -->
                    </div>
                </div>
            </header>
            <div class="flex-1 flex xl:overflow-hidden">
                
            <!-- Main content -->
            <div class="flex-1 xl:overflow-y-auto">
                <div class="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-8 lg:px-8">
                    
                <form class="mt-2 space-y-8 divide-y dark:divide-warm-gray-700 dark:divide-warm-gray-200" @submit.prevent="signInButtonPressed">
                    <!-- Personal Info -->
                    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                    <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-warm-gray-700 dark:text-warm-gray-400">Personal Info</h2>
                        <p class="mt-1 text-sm text-warm-gray-700 dark:text-warm-gray-400">
                            Your personal info will be seen by companies when they look you up within the platform. 
                        </p>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="firstname" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        First name
                        </label>
                        <input disabled v-model="user.firstname" type="text" name="first-name" id="first-name" autocomplete="given-name" class="disabled:opacity-90 disabled:cursor-not-allowed mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="lastname" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Last name
                        </label>
                        <input disabled v-model="user.lastname" type="text" name="last-name" id="last-name" autocomplete="family-name" class="disabled:opacity-90 disabled:cursor-not-allowed mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="gender" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Gender
                        </label>
                        <select v-model="user.gender" id="gender" name="gender" autocomplete="gender" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option>Male</option>
                            <option>Female</option>
                            <option>Neither</option>
                        </select>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="age" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Age
                        </label>
                        <input v-model="user.age" id="age" name="age" autocomplete="age" type="number" min="13" max="60" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                    </div>

                    <!-- <div class="sm:col-span-6">
                        <label for="username" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Username
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                        <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-blue-gray-300 bg-blue-gray-50 text-warm-gray-700 dark:text-warm-gray-400 sm:text-sm">
                            workcation.com/
                        </span>
                        <input type="text" name="username" id="username" autocomplete="username" value="someuser" class="flex-1 block w-full min-w-0 border-blue-gray-300 rounded-none rounded-r-md text-warm-gray-700 dark:text-warm-gray-400 sm:text-sm focus:ring-blue-500 focus:border-blue-500" />
                        </div>
                    </div> -->

                    <div class="sm:col-span-6">
                        <label for="photo" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Photo
                        </label>
                        <div class="mt-1 flex items-center">
                        <DefaultUser v-if="!this.user.photo" class="inline-block h-12 w-12"/>
                        <img v-else :src="this.user.photo" class="inline-block object-cover h-12 w-12 rounded-full"/>
                        <div class="ml-4 flex">
                            <div class="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
                            <label for="user-photo" class="relative text-sm font-medium text-warm-gray-700 dark:text-warm-gray-600 pointer-events-none">
                                <span>Change</span>
                                <span class="sr-only"> user photo</span>
                            </label>
                            <input id="user-photo" @change="someHandler" name="user-photo" type="file" accept="image/*" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" />
                            </div>
                            <span v-if="this.tempFileName" class="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">
                                Selected: {{this.tempFileName}}
                            </span>
                            <button v-if="this.tempFileName" @click="emptyFile" type="button" class="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-red-700 dark:text-red-400 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">
                            Remove
                            </button>
                        </div>
                        </div>
                    </div>

                    <div class="sm:col-span-6">
                        <label for="description" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        About Me
                        </label>
                        <div class="mt-1">
                        <textarea id="description" name="description" rows="4" class="block w-full border bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500" />
                        </div>
                        <p class="mt-3 text-sm text-warm-gray-700 dark:text-warm-gray-400">Brief description of yourself.</p>
                    </div>

                    </div>

                    <!-- Contact Info -->
                    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                    <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-warm-gray-700 dark:text-warm-gray-400">Contact Information</h2>
                        <p class="mt-1 text-sm text-warm-gray-700 dark:text-warm-gray-400">
                            This information is not public. However, companies looking for prospects will be able to see them so make sure they are up to date.
                        </p>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="email" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Email
                        </label>
                        <input disabled v-model="user.email" type="text" name="email" id="email" autocomplete="street-address" class="disabled:opacity-90 disabled:cursor-not-allowed mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="phone" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Phone
                        </label>
                        <div class="mt-1 rounded-md shadow-sm flex">
                            <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                            +639
                            </span>
                            <input v-model="user.phone" type="text" name="phone" id="phone" autocomplete="phone" class="focus:ring-yellow-500 focus:border-yellow-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
                        </div>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="street" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Street
                        </label>
                        <input v-model="user.street" type="text" name="street-address" id="street-address" autocomplete="street-address" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="city" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        City
                        </label>
                        <input v-model="user.city" type="text" name="city" id="city" autocomplete="address-level2" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="region" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Region
                        </label>
                        <input v-model="user.region" type="text" name="region" id="region" autocomplete="address-level1" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    <div class="sm:col-span-3">
                        <label for="postal" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Postal
                        </label>
                        <input v-model="user.postal" type="text" name="postal-code" id="postal-code" autocomplete="postal-code" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" />
                    </div>

                    </div>

                    <div class="pt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                    <div class="sm:col-span-6">
                        <h2 class="text-xl font-medium text-warm-gray-700 dark:text-warm-gray-400">Academic Information</h2>
                        <p class="mt-1 text-sm text-warm-gray-700 dark:text-warm-gray-400">
                            Your academic progression will be seen by companies when they look you up within the platform. If you don't see your school on the options, you can select the default schools for "AVT School", "AMT School" and "Engineering School". 
                        </p>
                    </div>

                    <div class="sm:col-span-6">
                        <label for="school" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                            School *
                        </label>

                        <select v-model="user.school" id="school" name="school" autocomplete="school" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option v-for="school in schools" :key="school.id" :value="school.id" :selected="(school.id == user.school) ? 'true' : 'false'">{{school.name}}</option>
                        </select>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="degree" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Degree *
                        </label>
                        <select v-model="user.degree" id="degree" required name="degree" autocomplete="degree" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option>Associate’s (2 years)</option>
                            <option>Bachelor’s (4 years)</option>
                            <option>Engineering (5 years)</option>
                            <option>Master’s</option>
                            <option>Vocational</option>
                        </select>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="course" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Course *
                        </label>
                        <select v-model="user.course" id="course" name="course" autocomplete="course" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option>Aircraft Maintenance Technology (AMT)</option>
                            <option>Aircraft Avionics/Electrical (AVT)</option>
                            <option>Aircraft/Aerospace Engineering (AE)</option>
                        </select>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="level" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Level
                        </label>
                        <select v-model="user.level" id="level" name="level" autocomplete="level" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option>Undergraduate</option>
                            <option>Graduate</option>
                            <option>OJT</option>
                            <option>Professional</option>
                        </select>
                    </div>

                    <div class="sm:col-span-3">
                        <label for="batch" class="block text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400">
                        Batch
                        </label>
                        <select v-model="user.batch" id="batch" name="batch" autocomplete="batch" class="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option>2025</option>
                            <option>2024</option>
                            <option>2023</option>
                            <option>2022</option>
                            <option>2021</option>
                            <option>2020</option>
                            <option>2019</option>
                            <option>2018</option>
                            <option>2017</option>
                            <option>2016</option>
                            <option>2015</option>
                            <option>2014</option>
                            <option>2013</option>
                            <option>2012</option>
                            <option>2011</option>
                            <option>2010</option>
                            <option>2009</option>
                            <option>2008</option>
                            <option>2007</option>
                            <option>2006</option>
                            <option>2005</option>
                            <option>2004</option>
                            <option>2003</option>
                            <option>2002</option>
                            <option>2001</option>
                            <option>2000</option>
                        </select>
                    </div>

                    <p class="text-sm text-warm-gray-700 dark:text-warm-gray-400 sm:col-span-6">This account was created on <time datetime="2017-01-05T20:35:40"> {{this.moment.unix(user.date_created/1000).format("MMMM Do YYYY - hh:mm:ss A") }}</time>.</p>
                    </div>

                    <div class="pt-8 flex justify-end">
                    <!-- <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-warm-gray-700 dark:text-warm-gray-400 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        Cancel
                    </button> -->
                    <!-- @TODO: Add infication that save was successful -->
                    <button id="saveButton" :disabled="this.isUpdating" @click="validateData()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-25">
                        {{ this.updateButtonText }} 
                    </button>
                    </div>
                </form>

                <!-- <button id="uploadButton" :disabled="this.isUpdating" @click="updateUserImage()" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-25">
                    Test Upload Image
                </button> -->

                </div>
            </div>
            </div>
        </div>
    </main>
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
const db = firebase.firestore();

import moment from 'moment';

import DefaultUser from "@/components/DefaultUser";
import LoadingScreen from "@/components/appshell/LoadingScreen"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"

export default {
    components: {
        DefaultUser, LoadingScreen
    },
    data(){
        return {
            selectedSchool: "Choose School",
            isLoading: true,
            isUpdating: false,
            user: null,
            needsRefresh: false,
            tempFileName: null,
            updateButtonText: "Update Account Info",
            schools: [],
            moment: moment,
        }
    },

    methods: {
        updateUserData(){
            
            /**
             * @TODO: Data Verification
             */

                // Update User Data
                db.collection("users").doc(firebase.auth().currentUser.uid).update(this.user)
                .then(() => { 
                    // console.log("User data successfully updated.") 

                    // Update Leaderboard User Info
                    db.collection("leaderboard").doc(firebase.auth().currentUser.uid).update(
                        { 
                            firstname: this.user.firstname,
                            lastname: this.user.lastname,
                            course: this.user.course,
                            degree: this.user.degree,
                            school: this.user.school,
                            school: this.user.school,
                            photo: this.user.photo,
                        })
                    .then(() => { 


                        // console.log("User leaderboard successfully updated.") 
                        this.isUpdating = false;
                        this.updateButtonText = "Successfully Updated!"

                        if(this.needsRefresh){
                            this.$router.go()
                        }else{
                            setTimeout(() => {
                                this.updateButtonText = "Update Account Info"
                                // console.log(this.user)
                            }, 3000);
                        }
                        
                    })
                    .catch((error) => { console.error("Error updating leaderboard: ", error) });
                        this.isUpdating = false;
                        this.updateButtonText = "Update Account Info"
                    })
                .catch((error) => { console.error("Error updating user data: ", error) });
            

        },

        validateData(){
            // Temporary null check
            if( this.user.firstname == "" || 
                this.user.lastname == "" || 
                this.user.school == "" || 
                this.user.course == "" || 
                this.user.degree == "")
            { 
                alert("Make sure to fill up all the required fields")
            }else{
                this.updateUserImage()
            }
        },

        updateUserImage(){

            this.isUpdating = true;
            this.updateButtonText = "Updating..."

            // console.log("Updating user image");
            
            var storageRef = firebase.storage().ref();
            var userPicImagesRef = storageRef.child(`images/users/${this.user.id}.jpg`);
            const selectedFile = document.getElementById('user-photo').files[0];

            // console.log(selectedFile)

            if(selectedFile){
                // 'file' comes from the Blob or File API
                userPicImagesRef.put(selectedFile).then((snapshot) => {
                    snapshot.ref.getDownloadURL().then( (downloadURL) => {
                        // console.log("User image updated");
                        // console.log("File available at", downloadURL);
                        this.user.photo = downloadURL
                        this.needsRefresh = true
                        this.updateUserData();
                    })
                })
            }else{
                // console.log("No file added");
                this.updateUserData();
            }
            
        },

        someHandler(event){
            // console.log("User updared the file")
            // tempFileName
            // console.log('Selected file: ' + event.target.files.item(0).name);
            // console.log('Selected file: ' + event.target.files.item(0).size);
            // console.log('Selected file: ' + event.target.files.item(0).type);
            
            var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
            
            // console.log(`File size: ${filesize}MB`)
            
            if(filesize > 2){
                alert("File size of 2MB exceeded")
                this.emptyFile()
            }else{
                this.tempFileName = event.target.files.item(0).name
                // console.log(event)
            }
        },

        emptyFile(){
            
            document.getElementById('user-photo').value = ""
            this.tempFileName = null
            
        }
    },

    async created(){
        this.isLoading = true

        /* CLAIMS */
        let claims = await userService.fetchUserClaims(firebase.auth())
        if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
        
        /* USER */
        this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
        if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });

        /* SCHOOLS */
        this.schools = await schoolService.fetchAllSchools(firebase.firestore())
        this.schools = schoolService.sortSchoolsAlpha(this.schools)

        // console.log(this.user)
        this.isLoading = false

        
        // console.log("Adding overflow-hidden")
        document.body.classList.add("overflow-hidden");
  }
}

/**
 * IMPORTANT: Some data in the user object is restricted (i.e. rank, skillpoints). 
 * For the MVP, we can just use .update() to update specific user data but for future versions,
 * we must put some restrictions in the DB for client to only update the data we allow.
 */

</script>
<template>
    <TransitionRoot as="template" :show="isPrizeModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closePrizeModal()">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closePrizeModal()">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <!-- Slides -->
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
                  
                    <div class="px-6 lg:px-8">
                        <div class="sm:flex sm:items-center">
                            <div class="sm:flex-auto">
                            <h1 class="text-xl font-semibold dark:text-white text-gray-900">Prizes</h1>
                            <p class=" mt-2 text-sm dark:text-gray-200 text-gray-700">For Season 1, Skilltech will give a total of roughly <strong class="font-semibold dark:text-white text-gray-900">30,000 PHP</strong> in prizes. The top 10 will also get a full refund for their Skilltech reviewer purchase.</p>
                            </div>
                            
                        </div>
                        <div class="-mx-6 mt-10 ring-1 ring-gray-500 sm:mx-0 sm:rounded-lg">
                    <table class="w-full divide-y dark:divide-gray-500 divide-gray-500">
                        <thead>
                            <tr>
                            <th scope="col" class="w-1/4 py-3.5 pl-6 pr-3 text-left text-sm font-semibold dark:text-gray-100 text-gray-900">Rank <span class="lg:hidden">& Prize</span></th>
                            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold dark:text-gray-100 text-gray-900 lg:table-cell">Prize</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">1ST 🏆</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>PHP 10,000 and a refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    PHP 10,000 and a refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">2ND 🥈</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>PHP 5,000 and a refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    PHP 5,000 and a refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">3RD 🥉</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>PHP 3,000 and a refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    PHP 3,000 and a refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">4TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>PHP 1,000 and a refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    PHP 1,000 and a refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">5TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>PHP 1,000 and a refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    PHP 1,000 and a refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">6TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>Full refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    Full refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">7TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>Full refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    Full refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">8TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>Full refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    Full refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">9TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>Full refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    Full refund for their reviewer purchase.
                                </td>
                            </tr>
                            <tr class="border-t dark:border-gray-500 border-gray-200">
                                <td class="relative py-4 pl-6 pr-3 text-sm">
                                    <div class="font-black dark:text-gray-100 text-gray-900">10TH 🏅</div>
                                    <div class="mt-1 flex flex-col dark:text-gray-100 text-gray-500 sm:block lg:hidden">
                                        <span>Full refund for their reviewer purchase.</span>
                                    </div>
                                </td>
                                <td class="hidden px-3 py-3.5 text-sm dark:text-gray-100 text-gray-500 lg:table-cell">
                                    Full refund for their reviewer purchase.
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
  
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  </template>
   
  <script>
  
  import { XIcon } from "@heroicons/vue/solid";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  export default {
    props: ["isPrizeModalOpen"],
    components: {
      XIcon,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    methods: {
      closePrizeModal() {
        this.$emit("closePrizeModal");
      },
      
    },
  };
  </script>
<template>
  <div class="caap">
    <!-- Breadcrumbs -->
    <nav class="flex px-2 lg:px-12 justify-between items-center" aria-label="Breadcrumb">
      <div class="flex items-center">
        <router-link :to="claims && claims.caap ? '/caap' : '/reviewers'" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-400"
          aria-current="page">
          <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
        </router-link>
      </div>
      <div class="text-sm font-medium text-warm-gray-500">
        <span class="hidden md:block">CAAP Mock Exam History</span>
        <span class="block md:hidden">Exam History</span>
      </div>
      <!-- FAQ Menu -->
      <Menu as="div" class="ml-3 relative inline-block text-left">
        <MenuButton
          class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          <span class="sr-only">Open options</span>
          <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems
            class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
              <button type="button"
                :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                <span>What am I seeing in this page?</span>
              </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <button
                :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                <span>What is a Practice/Mock Exam?</span>
              </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
              <button
                :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                <span>What are Achievements?</span>
              </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </nav>

    <!-- Main Content -->
    <main class="max-w-full mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="flex justify-between items-center">
        <div>
          <h2 class="text-2xl font-extrabold tracking-tight text-warm-gray-900 dark:text-true-gray-300">
            CAAP Mock Exam History
          </h2>
          <p class="mt-4 text-base text-warm-gray-500 max-w-2xl">
            See the extensive record of your learning progress so far by looking into all your Mock Exam results. Easily
            identify which areas you need to practice more.
          </p>
        </div>
        <!-- FAQ Menu -->
        <Menu as="div" class="ml-3 relative inline-block text-left">
          <MenuButton
            class="px-3 py-2 border dark:border-warm-gray-600 border-warm-gray-300 rounded-md shadow-sm flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
            <span class="sr-only">Open options</span>
            <SortAscendingIcon class="mr-3 h-5 w-5 text-warm-gray-500" aria-hidden="true" />
            Viewing: {{ this.getSelectedSubjectName(this.selected) }}
            <ChevronDownIcon class="ml-2.5 -mr-1.5 h-5 w-5 text-warm-gray-500" aria-hidden="true" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <MenuItems
              class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                <button type="button" key="all-subjects" @click="this.updateSelected('all-subjects')"
                  :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>All Subjects</span>
                </button>
                </MenuItem>
                <MenuItem v-for="subject in subjects" :key="subject.id" @click="this.updateSelected(subject.id)"
                  v-slot="{ active }">
                <button type="button"
                  :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>{{ subject.name }}</span>
                </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div v-if="isLoading" class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div
              class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg bg-true-gray-200 dark:bg-true-gray-800 animate-pulse h-96">
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="my-3 align-middle inline-block min-w-full">
            <div class="border-dashed border-true-gray-200 overflow-hidden sm:rounded-lg">
              <div v-if="this.exams == null || exams.length === 0">
                <div class="text-center p-10">
                  <CursorClickIcon class="mx-auto h-12 w-12 text-true-gray-400" />
                  <h3 class="mt-2 text-sm font-medium dark:text-true-gray-200 text-true-gray-900">No Mock Exams started
                    yet.</h3>
                  <p class="mt-1 text-sm text-true-gray-500">Get started by taking a Mock Exam over the CAAP Reviewer.</p>
                  <div class="mt-6">
                    <button type="button" @click="this.$router.push({ path: '/caap' })"
                      class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      Checkout CAAP Reviewer
                    </button>
                  </div>
                </div>
              </div>
              <!-- DESKTOP -->
              <table v-else class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
                <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                  <tr>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                      Date and Time
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                      Subject
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                      Results
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                      Rating
                    </th>
                    <th scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                      Skillpoints
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                  <!-- <tr v-for="exam in exams" :key="exam.id" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''"> -->
                  <tr v-for="exam in selectedSubjects" :key="exam.id">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div
                        :class="[(exam.status == 'completed') ? 'cursor-pointer hover:opacity-75 text-true-gray-900 dark:text-true-gray-100' : 'text-true-gray-300 dark:text-true-gray-400', 'flex items-center']"
                        @click="(exam.status == 'completed') ? redirectToExamHistory(exam.id, exam.time.start, exam.status) : null">
                        <!-- <div class="flex-shrink-0 h-10 w-10">
                          <img v-if="user.imageUrl" class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
                          <DefaultUser v-else class="block mx-auto h-10 w-10" />
                        </div> -->
                        <div class="ml-4">
                          <div class="text-sm font-medium flex items-center space-x-1">
                            <!-- {{ user.firstname }} {{ user.lastname }} -->
                            <!-- <div>{{ exam.id }}</div> -->
                            <span>{{ this.moment(exam.time.start).format("MMMM Do YYYY - hh:mm:ss A") }}</span>
                            <ExternalLinkIcon
                              v-if="isMockExamHistoryAvailable(exam.time.start) && exam.status == 'completed'"
                              class="w-4 h-4 text-indigo-400" />
                          </div>
                          <div class="text-sm text-true-gray-500 dark:text-true-gray-400">
                            <!-- {{ user.email }} -->
                            <!-- {{exam.time.start}} -->
                            Started {{ this.moment(exam.time.start).startOf('minutes').fromNow() }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                      <!-- {{ this.getOrdinal(user.rank) }} -->
                      {{ this.getSelectedSubjectName(exam.subject) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap ">
                      <div v-if="exam.result && exam.status == 'completed'"
                        class="text-sm text-true-gray-900 dark:text-true-gray-100">
                        <!-- {{ user.school }} -->
                        Score: {{ exam.score.toFixed(2) }}%
                      </div>
                      <div v-else-if="exam.status == 'started'"
                        class="text-sm text-true-gray-500 dark:text-true-gray-400">
                        <!-- {{ user.degree }} - {{ user.course }} -->
                        Status: INCOMPLETE
                      </div>
                      <div v-else class="text-sm text-true-gray-500 dark:text-true-gray-400">
                        <!-- {{ user.degree }} - {{ user.course }} -->
                        Status: {{ exam.status.toUpperCase() }}
                      </div>
                      <div class="text-sm text-true-gray-500 dark:text-true-gray-400">
                        <!-- {{ user.degree }} - {{ user.course }} -->
                        Time: {{ this.formatTime(exam.time.running) }}
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span v-if="exam.result && exam.status == 'completed'"
                        :class="[(exam.result == 'failed') ? 'bg-red-400 hover:bg-red-500' : 'bg-green-400 hover:bg-green-500', 'ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700']">
                        <!-- <Logo class="w-3.5 h-3.5 mr-1" />
                          {{ user.skillpoints }} -->
                        {{ this.computeRating(exam.score, exam.time.running, exam.time.limit) }}%
                      </span>
                      <span v-else
                        class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-red-400 hover:bg-red-500">
                        0.00%
                      </span>

                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span v-if="exam.skillpoints && exam.skillpoints > 0"
                        class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        +{{ exam.skillpoints }}
                      </span>
                      <span v-else
                        class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" /> 0
                      </span>

                    </td>

                  </tr>
                </tbody>
              </table>

              <div v-if="!this.isEnd" class="w-full flex justify-center mt-4">
                <button @click="fetchMoreExams()" :disabled="this.isWaiting" type="button" class="disabled:opacity-20 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Load More Exams
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </main>
  </div>
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import Logo from "@/components/Logo";

import { getDocs, collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc, limit, where, startAfter } from "firebase/firestore"

const db = firebase.firestore();



// Import UI dependencies
import { ExternalLinkIcon, ChevronDoubleLeftIcon, ChevronDownIcon, SortAscendingIcon, QuestionMarkCircleIcon, PlusIcon, CursorClickIcon } from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import moment from "moment";

import { userService } from '@/utils/user'
import { reviewerService } from '@/utils/reviewer'

export default {
  components: {
    ExternalLinkIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PlusIcon, CursorClickIcon, ChevronDownIcon, SortAscendingIcon,
    Menu, MenuButton, MenuItem, MenuItems,
    Logo
  },
  data() {
    return {
      user: null,
      isLoading: true,
      exams: [],
      subjects: [],
      moment: moment,
      selected: "all-subjects",
      lastDoc: null,
      isEnd: false,
      isWaiting: false,
      claims: null,
    }
  },
  name: "Caap Mock Exam History",
  computed: {
    selectedSubjects() {
      if (this.selected == "all-subjects") {
        return this.exams;
      } else {
        let filtered = this.exams.filter((e) => { return (e.subject == this.selected) })
        return filtered
      }
    }
  },
  // mounted() {
  //   firebase.auth().currentUser.getIdTokenResult()
  //     .then((tokenResult) => {
  //       (tokenResult.claims.caap) 
  //         ? this.caapPurchased = true 
  //         : this.$router.push({ path: "/reviewers" });
  //     });
  // },
  async created() {
    this.isLoading = true

    /* CLAIMS - Guard Clause */
    let claims = await userService.fetchUserClaims(firebase.auth())
    this.claims = claims;
    if (!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    if (!claims.caap && claims.isBlocked) this.$router.push({ path: '/reviewers' })  // reroute non-caap members

    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if (!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });

    var uid = firebase.auth().currentUser.uid
    // this.exams = await userService.fetchUserExams(db, uid)
    this.exams = await this.initFetchExams(db, uid);
    
    // if no exams found
    if(this.exams == null){
      this.isEnd = true;
    }

    // if there are exams but query has less 10, then it's already the end.
    if(this.exams && this.exams.length < 10){
      this.isEnd = true;
    }
    this.subjects = await reviewerService.fetchAllSubjects(db, "caap")

    this.isLoading = false
  },
  methods: {
    isMockExamHistoryAvailable(examTimeStart) {
      let timestamp_of_feature_release = 1688130323917;
      if (examTimeStart < timestamp_of_feature_release) {
        return false
      } else {
        return true
      }
    },
    redirectToExamHistory(exam_id, examTimeStart, status) {
      if (this.isMockExamHistoryAvailable(examTimeStart)) {
        this.$router.push({ path: `/caap/history/mock/${exam_id}` })
      }
    },
    updateSelected(s) {
      this.selected = s;
      // console.log("this.selected has changed to: => ", this.selected)
    },
    getSelectedSubjectName(subject_id) {
      if (this.subjects == null) return
      return (subject_id == "all-subjects") ? "All Subjects" : this.subjects.filter(e => { return (e.id == subject_id) })[0].name
    },
    computeRating(score, time_running, time_limit) {
      /*
        Ratings are computed by following a 50:50 ratio between your recorded Score and Time Left Percentage.

        For Example:
        Username: Tony Stark
        Subject: Spelling
        Total No. of Items: 75 Items
        Time Limit: 15 minutes (900 seconds)

        Mock Exam Score: 69 out of 75 [ 92% ]
        Time Consumed: 7 minutes (420 seconds)
        Time Left: 8 minutes (480 seconds) [ 53.33% of the time limit ]			  

        Rating 	= [ 50% of Score Percentage ] + [ 50% of Time Left Percentage ]
                = [ 50% x 92% ] + [ 50% x 53.33% ]
                = [ 46% ] + [ 26.66% ]
                = 72.66%
      */

      let time_consumed = time_running;
      let time_left = time_limit - time_consumed;
      let time_left_percent = (time_left / time_limit) * 100
      var rating = ((score / 2) + (time_left_percent / 2)).toFixed(2)
      return rating
    },
    formatTime(time) {
      let momentedTime = this.moment("2015-01-01").startOf("day").seconds(time);
      return this.moment(momentedTime).format("HH:mm:ss");
    },
    /**
     * Added October 28
     */
    async initFetchExams(db, userId) {
      return new Promise(async (resolve, reject) => {
        console.log('triggerring this.initFetchExams')
        db.collection("mock")
          .where("user", "==", userId)
          .orderBy("time.start", "desc")
          .limit(10)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size > 0) { // only check if there are existing practice exams started
              var mock_exams = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                mock_exams.push(data)
              });

              this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

              resolve(mock_exams);
            } else {
              // console.log("No previous mock exams were found!")
              resolve(null)
            }
          })
          .catch((error) => {
            console.error("Error getting document => ", error);
            reject("Error getting document")
          })

      })
    },
    async fetchMoreExams(){
      this.isWaiting = true;
      console.log(this.lastDoc)
      let _exams = await this.lazyFetchExams();
      console.log(_exams)
      if(_exams.length < 10){
        this.isEnd = true;
      }
      this.exams = this.exams.concat(_exams);
      this.isWaiting = false;

    },
    async lazyFetchExams() {
      
      let userId = firebase.auth().currentUser.uid
      return new Promise(async (resolve, reject) => {
        console.log('triggerring this.lazyFetchExams')
        db.collection("mock")
          .where("user", "==", userId)
          .orderBy("time.start", "desc")
          .limit(10)
          .startAfter(this.lastDoc)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size > 0) { // only check if there are existing practice exams started
              var mock_exams = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                mock_exams.push(data)
              });
              
              this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

              resolve(mock_exams);
            } else {
              // console.log("No previous mock exams were found!")
              resolve(null)
            }
            
          })
          .catch((error) => {
            console.error("Error getting document => ", error);
            reject("Error getting document")
          })

      })
    },
  }
};
</script>
import Reward from '../views/Reward.vue'
import RewardAssessment from '../views/Assessment.vue'
import firebase from "@/firebase/config"

import store from '@/store';


async function authGuard(to, from, next) {
    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } })
    } 

    if (to.path == '/reward/assessment') {
        
        if (!store.getters.user.info.isExamResultCompleted) {
            next({path: '/reward'})
        }
    }

    next();
}



export const rewardRoutes = [
    {
        path: '/reward',
        name: 'Reward',
        component: Reward,
        beforeEnter: authGuard
    },
    {
        path: '/reward/assessment',
        name: 'Reward-Assessment',
        component: RewardAssessment,
        beforeEnter: authGuard
    },
]

<template>
    <main id="review-container" v-if="!isWaiting"
        class="max-w-full mt-6 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 text-white">

        <nav class="flex justify-between items-center" aria-label="Breadcrumb">
            <div class="flex items-center">
                <div @click="openOnExitModal()"
                    class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer"
                    aria-current="page">
                    <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                </div>
            </div>
            <div class="text-sm font-medium text-warm-gray-500">
                <span>Exam for {{ subtopicName }}</span>
            </div>
            <!-- FAQ Menu -->
            <div>
                <div @click="openTipsModal()"
                    class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer"
                    aria-current="page">
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                </div>
            </div>
        </nav>


        <!-- Stats -->
        <div id="stats" class="mt-8 dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
            <div class="flex dark:text-warm-gray-500 text-warm-gray-800 text-md items-center">
                <FlagIcon class="w-5 h-5 mr-1" />
                <p class="hidden sm:block ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-800">
                    Question {{ exam.index + 1 }} out of {{ exam.questionnaire.length }}
                </p>
                <p class="block sm:hidden ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-800">
                    {{ exam.index + 1 }} of {{ exam.questionnaire.length }}
                </p>
            </div>
            <div class="flex text-warm-gray-800 text-md items-center">
                <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
                    <div class="mr-3 flex items-center dark:text-warm-gray-500 text-warm-gray-700">
                        <ClockIcon class="ml-1 w-5 h-5 mr-1" />
                        {{ formatTime(exam.time.running) }}
                    </div>
                    <div class="flex items-center dark:text-warm-gray-500 text-warm-gray-700">
                        <ChartSquareBarIcon class="ml-1 w-5 h-5 mr-1" />
                        {{ exam.score }}%
                    </div>
                </div>
            </div>
        </div>

        <!-- Exam Has Contexts -->
        <div v-if="hasContext" class="space-y-4">

            <!-- Set Instructions -->
            <div v-if="!isHtmlEmpty(getInstructions(questions[exam.index].tags.typeId))"
                class="select-none text-left text-true-gray-500 dark:text-true-gray-400 rounded-md -space-y-px p-6 bg-true-gray-200 dark:bg-true-gray-700 dark:bg-opacity-25 whitespace-pre-line">
                <div v-if="isInstructionsShown">
                    <p class="mb-4 font-bold">Instructions: </p>
                    <p class="mb-4" v-html="getInstructions(questions[exam.index].tags.typeId)"></p>
                </div>
                <div>
                    <button class="underline text-yellow-500" @click="isInstructionsShown = !isInstructionsShown">{{
        (isInstructionsShown) ? 'Hide' : 'Show' }} Instructions</button>
                </div>
            </div>

            <!-- Context -->
            <div v-if="!isHtmlEmpty(getContext(questions[exam.index].tags.contextId))"
                class="select-none text-left text-blue-500 dark:text-blue-400 rounded-md -space-y-px p-6 bg-blue-50 dark:bg-blue-900 dark:bg-opacity-25 whitespace-pre-line">
                <div v-if="isContextShown">
                    <p class="mb-4 font-bold">Context: </p>
                    <p class="mb-4" v-html="getContext(questions[exam.index].tags.contextId)"></p>
                </div>
                <div>
                    <button class="underline text-yellow-500" @click="isContextShown = !isContextShown">{{
        (isContextShown) ? 'Hide' : 'Show' }} Context</button>
                </div>
            </div>

        </div>
        <div v-else>
            <div v-if="!isHtmlEmpty(questions[exam.index].instructions)"
                class="mt-8 select-none text-left text-true-gray-500 dark:text-true-gray-400 rounded-md -space-y-px p-6 bg-true-gray-200 dark:bg-true-gray-700 dark:bg-opacity-25 whitespace-pre-line">
                <div v-if="isInstructionsShown">
                    <p class="mb-4 font-bold">Instructions: </p>
                    <p class="mb-4" v-html="questions[exam.index].instructions"></p>
                </div>
                <div>
                    <button class="underline text-yellow-500" @click="isInstructionsShown = !isInstructionsShown">{{
        (isInstructionsShown) ? 'Hide' : 'Show' }} Instructions</button>
                </div>
            </div>
        </div>


        <!-- Question -->

        <div v-if="!isHtmlEmpty(questions[exam.index].questionHtml)"
            class="mt-4 select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line">
            <span v-html="questions[exam.index].questionHtml"></span>
        </div>


        <!-- Choices -->
        <div id="choices" class="select-none mt-4">
            <ul class="text-warm-gray-800 dark:text-warm-gray-400 text-left rounded-md shadow-sm -space-y-px">
                <li v-for="(choice, idX) in exam.questionnaire[exam.index].choices" :key="idX">
                    <button @click="exam.answers[exam.index].answer = exam.questionnaire[exam.index].choices[idX].id"
                        :class="[showSelected(idX), setBorder(idX), 'flex justify-between border border-warm-gray-300 dark:border-true-gray-700 relative shadow-sm px-5 py-4 cursor-pointer text-left focus:outline-none w-full disabled:cursor-auto']">
                        <div v-html="choice.choiceHtml" class="w-full"></div>
                    </button>
                </li>
            </ul>
        </div>

        <!-- Action Buttons -->
        <nav class="mt-6 flex justify-between space-x-4">
            <button :disabled="!isSkippable" @click="skipQuestion()"
                class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Skip
                <span
                    class="inline-flex items-center justify-center ml-1 px-2 py-1 text-xs font-bold leading-none text-white bg-blue-400 rounded-full">
                    {{ exam.skips }}
                </span>
            </button>

            <!--  -->
            <button v-if="unansweredQuestions > 0" @click="transitionToNextQuestion()"
                :disabled="exam.answers[exam.index].answer == null"
                class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Next
                <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1" />
            </button>

            <button v-else @click="completeMock()" :disabled="exam.answers[exam.index].answer == null"
                class="disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Complete Exam
            </button>
        </nav>

    </main>
    <div v-else class="flex justify-center mt-8">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
        <div class="text-true-gray-700">Preparing mock exam session...</div>
    </div>

    <!-- Popup: On Complete -->
    <TransitionRoot appear :show="isOnCompleteOpen" as="template">
        <Dialog as="div">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0"
                        enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100"
                        leave-to="opacity-0">
                        <DialogOverlay
                            class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                    </TransitionChild>
                    <span class="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <div
                            class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">

                            <DialogTitle as="h3"
                                class="text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                                Mock Exam Results
                            </DialogTitle>

                            <div class="mt-2">
                                <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                                    <p v-if="exam.milestones.includes('master')">You’ve mastered it! 🥳🔥</p>
                                    <p v-else-if="exam.milestones.includes('speedrunner')">Fast like a rocket! 😲🚀</p>
                                    <p v-else-if="exam.milestones.includes('passer')">You passed! 😉👍🏼</p>
                                    <p v-else>Try harder next time. 🙁👎</p>
                                </div>
                                <p class="text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-1 mb-2">
                                    You got {{ countCorrectAnswers() }} correct answers out
                                    of {{ exam.questionnaire.length }} items.
                                </p>
                                <div
                                    class="flex flex-col justify-center space-y-2 my-2 flex-grow-0 dark:text-warm-gray-400 text-warm-gray-700">
                                    <div>Score: {{ exam.score }}% <span v-if="isRecordBreakScore"
                                            class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span></div>
                                    <div>Time: {{ formatTime(exam.time.running) }} <span v-if="isRecordBreakTime"
                                            class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span></div>
                                </div>
                                <div v-if="exam.result == 'passed'"
                                    class="text-sm dark:text-warm-gray-400 text-warm-gray-500 max-w-sm mx-auto">
                                    <p class=" text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-8">
                                        Your reward:
                                    </p>
                                    <div
                                        class="rounded-md dark:bg-true-gray-700 dark:bg-opacity-25 bg-warm-gray-100 overflow-hidden text-sm  text-left mt-2 w-full">
                                        <div
                                            class="my-4 flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-4 text-center items-center justify-center p-2">
                                            <div v-if="exam.milestones.includes('passer')" class="w-28">
                                                <div class="mx-auto bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                                    <PaperClipIcon class="text-blue-500 w-6 h-6" />
                                                </div>
                                                <h3
                                                    class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                                                    Passer</h3>
                                                <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ subtopic.exam.points.passer }} SP</p> -->
                                                <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+0 SP</p>
                                            </div>
                                            <div v-if="exam.milestones.includes('speedrunner')" class="w-28">
                                                <div
                                                    class="mx-auto bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                                    <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                                                </div>
                                                <h3
                                                    class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                                                    Speedrunner</h3>
                                                <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ subtopic.exam.points.speedrunner }} SP</p> -->
                                                <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+0 SP</p>
                                            </div>
                                            <div v-if="exam.milestones.includes('master')" class="w-28">
                                                <div
                                                    class="mx-auto bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                                                    <FireIcon class="text-yellow-500 w-6 h-6" />
                                                </div>
                                                <h3
                                                    class="dark:text-true-gray-300 text-warm-gray-800 text-base font-bold my-1">
                                                    Master</h3>
                                                <!-- <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+{{ subtopic.exam.points.master }} SP</p> -->
                                                <p class="dark:text-true-gray-400 text-warm-gray-600 text-sm">+0 SP</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="exam.result == 'failed'"
                                    class="bg-gray-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                                    <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                                        <div>
                                            <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                                        </div>
                                        <p class="text-yellow-700">
                                            <strong>Tip:</strong> {{ tips[Math.floor(Math.random() * tips.length)] }}
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-8 sm:mt-6 sm:flex sm:space-x-4">
                                    <button @click="exitMock()" type="button"
                                        class="my-1.5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:opacity-25"
                                        :disabled="isBusy">
                                        Exit
                                    </button>
                                    <!-- <button @click="exitMock(true)" type="button" class="my-1.5 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Review Exam Results
                      </button> -->
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Exit Modal -->
    <TransitionRoot as="template" :show="isOnExitOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="isOnExitOpen = false">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0"
                    enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay
                        class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                </TransitionChild>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>
                <TransitionChild as="template" enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4"></div>
                        <div class="sm:flex sm:items-start">
                            <div
                                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <DialogTitle as="h3"
                                    class="text-lg leading-6 font-medium dark:text-gray-200 text-warm-gray-900">
                                    Before you go...
                                </DialogTitle>
                                <div class="mt-2">
                                    <p class="text-sm text-warm-gray-500 dark:text-warm-gray-400">
                                        Please note that your progress in this Exam will
                                        <strong>not</strong> be saved. You can always come back later to start a new
                                        Exam session.
                                    </p>
                                    <p class="text-sm text-gray-500 dark:text-warm-gray-400 max-w-sm mt-4">
                                        Are you sure you want to proceed?
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button @click="quitMock()" type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-25"
                                :disabled="isBusy">
                                Quit
                            </button>
                            <button @click="(isOnExitOpen = false), (exam.status = 'started')" type="button"
                                class="mt-3 w-full inline-flex justify-center text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-700 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 rounded-md border dark:border-warm-gray-600 border-warm-gray-300 shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm disabled:opacity-25"
                                :disabled="isBusy">
                                Cancel
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <!-- Timed Out Modal -->
    <TransitionRoot appear :show="isOnTimedOutOpen" as="template">
        <Dialog as="div">
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0"
                        enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100"
                        leave-to="opacity-0">
                        <DialogOverlay
                            class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                    </TransitionChild>
                    <span class="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95">
                        <div
                            class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                            <DialogTitle as="h3"
                                class="text-sm uppercase font-semibold leading-6 text-gray-500 text-center">
                                Practice Exam Results
                            </DialogTitle>
                            <div class="mt-2">
                                <div class="text-3xl font-bold dark:text-gray-200 text-warm-gray-900 mt-8">
                                    <p>You ran out of time. 🙁⌛️</p>
                                </div>
                                <div
                                    class="flex flex-col justify-center space-y-0 my-3 flex-grow-0 dark:text-warm-gray-400 text-warm-gray-700">
                                    <div class="flex flex-inline items-center mx-auto">
                                        <ChartSquareBarIcon class="ml-1 w-5 h-5 mr-2" />
                                        <div>
                                            {{ exam.score }}% <span v-if="isRecordBreakScore"
                                                class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-inline items-center mx-auto">
                                        <ClockIcon class="ml-1 w-5 h-5 mr-2" />
                                        <div>
                                            {{ formatTime(exam.time.running) }} <span v-if="isRecordBreakTime"
                                                class="ml-1 text-yellow-400 font-bold text-xs">NEW RECORD!</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="bg-yellow-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                                    <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                                        <div>
                                            <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                                        </div>
                                        <p class="text-yellow-700">
                                            <strong>Tip:</strong> {{ tips[Math.floor(Math.random() * tips.length)]}}
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-8 sm:mt-6">
                                    <button @click="quitMock()" type="button"
                                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm disabled:opacity-25"
                                        :disabled="isBusy">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
<script setup>
import ROUTER from '@/router'
import { useRoute } from 'vue-router';
import firebase from '@/firebase/config'
import { ref, computed, onMounted } from 'vue'
import { criteria, tips } from '../instructions'

// Import UI dependencies
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { FlagIcon, ChevronDoubleRightIcon, ClockIcon, ChartSquareBarIcon, LogoutIcon, LightBulbIcon, QuestionMarkCircleIcon, ExclamationIcon, LightningBoltIcon, FireIcon, PaperClipIcon } from "@heroicons/vue/outline"

const route = useRoute();

const isWaiting = ref(true)
const subtopicId = computed(() => ROUTER.currentRoute.value.query.subtopicId)
const subtopicName = computed(() => ROUTER.currentRoute.value.query.subtopicName)

const user = ref(null);
const questionnaire = ref(null)
const tags = ref([])
const tagData = ref([])
const tagQuestionLinks = ref([])
const questions = ref(null)
const exam = ref(null)
const subtopic = ref(null)

const isInstructionsShown = ref(true)
const isContextShown = ref(true)
const hasContext = ref(null)
const isRecordBreakScore = ref(false)
const isRecordBreakTime = ref(false)
const isAchievementUnlocked = ref(false)
const isBusy = ref(false)

const isOnExitOpen = ref(false)
const isOnTimedOutOpen = ref(false)
const isOnCompleteOpen = ref(false)

const getInstructions = (tagId) => {
    return tags.value.filter(e => e.id == tagId)[0].description
}

const getContext = (tagId) => {
    return tags.value.filter(e => e.id == tagId)[0].description
}

/**
 * UI Variables
 */
const timer = ref(null)
const examTimeLimit = ref(null)

/**
 * Fetch user info from database 
 */
const fetchUser = () => {

    return new Promise(async (resolve, reject) => {
        let uid = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc(uid).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

/**
 * Functions that connects to the Firebase DB.
 * This one will fetch a tag based on its ID.
 */
const fetchQuestionnaireBySubtopicId = (subtopicId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("questionnaires").doc(subtopicId).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

const fetchTagById = (tagId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags").doc(tagId).get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()
                    data.id = doc.id
                    resolve(data)
                } else {
                    throw new Error(`No tag was found with tag ID: ${tagId}.`)
                }
            }).catch((error) => {
                reject(error)
            })

    })
}

const fetchTagQuestionLinkByTagId = (tagId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags_questions").where("tagId", "==", tagId).get()
            .then((querySnapshot) => {
                var results = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    results.push(data)
                });
                resolve(results)
            })
            .catch((error) => {
                console.error("Error getting tag--questions link: ", error);
                reject(error)
            })
    })
}

/**
 * This function will retrieve all the tag data from a specific tag ID.
 * It will call the database.
 */
const fetchTagData = (tagId) => {

    console.log('====>', tagId);
    
    return new Promise(async (resolve, reject) => {
        try {
            /**
             * Append the tagData array everytime
             * a result was returned.
             */
            let _tagData = await fetchTagById(tagId)
            tagData.value = [...tagData.value, _tagData]
            resolve(true)
        } catch (error) {
            /**
             * If an error happens during fetch,
             * then the promise all will fail.
             */
            reject(error)
        }
    })
}

const processTagQuestionLinks = (structure) => {
    return new Promise(async (resolve, reject) => {
        try {
            /**
             * Append the tag questions link array everytime
             * a result was returned.
             */
            let _tagQuestionLinks = await fetchTagQuestionLinkByTagId(structure['question-type-id'])

            /**
             * pick random items depending on the exam structure criteria
             * and merge it with the existing tagQuestionLinks.
             * By the end of it, we will have an array of random questions.
             */
            let randomTagQuestionLinks = getRandomItems(_tagQuestionLinks, structure['total'])
            let originalTagQuestionLinks = tagQuestionLinks.value // assining it here to avoid any errors
            let mergedArray = randomTagQuestionLinks.concat(originalTagQuestionLinks)
            tagQuestionLinks.value = mergedArray

            resolve(true)
        } catch (error) {
            /**
             * If an error happens during fetch,
             * then the promise all will fail.
             */
            reject(error)
        }
    })
}

const generateExamSheet = () => {
    // Create an 'answers' array with null values that matches the questionnaire size
    let answers = [];
    questions.value.forEach(() => answers.push({ answer: null }));

    let _questions = questions.value.map(question => ({
            ...question,
            choices: shuffle([...question.choices])
        }));

    // Create the exam data model
    return {
        user: firebase.auth().currentUser.uid,
        result: null,
        score: 0,
        skips: 3,
        status: "started",
        subtopic: subtopicId.value,
        time: {
            start: Date.now(),
            running: 0,
            end: 0,
            limit: 1300
        },
        index: 0,
        progress: 0,
        questionnaire: _questions,
        answers: answers,
        milestones: [],
        skillpoints: 0
    }
}

// CONNECTS TO FIRESTORE: retreive all the mtp reviewer's tags from the tags collection
const fetchTagsByReviewer = (reviewerId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags").where("reviewerId", "==", reviewerId).get()
            .then((querySnapshot) => {
                var tags = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    tags.push(data)
                });
                resolve(tags)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })
}


const fetchTagQuestionLinkByTagIdV2 = (subtopicId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags_questions").where("questionnaireId", "==", subtopicId).get()
            .then((querySnapshot) => {
                var results = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    results.push(data)
                });
                resolve(results)
            })
            .catch((error) => {
                console.error("Error getting tag--questions link: ", error);
                reject(error)
            })
    })
}

function generateUniqueRandomNumbers(N, R) {
    if (N > R + 1) {
        throw new Error('N cannot be greater than R + 1 when requiring unique numbers.');
    }

    let randomNumbers = new Set();
    while (randomNumbers.size < N) {
        const randomNumber = Math.floor(Math.random() * (R + 1));
        randomNumbers.add(randomNumber);
    }
    return Array.from(randomNumbers);
}


function findContextAndTypeIdByNumber(data, number) {
    for (const [typeId, contextList] of Object.entries(data)) {
        for (const contextObj of contextList) {
            for (const [contextId, numbers] of Object.entries(contextObj)) {
                if (numbers.includes(number)) {
                    return { typeId, contextId };
                }
            }
        }
    }
    return null; // Return null if the number isn't found
}

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/**
 * Function that runs the first time the page is mounted.
 */
const initialize = async () => {

    isWaiting.value = true
    try {

        user.value = await fetchUser();

        /**
         * Retreive the subtopic id passed. 
         * If there is an exam criteria for it, proceed normally.
         * Otherwise, return an error.
         */
        if (!criteria[subtopicId.value]) throw new Error('Criteria not found.')

        /**
         * Next, retreive the entire questionnaire of this subtopic and all the tags
         */
        questionnaire.value = await fetchQuestionnaireBySubtopicId(subtopicId.value)
        tags.value = await fetchTagsByReviewer('mtp')

        /** 
         * Set the hasContext flag
         */
        hasContext.value = criteria[subtopicId.value]['hasContext']

        /**
         * 
         * NOTE: THIS NEEDS REFACTORING
         * 
         * If the exam has contexts in the structure, it will have 
         * a different way of generating the questionnaire
         */
        if (hasContext.value) {

            let typeContextMapping = {}

            // gets all context ids of current subtopic
            let questionContextsRaw = tags.value.sort().filter(e => e.shorthand.includes(`${subtopicId.value}-context`)) // find contexts in tags collection
            let questionContexts = questionContextsRaw.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
            let contextTags = questionContexts.map(e => e.id)

            console.log(contextTags)

            // gets all question type ids of current subtopic question types which is "v4" (i.e. v4-a, v4-b)
            let questionType = criteria[subtopicId.value]['typeBase']

            let questionTypes = tags.value.sort().filter(e => e.shorthand.includes(questionType)) // find contexts in tags collection
            let typeTags = questionTypes.map(e => e.id)

            let questionTagLinks = await fetchTagQuestionLinkByTagIdV2(subtopicId.value)

            // map the types with the context
            typeTags.forEach(typeTag => {
                typeContextMapping[typeTag] = []
                contextTags.forEach(contextTag => {
                    let contextMap = {}

                    let questionsWithTypeTag = questionTagLinks.filter(e => e.tagId == typeTag).map(e => e.questionId)
                    let questionsWithContextTag = questionTagLinks.filter(e => e.tagId == contextTag).map(e => e.questionId)
                    let commonQuestions = questionsWithTypeTag.filter(element => questionsWithContextTag.includes(element))

                    if (commonQuestions.length > 0) {
                        contextMap[contextTag] = commonQuestions
                        typeContextMapping[typeTag].push(contextMap)
                    }
                })
            })





            /**
             * Loop through the list of 'structures' in the JSON files
             * i.e. 
             *  - Context 1 froom Question Type N1-A
             *  - Context 2 froom Question Type N1-B
             *  - etc...
             * 
             * and extract the questions
             */

            var _questions = []
            let q_structure = criteria[subtopicId.value]['structure']
            q_structure.forEach(q_struct => {

                let q_type = q_struct['question-type-id'] // question type from strcuture
                let contextTotal = q_struct['context-total'] // total number of context we need to take from the contexts and save to the questionnaire
                let questionnaireContextTotal = contextTags.length // actual total number of contexts from the list of tags
                let randomPicks = generateUniqueRandomNumbers(contextTotal, questionnaireContextTotal - 1) // pick random contexts to display in this exam (via the indeces)
                let contextIds = randomPicks.map(index => contextTags[index]) // using the randomPicks indeces, map the associated contextTags. Now we'll have the context IDs to use in the next step


                /**
                 * From the type--context mapping, we need to retreive
                 * the question IDs based on the structure (taken from the JSON file)
                 * 
                 * We basically check the type and contexts,
                 * retreive the the question IDs and store them as plain numbers (ids)
                 */
                let q_questionIds = []; // the question IDs
                if (typeContextMapping[q_type]) { // Check if the context exists
                    typeContextMapping[q_type].forEach(contextObj => {
                        const contextId = Object.keys(contextObj)[0]; // Assuming each object has only one key
                        if (contextIds.includes(contextId)) {
                            q_questionIds = q_questionIds.concat(contextObj[contextId]); // Concatenate the numbers
                        }
                    })
                }
                let q_questionnaire = questionnaire.value.questions.filter(question => q_questionIds.includes(question.id))


                /**
                 * Modify the final questions 
                 * Attach the necessary tag Ids and context Ids
                 */
                let q_tagIds = q_questionIds.map(e => findContextAndTypeIdByNumber(typeContextMapping, e))
                q_questionnaire.forEach((question, index) => {
                    q_questionnaire[index].tags = q_tagIds[index]
                })

                /**
                 * append the temporary questions variable
                 */
                _questions = [..._questions, ...q_questionnaire]

            });

            /** 
             * Store all the final questions 
             */
            questions.value = _questions


        } else {

            /**
             * If the exam does not have contexts in the structure, 
             * it will have its own way of structuring the exam
             */

            /**
             * Once a criteria is found, we need to retreive the
             * tag data of the question types associated with this exam.
             * This way, we will get the "instructions" (i.e. tag.description) 
             * and other data needed.
             * 
             * To do this, we need to go over each tag ids in the exam structure.
             * Store each DB fetch as a promise and do a primise all to fetch each of them.
             */
            const tagFetchPromises = criteria[subtopicId.value]['structure'].map(async item => fetchTagData(item['question-type-id']))
            await Promise.all(tagFetchPromises)

            /**
             * Next, we need to retreive all the questions that has the criteria tags.
             * We need to retreive all of them since we need to pick a predefined number
             * of questions randomly. We cannot do this like in firebase like `limit(5)` 
             * since this will return the same array of objects everytime.
             * 
             * We need to pick the random questions via front end for now. otherwise, we
             * do it in functions via generateMtpExamQuestionnaire();
             * 
             * All questions retreived via question type id will be stored in `questions`.
             * The processed questions (i.e. pick random, sequenced) will be stored in `questionnaire`.
             * 
             * To do this, we need to:
             * 
             * 1. fetch all tags--questions links via tagId
             * 2. from the links retreived, we pick criteria random questionIds and store it.
             */
            const processTagQuestionLinksPromises = criteria[subtopicId.value]['structure'].map(async structure => processTagQuestionLinks(structure))
            await Promise.all(processTagQuestionLinksPromises)

            /**
             * Now we get the final questions by filtering the questionnaire
             */
            const questionIds = tagQuestionLinks.value.map(item => item.questionId)
            const filteredQuestions = questionnaire.value.questions.filter(item => questionIds.includes(item.id));

            /**
             * Modify the final questions 
             * Attach the necessary tags and instructions
             * Filter these questions by Order
             */
            filteredQuestions.forEach((question, index) => {
                filteredQuestions[index].instructions = getTagData(question.id).description
                filteredQuestions[index].tag = getTagData(question.id).id
                filteredQuestions[index].shorthand = getTagData(question.id).shorthand
                filteredQuestions[index].tags = [{
                    'typeId': getTagData(question.id).id
                }]
            })
            questions.value = filteredQuestions

            /**
             * Finaly sort the final questions based on the 'question type'
             */
            questions.value.sort((a, b) => {
                if (a.shorthand < b.shorthand) return -1
                if (a.shorthand > b.shorthand) return 1
                return 0
            });

        }

        // this for the temp static data
        // since the criteria are not in the subtopic data or object 
        subtopic.value = criteria[subtopicId.value];

        /**
         * Generate the Exam Sheet/Object
         */
        exam.value = generateExamSheet()

        /**
         * Initialize the timer. Set the time limit from the criteria
         */
        examTimeLimit.value = criteria[subtopicId.value]['limits']['time']
        exam.value.time.limit = examTimeLimit.value
        startTimer()

    } catch (error) {
        console.error(error)
    } finally {
        isWaiting.value = false
    }
}

onMounted(initialize)

/**
 * Returns the number of unanswered questions in the questionnaire
 */
const unansweredQuestions = computed(() => {
    var count = 0
    exam.value.answers.forEach((e) => {
        if (e.answer == null) count++
    });
    return count
})

/**
 * Returns the HTML classes for the border of the choices container.
 * If first choice, set the top border as curved
 * If last choice, set the bottom border as curved
 * @NOTE: Utility function for UI
 * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
 * -- Issue for TODO: Using this.questionnaire (Practice) instead of exam.value.questionnaire (Mock)
 */
const setBorder = (i) => {
    if (i == 0) {
        return "rounded-tl-md rounded-tr-md"
    } else if (i == exam.value.questionnaire[exam.value.index].choices.length - 1) {
        return "rounded-bl-md rounded-br-md";
    }
}

/**
 * Returns the corresponding HTML classes for the selected choice or otherwise
 * @NOTE: Utility function for UI
 * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
 * -- Issue for TODO: Using this.questionnaire (Practice) instead of exam.value.questionnaire (Mock)
 */
const showSelected = (i) => {
    return exam.value.answers[exam.value.index].answer ==
        exam.value.questionnaire[exam.value.index].choices[i].id
        ? "bg-true-gray-100 dark:bg-true-gray-700"
        : "bg-white dark:bg-true-gray-800 hover:bg-true-gray-100 dark:hover:bg-true-gray-700";
}

/**
 * Fires when the user clicks the next button. In this function, we do any
 * tasks required before going to the next question
 */
const transitionToNextQuestion = () => {

    // scroll to the top of the reviewer container
    document.getElementById("review-container").scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the scroll container
        inline: 'nearest' // Align to the nearest edge (in case of horizontal scrolling)
    });

    /**
     * JRD: Dec. 5, 2023
     * Refresh skip count to 3
     */
    exam.value.skips = 3;

    /**
     * March 29 MOD. Adding time spent.
     */
    exam.value.answers[exam.value.index].timeMark = Date.now(); // mark the time this question has been addressed
    // Compute the progress
    exam.value.progress = ((exam.value.index + 1) / exam.value.questionnaire.length) * 100;
    exam.value.progress = exam.value.progress.toFixed(2);

    // Count the score
    countScore();
    goToNextQuestion();
}

/**
 * Function that counts the user's mock exam score.
 */
const countScore = () => {
    exam.value.score = (countCorrectAnswers() / exam.value.questionnaire.length) * 100;
    exam.value.score = parseFloat(exam.value.score.toFixed(2));
}

/**
 * Returns the number of running correct answers
 * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
 * -- Issue for TODO: Using this.questionnaire (Practice) instead of exam.value.questionnaire (Mock)
 */
const countCorrectAnswers = () => {
    var count = 0;
    exam.value.answers.forEach((e, i) => {
        if (e.answer != null && e.answer == exam.value.questionnaire[i].answer) count++;
    });
    return count;
}

/**
 * Function that retreives and sets the index of the next question.
 * If user skipped some questions, they will be checked and queued.
 * Due to the skippable nature of the exam, this function finds the next null answer.
 */
const goToNextQuestion = () => {

    var index_start = null // index of where to start looking for the next question
    if (!isQuestionnaireComplete()) {
        // If the current index is the last question, reset the index to -1. 
        // That way, index 0 can still be searched if it's skipped
        if (exam.value.index == exam.value.questionnaire.length - 1) {
            index_start = -1
        } else {
            // else, use the current index as a starting point moving forward
            index_start = exam.value.index
        }
    }

    // Find the next null answer but 'i' should be greater than the 'index_start'
    // This way, it looks for the next null answer relative to the current index
    var new_index = exam.value.answers.findIndex(
        (e, i) => e.answer == null && i > index_start
    );

    // set the new index. This will reflect in the UI immediately
    exam.value.index = new_index;
}

/**
 * Checks if a user is still allowed to skip a specific question.
 * Used to disable the Skip button.
 */
const isSkippable = computed(() => {
    if (exam.value.skips == 0) {
        // if no more skips, then disable
        return false;
    } else if (exam.value.answers[exam.value.index].isSkipped) {
        // if this question has been skipped, then disable
        return false;
    } else if (exam.value.answers[exam.value.index].answer != null) {
        // if this question has been answerd, then disable
        return false;
    } else if (unansweredQuestions == 1) {
        // if there are only 1 unanswered question, then disable
        return false;
    } else {
        return true;
    }
})

/**
 * Function that runs whenever a user skips a question.
 */
const skipQuestion = () => {
    exam.value.answers[exam.value.index].isSkipped = true; // mark this question as skipped
    exam.value.skips--; // reduce the number of skips
    goToNextQuestion();
}

/**
 * Checks if the mock exam questionnaire has been completely answered
 */
const isQuestionnaireComplete = () => {
    const found = exam.value.answers.findIndex((e, i) => e.answer == null);
    return found >= 0 ? false : true;
}

const completeMock = () => {

    // mark the time the last question has been addressed
    exam.value.answers[exam.value.index].timeMark = Date.now()

    // Turn off timer, count the score, save timestamp for end of exam
    clearInterval(timer.value)
    countScore()
    exam.value.time.end = Date.now()

    /**
     * Evaluate here if the user passed by retrieving the 
     * criteria for a 'passer' goal
     */

    let _skillpoints = 0 // temporary storage for Skillpoints
    let goals = criteria[subtopicId.value]['goals']
    let passer = goals.filter(e => e.name == 'passer')[0]
    let speedrunner = goals.filter(e => e.name == 'speedrunner')[0]
    let master = goals.filter(e => e.name == 'master')[0]

    let userScore = countCorrectAnswers()
    let questionnaireSize = exam.value.questionnaire.length
    let passerLimitPercent = passer.score
    let masterLimitPercent = master.score
    let passingScore = questionnaireSize * (passerLimitPercent / 100)
    let masterPassingScore = questionnaireSize * (masterLimitPercent / 100)
    let hasUserPassed = userScore >= passingScore

    console.log('correctAnswers', userScore)
    console.log('questionnaireSize', questionnaireSize)
    console.log('passerLimitPercent', passerLimitPercent)
    console.log('masterLimitPercent', masterLimitPercent)
    console.log('passingScore', passingScore)
    console.log('masterPassingScore', masterPassingScore)

    /**
     * Make sure to set the exam to complete first
     */
    exam.value.status = "completed";

    /**
     * If user failed, set the flags and once the user
     * continues to exit, the mock exam data will be saved to DB.
     */
    if (!hasUserPassed) {
        exam.value.result = "failed";
        openOnCompleteModal();
        return;
    }

    /**
     * User passed. Assign skillpoints, milestones and set flags
     */
    // _skillpoints += passer.points // The passer.points not yet set.
    exam.value.result = "passed";
    exam.value.milestones.push("passer");

    /**
     * If user passed, check if he achieved the 'speedrunner' badge
     * If so then add speedrunner milestone
     */
    let hasUserGotSpeedrunner = exam.value.time.running < speedrunner.time;
    console.log('User running time: ', exam.value.time.running);
    console.log('Speed Runner time: ', speedrunner.time);
    if (hasUserGotSpeedrunner) {
        // _skillpoints += speedrunner.points // The speedrunner.points not yet set.
        exam.value.milestones.push("speedrunner")
    }

    /**
     * If user passed and got speedrunner, 
     * check if he achieved the 'master' badge
     */
    let hasUserGotMaster = userScore >= masterPassingScore
    if (hasUserGotMaster && hasUserGotSpeedrunner) {
        // _skillpoints += master.points // the master.points not yet set.
        exam.value.milestones.push("master")
    }


    /**
     * Assign the Skillpoints to User and Exam object
     */
    user.value.skillpoints += _skillpoints;
    exam.value.skillpoints += _skillpoints;

    console.log('hasUserPassed:', hasUserPassed)
    console.log('hasUserGotSpeedrunner:', hasUserGotSpeedrunner)
    console.log('hasUserGotMaster:', hasUserGotMaster)
    console.log('total skillpoint got:', _skillpoints)

    /**
     * ++++++++ END OF MILESTONE CHECKS!!! +++++++++++++++++
     */

    /**
     * Check if the user already has existing mtp_subtopic field and data.
     * If yes, find the subtopic and save the index for reference later when we update.
     * Else, we create this new subtopic data for the user.
     */
    user.value.mtp_subtopics = user.value.mtp_subtopics ? user.value.mtp_subtopics : [];
    var user_subtopic_index = null;
    let user_subtopic = user.value.mtp_subtopics.filter((e, i) => {
        if (e.id == route.query.subtopicId) {
            user_subtopic_index = i
            return e
        }
    })


    /**
     * Prepare the date to save
     * This will be save to user "mtp_subtopics" field
     */
    let _milestones = null // temporary storage for milestones
    if (user_subtopic_index != null) {
        // User has existing mtp subtopic data.
        // merge the old milestones with the new one.
        _milestones = [...user.value.mtp_subtopics[user_subtopic_index].milestones, ...exam.value.milestones]

        // Flag if the user has a record breaking score or time. If yes, update
        isRecordBreakScore.value = (exam.value.result == "passed" && exam.value.score > user.value.mtp_subtopics[user_subtopic_index].score) ? true : false
        isRecordBreakTime.value = (exam.value.result == "passed" && exam.value.time.running < user.value.mtp_subtopics[user_subtopic_index].time) ? true : false

    } else {
        // User doesn't have existing subject data.
        // Score and time is a new record
        _milestones = exam.value.milestones
        isRecordBreakScore.value = true;
        isRecordBreakTime.value = true;
    }
    // Remove any duplicates
    _milestones = [...new Set(_milestones)]


    /**
     * Start setting the data to user object
     */
    if (user_subtopic.length > 0) {
        // User has existing mtp subtopic data.
        // Update this existing data.
        // Is record breaking score and time, overwrite existing.
        user.value.mtp_subtopics[user_subtopic_index] = {
            id: route.query.subtopicId,
            milestones: _milestones,
            score: (exam.value.score > user.value.mtp_subtopics[user_subtopic_index].score) ? exam.value.score : user.value.mtp_subtopics[user_subtopic_index].score,
            time: (exam.value.time.running < user.value.mtp_subtopics[user_subtopic_index].time) ? exam.value.time.running : user.value.mtp_subtopics[user_subtopic_index].time,
        }
    } else {
        // User doesn't have existing subject data.
        // Create a new one.
        user.value.mtp_subtopics.push({
            id: route.query.subtopicId,
            milestones: _milestones,
            score: exam.value.score,
            time: exam.value.time.running
        })
    }

    /**
     * Start saving the updated user data
     */
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set(user.value)
        .then(() => {
            console.log("User data successfully updated.")
        })
        .catch((error) => { console.error("Error updating user data: ", error) });

    /**
     * Lastly, Open Complete Modal
     */
    openOnCompleteModal();

}

const openOnCompleteModal = () => {

    console.log('openOnCompleteModal')
    console.log('Exam value:', exam.value);

    isOnExitOpen.value = false;
    isOnCompleteOpen.value = true;
    // isOnAchievementOpen.value = false;

}

/**
 * Timer Utility Functions
 */

const startTimer = () => {

    /**
     * Initialize the exam start time, if it's not set
     */
    if (exam.value.time.start == null) {
        exam.value.time.start = Date.now()
    }

    /**
     * reference the mock exam 'time' data object 
     * to this local 'time' variable so that setInterval will work
     */
    var time = exam.value.time;

    /**
     * Start the timer
     */
    timer.value = setInterval(function () {
        var subtrahend = time.continued != null ? time.continued : time.start; // use this time's start if exam was continued (applicable on practice)
        var delta = Date.now() - subtrahend; // milliseconds elapsed since start
        time.running = Math.floor(delta / 1000); // in seconds

        /**
         * if time reaches exam time limit, initiate timedOut process
         */
        if (time.running == examTimeLimit.value) {
            examTimedOut()
        }
    }, 1000) // update about every second
}

/**
 * Function that runs as soon as the mock exam times out.
 * Stops the timer, updates the exam data and 
 * proceeds to open the popup for Timed Out
 */
const examTimedOut = () => {
    clearInterval(timer.value); // turn off timer
    exam.value.time.isTimedout = true;
    exam.value.time.end = Date.now(); // set the current date as mock exam end
    openOnTimedOutModal()

}

/**
 * Formats seconds into HH:MM:SS
 */
const formatTime = (runningTime) => {
    let seconds = runningTime % 60;
    let minutes = Math.floor(runningTime / 60) % 60;
    let hours = Math.floor(runningTime / 3600);

    return [hours, minutes, seconds]
        .map(unit => unit.toString().padStart(2, '0')) // Ensures two digits
        .join(':');
}

/**
 * General Utility functions
 */

function getRandomItems(array, count) {
    // Using Durstenfeld shuffle algorithm for efficiency
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array.slice(0, count);
}

const getTagData = (questionId) => {
    // get the tagQuestionLink based on questionId
    let _filteredQuestions = tagQuestionLinks.value.filter(e => e.questionId == questionId)
    // once the tagQuestionLink is retreived, get its tagId
    // now then retreive the tagData from the tagId
    let _filteredTagData = tagData.value.filter(e => e.id == _filteredQuestions[0].tagId)
    // return the 'instuctions' or the description
    return _filteredTagData[0]
}

const isHtmlEmpty = (html) => {
    return (html == '<p><br></p>') ? true : false
}

const openOnExitModal = () => {
    isOnExitOpen.value = true
    isOnTimedOutOpen.value = false
}

const openOnTimedOutModal = () => {
    isOnExitOpen.value = false
    isOnTimedOutOpen.value = true
}

const openTipsModal = () => {
    console.log("Opening tips modal...")
}

/**
     * Wraps up the user's mock exam data as he/she exits the mock exam page and saves it to DB.
     * @NOTE: Connects to Firebase.
     */
const quitMock = () => {

    // Set the exam status if timedout or cancelled. 
    // Otherwise, exam is already completed.
    if (exam.value.time.isTimedout) {
        exam.value.status = "timedout";
    } else {
        exam.value.status = "cancelled";
    }

    isBusy.value = true;

    /**
     * Start saving mtp mock exam data and then redirect to mtp subtopics page
     */
    firebase.firestore().collection("mtp_mock").add(exam.value)
        .then(() => {
            ROUTER.go(-1);
            console.log("MTP Mock exam data successfully added.");
        })
        .catch((error) => { console.error("Error adding mock exam data: ", error) });

}

const exitMock = (isReviewExamResult = false) => {

    // No achievement feature for this exam yet!
    if (isAchievementUnlocked.value) {
        console.log("ACHIEVEMENT UNLOCKED!")
        // this.openOnAchievementModal();
    } else {

        isBusy.value = true;

        /**
         * Start saving mtp mock exam data and then redirect to mtp subtopics page
         */
        firebase.firestore().collection("mtp_mock").add(exam.value)
            .then(() => {
                console.log("MTP Mock exam data successfully added.")
                if (isReviewExamResult) {
                    // route push to review mock exam...
                } else {
                    ROUTER.go(-1);
                }

            })
            .catch((error) => { console.error("Error adding mock exam data: ", error) });

    }
}

const reviewExamResults = () => {

    if (this.isAchievementUnlocked) {
        // console.log("ACHIEVEMENT UNLOCKED!")
        this.openOnAchievementModal();
    } else {
        // update the mock exam data and then redirect back to course page
        db.collection("mtp_mock").doc(this.$route.query.examId).update(this.exam)
            .then(() => {
                let examId = this.$route.query.examId
                this.$router.push({ path: `/lite/review`, query: { examId: examId, fromCourse: true } })
            })
            .catch((error) => {
                console.error("Error updating Mock Exam data => ", error);
            });
    }

}

</script>
export const criteria = {
  /**
   * General Knowledge
   */
  'mtp-aviation-basics': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-g1-a',
        'question-type-id': 'RnxNZSdDwHSK0uRQkIOb',
        'order': 1,
        'total': 30,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 900
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 900,
    }
  },
  /**
   * VERBAL REASONING
   */
  'mtp-spelling': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-v1-a',
        'question-type-id': 'u5Z726ZmGnMjJw6jIaQy',
        'order': 1,
        'total': 75,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 900
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 900,
    }
  },
  'mtp-word-meaning': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-v2-a',
        'question-type-id': 'VEplFNpG5BItCmI2a3hM',
        'order': 1,
        'total': 10,
      },
      {
        'question-type': 'question-type-v2-b',
        'question-type-id': 'VQTZT4gj7PNq5USuMKBa',
        'order': 1,
        'total': 10,
      },
      {
        'question-type': 'question-type-v2-c',
        'question-type-id': 'jLcKHYuK9iD16FNxX7zF',
        'order': 1,
        'total': 10,
      },
      {
        'question-type': 'question-type-v2-d',
        'question-type-id': 'bimPP5m4boQXznt8DCGV',
        'order': 1,
        'total': 10,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  'mtp-word-relationship': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-v3-a',
        'question-type-id': '10fONsyc0KpDdU1wwyrO',
        'order': 1,
        'total': 10,
      },
      {
        'question-type': 'question-type-v3-b',
        'question-type-id': 'hOxfCov3RVjSLfjX46da',
        'order': 1,
        'total': 10,
      },
      {
        'question-type': 'question-type-v3-c',
        'question-type-id': 'VPRQmVayy7aAPOzpmoWx',
        'order': 1,
        'total': 10,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  /** 🔴 NEEDS CONTEXT */
  'mtp-comprehension': {
    'hasContext': true,
    'typeBase': 'question-type-v4',
    'structure': [
      {
        'question-type': 'question-type-v4-a',
        'question-type-id': 'wb15ImqHdq9dnpy2U4Vv',
        'type-total': 5,
        'context-total': 3,
        'order': 1
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  'mtp-critical-thinking': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-v5-a',
        'question-type-id': 'ivRlMMuvj3zuaSGBrrOw',
        'order': 1,
        'total': 5,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1500
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 900
      },
      {
        'name': 'master',
        'score': 90,
        'time': 900
      },
    ],
    'limits': {
      'time': 1500,
    }
  },

  /**
   * NUMERICAL ABILITY
   */
  'mtp-computation': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-n1-a',
        'question-type-id': 'FNwpUHs0dbuW8NobKedV',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n1-b',
        'question-type-id': 'bS7EtQNq7pB5i9iU9s2O',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n1-c',
        'question-type-id': '8GcHK1ib1h47avfN1ELb',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n1-d',
        'question-type-id': '1fdQvaWqwC9u0ALLsOE1',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n1-e',
        'question-type-id': '0ZzKMG62iSufNkuleD3F',
        'order': 1,
        'total': 5,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  'mtp-estimation': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-n2-a',
        'question-type-id': 'I8BJUvOLCKVjkn6NTecY',
        'order': 1,
        'total': 20,
      },
      {
        'question-type': 'question-type-n2-b',
        'question-type-id': 'oc9b3pdci1DjN1ao9uED',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n2-c',
        'question-type-id': '2yaeg288Jzu4vJUyBCwr',
        'order': 1,
        'total': 5,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  'mtp-numerical-reasoning': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-n3-a',
        'question-type-id': 'M4iMss1cJEQEpPGIMMz6',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n3-b',
        'question-type-id': 'O383GM9U6gXBEoRdSGtf',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-n3-c',
        'question-type-id': 'TNu63WdzUIIwUwu1PU4r',
        'order': 1,
        'total': 5,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  /** 🔴 NEEDS CONTEXT */
  'mtp-data-interpretation': {
    'hasContext': true,
    'typeBase': 'question-type-n4',
    'structure': [
      {
        'question-type': 'question-type-n4-a',
        'question-type-id': 'M6qqj2muxWAK1OH6riCV',
        'question-total': 5,
        'context-total': 2,
        'order': 1
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 900
      },
      {
        'name': 'speedrunner',
        'score': 70,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 900,
    }
  },
  
  /**
   * ABSTRACT REASONING
   */
  'mtp-abstract-ability': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-a1-a',
        'question-type-id': '3bqWGAahl4dVTHhtPjEO',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-a1-b',
        'question-type-id': 'kisiKYJf1Kv2Ecgb1RwL',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-a1-c',
        'question-type-id': '7Kw9LvCJfgqhzUjbKPbA',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-a1-d',
        'question-type-id': 'fkgMb0tkFcFUVLDx5N5c',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a1-e',
        'question-type-id': 'NQp86TCjVorVUYHRGG0p',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a1-f',
        'question-type-id': '85zVJwYYD34jKK56Pbaa',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a1-g',
        'question-type-id': 'yllEYZ89RoW1wQMrytcu',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a1-h',
        'question-type-id': 'sNcCzWlUlVBeYjYgKMfR',
        'order': 1,
        'total': 2,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1800
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'master',
        'score': 90,
        'time': 1200
      },
    ],
    'limits': {
      'time': 1800,
    }
  },
  /** 🔴 NEEDS CONTEXT */
  'mtp-diagrammatic-ability': {
    'hasContext': true,
    'typeBase': 'question-type-a2',
    'structure': [
      {
        'question-type': 'question-type-a2-a',
        'question-type-id': 'EB4jq4P1DC77pyJ8CNEJ',
        'question-total': 5,
        'context-total': 2,
        'order': 1
      },
      {
        'question-type': 'question-type-a2-b',
        'question-type-id': '3UmRHFTFr9yMXfwHnR73',
        'question-total': 5,
        'context-total': 2,
        'order': 2
      },
      {
        'question-type': 'question-type-a2-c',
        'question-type-id': 'enyQhaiGf4nn16FfUj6n',
        'question-total': 5,
        'context-total': 1,
        'order': 3
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1800
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'master',
        'score': 90,
        'time': 1200
      },
    ],
    'limits': {
      'time': 1800,
    }
  },
  'mtp-concentration': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-a3-a',
        'question-type-id': 'LZMEX6POkIXFoCbIy7ud',
        'order': 1,
        'total': 12,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 600
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 300
      },
      {
        'name': 'master',
        'score': 90,
        'time': 300
      },
    ],
    'limits': {
      'time': 600,
    }
  },
  /** 🔴 NEEDS CONTEXT */
  'mtp-spatial-ability': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-a4-a',
        'question-type-id': 'V9rLQ1r9PWE6ajfdokVz',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-a4-b',
        'question-type-id': 'V9rLQ1r9PWE6ajfdokVz',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a4-c',
        'question-type-id': '1U8E6HLXLj7a7K79ftKa',
        'order': 1,
        'total': 3,
      },
      {
        'question-type': 'question-type-a4-d',
        'question-type-id': 'QopJvYvoRJXC9WrylP5Y',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-a4-e',
        'question-type-id': '07iNWbcokxFnwp6yA9ql',
        'order': 1,
        'total': 3,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  /**
   * MECHANICAL APTITUDE
   */
  'mtp-mechanical-reasoning': {
    'hasContext': false,
    'typeBase': '',
    'structure': [
      {
        'question-type': 'question-type-m1-a',
        'question-type-id': '9IldmAMFvCnKkblS12tc',
        'order': 1,
        'total': 5,
      },
      {
        'question-type': 'question-type-m1-b',
        'question-type-id': '7TUFiau7mbRU9ZYjNOwF',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-m1-c',
        'question-type-id': 'gyGVb5LmtQxrZojNTJGh',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-m1-d',
        'question-type-id': 'gHfezGmDLQDauo3ShBFM',
        'order': 1,
        'total': 1,
      },
      {
        'question-type': 'question-type-m1-e',
        'question-type-id': '1NjLMVqtBrfz6dmNRfVP',
        'order': 1,
        'total': 1,
      },
      {
        'question-type': 'question-type-m1-f',
        'question-type-id': 'vn9wRJ8KGvANLNL30Kzs',
        'order': 1,
        'total': 2,
      },
      {
        'question-type': 'question-type-m1-g',
        'question-type-id': 'w294Jloezad212os0qUz',
        'order': 1,
        'total': 2,
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 900
      },
      {
        'name': 'master',
        'score': 90,
        'time': 900
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  /** 🔴 NEEDS CONTEXT */
  'mtp-fault-diagnosis': {
    'hasContext': true,
    'typeBase': 'question-type-m2',
    'structure': [
      {
        'question-type': 'question-type-m2-a',
        'question-type-id': 'SrdJKPAyQK0lP173JfPR',
        'question-total': 5,
        'context-total': 1,
        'order': 1
      },
      {
        'question-type': 'question-type-m2-b',
        'question-type-id': 'epwpnSgpxcjfYAtbvY3z',
        'question-total': 5,
        'context-total': 1,
        'order': 2
      },
    ],
    'goals': [
      {
        'name': 'passer',
        'score': 75,
        'time': 1200
      },
      {
        'name': 'speedrunner',
        'score': 75,
        'time': 600
      },
      {
        'name': 'master',
        'score': 90,
        'time': 600
      },
    ],
    'limits': {
      'time': 1200,
    }
  },
  

}


export const tips = [
  "The CAAP Knowledge Test are given in various exam sets. Meaning, your exam may differ from what your friend is taking, even if you’re taking the test for the same subject.",
  "CAAP exams have time limits. Typically, the number of items in a subject is also the number of minutes allotted to complete the exam. For example, 30 items means 30 minutes. ",
  "One of the creators of this app has managed to pass all the CAAP Knowledge Test for AMT subjects in only one day. 7 subjects in 1 day. It’s possible.",
  "The passing score for the actual exam is 70% across all subjects. Want to know if you’re ready? Our Mock Exams follows the same passing rates so always give it a try.",
  "Skilltech Reviewer’s concepts and features are the results of in-depth research into real life experiences of Filipino AMT and AVT exam takers. ",
  "This reviewer has been created with the inputs of actual CAAP exam passers. Through hundreds of interviews, surveys, and statistics, we’ve managed to collect the best ideas from the Filipino aviation community. ",
  "In the Philippines, your CAAP Airmen License will be required first before a company promotes you to a Certifying Staff. No license, no promotion. That’s why it’s always better to have it ready.",
  "The CAAP Airmen License is one of the most frequently required credentials when applying for jobs abroad. Job agencies in the Philippines usually include your license as part of their requirements.",
  "Our survey shows that the most exam takers consider Air Law and Airworthiness to be the most difficult subject and Human Performance to be the easiest.",
  "We graduated as BSAMT but we started our OJT right after getting our Associate’s degree. After completing our OJT, we immediately took the CAAP exams. By the time we’re in 3rd year college, we already got our CAAP AMT licenses.",
]
<template></template>

<script>
import firebase from "@/firebase/config";
import "firebase/auth";

import { setPresence } from '@/firebase/presenceController'

import {logOutUser} from '../../firebase/usersController';
const NAME = "Logout";

export default {
  name: NAME,
  site_title: NAME,
  mounted() {
    var user = firebase.auth().currentUser;
    setPresence('offline', user);
    logOutUser(false);
  },
};
</script>

<style scoped>
</style>

<template>
  <TransitionRoot as="template" :show="isExamTrackerModalOpen">
    <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeExamTrackerModal()">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
            <!-- Close Button -->
            <div class="block absolute top-0 right-0 pt-4 pr-4">
              <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                @click="closeExamTrackerModal()">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <!-- Slides -->
            <div v-if="activeSubject != null" class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
              <!-- Preload images here -->
              <!-- <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fqrcodes%2F999-qr-dark.png?alt=media&token=5a900519-af74-4964-8ec3-93f248437225" rel="preload" />
                  <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-1b.png?alt=media&token=6b813c15-d943-455d-9895-ebd9651586d0" />
                  <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-2b.png?alt=media&token=b689c58e-2653-41c4-ab08-15b0dd630091" /> -->

              <div class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                <span
                  class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Update
                  {{ activeSubject.name }} Exam Results</span>

                <h1 class="text-xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl mb-2 mt-6">
                  <span class="block xl:inline">Tell us about your progress!</span>
                </h1>
                <p>
                  Skilltech's primary mission is to help all aspiring aircraft mechanics pass their CAAP License Exam.
                  Knowing your progress and how you went through the exam will contribute to the efficiency of our
                  platform.
                </p>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">When did you take your exam on {{ activeSubject.name }}?</label>

                    <input v-model="activeSubject.survey.date"  type="date" name="date" id="date" class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  
                </div>

                

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">Did you pass the exam?</label>
                  <select v-model="activeSubject.survey.passed" @change="checkIfCorrected(activeSubject)" id="exam-tracker-question-1" name="exam-tracker-question-1"
                    class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option :value="true" :selected="activeSubject.survey.passed">Yes</option>
                    <option :value="false" :selected="!activeSubject.survey.passed">No</option>
                  </select>
                </div>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">What is your exam
                    result?</label>
                  <select v-model="activeSubject.survey.score" @change="checkIfCorrected(activeSubject)" id="exam-tracker-question-1" name="exam-tracker-question-1"
                    class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in 101" :selected="(n - 1) == activeSubject.survey.score" :value="(n - 1)">{{ (n - 1)
                    }}%</option>
                  </select>
                </div>

                <div
                  v-if="(!activeSubject.survey.passed && activeSubject.survey.score >= passing)"
                  class="mt-6">
                  <label for="exam-tracker-question-22"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">🤔 It seems like you passed the exam...</label>
                  <p class="mb-5 mt-2 text-sm text-gray-500 dark:text-warm-gray-400">
                    Your current score is {{ activeSubject.survey.score }}%. Based on our record, the passing rate for {{ activeSubject.name }} was supposed to be
                    {{ passing }}%. Please double check. Howevever, did CAAP change the passing rate for {{
                      activeSubject.name }}?
                    If <strong>YES</strong>, please tell us the new passing rate for {{ activeSubject.name }} below:
                  </p>

                  <select v-model="activeSubject.survey.newPassing" id="exam-tracker-question-22" name="exam-tracker-question-22"
                    class="-mt-3 text-md block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in 101" :selected="(n) => { if(activeSubject.survey.newPassing){ return (n - 1) == activeSubject.survey.newPassing } else { return (n - 1) == 75} }" :value="(n - 1)">
                      {{ (n - 1) }}%
                    </option>
                  </select>
                </div>

                <div
                  v-else-if="activeSubject.survey.passed && activeSubject.survey.score < passing"
                  class="mt-6">
                  <label for="exam-tracker-question-22"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">🤔 It seems like you failed
                    the exam...</label>
                  <p class="mb-5 mt-2 text-sm text-gray-500 dark:text-warm-gray-400">
                    Your current score is {{ activeSubject.survey.score }}%. Based on our record, the passing rate for {{ activeSubject.name }} was supposed to be
                    {{ passing }}%. Please double check. Howevever, did CAAP change the passing rate for {{
                      activeSubject.name }}?
                    If <strong>YES</strong>, please tell us the new passing rate for {{ activeSubject.name }} below:
                  </p>
                  <select v-model="activeSubject.survey.newPassing" id="exam-tracker-question-22"
                    name="exam-tracker-question-22"
                    class="-mt-3 text-md block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in 101" :selected="(n - 1) == activeSubject.survey.newPassing" :value="(n - 1)">{{
                      (n - 1) }}%</option>
                  </select>
                </div>

                <!-- <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">How many times did you
                    take the exam?</label>
                  <select id="exam-tracker-question-1" name="exam-tracker-question-1"
                    class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in 4" :selected="n == 1" :value="(n - 1)">{{ n }} take{{ n > 1 ? 's' : '' }}</option>
                  </select>
                </div>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">How many items are there
                    in this exam?</label>
                  <select id="exam-tracker-question-1" name="exam-tracker-question-1"
                    class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in item_range" :value="n">{{ n }} {{ n == 'Other' ? '' : 'Items' }}</option>
                  </select>
                </div>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">From your review (using
                    the Skilltech App), how many items did appear in your CAAP exam for the subject {{
                      activeSubject.name }}?</label>
                  <select id="exam-tracker-question-1" name="exam-tracker-question-1"
                    class="text-md mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option v-for="n in item_range" :value="n">{{ n }} {{ n == 'Other' ? '' : 'Items' }}</option>
                  </select>
                </div>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">
                    In those items that appeared in your exam, in what following {{ activeSubject.name }} subtopics did it
                    come from?
                  </label>
                  <div class="mt-2 space-y-2">
                    <div v-for="subtopic in subtopics" class="relative flex items-start">
                      <div class="flex h-6 items-center">
                        <input id="comments" aria-describedby="comments-description" name="comments" type="checkbox"
                          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                      </div>
                      <div class="ml-3 text-sm leading-6">
                        <label for="comments" class="font-base text-gray-700 dark:text-warm-gray-400">{{ subtopic
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-6">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">
                    What item/s in your CAAP exam didn't appear from our reviewer?
                  </label>
                  <p class="mb-5 mt-2 text-sm text-gray-500 dark:text-warm-gray-400">If you can't recall the exact
                    question or choices, just type as much as you can remember. You can add as many questions as much as
                    you can possibly recall.</p>
                  <div class="border dark:border-warm-gray-700 border-warm-gray-300 px-4 py-5">
                    <label for="about"
                      class="block text-sm font-medium leading-6 text-gray-900 dark:text-warm-gray-200">Question</label>
                    <div class="mt-2">
                      <textarea id="about" name="about" rows="3"
                        class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
                    </div>


                    <label for="choice-a"
                      class="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-warm-gray-200">Choice
                      A</label>
                    <div class="mt-2 flex rounded-md shadow-sm">
                      <input type="text" name="choice-a" id="choice-a" autocomplete="choice-a"
                        class="block w-full min-w-0 flex-grow rounded-none rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
                    </div>

                    <label for="choice-b"
                      class="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-warm-gray-200">Choice
                      B</label>
                    <div class="mt-2 flex rounded-md shadow-sm">
                      <input type="text" name="choice-b" id="choice-b" autocomplete="choice-b"
                        class="block w-full min-w-0 flex-grow rounded-none rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
                    </div>

                    <label for="choice-c"
                      class="mt-4 block text-sm font-medium leading-6 text-gray-900 dark:text-warm-gray-200">Choice
                      C</label>
                    <div class="mt-2 flex rounded-md shadow-sm">
                      <input type="text" name="choice-c" id="choice-c" autocomplete="choice-c"
                        class="block w-full min-w-0 flex-grow rounded-none rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
                    </div>
                  </div>

                  <button type="button"
                    class="flex items-center mt-4 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-4 h-4 mr-1">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span>Add Another Question</span>

                  </button>

                </div> -->


                <div class="mt-8">
                  <label for="exam-tracker-question-1"
                    class="block text-md font-medium leading-6 dark:text-gray-300 text-gray-700">
                    Finally, do you have any concerns or comments regarding the topic {{ activeSubject.name }}?
                  </label>
                  <p class="mb-5 mt-2 text-sm text-gray-500 dark:text-warm-gray-400">Don't hold back! Tell us below what
                    your thoughts and experiences are during your review process so we can improve it for everyone.</p>

                <div class="mt-2">
                  <textarea v-model="activeSubject.survey.comment" id="comment" name="comment" rows="3"
                    class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <button @click="closeExamTrackerModal()"
                class="mt-6 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                Save and Close
              </button>
            </div>

          </div>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</TransitionRoot></template>
   
<script setup>

import { onUpdated } from 'vue'

import { XIcon } from "@heroicons/vue/solid";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

const { activeSubject, isExamTrackerModalOpen } = defineProps(["activeSubject", "isExamTrackerModalOpen"])
const emit = defineEmits(['closeExamTrackerModal'])

// const passing = 75
// const item_range = [20,30,40,50, 'Other']
// const subtopics = [
//   'BLADES AND PROPELLER',
//   'ENGINE CARBURETION',
//   'ENGINE (RECIP)',
//   'ENGINE (TURBINE)',
//   'FUEL CONTROL MECHANISM',
//   'IGNITION MECHANISM',
//   'POWERPLANT FUEL MECHANISM',
//   'POWERPLANT INSTRUMENTS',
// ]

    // for ui temp values
    
const passing = 75;
const closeExamTrackerModal = () => emit('closeExamTrackerModal')

const checkIfCorrected = (subj) => {

  if(subj.survey.passed && subj.survey.score >= passing){
    subj.survey.newPassing = null
  }else if(!subj.survey.passed && subj.survey.score < passing){
    subj.survey.newPassing = null
  }
}

// const processExamTrackerResults = () => {
//   // populate the actual prop vales from the UI values
//   activeSubject.survey.passed = passed
//   activeSubject.survey.score = score

//   // if the user passed and the score is equal or above passing, then there are no changes in the passing rate
//   // if the user failed and the score is below passing, then there is no changes in the passing rate
//   // both of which, we pass a NULL or 0 passingRate.
//   // otherwise, we should pass the new passing rate.
//   if (passed && score >= passing) {
//     activeSubject.survey.newPassing = null
//   } else if (!passed && score < passing) {
//     activeSubject.survey.newPassing = null
//   } else {
//     activeSubject.survey.newPassing = newPassing
//   }

//   // push the changes
//   $emit("processExamTrackerResults", activeSubject.survey);

//   /**
//    * Reset the UI values
//    */
//   score = 0
// }

// onUpdated(() => {
//   console.log(activeSubject)
//   if(!activeSubject){
//     console.log("initializing modal values")
//   }
  
// })

</script>
<template>

  <!-- Announcement Banner -->
  <div v-if="$route.params.subject == 'human-performance'" class="pointer-events-auto overflow-hidden rounded-lg bg-gradient-to-r from-indigo-800 to-indigo-500 shadow-lg ring-1 ring-black ring-opacity-5 mb-4 w-full">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 text-white">
            <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-2 w-0 flex-1 pt-0.5 items-center lg:flex lg:space-x-1">
          <p class="text-sm font-bold text-white">ADVISORY:</p>
          <p class="text-sm text-indigo-100">Carefully read and understand the contents of the subtopic "Human Factor Models" for a more effective review. Treat this as a <strong class="text-white">context-based</strong> rather than a read-&-memorize type of study.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="subtopics">

    <!-- Breadcrumbs -->
    <nav class="flex px-2 lg:px-12 justify-between items-center" aria-label="Breadcrumb">
      <div class="flex items-center">
        <router-link :to="`/caap/course/${$route.params.course}`" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-400" aria-current="page">
          <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
        </router-link>
      </div>
      <div class="text-sm font-medium  text-warm-gray-500">
        <span class="hidden md:block">Showing all Subtopics under {{this.toCamelCaps($route.params.subject.replace('-',' '))}} for {{$route.params.course.toUpperCase()}}</span>
        <span class="block md:hidden">{{this.toCamelCaps($route.params.subject.replace('-',' '))}}</span>
      </div>
      <!-- FAQ Menu -->
      <Menu as="div" class="ml-3 relative inline-block text-left">
        <MenuButton class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          <span class="sr-only">Open options</span>
          <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button type="button" :class="[ active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What am I seeing in this page?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What is a Practice/Mock Exam?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What are Achievements?</span>
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </nav>
    
    <!-- Main Content -->
    <main class="max-w-full mx-auto py-6 px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4">
      <!-- Display waiting screen if app is loading -->
      <div v-if="isLoading" class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="c in 9" :key="c" class="h-40 dark:bg-true-gray-800 bg-warm-gray-200 animate-pulse relative rounded-lg bg-white px-6 py-5 shadow-sm flex items-center space-x-3 "></div>
      </div>
      <div v-else class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="subtopic in subtopics" :key="subtopic.id"  class="relative rounded-lg border border-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
          <button @click="updateSelected(subtopic.id)" class="flex-1 min-w-0 text-left outline-none">

            <!-- Subtopic Data -->
            <div class="focus:outline-none cursor-pointer">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <div class="text-base font-medium dark:text-warm-gray-400 text-gray-900 flex justify-between items-start">
                <div class="flex flex-inline items-start">
                  <CheckCircleIcon v-if="subtopic.isCompleted" class="dark:text-green-400 text-green-500 w-6 h-6 mr-1 flex-shrink-0" />
                  <CheckCircleIcon v-else class="dark:text-warm-gray-600 text-warm-gray-300 w-6 h-6 mr-1 flex-shrink-0" />
                  <span>{{subtopic.name}}</span>
                </div>
                <KeyIcon v-if="subtopic.isKey" class="w-6 h-6 text-yellow-400" />
              </div>
              <p class="text-sm text-warm-gray-500 mt-2">
                {{subtopic.items}} Items
              </p>
            </div>
            <!-- Progress Bar -->
            <!-- <div v-if="subtopic.isStarted == null"> -->
            <div v-if="subtopic.isStarted == null"> <!-- Still Loading -->
              <div class="flex mb-2 items-center justify-between relative pt-1 mt-5">
                <div><span class="h-5 w-24 animate-pulse text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700"></span></div>
              </div>
              <div class="overflow-hidden animate-pulse h-2 text-xs -mt-2 flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                <div style="width:0%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500"></div>
              </div>
            </div>
            <div v-else>
              <!-- <div v-if="!subtopic.isStarted" class="relative pt-1 mt-4">  -->
              <div v-if="!subtopic.isStarted" class="relative pt-1 mt-4"> 
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700">
                      Not Yet Started
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-warm-gray-600 dark:text-true-gray-400">
                      0%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                  <div style="width:0%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500"></div>
                </div>
              </div>
              <div v-else-if="subtopic.isInProgress" class="relative pt-1 mt-4">
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                      In progress
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-indigo-600">
                      {{subtopic.pausedPractice.progress}}%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded bg-indigo-200">
                  <div :style="`width:${subtopic.pausedPractice.progress}%`" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"></div>
                </div>
                
              </div>
              <div v-else-if="subtopic.isCompleted" class="relative pt-1 mt-4">
                <div class="flex mb-2 items-center justify-between">
                  <div>
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                      Completed
                    </span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs font-semibold inline-block text-green-600">
                      100%
                    </span>
                  </div>
                </div>
                <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                  <div style="width:100%" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"></div>
                </div>
              </div>
            </div>

          </button>
        </div>
      </div>
    </main>

    <!--  Popup: Subtopic -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div class="inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 dark:text-true-gray-300 text-gray-900">
                  {{(this.selected) ? this.subtopics.find(e => e.id === this.selected).name : null}}
                </DialogTitle>
                <span class="rounded-full bg-purple-500 text-purple-50 text-xs font-normal py-0.5 px-1.5">{{(this.selected) ? this.subtopics.find(e => e.id === this.selected).items : null}} Items</span>
                <button type="button" @click="closeModal" class="absolute top-0 right-0 mr-4 mt-4">
                  <svg class="w-6 h-6 dark:text-true-gray-400 text-gray-400 hover:text-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-2">
                  
                  <!-- More Info -->
                  <p class="text-sm dark:text-true-gray-400 text-gray-700 text-left mt-8">
                    Before you begin, please take note:
                  </p>
                  <p class="text-sm dark:text-true-gray-400 text-gray-700 text-left">
                    <ol class="list-decimal ml-4 space-y-2 mt-2">
                      <li class="pl-1.5">There’s no time limit for this Practice Exam.</li>
                      <li class="pl-1.5">A counter will tally your correct and wrong answers.</li>
                      <li class="pl-1.5">The right answer will be revealed after you pick a choice.</li>
                      <li class="pl-1.5">Click ‘Next’ or ‘Previous’ to see other questions.</li>
                      <li class="pl-1.5">Click ‘Quit’ icon to exit and save your progress for later.</li>
                    </ol>
                  </p>
                  
                  <!-- Tip Section -->
                  <div class="rounded-md bg-yellow-50 bg-yellow-50 overflow-hidden text-sm  text-left mt-8 max-w-lg p-2 sm:p-4">
                    <div v-if="this.subtopics.find(e => e.id === this.selected).isKey" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Key Subtopic: </strong>Based on our latest statistics, there’s a high chance that a significant number of items from this topic will be included in the actual exam.</p>
                    </div>
                    <div v-if="this.subtopics.find(e => e.id === this.selected).subjects.length > 1" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Duplicate Subtopic: </strong>This topic is also covered in other subject/s. Your saved progress here will also be synced in those subjects for a much easier tracking.</p>
                    </div>
                    <div v-if="this.subtopics.find(e => e.id === this.selected).subjects.length <= 1 && !this.subtopics.find(e => e.id === this.selected).isKey" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Tip: </strong>{{ this.currTip }}</p>
                    </div>
                  </div>

                  <!-- Action Buttons -->
                  <div v-if="this.subtopics.filter(e => e.id == this.selected)[0].pausedPractice != null" class="mt-8 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button @click="this.continuePracticeTest(this.selected)" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                      Continue Practice Test
                    </button>
                    <button @click="this.startNewPracticeTest(this.selected)" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border dark:border-warm-gray-500 border-gray-300 shadow-sm px-4 py-2 dark:bg-warm-gray-700 bg-white text-base font-medium dark:text-warm-gray-200 text-warm-gray-700 hover:bg-gray-50 dark:hover:bg-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                      Start New Practice
                    </button>
                  </div>
                  <div v-else class="mt-8 sm:mt-6 w-full">
                    <button @click="this.$router.push({ path: `/caap/course/${$route.params.course}/${$route.params.subject}/${this.selected}/practice` })" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                      Begin Practice Test
                    </button>
                  </div>

                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

  </div>
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

// Import UI dependencies
import { ref } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckCircleIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, KeyIcon } from "@heroicons/vue/solid";

// Import other dependencies
import moment from "moment";

import { userService } from "@/utils/user"
import { reviewerService } from "@/utils/reviewer"
import { expirationGuard } from "@/utils/helper"

export default {
  components: {
    CheckCircleIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, KeyIcon,
    TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle,
    Menu, MenuButton, MenuItem, MenuItems,
  },
  setup() {
    const isOpen = ref(false);
    return {
      isOpen,
      closeModal() { isOpen.value = false; },
      openModal() { isOpen.value = true; },
    };
  },
  data() {
    return {
      isLoading: true,
      subtopics: null,
      user: null,
      moment: moment,
      progress: 0,
      // @TODO: Move this to DB
      tips: [
        "Since the questions for one subject in a CAAP Knowledge Test is made out of different sets, you might want to refrain asking anyone for pointers. Yours could be totally different from theirs so just review them all.  ",
        "Our Mock Exams are designed to create a simulation of the real exam. But passing them once or twice doesn’t mean your work is done. Your goal is to do it regularly until it’s easy to get a passing score, or even a perfect one.",
        "Based on our interviews of previous CAAP exam takers, one of the best ways to review for the test is to master a subject first before moving to the next one.",
        "Our survey shows that 60% of those who failed the exam skipped some of the coverage and instead, followed a short list of unverified pointers. Avoid this. Reviewing the whole thing might take more effort but it’s gonna be worth it.",
        "If you’re feeling that the coverage is too heavy for you, try setting specific goals for each study session rather than finishing them all at once. Forcing it won’t help you at all.",
        "CAAP exams are given thru a computer and not thru smartphones or tablets. If you want to get the full experience, try opening this reviewer in a laptop or PC and go practice like it’s the real thing.",
        "As much as possible, try to study alone. You have to get used to answering these questions on your own just like in the actual exam. So when you’re using this reviewer, try to get some solo time.",
        "Keywords are helpful in trying to remember the correct answers. But you should always read the whole question. According to our survey, around 40% failed the CAAP exam because they only tried to memorize a specific keyword found in the choices.",
        "In your CAAP exam, you can constantly see your score displayed in your screen. It starts at zero and will only increase if you got the correct answer. Don’t get distracted by it though. Instead, focus on the question in front of you. ",
        "If you’re planning to take more than one subject in a single day for your CAAP exam, try choosing subjects with matching Duplicate Subtopics. It will greatly lessen the number of items you have to cover for your review.",
      ],
      currTip: null // pointer for current tip; a workaround for tip to not autoupdate
    };
  },

  methods: {

    /**
     * Function to update the selected subtopic to be displayed in the popup.
     * Also sets the current tip to be shown.
     */
    updateSelected(id) {
      this.selected = id;
      this.currTip = this.tips[Math.floor(Math.random() * this.tips.length)]
      this.openModal();
    },

    /**
     * Proceed to start a new practice test.
     * @NOTE: Connects to Firebase.
     */
    startNewPracticeTest(id){
      let subtopic = this.subtopics.filter( e => e.id == id )[0]
      let exam_id = subtopic.pausedPractice.id
      // cancel the existing practice exam of a subtopic first
      db.collection("practice").doc(exam_id).update({ status: "cancelled" })
        .then(() => {
          // once cancelled, proceed to practice
          this.$router.push({ path: `/caap/course/${this.$route.params.course}/${this.$route.params.subject}/${id}/practice` })
        })
        .catch((error) => { console.error("Error updating document: ", error) }); // The document probably doesn't exist.
    },

    /**
     * Proceed to continue a specific practice test.
     */
    continuePracticeTest(id){
      let subtopic = this.subtopics.filter( e => e.id == id )[0]
      let exam_id = subtopic.pausedPractice.id
      this.$router.push({ path: `/caap/course/${this.$route.params.course}/${this.$route.params.subject}/${id}/practice`, query: { id: exam_id} })
    },

    /**
     * Utility: Convert a string to Camel Caps.
     * @NOTE: Utility function.
     * @TODO: Convert into reusable library
     */
    toCamelCaps(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) { arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1); }
      return arr.join(" ");
    },

    /**
     * Get all Subtopics Practice Data
     * Check if a subtopic is a Key subtopic
     * Check if a subtopic has been completed
     * Check if a subtopic has a paused practice exam
     */
    async getSubtopicsPracticeData(uid){

      this.subtopics.forEach( (subtopic, index) => {
      
        db.collection("practice")
          .where("user", "==", uid)
          .where("subtopic", "==", subtopic.id)
          .where("status", "in", ["started","completed"])
          .get()
          .then((querySnapshot) => {

            var subtopics_data = [];
            if(querySnapshot.size > 0){

              // this subtopic already has practices that are started.
              this.subtopics[index].isStarted = true
              
              querySnapshot.forEach( doc => {
                // console.log(doc.data());
                let data = doc.data()
                data.id = doc.id
                subtopics_data.push(data)
              })

              // Check if this subtopic is already completed. 
              // If there are any existing practice that is "status==completed",
              // this means the user already went through it.
              var subtopic_practices_completed = subtopics_data.filter( e => e.status == "completed")
              this.subtopics[index].isCompleted = (subtopic_practices_completed.length > 0) ? true : false

              // Even if a subtopic practice is completed, 
              // a user can still start a practice session
              // and there might be open practices (i.e. "status==started") 
              // which is in progress. Fetch them so user can allow to continue
              var subtopic_practices_started = subtopics_data.filter( e => e.status == "started")
              this.subtopics[index].isInProgress = (subtopic_practices_started.length > 0) ? true : false

              // If there's a paused exam, store it for reference later
              var most_recent_practice = null;
              if(subtopic_practices_started.length > 0){
                // Reduce the array to the most recently taken exam
                most_recent_practice = subtopic_practices_started.reduce( (previousValue, currentValue) => {
                  return (currentValue.time.start > previousValue.time.start) ? currentValue : previousValue
                });
              }
              this.subtopics[index].pausedPractice = most_recent_practice // will return null if none

              



            }else{
              this.subtopics[index].isStarted = false
            }
            
          })
          .catch((error) => {
            console.error("Error getting document => ", error);
            return "Error getting document => " + error;
          })
      
      });
    }

  },
  
  async created(){
    
    this.isLoading = true

    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    /* CLAIMS - Guard Clause */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    if(!claims.caap) this.$router.push({ path: '/reviewers' })  // reroute non-caap members

    console.log('claims', claims)
   
    var reviewer_id = this.$route.path.split('/').filter(String)[0] // caap
    var course_id = this.$route.params.course // or filter (**not used here**)
    var subject_id = this.$route.params.subject // or category

    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    expirationGuard(this.user, this.$router) // Check if access already expired. Added November 17, 2023
    console.log("this.user => ", this.user)

    /* Get Subtopics insde the reviewer based on its category/subject */
    this.subtopics = await reviewerService.fetchSubtopicsBySubject(db, reviewer_id, subject_id)
    // console.log("subtopics => ", this.subtopics)

    /* Update the Subtopics object with User's subject data */
    await this.getSubtopicsPracticeData(firebase.auth().currentUser.uid)
    // console.log("updated this.subtopics => ", this.subtopics)

    // Check if isKey subtopic
    this.subtopics.forEach( (subtopic, index) => {
      let i = subtopic.subjects.indexOf(subject_id);
      this.subtopics[index].isKey = null
      if( i > -1) this.subtopics[index].isKey = (subtopic.keys && subtopic.keys[i]) ? true : false;
    });
    
    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");
    
    this.isLoading = false
  }
};
</script>
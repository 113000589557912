<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 512 512" xml:space="preserve">
	<g>
		<g>
			<path d="M363.174,72.046C331.709,26.856,280.491,0,225.298,0C133.072,0,57.971,74.717,57.359,166.801L14.691,311.872l42.664,0.003    v50.78c0,33.794,27.494,61.289,61.289,61.289h90.808V512h183.792V383.918h104.066V72.046H363.174z M377.56,496.317H225.134    v-88.056H118.643c-25.147,0-45.606-20.459-45.606-45.606v-66.463l-37.387-0.001l37.387-127.118v-1.129    c0-83.957,68.304-152.261,152.262-152.261c46.275,0,89.47,20.826,118.272,56.363h-86.089v311.872H377.56V496.317z     M481.626,368.235H273.165V87.729h208.461V368.235z"/>
		</g>
	</g>
	<g>
		<g>
			<polygon points="359.843,102.36 321.36,140.845 302.89,122.374 291.799,133.463 321.36,163.024 370.934,113.451   "/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="128.61" width="79.46" height="15.683"/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="153.703" width="47.049" height="15.683"/>
		</g>
	</g>
	<g>
		<g>
			<polygon points="359.843,182.412 321.36,220.895 302.89,202.424 291.799,213.515 321.36,243.074 370.934,193.502   "/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="210.161" width="79.46" height="15.683"/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="233.163" width="47.049" height="15.683"/>
		</g>
	</g>
	<g>
		<g>
			<polygon points="359.843,262.462 321.36,300.947 302.89,282.476 291.799,293.566 321.36,323.126 370.934,273.553   "/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="288.575" width="79.46" height="15.683"/>
		</g>
	</g>
	<g>
		<g>
			<rect x="378.585" y="312.623" width="47.049" height="15.683"/>
		</g>
	</g>
	</svg>
	</template>
<template>
    <div class="lg:col-span-4 rounded-lg dark:bg-true-gray-800 bg-white overflow-hidden shadow px-6 py-8">
        <h2 class="text-base font-bold dark:text-warm-gray-300 text-warm-gray-700" id="exam-tracker-title">
            🎯 CAAP License Exam Tracker
        </h2>

        <div v-if="isExamTrackerComplete && areAllExamPassed" >
            <p v-if="!results.isBoxClaimed" class="mt-1 text-sm dark:text-warm-gray-500 text-warm-gray-500">
                You've completed the exam tracker! You can now claim your Reward Pack by submitting your test results for evaluation. <br/><br/> <strong>IMPORTANT: </strong>Please make sure that you've entered the correct information on the exam tracker. Once you submit your results, everything will be evaluated by our team for correctness.
            </p>
            <p v-else class="mt-1 text-sm dark:text-warm-gray-500 text-warm-gray-500">
                You've submitted your exam results. Our team is now evaluating your exam results and once they're done, we will contact you for instructions on how to claim your Reward Pack.
            </p>
            <button
                v-if="!results.isBoxClaimed"
                @click="handleClaimClick()"
                class="mt-3 w-full flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                Claim Reward Pack 🎁
            </button>
            <button
                v-else
                disabled
                class="disabled:opacity-75 disabled:cursor-default mt-3 w-full flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                Waiting for Confirmation
            </button>
        </div>
        <div v-else-if="isExamTrackerComplete && !areAllExamPassed" >
            <p class="mt-1 text-sm dark:text-warm-gray-500 text-warm-gray-500">
                You still have some subjects that are not yet passed. Don't give up! Make sure you've covered everything for your next attempt. If you need help or if you think there are outdated topics in the reviewer, contact our team by clicking the button below. <br/><br/> <strong>IMPORTANT:</strong> Make sure that you've filled up your exam tracker! Especially the subjects you failed so we can review it on our end.
            </p>
            <button
                @click=""
                class="mt-3 w-full flex justify-center items-center px-4 py-2 border border-true-gray-300 dark:border-true-gray-600 shadow-sm text-sm font-medium rounded-md dark:text-warm-gray-300 text-warm-gray-600 dark:bg-true-gray-700 dark:hover:bg-true-gray-600 hover:bg-true-gray-100 bg-true-gray-50">
                Ask for Help 🛟
            </button>
        </div>
        <div v-else class="mt-3 flex items-center">
            <p class="text-sm dark:text-warm-gray-500 text-warm-gray-500">
                Your progress matters! After conquering each subject in the license exam, don't forget to log your results below by simply clicking the edit icon. Once you've completed it and passed the exam, you can claim an exclusive Reward Pack 🎁
            </p>
        </div>

        <div class="mt-6">
            <h3 class="sr-only">Exam Tracker data</h3>
            <dl class="space-y-3">
                <div v-for="(subject, index) in results.subjects" :key="index" class="flex flex-col items-center text-sm">
                    <div class="w-full flex items-center dark:text-warm-gray-300 text-warm-gray-500">
                        <span>{{ subject.name }}</span>
                        <button 
                            v-if="!results.isBoxClaimed" 
                            @click="handleEditClick(subject)"
                            class="ml-1 hover:opacity-75">
                            <PencilAltIcon class="w-4 h-4" />
                        </button>
                    </div>

                    
                    <div v-if="subject.survey != null" class="w-full flex">
                        <dt class=" flex flex-1 items-center">
                            <div aria-hidden="true" class="flex flex-1 items-center">
                                <div class="relative flex-1">
                                    <div class="h-3 rounded-full border border-gray-200 bg-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-700" />
                                    <div 
                                        v-if="subject.survey.score > 0"
                                        :class="[
                                            (!subject.survey.newPassing && subject.survey.passed) ? 'border-green-400 bg-green-400' : '',
                                            (!subject.survey.newPassing && !subject.survey.passed) ? 'border-red-500 bg-red-500' : '',
                                            (subject.survey.newPassing && subject.survey.newPassing != passingScore) ? 'border-yellow-400 bg-yellow-400' : '',
                                            'absolute inset-y-0 rounded-full border'
                                        ]"
                                        :style="{ width: `calc(${subject.survey.score}%)` }" />
                                </div>
                            </div>
                        </dt>
                        <dd :class="[
                            (subject.survey.passed) ? 'dark:text-green-300 text-green-500' : '',
                            (!subject.survey.passed) ? 'dark:text-red-300 text-red-500' : '',
                            (subject.survey.score == 0) ? 'dark:text-warm-gray-300 text-warm-gray-500' : '',
                            'ml-2 w-10 text-right text-sm tabular-nums'
                        ]">
                            {{ subject.survey.score }}%
                        </dd>
                    </div>

                    <div v-else class="w-full flex">
                        <dt class=" flex flex-1 items-center">
                            <div aria-hidden="true" class="flex flex-1 items-center">
                                <div class="relative flex-1">
                                    <div class="h-3 rounded-full border border-gray-200 bg-gray-100 dark:border-warm-gray-600 dark:bg-warm-gray-700" />
                                </div>
                            </div>
                        </dt>
                        <dd :class="[
                            'dark:text-warm-gray-300 text-warm-gray-500',
                            'ml-2 w-10 text-right text-sm tabular-nums'
                        ]">
                            0%
                        </dd>
                    </div>
                    <div class="flex w-full justify-between">
                        <div v-if="subject.survey.date" class="text-xs dark:text-warm-gray-500 text-warm-gray-500">
                            Date Taken: {{ subject.survey.date }}
                        </div>
                        <div v-if="subject.survey.newPassing && subject.survey.newPassing != passingScore" class="text-xs dark:text-warm-gray-500 text-warm-gray-500">
                            New Passing Rate: {{ subject.survey.newPassing }}%
                        </div>
                    </div>
                    
                    
                </div>
            </dl>
        </div>
    </div>
    <ExamTrackerModal
        :activeSubject="activeSubject"
        :isExamTrackerModalOpen="isExamTrackerModalOpen"
        @closeExamTrackerModal="isExamTrackerModalOpen = false"
        @processExamTrackerResults="processExamTrackerResults"
      />

    <ExamTrackerSubmitModal
        :isExamTrackerSubmitOpen="isExamTrackerSubmitOpen"
        @closeExamTrackerSubmitModal="isExamTrackerSubmitOpen = false, claimResults()"
        
      />
</template>
<script setup>

import ExamTrackerModal from '@/components/ExamTrackerModal.vue'
import ExamTrackerSubmitModal from '@/components/ExamTrackerSubmitModal.vue'

import { ref, computed } from "vue"
import { PencilAltIcon } from '@heroicons/vue/solid'

const activeSubject = ref(null)

const isExamTrackerModalOpen = ref(false)
const isExamTrackerSubmitOpen = ref(false)
const passingScore = 75;

const results = ref({
    id: 'user-id',
    subjects: [
        { 
            name: 'Air Law', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Aircraft Engineering', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Aircraft Maintenance', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Airframe Rating', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Human Performance', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Nat/Sci', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Powerplant Rating', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
        { 
            name: 'Avionics Rating', 
            survey: {
                date: null,
                passed: false,
                score: 0,
                takes: 0,
                totalItems: 0,
                totalItemsAppeared: 0,
                subtopicsAppeared: [],
                missingItems: [],
                comments: '',
                newPassing: null,
            }
        },
    ],
    isBoxClaimed: false
})

const processExamTrackerResults = (survey) => {

    console.log('results passed:')
    console.log(survey)

    let index = results.value.subjects.findIndex(e => e.name == activeSubject.value.name);

    results.value.subjects[index].survey = survey;

    activeSubject.value = null
    isExamTrackerModalOpen.value = false
}

const handleClaimClick = () => {
    // results.value.isBoxClaimed = true;
    isExamTrackerSubmitOpen.value = true
}

const claimResults = () => {
    results.value.isBoxClaimed = true;
}

const handleEditClick = (subject) => {
    if(!subject.survey){
        subject.survey = {};
    }
    activeSubject.value = subject;

    isExamTrackerModalOpen.value = true;
}

const isExamTrackerComplete = computed( () => results.value.subjects.every( subject => subject.survey && subject.survey.score != 0 ))
const areAllExamPassed = computed( () => results.value.subjects.every( subject => subject.survey && subject.survey.passed ))

</script>

<template>
  <TransitionRoot as="template" :show="isExamTrackerSubmitOpen">
    <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="closeExamTrackerSubmitModal()">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
        </TransitionChild>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <TransitionChild as="template" enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
            <!-- Close Button -->
            <div class="block absolute top-0 right-0 pt-4 pr-4">
              <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                @click="closeExamTrackerSubmitModal()">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <!-- Slides -->
            <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
              <!-- Preload images here -->
              <!-- <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fqrcodes%2F999-qr-dark.png?alt=media&token=5a900519-af74-4964-8ec3-93f248437225" rel="preload" />
                  <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-1b.png?alt=media&token=6b813c15-d943-455d-9895-ebd9651586d0" />
                  <img class="hidden" src="https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-2b.png?alt=media&token=b689c58e-2653-41c4-ab08-15b0dd630091" /> -->

              <div class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                <span
                  class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Submit Results</span>

                <h1 class="text-xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl mb-2 mt-6">
                  <span class="block xl:inline">Submit your CAAP License Exam Results!</span>
                </h1>
                <p>
                  Skilltech's primary mission is to help all aspiring aircraft mechanics pass their CAAP License Exam.
                  Knowing your progress and how you went through the exam will contribute to the efficiency of our
                  platform.
                </p>

                

              <button @click="closeExamTrackerSubmitModal()"
                class="mt-6 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                Submit
              </button>
            </div>

          </div>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</TransitionRoot></template>
   
<script setup>

import { onUpdated } from 'vue'

import { XIcon } from "@heroicons/vue/solid";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

const { isExamTrackerSubmitOpen } = defineProps(["isExamTrackerSubmitOpen"])
const emit = defineEmits(['closeExamTrackerSubmitModal'])

// const passing = 75
// const item_range = [20,30,40,50, 'Other']
// const subtopics = [
//   'BLADES AND PROPELLER',
//   'ENGINE CARBURETION',
//   'ENGINE (RECIP)',
//   'ENGINE (TURBINE)',
//   'FUEL CONTROL MECHANISM',
//   'IGNITION MECHANISM',
//   'POWERPLANT FUEL MECHANISM',
//   'POWERPLANT INSTRUMENTS',
// ]

    // for ui temp values
    
const passing = 75;
const closeExamTrackerSubmitModal = () => emit('closeExamTrackerSubmitModal')



// const processExamTrackerResults = () => {
//   // populate the actual prop vales from the UI values
//   activeSubject.survey.passed = passed
//   activeSubject.survey.score = score

//   // if the user passed and the score is equal or above passing, then there are no changes in the passing rate
//   // if the user failed and the score is below passing, then there is no changes in the passing rate
//   // both of which, we pass a NULL or 0 passingRate.
//   // otherwise, we should pass the new passing rate.
//   if (passed && score >= passing) {
//     activeSubject.survey.newPassing = null
//   } else if (!passed && score < passing) {
//     activeSubject.survey.newPassing = null
//   } else {
//     activeSubject.survey.newPassing = newPassing
//   }

//   // push the changes
//   $emit("processExamTrackerResults", activeSubject.survey);

//   /**
//    * Reset the UI values
//    */
//   score = 0
// }

// onUpdated(() => {
//   console.log(activeSubject)
//   if(!activeSubject){
//     console.log("initializing modal values")
//   }
  
// })

</script>
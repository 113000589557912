<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1"
		id="Layer_1" viewBox="0 0 512.001 512.001" xml:space="preserve">
		<g>
			<g>
				<path
					d="M384.467,128.086c-8.522-34.931-28.324-66.574-56.246-89.653C298.236,13.648,260.261,0,221.295,0    C129.07,0,53.969,74.717,53.356,166.8L10.691,311.866l42.662,0.003v50.79c0,33.791,27.491,61.282,61.283,61.282h90.818v88.06    h183.781V311.867h24.016V128.086H384.467z M373.567,496.331H221.123v-88.06H114.636c-25.152,0-45.615-20.462-45.615-45.613    v-66.459l-37.391-0.002l37.391-127.129v-1.128c0-83.962,68.309-152.272,152.273-152.272c35.333,0,69.761,12.374,96.945,34.841    c24.332,20.112,41.868,47.385,50.056,77.576h-26.749v183.78h32.021V496.331z M397.583,296.198h-40.37V143.754h40.37V296.198z" />
			</g>
		</g>
		<g>
			<g>
				<path
					d="M253.487,184.123v127.744h71.706V184.123H253.487z M309.525,296.198h-40.369v-96.407h40.369V296.198z" />
			</g>
		</g>
		<g>
			<g>
				<path
					d="M165.429,240.161v71.706h71.706v-71.706H165.429z M221.466,296.197h-40.369v-40.369h40.369V296.197z" />
			</g>
		</g>
		<g>
			<g>
				<path d="M429.605,72.048v239.818h71.706V72.048H429.605z M485.642,296.198h-40.369V87.717h40.369V296.198z" />
			</g>
		</g>
	</svg>
</template>
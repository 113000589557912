<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Numerical estimation questions test your ability <strong>to make quick estimates of the answers</strong> to fairly straightforward numerical questions.
        </p>

        <h3>💡 Sample Questions</h3>
        <section>
            <p>
                <strong>Strategy:</strong> To score well on these questions, you will need to make quick approximations of the answer. You must avoid the trap of working out the exact answers, which will take up too much time and hinder you from answering enough questions to get a good score.
            </p>
            <hr />
            <p class="font-bold">
                1. 508 x 5.91
            </p>
            <p>
                <ul>
                    <li>A. 1,800</li>
                    <li>B. 3,200</li>
                    <li>C. 3,000</li>
                    <li>D. 1,300</li>
                    <li>E. 2,000</li>
                </ul>
            </p>
            <p>⭐️ The exact answer for this multiplication problem is 3,002.28. But in order to get the exact answer, you may spend more time, making it a lot harder to pass these types of exams. Remember, the goal here is to get the estimate as fast as you can. In order to do that, you can use these following steps:</p>
            <p>
                <ul>
                    <li>1 — Change 508 to 500 </li>
                    <li>2 — Round-off 5.91 to 6</li>
                    <li>3 — 500 x 6 = 3,000 (easier equation)</li>
                </ul>
            </p>
            <hr />
            <p class="font-bold">
                2. If a ream of paper costs $7.47, how much would 11 reams cost?
            </p>
            <p>
                <ul>
                    <li>A. 75</li>
                    <li>B. 70</li>
                    <li>C. 90</li>
                    <li>D. 80</li>
                    <li>E. 60</li>
                </ul>
            </p>
            <p>⭐️ The exact answer to this is $82.12. But to arrive to the nearest estimate of the answer, you can mentally solve this by simplifying it:	</p>
            <p>
                <ul>
                    <li>1 — Change $7.47 to $7.50</li>
                    <li>2 — Multiply 7.50 with 10 reams first: (7.5 x 10= 75)</li>
                    <li>3 — Add the remaining 1 ream: (75 + 7.5)</li>
                    <li>4 — Answer is 82.5. Then choose the nearest from the choices.</li>
                </ul>
            </p>
            <hr />
            <p>There are times that simplification techniques are not sufficient. Some of them require basic knowledge about formulas involving area, volume, etc. But the main purpose of these shortcuts is to give you an example on how to make it easier to get your estimates.</p>
            <p><strong>This is only a principle, not a procedure</strong>. There is no right or wrong way of getting estimates; it all depends on how you choose to mentally solve these problems. You can always develop your own techniques that conveniently fit your own way of computing.</p>
        </section>

    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

h4 {
    @apply mt-6 mb-2 text-sm font-bold text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

section {
    @apply ml-6 space-y-4
}

.question-grid-wrapper {
    @apply border border-warm-gray-200 dark:border-warm-gray-700 my-4 py-4 px-6
}

.question-grid {
    @apply grid grid-cols-2
}

.question-grid-answer {
    @apply w-full text-left sm:text-center mt-4
}

img {
    @apply mx-auto my-2
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}

ul {
    @apply space-y-1.5
}
</style>
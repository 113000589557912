<template>
    <div>
        <section>
            <h1>Skilltech Terms and Conditions</h1>
            <h2>Last Updated: <em>October 1, 2023</em></h2>
            <dl v-for="(term, index) in terms" :key="`term-${index}`">
                <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                        <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                            <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                                {{ `${index+1}. ${term.title}` }}
                            </span>
                            <span class="ml-6 h-7 flex items-center">
                                <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']"
                                    aria-hidden="true" />
                            </span>
                        </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                        <p v-for="content in term.contents" v-html="content"></p>
                    </DisclosurePanel>
                </Disclosure>
            </dl>
        </section>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

export default {
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronDownIcon,
    },
    created() {
        // console.log("Adding overflow-hidden")
        document.body.classList.remove("overflow-hidden");
    },
    data() {
        return {
            terms: [
                {
                    title: `Introduction`,
                    contents: [
                        `Welcome to <strong>Skilltech</strong>!`,
                        `Thank you for choosing to use Skilltech, a software platform provided by Skilltech Inc. (<strong>"Skilltech Inc."</strong>, <strong>"we"</strong>, <strong>"our"</strong>, or <strong>"us"</strong>), located at Mandaue City, Cebu, Philippines. This software platform (referred to herein as the <strong>"Platform"</strong> or <strong>"App"</strong>) is designed to assist graduates (and/or students) on their journey towards being professionals by offering a comprehensive review for licensing exams among other services.`,
                        `By using our App, Users (<strong>"Users"</strong>, <strong>"User"</strong>,<strong>"you"</strong>) are agreeing to these terms. Please read them carefully. Your access to and use of the App is conditioned upon your acceptance of and compliance with these terms. If you do not agree to be bound by these terms, then you are advised not to use the App.`,
                    ]
                },
                {
                    title: `Changes to Terms`,
                    contents: [
                        `We reserve the right to modify these Terms and Conditions at any time.  It is your responsibility to review these Terms and Conditions periodically. Your continued use of the App following the posting of any changes to the Terms and Conditions constitutes acceptance of those changes.`,
                        `By continuing to access or use our App after any revisions become effective, you agree to be bound by the revised Terms and Conditions. If you do not agree to the new terms, you are no longer authorized to use the App.`,
                    ]
                },
                {
                    title: `Changes to Platform`,
                    contents: [
                        `We reserve the right to withdraw or amend our Platform, and any service or material we provide, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the App, or the entire Platform, to Users, including registered Users.`
                    ]
                },
                {
                    title: `Use of the Platform`,
                    contents: [
                        `a) <strong>License:</strong> Subject to your compliance with these terms, we grant you a limited, non-exclusive, non-transferable license to use the App.`,
                        `b) <strong>Restrictions:</strong> You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the App without express written permission by Skilltech Inc.`,
                        `c) <strong>Analytics:</strong> We may use third-party Service Providers to monitor and analyze the use of our App.`,
                    ]
                },
                {
                    title: `Subscription Terms`,
                    contents: [
                        `a) <strong>Billing:</strong> By subscribing to the Platform's products, you agree to pay the subscription fees specified at the time of purchase.`,
                        `b) <strong>Cancellation:</strong> You may cancel your subscription at any time, but no refunds will be issued for payments already made.`,
                        `c) <strong>Termination:</strong> By default, your subscription will be automatically terminated upon the expiration of the initial subscription period. You may re-subscribe at any time.`,
                        `d) <strong>Renewal:</strong> Auto-renewal of subscription is turned off by default. However, you can set your subscription to automatically renew for another term, and your payment method will be charged or we will send you a new invoice, unless you cancel your subscription before the renewal date. `,
                    ]
                },
                {
                    title: `Refunds`,
                    contents: [
                        `a) <strong>Refund Eligibility:</strong> Refund requests will be considered only if the User can provide substantial evidence of Skilltech Inc.'s mishandling of the purchase procedure. Each request will be thoroughly reviewed and assessed by our team to determine eligibility for a refund.`,
                        `b) <strong>Refund Limitations:</strong> Refunds will not be granted in cases of discontent or dislike of the offered Service. You are encouraged to review all available information and utilize trial options (if available) before making a purchase to ensure the Service meets their needs and expectations.`,
                        `c) <strong>Refund Process:</strong> Eligible refunds will be processed within a certain number of business days from the approval of the refund request. Refunds will be returned via the original payment method used during the purchase.`,
                        `d) <strong>Contact Information:</strong> Users seeking a refund should contact our customer support team at <a href="mailto:support@skilltech.ph">support@skilltech.ph</a> to initiate the refund request process.`,
                        `e) <strong>Final Decision:</strong> The decision of Skilltech Inc. regarding the eligibility for a refund shall be final and binding.`,
                        `By making a purchase on the Platform, you agree to abide by this refund policy. Please ensure you understand our refund policy before making a purchase.`,
                    ]
                },
                {
                    title: `Submission of Test Results and Claiming Rewards`,
                    contents: [
                        `a) <strong>Submission of Test Results:</strong> Users may submit their test results to Skilltech Inc. through the App to claim specific rewards. By submitting test results, Users agree that the test results are accurate and authentic. We reserve the right to verify the accuracy of test results and to refuse to grant rewards for test results that are found to be inaccurate or fraudulent.`,
                        `b) <strong>Confidentiality of Test Results:</strong> Skilltech Inc. will handle all submitted test results confidentially. Test results will be used exclusively for determining eligibility for rewards and will not be disclosed to third parties, except as outlined in the Privacy Policy or as required by law.`,
                        `c) <strong>Public Sharing of Test Results:</strong> By submitting test results, Users grant Skilltech Inc. permission to share their test results publicly on the Platform and on our social media channels, in accordance with the Users' indicated preferences at the time of submission. Users may revoke this permission at any time by contacting us, but this will not affect any sharing that has already occurred.`,
                        `d) <strong>Rewards:</strong> Rewards are granted at the sole discretion of Skilltech Inc. Submission of test results does not guarantee the receipt of a reward. We reserve the right to change, modify, or cancel the rewards program at any time without notice.`,
                        `By submitting test results on the Platform, you agree to abide by these terms. Users who do not agree to these terms should not submit test results to claim rewards.`,
                    ]
                },
                {   
                    title: `User Responsibilities`,
                    contents: [
                        `You are responsible for all activities that occur under your account and any content uploaded or submitted by you.`,
                        `You should not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.`,
                    ]
                },
                {
                    title: `Access`,
                    contents: [
                        `We reserve the right to grant, alter, or revoke your access to the Software at any time and for any reason, at its sole discretion.`,
                        `Users are allowed to log into the App on one device at a time. If a User logs in on a new device, any other existing logged-in sessions on other devices will be automatically logged out. This limitation is enforced to enhance the security and integrity of User accounts and the overall system. Users found attempting to circumvent this limitation may have their accounts suspended or terminated at the sole discretion of Skilltech Inc.`,
                    ]
                },
                {
                    title: `Privacy`,
                    contents: [
                        `Please refer to our Privacy Policy found <a href="/privacy" class="underline">here</a> for information on how we collect, use, and protect your personal information.`,
                    ]
                },
                {
                    title: `Copyright Policy`,
                    contents: [
                        `We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Platform infringes on the copyright or other intellectual property rights (<strong>“Infringement”</strong>) of any person or entity.`,
                        `If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to <a href="mailto:support@skilltech.ph">support@skilltech.ph</a>, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement.`,
                        `You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad-faith claims on the infringement of any content found on and/or through the Platform on your copyright.`,
                    ]
                },
                {
                    title: `Error Reporting and Feedback`,
                    contents: [
                        `You may provide us either directly at <a href="mailto:support@skilltech.ph">support@skilltech.ph</a> or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Platform (“Feedback”). You acknowledge and agree that: `,
                        `<strong>(i)</strong> You shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; `,
                        `<strong>(ii)</strong> Skilltech Inc. may have development ideas similar to the Feedback; `,
                        `<strong>(iii)</strong> Feedback does not contain confidential information or proprietary information from you or any third party; and `,
                        `<strong>(iv)</strong> Skilltech Inc. is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant us and our affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) the Feedback in any manner and for any purpose.`,
                    ]
                },
                {
                    title: `Termination`,
                    contents: [
                        `We reserve the right to terminate or suspend your account and access to the Platform at its sole discretion, without notice, for conduct that Skilltech Inc. believes violates these Terms and Conditions or is harmful to other users of the Platform, Skilltech Inc., or third parties, or for other lawful reasons.`,
                        `All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`,
                        `If you wish to terminate your account, you may simply discontinue using Service.`,
                    ]
                },
                {
                    title: `Disclaimers`,
                    contents: [
                        `The App is provided “as is,” and Skilltech Inc. makes no representations or warranties of any kind, express or implied, as to the operation of the App or the information, content, or materials included therein.`,
                        `Any product names, logos, brands, and other trademarks or images featured or referred to within the Platform are property of their respective trademark holders. Any of these trademark holders are not affiliated with us, our products, or our apps. They do not sponsor this app or any of our online products.`,
                    ]
                },
                {
                    title: `Limitation of Liability`,
                    contents: [
                        `EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. `,
                    ]
                },
                {
                    title: `Governing Law`,
                    contents: [
                        `These Terms shall be governed and construed in accordance with the laws of the Republic of the Philippines, which governing law applies to agreement without regard to its conflict of law provisions.`,
                        `Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.`,
                    ]
                },
                {
                    title: `Waiver And Severability`,
                    contents: [
                        `No waiver by Skilltech Inc. of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Skilltech Inc. to assert a right or provision under Terms shall not constitute a waiver of such right or provision.`,
                        `If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.`,
                    ]
                },
                {
                    title: `Acknowledgement`,
                    contents: [
                        `BY USING OUR PLATFORM OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.`
                    ]
                },
                {
                    title: `Contact Information`,
                    contents: [
                        `If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:support@skilltech.ph">support@skilltech.ph</a>.`
                    ]
                },
            ]
        }
    }
}
</script>

<style scoped>
h1 {
    @apply my-4 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl
}

h2 {
    @apply mt-1 text-base text-center text-warm-gray-900 dark:text-true-gray-300 sm:text-lg mx-auto
}

dl {
    @apply mt-6 space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 max-w-3xl mx-auto
}

p {
    @apply mt-4 text-sm text-warm-gray-500 max-w-3xl mx-auto
}

p a{
    @apply text-yellow-500
}

section{
    @apply dark:bg-true-gray-800 bg-white py-24
}

</style>
<script setup>
</script>

<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p>
            Information is provided that requires you to interpret it and then apply the appropriate logic to answer the
            questions. In other words, you need to work out how to get the answer rather than what calculations to
            apply.
        </p>

        <h3>💡 Learn Number Sequences</h3>
        <section>
            <p>
                These questions require you to find the missing number in a sequence of numbers. This missing number may
                be at the beginning or middle but is usually at the end.
            </p>
            <p class="font-bold">
                Find the missing number in the series.
            </p>
            <p class="space-x-8">
                <span>1)</span> <span>4</span><span>8</span><span>16</span><span>32</span><span>?</span>
            </p>
            <ul>
                <li>A] 48</li>
                <li>B] 64</li>
                <li>C] 40</li>
                <li>D] 46</li>
            </ul>
            <p>
                ⭐️ The answer is B – The numbers double each time.
            </p>
            <hr />
            <p class="space-x-8">
                <span>2)</span> <span>54</span><span>49</span><span>?</span><span>39</span><span>34</span>
            </p>
            <ul>
                <li>A] 47</li>
                <li>B] 44</li>
                <li>C] 45</li>
                <li>D] 46</li>
            </ul>
            <p>
                ⭐️ The answer is B – The numbers decrease by 5 each time.
            </p>
            <hr />
            <p class="space-x-8">
                <span>3)</span> <span>?</span><span>19</span><span>23</span><span>29</span><span>31</span>
            </p>
            <ul>
                <li>A] 12</li>
                <li>B] 15</li>
                <li>C] 16</li>
                <li>D] 17</li>
            </ul>
            <p>
                ⭐️ The answer is D – The numbers are primes (divisible only by 1 and themselves).
            </p>
            <hr />
            <p class="space-x-8">
                <span>4)</span> <span>3</span><span>6</span><span>11</span><span>18</span><span>?</span>
            </p>
            <ul>
                <li>A] 30</li>
                <li>B] 22</li>
                <li>C] 27</li>
                <li>D] 29</li>
            </ul>
            <p>
                ⭐️ The answer is C – The interval, beginning with 3, increases by 2 each time.
            </p>
            <hr />
            <p class="space-x-8">
                <span>4)</span>
                <span>5</span><span>6</span><span>7</span><span>8</span><span>10</span><span>11</span><span>14</span><span>?</span><span>?</span>
            </p>
            <ul>
                <li>A] 19</li>
                <li>B] 17</li>
                <li>C] 15</li>
                <li>D] 16</li>
            </ul>
            <p>
                ⭐️ C & A – There are 2 simple interleaved sequences 5,7,10,14,19 and 6,8,11,15
            </p>
        </section>

        <h3>💡 Letters of the Alphabet as Numbers</h3>
        <section>
            <p>
                Another type of sequence question which appears in these tests involves the substitution of letters of
                the alphabet for numbers. For example A=1, B=2 etc. It may seem strange to consider these as numerical
                reasoning questions but they actually work in the same way once you have changed them back into numbers.
            </p>
            <p>
                Example: Find the next letter in the series.
            </p>
            <hr />
            <p class="space-x-8">
                <span>1)</span> <span>B</span><span>E</span><span>H</span><span>K</span><span>?</span>
            </p>
            <ul>
                <li>A] L</li>
                <li>B] M</li>
                <li>C] N</li>
                <li>D] O</li>
            </ul>
            <p>
                ⭐️ The answer is C – There are two letters missing between each one, so N is next
            </p>
            <hr />
            <p class="space-x-8">
                <span>2)</span> <span>A</span><span>Z</span><span>B</span><span>Y</span><span>?</span>
            </p>
            <ul>
                <li>A] C</li>
                <li>B] X</li>
                <li>C] D</li>
                <li>D] Y</li>
            </ul>
            <p>
                ⭐️ The answer is A – There are 2 interleaved sequences A,B,C and Z,Y, so C is next.
            </p>
            <hr />
            <p class="space-x-8">
                <span>2)</span> <span>T</span><span>V</span><span>X</span><span>Z</span><span>?</span>
            </p>
            <ul>
                <li>A] Y</li>
                <li>B] B</li>
                <li>C] A</li>
                <li>D] W</li>
            </ul>
            <p>
                ⭐️ The answer is B – Miss a letter each time and ‘loop’ back, so B is next.
            </p>
        </section>
        <h3>💡 Using Sequences in Solving Problems</h3>
        <section>
            <p>
                Sometimes, an item in this exam can be represented through problems in a form of a phrase or statement.
                It’s very important to understand the problem in order to come up with the required formula in
                determining the sequence.

            </p>
            <p>
                Example:
            </p>
            <p>
                A bank offers 6% per annum interest which is calculated and added at the end of the year. Another bank
                offers 10% per annum which is calculated and added every six months. What is the difference on a deposit
                of $100 after one year?
            </p>
            <ul>
                <li>A] $ 4.00</li>
                <li>B] $ 4.05</li>
                <li>C] $ 4.10</li>
                <li>D] $ 4.15</li>
                <li>E] $ 4.25</li>
            </ul>
            <p>
                ⭐️ At the bank paying 6%, the amount will be $106 ($100 * 1.06) at the end of the first year. At the bank paying 10% the amount will be $105 ($100 * 1.05) at the end of the first six months and $110.25 ($105 * 1.05) at the end of the year. Therefore the difference will be <strong>$4.25</strong>.
            </p>
        </section>

    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

h4 {
    @apply mt-6 mb-2 text-sm font-bold text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

section {
    @apply ml-6 space-y-4
}

.question-grid-wrapper {
    @apply border border-warm-gray-200 dark:border-warm-gray-700 my-4 py-4 px-6
}

.question-grid {
    @apply grid grid-cols-2
}

.question-grid-answer {
    @apply w-full text-left sm:text-center mt-4
}

img {
    @apply mx-auto my-2
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}

ul {
    @apply space-y-1.5
}
</style>
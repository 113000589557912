<template>
  <LoadingScreen v-if="isLoading" />
  <main v-else>
    <header class="mt-0 sm:mt-4">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 sm:flex sm:items-center sm:justify-between align-middle">
        <div class="my-5 sm:my-0">
          <h1 class="text-xl font-bold leading-7 text-warm-gray-200 sm:text-2xl sm:truncate">
            Leaderboard: The Top 20 🎯
          </h1>
          <p class="mt-1 text-warm-gray-400 max-w-2xl">
            Here are the Top 20 highest performing users of Skilltech. Rise above the competition and be on top of this nationwide ranking.
          </p>
          <p class="mt-4 text-warm-gray-400 max-w-2xl flex inline-flex items-center space-x-1">
            Users with the <BadgeCheckIcon class="w-4 h-4 text-blue-500 mx-1" /> badge are CAAP Knowledge Test Passers.
          </p>
          
        </div>
      </div>
    </header>
    <div class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
              <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs hidden lg:block font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    School
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Skillpoints
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                <tr v-for="user in leaderboard" :key="user.rank" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                    {{ this.getOrdinal(user.rank) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full" :src="user.photo" alt="" />
                        <DefaultUser v-else class="block mx-auto h-10 w-10" />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-true-gray-900 dark:text-true-gray-100 flex flex-inline items-center space-x-1">
                          <span>{{ user.firstname }} {{ user.lastname }} </span>
                          <BadgeCheckIcon v-if="user.isVerified" class="w-4 h-4 text-blue-500" />

                          <!-- <a href="#" v-if="user.lastname == 'Ampong Jr.'" class="ml-1 dark:text-yellow-400 text-yellow-500 animate-pulse">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                            </svg>
                          </a> -->

                          <!-- <div v-if="user.lastname == 'Ampong Jr.'" href="#" class="ml-1  inline-flex items-center shadow-sm px-2 py-0.5 text-xs font-medium rounded-full text-white bg-gradient-to-r from-green-400 to-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                            </svg>  
                            <span>PASSER</span>
                          </div> -->
                        </div>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap hidden lg:block ">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <router-link :to="`/school/${getSchoolById(schools, user.school).shorthand.toLowerCase()}`" class="hover:text-md">
                          <img v-if="user.school" class="object-cover mx-auto h-10 w-10 rounded-md" :src="getSchoolById(schools, user.school).logoUrl" alt="" />
                        </router-link>
                      </div>
                      <div class="ml-4">
                        <router-link :to="`/school/${getSchoolById(schools, user.school).shorthand.toLowerCase()}`" class="hover:text-md">
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100">{{ getSchoolById(schools, user.school).name }} ({{getSchoolById(schools, user.school).shorthand}})</div>
                          <div class="text-sm text-true-gray-500 dark:text-true-gray-400">{{ user.course }}</div>
                        </router-link>    
                      </div>
                    </div>
                    
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ user.skillpoints.toLocaleString() }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>

  
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";

import Logo from '@/components/Logo'
import DefaultUser from '@/components/DefaultUser'
import LoadingScreen from "@/components/appshell/LoadingScreen"

import { leaderboardService } from "@/utils/leaderboard"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"

import { BadgeCheckIcon } from '@heroicons/vue/solid'

export default {
  components: { 
    Logo,
    DefaultUser,
    LoadingScreen,
    BadgeCheckIcon
  },
  data(){
    return {
      isLoading: true,
      user: null,
      leaderboard: [],
      schools: [],
      
    }
  },
  
  methods: {

    getSchoolById: schoolService.getSchoolById,
    
    /**
     * Get the ordinal of a number for ranking system
     * i.e. getOrdinal(1) returns String "1st"
     * @NOTE: Duplicate over /dashboard
     */
    getOrdinal(i) { 
      var j = i % 10, k = i % 100;
      if (j == 1 && k != 11) { return i + "st"; }
      if (j == 2 && k != 12) { return i + "nd"; }
      if (j == 3 && k != 13) { return i + "rd"; }
      return i + "th";
    },
  },
  async created(){
    this.isLoading = true

    /* CLAIMS */
    // let claims = await userService.fetchUserClaims(firebase.auth())
    // if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    
    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    console.log("this.user", this.user)

    /* LEADERBOARD / RANK */
    var all_leaderboard
    all_leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());
    all_leaderboard = leaderboardService.sortLeaderboard(all_leaderboard) // sort the leaderboard    
    all_leaderboard = leaderboardService.adjustLeaderboardForUser(this.user, all_leaderboard) // adjust the leaderboard for current user

    var lboard_user_rank = all_leaderboard.filter( e => { return e.id == this.user.id }) // get the user from leaderboard
    all_leaderboard.splice(20) // splice the all_leaderboard array to only top 20
    if(lboard_user_rank[0].rank < 20){
      this.leaderboard = all_leaderboard // if the user is within the top 20, display the spliced all_leaderboard
    }else{
      this.leaderboard = [...all_leaderboard, ...lboard_user_rank] // else, append the user into the all_leaderboard at the very end
    }
    
    
    /* SCHOOLS */
    this.schools = await schoolService.fetchAllSchools(firebase.firestore())
    this.schools = schoolService.sortSchoolsAlpha(this.schools)

    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false

  }
}
</script>
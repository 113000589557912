<template>
     <div class="lg:hidden">
        <div class="dark:bg-true-gray-800 bg-warm-gray-600 py-2 px-4 flex items-center justify-between sm:px-6">
          <div>
            <router-link to="/">
              <Logo class="h-8 w-auto dark:text-yellow-400 text-warm-gray-200" />
            </router-link>
          </div>
          <div>
            <button
              type="button"
              class="-mr-3 h-12 w-12 inline-flex items-center justify-center dark:bg-true-gray-800 bg-warm-gray-600 rounded-md text-white dark:hover:bg-true-gray-700 hover:bg-warm-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              @click="this.openMobileMenu()"
            >
              <span class="sr-only">Open sidebar</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
</template>
<script>

import { MenuIcon } from "@heroicons/vue/outline";
import Logo from "@/components/Logo";

export default {
  components: { MenuIcon, Logo },
  methods: {
    openMobileMenu() {
      this.$emit("openMobileMenu");
    },
  },
};
</script>
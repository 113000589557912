<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 471.098 471.098"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M282.65,200.222c0,0-23.548-71.306-23.548-114.482c0-26.128-8.772-34.009-15.701-36.191
        c-1.723-0.543-3.416-0.739-4.839-0.777c1.36,0.024,3.023,0.205,4.839,0.777V19.662c0-4.33-3.521-7.848-7.853-7.848
        c-4.33,0-7.849,3.518-7.849,7.848v29.974c-7.005,2.292-15.701,10.291-15.701,36.105c0,43.176-23.552,114.482-23.552,114.482
        S10.181,217.234,0.367,259.098c-9.812,41.869,180.229,27.808,188.08,41.869v71.962l18.314,9.16c0,0,20.938-56.26,20.938,22.242
        h-94.207l-2.613,47.103L227.7,437.02v15.564c0,3.703,2.998,6.7,6.703,6.7h2.292c3.707,0,6.707-2.997,6.707-6.7v-15.793
        l97.593,14.643l-2.617-47.103h-94.202c0-78.502,20.546-22.242,20.546-22.242l17.93-9.16v-71.962
        c7.851-14.069,197.895,0,188.08-41.869C460.917,217.234,282.65,200.222,282.65,200.222z M235.258,66.703
        c0.126-0.042,0.291-0.08,0.291-0.103c0.495-0.024,0.872-0.096,1.312-0.142c-0.172,0.03-0.314,0.126-0.495,0.142
        c-0.284,0.03-0.525,0.165-0.817,0.165C235.447,66.765,235.354,66.727,235.258,66.703z"
        ></path>
        <path
          d="M211.999,43.213c4.33,0,7.851-3.519,7.851-7.851c0-4.33-3.521-7.851-7.851-7.851h-39.252
        c-4.332,0-7.851,3.521-7.851,7.851c0,4.332,3.519,7.851,7.851,7.851H211.999z"
        ></path>
        <path
          d="M259.103,43.213h39.249c4.332,0,7.851-3.519,7.851-7.851c0-4.33-3.519-7.851-7.851-7.851h-39.249
        c-4.332,0-7.851,3.521-7.851,7.851C251.252,39.695,254.771,43.213,259.103,43.213z"
        ></path>
      </g>
    </g>
  </svg>
</template>
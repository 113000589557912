<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 472.072 472.072"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M471.548,283.596L369.49,212.768v-10.299c4.641-2.388,7.851-7.175,7.851-12.75v-10.465
                                  c0-7.953-6.439-14.396-14.387-14.396h-2.616c-7.959,0-14.399,6.444-14.399,14.391v10.463c0,3.384,1.215,6.452,3.174,8.909
                                  l-34.571-23.98v-11.423c4.636-2.388,7.851-7.175,7.851-12.75v-10.464c0-7.955-6.44-14.398-14.391-14.398h-2.617
                                  c-7.955,0-14.395,6.444-14.395,14.391v10.465c0,5.581,3.21,10.361,7.851,12.748v0.525l-39.253-27.239V54.952
                                  c0-13.01-10.54-54.952-23.552-54.952c-13.01,0-23.552,41.941-23.552,54.952v81.551l-39.251,27.238v-0.525
                                  c4.639-2.388,7.851-7.175,7.851-12.75v-10.464c0-7.955-6.444-14.398-14.391-14.398h-2.615c-7.953,0-14.396,6.444-14.396,14.391
                                  v10.465c0,5.581,3.212,10.361,7.851,12.748v11.423l-34.572,23.989c1.956-2.445,3.17-5.517,3.17-8.901v-10.465
                                  c0-7.953-6.442-14.396-14.389-14.396h-2.615c-7.953,0-14.396,6.444-14.396,14.391v10.463c0,5.583,3.212,10.361,7.851,12.75v10.299
                                  L0.524,283.596v30.42l102.871-39L0.524,346.396v30.421l211.959-80.357v81.832l-70.653,60.096v33.686l75.231-42.158
                                  c2.763,3.779,6.622,6.669,11.123,8.264v17.144c0,4.332,3.519,7.851,7.851,7.851s7.851-3.519,7.851-7.851v-17.144
                                  c4.5-1.607,8.355-4.484,11.125-8.264l75.23,42.158v-33.686l-70.654-60.096v-81.832l211.961,80.357v-30.421l-102.867-71.38
                                  l102.867,39V283.596z"
        ></path>
      </g>
    </g>
  </svg>
</template>
<template>
  <LoadingScreen v-if="isLoading" />
    <div v-else class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-8">
      
      <div v-if="this.slide_index == 0" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Account Info Update</span>
        <h1 class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
          <span class="block xl:inline">Do you have a date for your </span>
          {{ ' ' }}
          <span class="block dark:text-yellow-200 text-yellow-400 xl:inline">CAAP Exam?</span>
        </h1>
        <p v-if="!license.isLicensed" class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          If you already have a scheduled date for your CAAP Exam, we'd like to know. Otherwise, it's always a great idea to set a target date to make sure you move towards that goal. We will also remind you about it. 
        </p>
        <p v-else class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          Congratulations for passing your CAAP Exam! We are offerring you a "CAAP Passer" badge and 2,000 Skillpoints! All you need to do is get verified by uploading your Test Results. Verification can take up to 24-48 hours.
        </p>
        <div>
          <label v-if="!license.isLicensed" for="phone" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">I will be taking my CAAP Exam on...</label>
          <label v-else for="date" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">I took my CAAP Exam last...</label>
          <div class="mt-4">
            <input v-model="license.exam_date"  type="date" name="date" id="date" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" />
          </div>
          <div v-if="license.isLicensed && license.exam_date != null && this.moment(license.exam_date).isAfter(moment())" class="right-0 text-sm font-medium text-red-700 dark:text-red-400 mt-2">
            Date should be today or in the past.
          </div>
          <div v-if="!license.isLicensed && license.exam_date != null && this.moment(license.exam_date).isBefore(moment(-1,'days'))" class="right-0 text-sm font-medium text-red-700 dark:text-red-400 mt-2">
            Date should be today or in the future.
          </div>
        </div>

        <div v-if="!license.isLicensed">
          <label for="phone" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Just in case I forget, my phone number is...</label>
          <div class="mt-4">
            <input v-model="user.phone" type="phone" name="phone" id="phone" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input 11 Digit Phone Number" />
          </div>
        </div>

        <div v-else>
          <label for="results" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Attached below are my test results...</label>
          <div class="mt-4 shadow-sm bg-white  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg">
              <input id="test_results" @change="someHandler" name="test_results" type="file" accept="application/pdf,image/jpeg,image/png" class="p-4 inset-0 opacity-0 w-full bg-red-200 cursor-pointer border-gray-300 rounded-md" />
              <label for="test_results" class="flex justify-between text-sm text-gray-500 pointer-events-none w-full p-4 -mt-14">
                <span v-if="!license.test_results">Upload your Test Results here (accepts PDF, JPEG, PNG; file size below 2MB)</span>
                <span v-else>Selected: {{license.test_results}}  </span>
                <span v-if="license.test_results" class="right-0 text-sm font-medium text-red-700 dark:text-red-400">Change</span>
              </label>
          </div>
        </div>

        <div class="flex items-center h-5 my-8">
          <input v-model="license.isLicensed" @change="this.clearEverything()" id="isLicensed" name="isLicensed" type="checkbox" class="cursor-pointer border-2 focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" />
          <p class="ml-3 text-xl dark:text-gray-400 text-gray-600 leading-8">I already have my CAAP license.</p>
        </div>

        <!-- <button :disabled="!readyToProceedToTwo" @click="this.nextStep(1)" class="mt-12 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto"> -->
        <button :disabled="!readyToComplete" @click="this.completeCaapOnboarding()" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
          Complete
        </button>
      </div>
      

      <!-- Step 4 -->
      <div v-if="this.slide_index == 1" class="text-lg max-w-prose mx-auto">
        <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Welcome To Skilltech</span>
        <h1 class="text-4xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 sm:text-5xl md:text-6xl mt-8">
          <span v-if="!readyToExit" class="block xl:inline">Please wait while we update your data.</span>
          <span v-else class="block xl:inline">Perfect! Your <span class="dark:text-yellow-200 text-yellow-400">data</span> has been updated.</span>
        </h1>
        <p class="mt-8 text-xl dark:text-gray-400 text-gray-600 leading-8">
          We are excited for you to start your review! Your CAAP access will be granted after payment is received and confirmed in a few moments. Expect an email confirmation within 24 hours!
        </p>

        <div v-if="!readyToExit" class="relative pt-1 mt-8">
          <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
              <div
              :style="`width: ${progress}%`"
              class="transition-all duration-500 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"
              ></div>
          </div>
        </div>
        
        <div class="bg-yellow-50 overflow-hidden rounded-lg text-xl text-warm-gray-500 text-left mt-8 max-w-full">
          <div class="px-4 py-5 sm:py-8 sm:px-9 flex space-x-2 items-start">
            <div>
              <LightBulbIcon class="text-yellow-400 w-5 h-5" />
            </div>
            <p class="text-yellow-700">
              <strong>Tip:</strong> {{tips[current]}}
            </p>
          </div>
        </div>
        
        <button :disabled="!readyToExit" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
          Close
        </button>
      </div>

    </div>
  
  
</template>

<script>

import firebase from "@/firebase/config";
// import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
const db = firebase.firestore();

import LoadingScreen from "@/components/appshell/LoadingScreen"
import { LightBulbIcon } from "@heroicons/vue/solid";
import { homeData } from "@/data/home.data"
import Logo from "@/components/Logo";

import moment from "moment";

export default {
  components: { LoadingScreen, LightBulbIcon, Logo },
  data() {
    return {
      license: {
        exam_date: null,
        test_results: null,
        isLicensed: false,
        isVerified: false,
      },
      user: {
        phone: null
      },
      // ui data
      moment: moment,
      isLoading: false,
      progress: 0,
      slide_index: 0,
      current: 0,
      tips: homeData.tips,
      readyToExit: false,
      tmpFile: null
    };
  },
  computed: {
    readyToComplete(){ // Verify user input here      
      if(this.license.exam_date == null ) return // exam_date must always have a value
      if(this.license.isLicensed){
        // If user already has CAAP license
        if(moment(this.license.exam_date).isAfter(moment())) return // date must be today or before today
        if( this.license.test_results == null ) return
      }else{
        // If user doesn't have a CAAP license yet
        if(moment(this.license.exam_date).isBefore(moment(-1,"days"))) return // date must be today or after today
        if( this.user.phone == null ) return
        if( this.user.phone.length < 11 ) return
        if( this.user.phone.length > 11 ) return
        if(!/^09(\d{9})$/.test(this.user.phone)) return // i.e. 09062738475
      }
      return true
    },
  },

  methods: {
    someHandler(event){
        console.log("User updared the file")
        // tempFileName
        // console.log('Selected file: ' + event.target.files.item(0).name);
        console.log('Selected file: ' + event.target.files.item(0).size);
        // console.log('Selected file: ' + event.target.files.item(0).type);
        
        var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
        
        console.log(`File size: ${filesize}MB`)
        
        if(filesize > 2){
            alert("File size of 2MB exceeded")
            // this.emptyFile()
            this.license.test_results = null 
        }else{
            this.license.test_results = event.target.files.item(0).name
            this.tmpFile = event.target.files.item(0)
            console.log(event)
        }
    },
    clearEverything(){
      this.user.phone = null
      this.license.exam_date = null
      // this.license.test_results = null 
      // this.emptyFile()
      console.log("Clearing everything...")
    },
    // emptyFile(){    
    //   // document.getElementById('test_results').value = ""
    //   this.license.test_results = null 
    // },
    nextStep(index){
      this.slide_index = index;
      document.querySelector("body").scrollTop;
    },
    async completeCaapOnboarding(){
      // let isValidEndorserCode = await userService.isValidEndorserCode(db, this.user.endorser)
      // if(!isValidEndorserCode){
      //   alert("Endorser code not found")
      // }else{
        // this.user.level = (this.isStudent) ? 'Undergraduate' : '';
        // console.log("user: ", this.user)

        if(this.license.isLicensed){
          console.log("USER IS LICENSED")
          console.log("USER TOOK EXAM ON: ", this.license.exam_date)
          console.log("USER TEST RESULTS: ", this.license.test_results)
        }else{
          console.log("USER IS NOT YET LICENSED")
          console.log("USER WILL TAKE EXAM ON: ", this.license.exam_date)
          console.log("USER CONTACT NUM: ", this.user.phone)
        }
        
        this.nextStep(1) // navigate to the final slide
        this.onboardUser(); // run loaders and tips
      // }    
    },

    async onboardUser(){
            
      var current_progress = 0;
      var step = 0.05; // the smaller this is the slower the progress bar

      // run interval for progress bar
      const interval = setInterval( () => {
          current_progress += step;
          this.progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
          if (this.progress >= 100){
            clearInterval(interval);
          }else if(this.progress >= 20) {
            step = 0.1
          }
      }, 100);

      // run interval for tips
      const tipsInterval = setInterval(() => {
        this.current = (this.current < this.tips.length-1) ? this.current+1 : 0;
        // console.log(this.current)
      }, 4000);

      // convert the exam_date into moment milliseconds  
      this.license.exam_date =  this.moment(this.license.exam_date).format("x");
      
      // If user has a license, upload the license first and then save
      if(this.license.isLicensed){

        var storageRef = firebase.storage().ref();
        var fileExt = this.license.test_results.split('.').pop();
        var userPicImagesRef = storageRef.child(`testresults/${firebase.auth().currentUser.uid}.${fileExt}`);
        // const selectedFile = document.getElementById('test_results').files[0];
        const selectedFile = this.tmpFile

        console.log('selectedFile: ', selectedFile)
        
        if(selectedFile){
          // 'file' comes from the Blob or File API
          userPicImagesRef.put(selectedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then( (downloadURL) => {
              console.log("User image updated");
              console.log("File available at", downloadURL);
              
              this.license.test_results = downloadURL

              // upload without the phone number
              db.collection("users").doc(firebase.auth().currentUser.uid).update({ license: this.license })
                .then(() => { 

                  // Clear timers
                  clearInterval(tipsInterval); 
                  clearInterval(interval);
                  
                  // enable buttons and everything
                  this.progress = 100; // set progress to 100
                  this.readyToExit = true;
                })
                .catch((error) => { console.error("Error updating user data: ", error)  });                
            })
          })
        }else{
          console.error("No file added");
          alert("An error occurred while uploading your test results")
        }
      
      
      }else{
        // User Doesn't have a license. Upload data directly

        db.collection("users").doc(firebase.auth().currentUser.uid).update({ license: this.license, phone: this.user.phone })
        .then(() => { 
          
          // Clear timers
          clearInterval(tipsInterval); 
          clearInterval(interval);
          
          // enable buttons and everything
          this.progress = 100; // set progress to 100
          this.readyToExit = true;
        })
        .catch((error) => { console.error("Error updating user data: ", error)  });
      }
        

     
    },    

  },

  async created(){
    this.isLoading = true
    this.isLoading = false
  },
};
</script>
<template>
  <LoadingScreen v-if="isLoading" />
  <main v-else>
    <header class="mt-0 sm:mt-4">

      <!-- <div class="sm:flex sm:items-center sm:justify-between"> -->
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 sm:flex sm:items-center sm:justify-between align-middle mt-8">
        <div class="sm:flex sm:space-x-5">
          <div class="flex-shrink-0 mb-4">
            <img class="object-cover mx-auto h-20 w-20 rounded-md" :src="this.school.logoUrl" alt="" />
          </div>
          <div class="text-center sm:mt-0 sm:pt-1 sm:text-left">
            <h1 class="text-xl font-bold leading-7 text-warm-gray-900 dark:text-warm-gray-200 sm:text-2xl sm:truncate">
                {{this.school.name}}
              </h1>
              <!-- <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl">
                Some description of the college. Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
              </p> -->
              <div v-if="leaderboard.length <= 0" class="flex inline-flex mt-2 text-warm-gray-700 dark:text-warm-gray-400">
                <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl flex inline-flex space-x-2 mt-2">
                <UserGroupIcon class="text-gray-700 dark:text-gray-200 h-6 w-6"/>
                <span>0 Total Users</span>
                </p>
                <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl flex inline-flex space-x-2 mt-2 ml-5">
                <Logo class="text-gray-200 h-6 w-6"/>
                <span>0 Total Skillpoints</span>
                </p>
              </div>
              <div v-else class="flex inline-flex mt-2">
                <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl flex inline-flex space-x-2 mt-2">
                <UserGroupIcon class="text-gray-700 dark:text-gray-200 h-6 w-6"/>
                <span>{{leaderboard.length}} Total Users</span>
                </p>
                <p class="mt-1 text-warm-gray-700 dark:text-warm-gray-400 max-w-2xl flex inline-flex space-x-2 mt-2 ml-5">
                <Logo class="text-gray-700 dark:text-gray-200 h-6 w-6"/>
                <span>{{ school.skillpoints }} Total Skillpoints</span>
                </p>
              </div>
          </div>
        </div>
      </div>
    </header>
    <div class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div v-if="leaderboard.length > 0" class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
              <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs hidden lg:block font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    School
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Skillpoints
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                <tr v-for="user in leaderboard" :key="user.rank" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''">
                <!-- <tr v-for="user in leaderboard" :key="user.rank" class='bg-warm-gray-100 dark:bg-true-gray-600'> -->
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                    {{ this.getOrdinal(user.rank) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full" :src="user.photo" alt="" />
                        <DefaultUser v-else class="block mx-auto h-10 w-10" />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-true-gray-900 dark:text-true-gray-100 flex inline-flex items-center space-x-1">
                          <span>{{ user.firstname }} {{ user.lastname }}</span>
                          <BadgeCheckIcon v-if="user.isVerified" class="w-4 h-4 text-blue-500" />
                        </div>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap hidden lg:block ">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img v-if="user.school" class="object-cover mx-auto h-10 w-10 rounded-md" :src="getSchoolById(schools, user.school).logoUrl" alt="" />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm text-true-gray-900 dark:text-true-gray-100">{{ getSchoolById(schools, user.school).name }} ({{getSchoolById(schools, user.school).shorthand}})</div>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400">{{ user.course }}</div>
                      </div>
                    </div>
                    
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ user.skillpoints }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="shadow overflow-hidden bg-warm-gray-300 dark:bg-true-gray-800 sm:rounded-lg">
            <p class="px-6 py-8 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider text-center">
              This school doesn't have any users yet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>

  
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";

import Logo from '@/components/Logo'
import DefaultUser from '@/components/DefaultUser'
import LoadingScreen from "@/components/appshell/LoadingScreen"

import { BadgeCheckIcon } from '@heroicons/vue/solid'

import { leaderboardService } from "@/utils/leaderboard"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"
import { UserGroupIcon } from '@heroicons/vue/outline';

export default {
  components: { 
    Logo,
    DefaultUser,
    LoadingScreen,
    UserGroupIcon,
    BadgeCheckIcon
  },
  data(){
    return {
      isLoading: true,
      user: null,
      leaderboard: [],
      schools: [],
      school: null
      
    }
  },
  
  methods: {

    getSchoolById: schoolService.getSchoolById,
    
    /**
     * Get the ordinal of a number for ranking system
     * i.e. getOrdinal(1) returns String "1st"
     * @NOTE: Duplicate over /dashboard
     */
    getOrdinal(i) { 
      var j = i % 10, k = i % 100;
      if (j == 1 && k != 11) { return i + "st"; }
      if (j == 2 && k != 12) { return i + "nd"; }
      if (j == 3 && k != 13) { return i + "rd"; }
      return i + "th";
    },
  },
  async created(){
    this.isLoading = true
    
    // school_shorthand
    var school_shorthand = this.$route.params.id // or filter
    if(!school_shorthand || school_shorthand == '') this.$router.push({ path: '/schools' })  // reroute if no ID is passed
    // console.log(school_shorthand)
    
    /* CLAIMS */
    // let claims = await userService.fetchUserClaims(firebase.auth())
    // if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users

    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });

    
    /* LEADERBOARD / RANK */
    this.leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());

    /* SCHOOLS */
    this.schools = await schoolService.fetchAllSchools(firebase.firestore())
    this.schools = schoolService.sortSchoolLeaderboard(this.schools, this.leaderboard)

    var temp = this.schools.filter( e => {return (e.shorthand.toLowerCase() == school_shorthand)})
    if(temp.length <= 0) this.$router.push({ path: '/schools' })  // reroute if school shorthand not found
    this.school = temp[0]
    

    this.leaderboard = this.leaderboard.filter( e => {return (e.school == this.school.id) }) // get only the users under this school  
    this.leaderboard = leaderboardService.sortLeaderboard(this.leaderboard) // sort the leaderboard

    

    if(this.user.school == this.school.id)
      this.leaderboard = leaderboardService.adjustLeaderboardForUser(this.user, this.leaderboard) // adjust the leaderboard for current user
    
    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false

  }
}
</script>
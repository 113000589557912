<template>
  <div class="min-h-screen bg-warm-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <!-- Headline -->
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <a href="https://skilltech.ph/">
        <Logo class="text-warm-gray-900 w-12 h-12 mx-auto" />
      </a>
      <h2 class="mt-6 text-center text-3xl font-extrabold text-warm-gray-900">
        Sign in to your account
      </h2>
      <p class="text-center py-4 max-w-xs mx-auto text-warm-gray-600">
        You are few moments away to jump starting your Aviation career. 
      </p>
    </div>

    <!-- Signin Container -->
    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <!-- "Continue with..." -->
        <div class="mt-2">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-warm-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-warm-gray-700">
                Continue with
              </span>
            </div>
          </div>

          <!-- Providers -->
          <div class="mt-6 flex flex-col space-y-6">
            <!-- Google -->
            <button
              @click="ssoLogin('google')"
              type="button"
              :disabled="isWaiting"
              class="btn-primary"
            >
              <GoogleIcon class="w-5 h-5"/>
              <span class="ml-2">Google</span>
            </button>
            <!-- Yahoo -->
            <button
              @click="ssoLogin('yahoo')"
              type="button"
              :disabled="isWaiting"
              class="btn-primary"
            >
              <YahooIcon class="w-5 h-5"/>
              <span class="ml-2">Yahoo</span>
            </button>
          </div>

          <p class="mt-6 text-sm text-warm-gray-600">
            Don't have an account yet? No worries, we'll onboard you after you sign in. 🎉
          </p>

          <!-- display while is waiting... -->
          <div v-if="isWaiting" class=" text-yellow-600 flex items-center justify-start mt-3">
            <SpinnerIcon class="mr-2 h-3 w-3" />
            <span class=" text-sm">Trying to sign in...</span>           
          </div>

          <!-- error display -->
          <div v-if="error" class="rounded-md bg-yellow-50 p-4 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationIcon
                  class="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-yellow-800">
                  {{ error.message }}
                </p>
              </div>
              <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    @click="error = ''"
                    type="button"
                    class="btn-dismiss"
                  >
                    <span class="sr-only">Dismiss</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import firebase from 'firebase/app';
import firebaseApp from "@/firebase/config";
import "firebase/auth";
import "firebase/functions";

import { useRoute, useRouter } from 'vue-router'

import { ref } from "vue";
import { ExclamationIcon, XIcon } from "@heroicons/vue/solid";
import Logo from "@/components/Logo";
import YahooIcon from "@/components/icons/YahooIcon"
import GoogleIcon from "@/components/icons/GoogleIcon"
import SpinnerIcon from "@/components/icons/SpinnerIcon"

const route = useRoute();
const router = useRouter();

const name = "Login";
const site_title = "Login";

const googleProvider = new firebase.auth.GoogleAuthProvider();
const yahooProvider = new firebase.auth.OAuthProvider('yahoo.com');

const isWaiting = ref(false);
const error = ref(null);

const ssoLogin = async (provider) => {
    
    isWaiting.value = true;
    error.value = null;

    // evaluate provider
    if (provider == 'google') {
      // continue using Google
      await firebaseApp.auth()
        .signInWithPopup(googleProvider)
        .then((result) => {
          // router.go();
          if(route.query && route.query.referrer){
            router.push({ path: '/onboarding', query: { referrer: route.query.referrer } })
          }else{
            router.go();
          }
          
        }).catch((err) => {
          console.log(err);
          if (err.code != 'auth/popup-closed-by-user' && err.code != 'dsf') { 
            console.log('Login Error!')
          }
        });

    }else{
      // continue using Yahoo
      await firebaseApp.auth().
        signInWithPopup(yahooProvider)
        .then((result) => {
          // router.go();
          if(route.query && route.query.referrer){
            router.push({ path: '/onboarding', query: { referrer: route.query.referrer } })
          }else{
            router.go();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code != 'auth/popup-closed-by-user' && err.code != 'sd') { 
            console.log('Login Error!')
          }
        });
    }
    isWaiting.value = false;
}

</script>

<style lang="postcss" scoped>
@layer components {
  .btn-primary {
    @apply w-full inline-flex justify-center py-2 px-4 border border-warm-gray-300 rounded-md shadow-sm text-sm font-medium text-warm-gray-500 bg-warm-gray-50 hover:bg-warm-gray-100 disabled:opacity-50 disabled:cursor-wait;
  }
  .btn-dismiss {
    @apply inline-flex bg-yellow-50 rounded-md p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600;
  }
}
</style>
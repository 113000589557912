
<template>
  <TransitionRoot as="template" :show="isActivateModalOpen">
      <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closeActivateModal()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <!-- Close Button -->
              <div class="block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closeActivateModal()">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Slides -->
              <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
                <div class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Reviewer Activation</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="block xl:inline">Okay, let's gooo! 💪🏼🛠️</span>
                  </h1>
                  
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="mx-auto flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                      </svg>
                    </div>
                    <p>
                      Note: Once your reviewer access is activated, you will have access to the CAAP License Exam reviewer for <strong>6 months (or 180 days)</strong>. Your reviewer access will expire on <strong>{{ expiryDate }}</strong>.
                    </p>
                  </div>
                  <p class="my-8">
                    Reviewer access will be granted a few seconds after you proceed with activation below. If you have any other issues or concerns, please reach out to us via email at <strong>support@skilltech.ph</strong> or through our official <strong>Facebook Messenger</strong>.
                  </p>
                  <button @click="this.handleActivateClick()" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    I understand. Proceed with Activation.
                  </button>    
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
 
<script>

import { XIcon } from "@heroicons/vue/solid";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
export default {
  props: ["isActivateModalOpen"],
  components: {
    XIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data() {
    return {
      expiryDate: this.getReviewerExpiry(),
    }
  },
  methods: {
    closeActivateModal() {
      this.$emit("closeActivateModal", "initiateActivation");
    },
    async handleActivateClick(){
      this.$emit("initiateActivation");
      this.$emit("closeActivateModal");
    },
    getReviewerExpiry() {
      let durationInDays = 180; // 30 days default. Ideally, it should be set by user
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() + durationInDays)
      // const expiryTimestamp = Timestamp.fromDate(currentDate)
      return currentDate
    }
  },
};
</script>
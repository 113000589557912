/**
 * sort the users via skillpoints for the leaderboard
 */
function sortLeaderboard(users){
    let sorted_users = users.sort(function (a, b) {
        if( a.skillpoints === b.skillpoints){ return a.date_started - b.date_started } // if they have equal points, give priority to people who signed up earlier
        else{ return b.skillpoints - a.skillpoints; } // sort via skillpoints
    });
    sorted_users.forEach( (user, i) => { user.rank = i+1; });
    return sorted_users
}

/**
 * NOTE: Connects To Firebase
 * fetch the leaderboard
 */
function fetchLeaderboard(db){
    return db.collection('leaderboard').orderBy("date_created", "asc").get()
      .then((querySnapshot) => {
        var leaderboard = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          data.id = doc.id
          leaderboard.push(data)
        });
        // console.log({leaderboard})
        return leaderboard;
      })
      .catch((error) => {
        console.error("Error getting Leaderboard data: ", error);
        throw "Error getting Leaderboard data"
      })
}

function adjustLeaderboardForUser(user, leaderboard){
    // console.log(leaderboard)
    // console.log(user.id)
    let i = leaderboard.findIndex( e => e.id == user.id )
    if( i < 0){
      // User is not in the leaderboard. Put him at the last part.
      // console.log("User is in the leaderboard")
      leaderboard.push(user)
      return leaderboard
    }else{
      // User is in the leaderboard. Do nothing
      // console.log("User is in the leaderboard")
      return leaderboard
    }
}

exports.leaderboardService = {
    fetchLeaderboard, sortLeaderboard, adjustLeaderboardForUser
}
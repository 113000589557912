<script setup>
import ROUTER from "@/router"
import {ref, onMounted, computed} from 'vue';
import firebase from "@/firebase/config";

import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

import { 
    BookOpenIcon,
    ChevronDoubleLeftIcon, ChevronDoubleRightIcon, 
    LogoutIcon,
    ExclamationIcon,
    UploadIcon,
    XIcon,
} from "@heroicons/vue/outline"
import { 
    QuestionMarkCircleIcon, LightBulbIcon 
} from "@heroicons/vue/solid"

const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId)
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName)
const subtopicId = computed(() => ROUTER.currentRoute.value.query.subtopicId)
const subtopicName = computed(() => ROUTER.currentRoute.value.query.subtopicName)

const isWaitingToExit = ref(false);
const isWaiting = ref(false) // ui status
const session = ref(null)
const items = ref([]);
const user = ref(null);
const currentItemIndex = ref(0);

const isOnQuitOpen = ref(false) // flag for the OnQuit modal. when user quits
const isOnCompleteOpen = ref(false) // flag for the onComplete modal

const isExplorerShown = ref(false);


/**
 * Read the document for this subtopic from questionnaires collection
 * Then, fetch all questions that contain a study field or study field is not blank.
 */
const fetchItems = () => {

    return new Promise(async (resolve, reject) => {


        firebase.firestore().collection("questionnaires").doc(subtopicId.value).get()
        .then((doc) => {

            let filteredItems = [];

            let data = {
                data: doc.data(),
                id: doc.id
            }

            // filter, only get study items
            data.data.questions.forEach((q)=>{
                if (q.study && (q.study.trim() != '<p><br></p>' || q.study.trim() != '<p></p>') ) {
                    filteredItems.push(q);
                }
            })

            resolve(filteredItems)
        })
        .catch((error) => {
            console.error("Error getting questionnaires data for the current subtopic: ", error);
            reject(error)
        })
    })

}

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const fetchSession = async () => {
    return new Promise(async (resolve, reject) => {

        firebase.firestore().collection("plus_study")
        .where('subjectId','==', subjectId.value)
        .where('subtopicId','==', subtopicId.value)
        .where('user','==', user.value.id).get()
        .then((qs) => {
            
            let data = null;

            qs.forEach((doc) => {
                data = {
                    data: doc.data(),
                    id: doc.id
                }
            });

            resolve(data)
        })
        .catch((error) => {
            console.error("Error getting questionnaires data for the current subtopic: ", error);
            reject(error)
        })

    })
}

const extractH1Text = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const h1Tags = doc.querySelectorAll('h1');
    const h1Texts = Array.from(h1Tags).map(h1 => h1.textContent).join(', ');
    return h1Texts || '---';

    // if (h1Tag) {
    //     // Get the full text content of the H1 tag
    //     return h1Tag.textContent;

    // } else {
    //     // If no H1 tag, remove the image tag if it exists
    //     const imgTag = doc.querySelector('img');
    //     if (imgTag) {
    //     imgTag.remove(); // Remove the image tag
    //     }

    //    // Get the remaining text content
    //     const remainingText = doc.body.textContent.trim();

    //     // Truncate the text to 10 characters and add '...' if necessary
    //     return remainingText.length > 30 ? remainingText.substring(0, 30) + '...' : remainingText;
    // }
}

const jump = (toIndex) => {
    currentItemIndex.value = toIndex;
    isExplorerShown.value = false;
    scrollBackToTop();
}

const handleNextItemClick = () => {
    currentItemIndex.value++;
    scrollBackToTop();
}

const handlePrevItemClick = () => {
    if (currentItemIndex.value > 0) {
        currentItemIndex.value--;
        scrollBackToTop();
    }
}

const scrollBackToTop = () => {
    const element = document.getElementById('target-div');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

const exitStudy = async (isToSaveSession) => {

    isWaitingToExit.value = true;

    if (isToSaveSession) {
        await saveSession(false);
    }
    
    ROUTER.push({ path: `/plus/subtopics`, query: { subjectId: subjectId.value, subjectName: subjectName.value } })
}

const completeStudy = async () => {
    // save the study session
    await saveSession(true);
    isOnCompleteOpen.value = true;
}

const saveSession = async (isComplete) => {

    return new Promise(async (resolve, reject) => {

        if (session.value === null) {
         
            /**
             * Save caap plus study session data and then redirect to caap plus subtopics page
             */
            firebase.firestore().collection("plus_study").add({
                isComplete: isComplete,
                progress: (currentItemIndex.value + 1) / items.value.length,
                lastIndex: currentItemIndex.value,
                lastDateStudy: new Date(),
                subjectId: subjectId.value,
                subtopicId: subtopicId.value,
                user: user.value.id
            })
            .then(() => {
                console.log("CAAP Plus Study Session successfully saved.");
                resolve(true);
            })
            .catch((error) => {
                console.error("Error saving study session: ", error);
                reject(false);
            });   

        }else{
            /**
             * Save caap plus study session data and then redirect to caap plus subtopics page
             */
             firebase.firestore().collection("plus_study").doc(session.value.id).update({
                isComplete: isComplete,
                progress: (currentItemIndex.value + 1) / items.value.length,
                lastIndex: currentItemIndex.value,
                lastDateStudy: new Date(),
                subjectId: subjectId.value,
                subtopicId: subtopicId.value,
                user: user.value.id
            })
            .then(() => {
                console.log("CAAP Plus Study Session successfully saved.");
                resolve(true);
            })
            .catch((error) => {
                console.error("Error saving study session: ", error);
                reject(false);
            });   

        }

    })

}

onMounted(async ()=>{
    isWaiting.value = true;
    [ items.value, user.value ] = await Promise.all([
        fetchItems(),
        fetchUser()
    ]);

    // check for existing session
    session.value = await fetchSession();

    // if has previous sesison and is not complete, set the last index
    if (session.value !== null && session.value.data.isComplete === false) {
        currentItemIndex.value = session.value.data.lastIndex
    }

    isWaiting.value = false;

})

</script>

<template>
    <div id="review-container" class="w-full mx-auto">
        <!-- The page has been initialized here -->
        <div v-if="!isWaiting">

            <!-- Breadcrumbs -->
            <nav id='practice-nav' class="flex px-1 lg:px-7 justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                <div @click=" items.length > 0 ? isOnQuitOpen = true : exitStudy()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer" aria-current="page">
                    <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                </div>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span>Study for {{ subtopicName }}</span>
                </div>
                <!-- FAQ Menu -->
                <button class="text-warm-gray-500">
                    <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
                </button>
            </nav>

            <section id="questionExplorer" v-if="isExplorerShown" class="flex flex-col items-end mt-8 mb-28">
                <button @click="isExplorerShown = false" class="my-2 flex hover:opacity-75 items-center text-indigo-500">
                    <XIcon class="h-5 w-5  mt-0.5 mr-1"/>
                    <span>Cancel and Close</span>
                    
                </button>
                <div class="w-full">
                    <ul>
                    <li v-for="(item, index) in items" :key="`question-explorer-${index}`" class="">
                        <button 
                        @click="jump(index)"
                        :class="['w-full my-2 space-x-2 flex flex-inline justify-between items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800']">
                        <div class="flex text-left space-x-2 items-center">
                            <span>{{ extractH1Text(item.study) }}</span>
                            <!-- <span class="text-left">{{ item.question }}</span> -->
                        </div>
                        <span class="ml-3">
                            <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </span>
                        </button>
                        
                    </li>
                    </ul>
                </div>
            </section>
            <div v-else>
                <!-- Main Content. Shown if there are items available -->
                <main v-if="items.length > 0" class="max-w-full mt-6 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4 mb-28">
                    
                    <!-- Stats -->
                    <div id="stats" class="dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
                        <div id="target-div" class="flex dark:text-warm-gray-500 text-warm-gray-700  text-md items-center">
                            <BookOpenIcon class="w-5 h-5 mr-1" />
                            <p class="sm:block ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-700">
                                Item {{ currentItemIndex + 1 }} of {{ items.length }}
                            </p>
                        </div>
                        <div class="flex text-warm-gray-800 text-md items-center">
                            <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
                                <!-- <div class="flex items-center mr-3 text-green-500">
                                    <CheckCircleIcon class="ml-1 w-5 h-5 mr-1" />
                                    {{ countCorrectAnswers() }}
                                    <span class="hidden sm:block ml-1">Correct</span>
                                </div>
                                <div class="flex items-center text-red-500">
                                    <XCircleIcon class="ml-1 w-5 h-5 mr-1" />
                                    {{ countIncorrectAnswers() }}
                                    <span class="hidden sm:block ml-1"> Wrong</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
    
                    <!-- Item Content -->
                    <div class="select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line" >
                        <div class="quill-content" v-html="items[currentItemIndex].study"></div>
                    </div>
    
                    <!-- Action Buttons -->
                    <nav class="mt-6 flex flex-col sm:flex-row justify-between">
                        <button
                            @click="handlePrevItemClick()"
                            :disabled="currentItemIndex === 0"
                            class="order-last sm:order-first my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <ChevronDoubleLeftIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 mr-1"/>
                            Previous
                        </button>
    
                        <button
                        v-on:click="isExplorerShown = true"
                        class="my-2 disabled:opacity-50 w-full sm:w-auto space-x-2 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5  border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Jump<span class="ml-1 hidden sm:block">To</span>
                            <UploadIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </button>
    
                        <button
                            v-if="currentItemIndex < items.length - 1"
                            @click="handleNextItemClick()"
                            class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Next
                            <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                        </button>
                        <button
                            v-else
                            @click="completeStudy()"
                            class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Complete Study
                        </button>
                    </nav>
    
    
    
                </main>
                <div v-else class="text-warm-gray-600 w-full text-center mt-14 italic text-sm">
                    Items seems to be empty. No Study material for this subtopic.
                </div>
            </div>


        </div>
        <!-- Otherwise, show the loading animation for now -->
        <div v-else class="flex justify-center mt-8">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div class="text-true-gray-700">Preparing study session...</div>
        </div>
    </div>

    <!-- Popup: On Complete -->
    <TransitionRoot appear :show="isOnCompleteOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class=" text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                    Study Completed
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                      <p>Good job!</p>
                    </div>
                    <p class="text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-1 mb-8">
                        You've completed studying this subtopic. 💪😎
                    </p>
                    <div class="bg-gray-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                      <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                        <div>
                          <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                        </div>
                        <p class="text-yellow-700">
                          <strong>Did You Know?</strong> When reading, it's easy to get distracted by notifications, emails, or other apps. Consider turning off notifications while you study to maintain focus.
                        </p>
                      </div>
                    </div>
                    <div class="mt-8 sm:mt-6">
                      <button @click="exitStudy(false)" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>

    <!-- Popup: are you sure? -->
    <TransitionRoot appear :show="isOnQuitOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-warm-gray-400 text-gray-900">Quit Study</DialogTitle>
                    <div class="mt-2">
                        <p class="text-sm dark:text-warm-gray-500 text-gray-500">Are you sure you want to exit this study session? You are currently at item {{ currentItemIndex + 1 }} of {{ items.length }}. <br/> <br/>Would you like to save this session?</p>
                    </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-30" @click="exitStudy(false)" :disabled="isWaitingToExit">Quit</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 sm:ml-3 sm:mt-0 sm:w-auto disabled:opacity-30" @click="exitStudy(true)" :disabled="isWaitingToExit">Quit and Save</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:opacity-30" @click="isOnQuitOpen = false" ref="cancelButtonRef" :disabled="isWaitingToExit">Cancel</button>
                </div>
                  
                  
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>
    
</template>

<style scooped>
html {
  scroll-behavior: smooth;
}

button{
    @apply px-2 py-1 bg-true-gray-600
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>
<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 488.371 488.371"
    xml:space="preserve"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M228.483,339.215c0,33.494,0.04,54.469,0.104,67.12l-70.757,50.634v31.402c0,0,65.417-15.048,74.578-26.826
                                    c9.163-11.773,0.653,21.588,11.776,21.588c11.124,0,2.612-33.361,11.778-21.588c9.16,11.778,74.578,26.826,74.578,26.826v-31.402
                                    l-70.764-50.634c0.068-12.651,0.108-33.626,0.108-67.12c0-135.419,219.812,0,219.812,0v-37.942l-54.954-40.647v-31.316
                                    c0-4.332-6.364-7.851-11.773-7.851c-5.414,0-11.778,3.519-11.778,7.851v13.888l-40.884-30.248
                                    c0-85.043-13.409-76.542-13.409-76.542s-10.463,7.199-11.773,47.754c-0.785,24.375-24.962,7.401-44.138-10.024l-10.764-49.834
                                    l-12.487-6.743c0-16.974,0-38.78,0-61.61C267.736,6.231,243.855,0,243.855,0s-23.223,6.226-23.223,55.949
                                    c0,22.53,0,44.066,0,60.935l-13.737,6.834l-11.062,50.638h0.008c1.579-0.928,3.128-1.98,4.643-3.086
                                    c-1.507,1.415-3.056,2.832-4.643,4.254c-18.901,16.91-41.823,32.376-42.595,8.644c-1.311-40.563-11.776-47.754-11.776-47.754
                                    s-13.409-8.501-13.409,76.542l-40.884,30.24v-13.888c0-4.332-6.36-7.851-11.776-7.851s-11.776,3.519-11.776,7.851v31.316
                                    L8.674,301.272v37.942C8.674,339.215,228.483,203.795,228.483,339.215z"
        ></path>
      </g>
    </g>
  </svg>
</template>
import firebase from '@/firebase/config';
import store from '@/store';

import Caap101Subjects from '../views/Caap101Subjects.vue';
import Caap101Subtopics from '../views/Caap101Subtopics.vue';
import Caap101Practice from '../views/Caap101Practice.vue';

async function authGuard(to, from, next) {
    /**
     * Is the user authenticated?
     */
    const isAuthenticated = firebase.auth().currentUser;
    if (!isAuthenticated) {
        next({ path: '/login', query: { unauthorized: 'true' } });
    } else {

        /**
         * Fetch the user info and evaluate whether the user 
         * purchased a CAAP Knowledge or CAAP Practical reviewer.
         * 
         * No need to check claims because the caap knowledge and practical claims
         * will remain false (i.e. when user is waiting for confirmation or activation),
         * and the user can still access caap 101.
         * 
         * @todo @junrey
         * Issue: This needs to be checked via resource or claims. Otherwise, it will be a 
         * security issue since the user does not have a DB rule when accessing data inside caap-101.
         * 
         */
        let userInfo = store.getters.user.info;
        console.log({userInfo})
        if (userInfo.reviewers.caap != 'inactive' || userInfo.reviewers.caap_plus != 'inactive') {
            next();
        }else{
            next({ path: '/reviewers' });
        }
    }
}


export const caap101Routes = [
    {
        path: '/caap-101',
        name: 'CAAP 101',
        component: Caap101Subjects,
        beforeEnter: authGuard
    },
    {
        path: '/caap-101/subtopics',
        name: 'CAAP 101 Subtopics',
        component: Caap101Subtopics,
        beforeEnter: authGuard
    },
    {
        path: '/caap-101/practice',
        name: 'CAAP 101 Practice',
        component: Caap101Practice,
        beforeEnter: authGuard
    },
]
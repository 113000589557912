<template>
    <TransitionRoot as="template" id="test2" :show="isNewCaapExamResultModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closeNewCaapExamResultModal()">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closeNewCaapExamResultModal()">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                
                <!-- Slides -->
                <div class="relative px-4 sm:px-5 lg:px-5 max-w-4xl mx-auto py-3 mt-4">
                    
                    <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">
                        {{ openedSubject.subjectName }}
                    </span>

                    <!-- for web -->
                    <div class=" hidden lg:flex justify-between space-x-2 w-full mt-10">

                        <div class="flex justify-start items-center flex-1">
                            <div class="w-8 h-8 flex items-center justify-center rounded-full" :class="step > 1 ? 'bg-blue-600 text-white':'bg-gray-300 text-warm-gray-700'">
                                <span>1</span>
                            </div>
                            <span class="ml-2" :class="step>1? 'text-blue-600':'text-gray-400'">Exam Result</span>
                            <div class="h-0.5 flex-1 ml-2" :class="step > 1 ? 'bg-blue-600':'bg-gray-600'"></div>
                        </div>

                        <div class="flex justify-start items-center flex-1">
                            <div class="w-8 h-8 flex items-center justify-center rounded-full" :class="step > 2 ? 'bg-blue-600 text-white':'bg-gray-300 text-warm-gray-700'">
                                <span>2</span>
                            </div>
                            <span class="ml-2" :class="step>2? 'text-blue-600':'text-gray-400'">App Accuracy</span>
                            <div class="h-0.5 flex-1 ml-2" :class="step > 2 ? 'bg-blue-600':'bg-gray-600'"></div>
                        </div>

                        <div class="flex justify-start items-center flex-1">
                            <div class="w-8 h-8 flex items-center justify-center rounded-full" :class="step > 3 ? 'bg-blue-600 text-white':'bg-gray-300 text-warm-gray-700'">
                                <span>3</span>
                            </div>
                            <span class="ml-2" :class="step>3? 'text-blue-600':'text-gray-400'">Add Question</span>
                            <div class="h-0.5 flex-1 ml-2" :class="step > 3 ? 'bg-blue-600':'bg-gray-600'"></div>
                        </div>

                        <div class="flex justify-start items-center">
                            <div class="w-8 h-8 flex items-center justify-center rounded-full bg-gray-300 text-warm-gray-700">
                                <span>4</span>
                            </div>
                            <span class="text-gray-400 ml-2">Comment</span>
                        </div>

                    </div>

                    <!-- for mobile -->
                    <div class=" lg:hidden flex justify-start space-x-2 mt-10">
                        <div class="flex justify-start items-center text-gray-400">
                            <div class="w-5 h-5 flex items-center justify-center rounded-full text-xs font-semibold mt-0.5" :class="step>1 ?'bg-blue-600': 'bg-gray-300'">
                                <span :class="step>1?'text-gray-100':'text-gray-600'">1</span>
                            </div>
                            <span class="ml-1" :class="step==1? 'block':'hidden'">Result</span>
                            <div class="ml-2">/</div>
                        </div>
                        <div class="flex justify-start items-center text-gray-400">
                            <div class="w-5 h-5 flex items-center justify-center rounded-full bg-gray-300 text-xs font-semibold mt-0.5 " :class="step>2 ?'bg-blue-600': 'bg-gray-300'">
                                <span :class="step>2?'text-gray-100':'text-gray-600'">2</span>
                            </div>
                            <span class="ml-1" :class="step==2? 'block':'hidden'">Accuracy</span>
                            <div class="ml-2">/</div>
                        </div>
                        <div class="flex justify-start items-center text-gray-400">
                            <div class="w-5 h-5 flex items-center justify-center rounded-full bg-gray-300 text-xs font-semibold mt-0.5 " :class="step>3 ?'bg-blue-600': 'bg-gray-300'">
                                <span :class="step>3?'text-gray-100':'text-gray-600'">3</span>
                            </div>
                            <span class="ml-1" :class="step==3? 'block':'hidden'">Add Question</span>
                            <div class="ml-2">/</div>
                        </div>
                        <div class="flex justify-start items-center text-gray-400">
                            <div class="w-5 h-5 flex items-center justify-center rounded-full bg-gray-300 text-xs font-semibold mt-0.5 ">
                                <span class="text-gray-600">4</span>
                            </div>
                            <span class="ml-1" :class="step==4? 'block':'hidden'">Comment</span>
                        </div>
                    </div>

                    <!-- Step 1 -->
                    <div v-show="step==1" class="sm:flex-auto mt-10">
                        <h1 class="text-xl font-semibold dark:text-white text-gray-900">Your Exam Result</h1>
                        <p class=" mt-2 text-sm dark:text-gray-200 text-gray-700">
                            Please provide your test result and other information for {{ openedSubject.subjectName }} subject.
                        </p>

                        <!-- form -->
                        <div class="mt-10">
                            <div>
                                <label for="exam-date" class="text-gray-600 dark:text-gray-300">Date of your exam?</label>
                                <input v-model="resultForm.examDate" type="date" name="exam-date" id="exam-date" class="w-full p-2 rounded-md mt-2 bg-warm-gray-100">
                            </div>

                            <div class="mt-4">
                                <label for="result" class="text-gray-600 dark:text-gray-300">Did you pass the exam?</label>
                                <select v-model="resultForm.isPassed" name="result" id="result" class="w-full p-2 rounded-md mt-2 bg-warm-gray-100" value="">
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                </select>
                            </div>

                            <div class="mt-4">
                                <label for="score" class="text-gray-600 dark:text-gray-300">What is your score (percentage)?</label>
                                <input v-model="resultForm.score" type="number" name="score" id="score" class="w-full p-2 rounded-md mt-2 bg-warm-gray-100">
                            </div>

                            <!-- Number of passing score change alert -->
                            <div v-show="isPassingScoreChange" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-4" role="alert">
                                <p class="font-bold">Passing score change!</p>
                                <p>The typical passing score for this exam is 70%. Are you certain that you have entered the correct value?</p>
                                <div class="mt-4">
                                    <label class="inline-flex items-center">
                                    <input type="checkbox" class="form-checkbox h-5 w-5 text-yellow-600" v-model="isUserConfirmChangesOfPassingScore" />
                                    <span class="ml-2 text-sm">Yes, I'm sure!</span>
                                    </label>
                                </div>
                            </div>

                            
                        </div>

                        <button :disabled="!(nextButton.step1 && debounceTimer == null)" @click="step++" class="disabled:bg-gray-400 mt-8 mb-8 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                            Proceed To Next Step
                        </button>

                    </div>

                    <!-- Step 2 -->
                    <div v-show="step==2" class="sm:flex-auto mt-10">
                        <h1 class="text-xl font-semibold dark:text-white text-gray-900">Skilltech Reviewer Accuracy Check</h1>
                        <p class=" mt-2 text-sm dark:text-gray-200 text-gray-700">
                            In order to assist more of our fellow airmen, we will be asking a few questions to evaluate the accuracy of our reviewer.
                        </p>

                        <!-- form -->
                        <div class="mt-10">

                            <div>
                                <label for="items-count" class="text-gray-600 dark:text-gray-300">How many items are there in the exam for this subject?</label>
                                <select v-model="numItemsInExamSelection" name="items-count" id="items-count" class="w-full p-2 rounded-md mt-2 bg-warm-gray-200" value="">
                                    <option :value="20">20 items</option>
                                    <option :value="30">30 items</option>
                                    <option :value="40">40 items</option>
                                    <option :value="50">50 items</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>

                            <div v-if="numItemsInExamSelection == 'others'" class="mt-4">
                                <label for="q-count" class="text-gray-600 dark:text-gray-300">Please specify how many items are there in the exam for this subject.</label>
                                <input v-model="resultForm.numItemsInExam" type="number" name="q-count" id="q-count" class="w-full p-2 rounded-md mt-2 bg-warm-gray-200">
                            </div>

                            <!-- Number of passing score change alert -->
                            <div v-show="isNumItemsInExamChange" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-4" role="alert">
                                <p class="font-bold">Number of items change!</p>
                                <p>The typical number of item for this exam is {{ this.openedSubject.itemCount }}. Are you certain that you have entered the correct value?</p>
                                <div class="mt-4">
                                    <label class="inline-flex items-center">
                                    <input type="checkbox" class="form-checkbox h-5 w-5 text-yellow-600" v-model="isUserConfirmChangesOfItemCount" />
                                    <span class="ml-2 text-sm">Yes, I'm sure!</span>
                                    </label>
                                </div>
                            </div>

                            <div class="mt-4">
                                <label for="q-count-from-app" class="text-gray-600 dark:text-gray-300">From your review (using Skilltech App), how many items did appear in your CAAP exam for the subject “{{ openedSubject.subjectName }}”?</label>
                                <input v-model="resultForm.numItemsFromApp" type="number" name="q-count-from-app" id="q-count-from-app" class="w-full p-2 rounded-md mt-2 bg-warm-gray-200" :class=" errorNumItemsFromApp && 'border-2 border-red-600'">
                                <small v-if="errorNumItemsFromApp" class=" text-red-600 text-xs">Items appear from the app can't be greater than number of items in the exam</small>
                            </div>

                            <div class="mt-4">
                                <label for="subtopics-vote" class="text-gray-600 dark:text-gray-300">In those items that appeared in your exam, in what following subtopics did it come from?</label>
                                <div class="mt-2 w-full">
                                    <div v-for="subtopic in openedSubject.subtopics" class=" mr-2 mb-2 md:inline-flex items-center px-3 py-1 bg-warm-gray-400 dark:bg-warm-gray-700 text-white rounded-md shadow-md hover:bg-warm-gray-500 focus:outline-none focus:ring-0 ">
                                        <input v-model="resultForm.subtopicVotes" type="checkbox" :id="'cb-' + subtopic" :value="subtopic" class="h-4 w-4 focus:ring-0 rounded">
                                        <label :for="'cb-' + subtopic" class="ml-2 text-sm">{{ convertToProperName(subtopic) }}</label>
                                    </div>
                                </div>
                            </div>
                            

                        </div>

                        <button :disabled="!nextButton.step2" @click="step++" class="disabled:bg-gray-400 mt-8 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                            Proceed To Next Step
                        </button>

                        <div class="mt-4 mb-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                            <a href="#" @click="step--">Go back</a>
                        </div>

                    </div>

                    <!-- Step 3 -->
                    <div v-show="step==3" class="sm:flex-auto mt-10">
                        <h1 class="text-xl font-semibold dark:text-white text-gray-900">Contribute Question</h1>
                        <p class=" mt-2 text-sm dark:text-gray-200 text-gray-700 mb-10">
                            What item/s in your CAAP exam didn't appear from our reviewer? (You can type the item in the blank. If you can’t input the exact question or choices, just type as much as you can remember.)
                        </p>

                        <!-- no questions view -->
                        <div v-if="resultForm.addedQuestion.length === 0" class=" border border-dashed rounded-md p-5 border-warm-gray-600 flex flex-col justify-center items-center">
                            <p class=" text-warm-gray-500">No questions has been added yet.</p>
                        </div>

                        
                        <div v-else class="overflow-x-auto sm:-mx-6 lg:-mx-8">

                            <!-- PC -->
                            <div class=" hidden md:inline-block py-2 align-middle min-w-full sm:px-6 lg:px-8">
                                <div class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
                                        <thead class="bg-warm-gray-400  dark:bg-true-gray-900">
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                                    Questions
                                                </th>
                                                <th scope="col" class=" hidden md:table-cell px-6 py-3 text-right text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                                            <tr v-for=" (addedQuestion,i) in resultForm.addedQuestion">
                                                <td class="px-6 py-4 whitespace-nowrap text-left"> 
                                                    <p class="w-full max-w-xl truncate text-warm-gray-500 dark:text-warm-gray-400">
                                                        {{ addedQuestion.question }}
                                                    </p>
                                                </td>
                                                <td class=" hidden md:flex px-6 py-4 whitespace-nowrap text-right justify-end space-x-3">
                                                    <PencilIcon @click="updateQuestion(i)" class=" w-5 h-5 cursor-pointer text-yellow-900 hover:text-yellow-700"/>
                                                    <TrashIcon @click="deleteQuestion(i)"  class=" w-5 h-5 cursor-pointer text-yellow-900 hover:text-yellow-700" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <!-- Mobile -->
                            <div class="md:hidden flex flex-col space-y-4">
                                <div v-for=" (addedQuestion,i) in resultForm.addedQuestion" class="rounded border border-true-gray-400 dark:border-true-gray-700 bg-true-gray-300 dark:bg-true-gray-700 p-3">
                                    <span class="text-warm-gray-700 dark:text-warm-gray-200">Question:</span>
                                    <p  class=" text-warm-gray-500 dark:text-warm-gray-400 mt-2">
                                        {{ truncateText(addedQuestion.question, 100) }}
                                    </p>
                                    <div class=" border-t border-warm-gray-400 pt-2 mt-2 flex justify-end space-x-2">
                                        <PencilIcon @click="updateQuestion(i)" class=" w-5 h-5 cursor-pointer text-yellow-700"/>
                                        <TrashIcon @click="deleteQuestion(i)" class=" w-5 h-5 cursor-pointer text-yellow-700" />
                                    </div>
                                </div>
                            </div>


                        </div>


                        
                        <div class=" flex flex-col items-start w-full mt-7">

                            <button @click="showAddQuestionForm = !showAddQuestionForm" class="-mb-4 bg-white dark:bg-true-gray-800 dark:text-white ml-1 px-2 flex justify-center items-center z-50" :class="!showAddQuestionForm  ? '' : '' ">
                                {{ newQuestionForm.fromIndex !== null ? 'Update' : 'Add new'}} question
                                <ChevronUpIcon v-if="showAddQuestionForm == true" class=" h-5 w-5 ml-2 cursor-pointer"/>
                                <ChevronDownIcon v-else class=" h-5 w-5 ml-2 cursor-pointer"/>
                            </button>

                            <div class="border-warm-gray-600 rounded p-1 overflow-hidden w-full" :class="!showAddQuestionForm  ? 'hidden' : 'border' ">
                                
                                <div class="m-4 pt-5">

                                    <div v-if="errorSaveQuestion" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2" role="alert">
                                        <strong class="font-bold">Unable to save question!</strong>
                                        <br/>
                                        <span class="block sm:inline ">{{ errorSaveQuestion }}</span>
                                        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                                            <svg @click="errorSaveQuestion = null" class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                                        </span>
                                    </div>


                                    <label for="new-question" class="text-warm-gray-600 dark:text-warm-gray-300">Question:</label>
                                    <textarea v-model="newQuestionForm.data.question" name="new-question" id="new-question" rows="6" class="w-full rounded mt-2 bg-warm-gray-100" placeholder="Type the question here."></textarea>
                                    
                                    <div class=" mt-4">
                                        
                                        <div v-for="(choice,i) in newQuestionForm.data.choices" :key="i">
                                            <p class=" text-warm-gray-600 dark:text-warm-gray-300 mt-2">Choice {{ i + 1 }}:</p>
                                            <textarea v-model="newQuestionForm.data.choices[i]" name="new-question" id="new-question" rows="2" class="w-full rounded mt-2 bg-warm-gray-100" placeholder="Type choice here."></textarea>
                                        </div>

                                        <div class=" flex justify-between items-start mt-2">
                                            <div class="flex justify-start items-center">
                                                <button @click="addChoice()" class=" border rounded border-warm-gray-400 text-warm-gray-400 py-1 px-1 text-xs"> <PlusIcon class="w-3 h-3"/> </button>
                                                <button @click="removeChoice()" class=" border rounded border-red-700 text-red-700 py-1 px-1 text-xs ml-2"> <MinusIcon class="w-3 h-3"/> </button>
                                            </div>
                                            <div class="flex justify-end">
                                                <button @click="showAddQuestionForm = false;  resetNewQuestionForm()" class="text-warm-gray-600 hover:text-warm-gray-400 mr-2">Cancel</button>
                                                <button @click="saveQuestion()" class=" rounded text-warm-gray-200 bg-yellow-800 py-1.5 px-2.5">{{ newQuestionForm.fromIndex !== null ? 'Update' : 'Save'}}</button>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
    
                            </div>
                        </div>





                        <button :disabled="false" @click="step++" class="disabled:bg-gray-400 mt-10 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                            Proceed To Next Step
                        </button>

                        <div class="mt-4 mb-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                            <a href="#" @click="step--">Go back</a>
                        </div>

                    </div>

                    <!-- Step 4 -->
                    <div v-show="step==4" class="sm:flex-auto mt-10">
                        <h1 class="text-xl font-semibold dark:text-white text-gray-900">Comment</h1>
                        <p class=" mt-2 text-sm dark:text-gray-200 text-gray-700">
                            Your input is vital to us. Please feel free to let us know anything that can help enhance this part of the reviewer to your satisfaction.
                        </p>

                        <!-- form -->
                        <div class="mt-10">
                            <textarea v-model="resultForm.comments" name="comment" id="comment" class="w-full p-2 rounded-md mt-2 bg-warm-gray-100" rows="10"></textarea>
                        </div>

                        <button @click="submitResult()" :disabled="!nextButton.submit || isSubmitting" class="disabled:bg-gray-400 mt-8 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                            {{ isSubmitting ? 'Submitting...' : 'Submit' }}
                        </button>

                        <div class="mt-4 mb-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                            <a href="#" @click="step--">Go back</a>
                        </div>
                    </div>

                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  </template>
   
  <script>

import firebase from "@/firebase/config";
const db = firebase.firestore()
  
  import { MinusIcon, XIcon } from "@heroicons/vue/solid";
  import { ChevronDownIcon, ChevronUpIcon, PencilIcon, TrashIcon, PlusCircleIcon, MinusCircleIcon, PlusIcon  } from "@heroicons/vue/outline";

  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  export default {
    props: ["isNewCaapExamResultModalOpen","openedSubject"],
    data(){
        return{
            isSubmitting: false,
            currentUser: null,
            isNumItemsInExamChange: false,
            isUserConfirmChangesOfItemCount: false,
            isPassingScoreChange: false,
            isUserConfirmChangesOfPassingScore: false,
            step: 1,
            selectedSubtopics: [],
            showAddQuestionForm: false,
            errorNumItemsFromApp: false,
            errorSaveQuestion: null,
            nextButton: {
                step1: false,
                step2: false,
                submit: false
            },
            numItemsInExamSelection: null,
            resultForm: {
                takeCount: 1,
                subjectId: '',
                userId: '',
                email: '',
                userDisplayName: '',
                examDate: null,
                isPassed: null,
                score: null,
                numItemsInExam: null,
                numItemsFromApp: null,
                subtopicVotes: [],
                addedQuestion: [],
                comments: '',
                submittedDate: null,
                isChangePassingScore: false,
                isChangeItemCount: false,
            },
            newQuestionForm: {
                fromIndex: null, // if null then its new question to add
                data:{
                    question: '',
                    choices: ['','']
                }
            },
            debounceTimer: null
        }
    },
    components: {
        XIcon,
        ChevronDownIcon,
        ChevronUpIcon,
        PencilIcon,
        TrashIcon,
        PlusCircleIcon,
        MinusCircleIcon,
        PlusIcon,
        MinusIcon,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle,
        MinusIcon
    },
    
    watch:{
        isNewCaapExamResultModalOpen : function (newVal, oldVal){
            if (newVal) {
                this.numItemsInExamSelection = this.openedSubject.itemCount;
                this.currentUser = firebase.auth().currentUser;
            }
        },
        numItemsInExamSelection : function (newVal, oldVal){
            if (newVal == 'others') {
                this.resultForm.numItemsInExam = null;
            }else{
                this.resultForm.numItemsInExam = newVal;
            }
        },
        isUserConfirmChangesOfPassingScore : function(newVal, oldVal) {
            this.resultForm.isChangePassingScore = newVal;
            this.activateStepOneNextButton();
        },
        isUserConfirmChangesOfItemCount : function(newVal, oldVal) {
            this.resultForm.isChangeItemCount = newVal
            this.activateStepTwoNextButton();
        },
        // for step 1
        'resultForm.examDate'(newVal) {this.activateStepOneNextButton()},
        'resultForm.isPassed'(newVal) { this.checkIfPassingScoreChange(); this.activateStepOneNextButton(); },
        'resultForm.score'(newVal) { this.checkIfPassingScoreChange(); this.activateStepOneNextButton(); },
        // for step 2
        'resultForm.numItemsInExam'(newVal) {

            if (newVal != this.openedSubject.itemCount) {
                this.isNumItemsInExamChange = true;
            }else{
                this.isNumItemsInExamChange = false;
                this.isUserConfirmChangesOfItemCount = false;
            }

            this.activateStepTwoNextButton();
        },
        'resultForm.numItemsFromApp'(newVal) {this.activateStepTwoNextButton()},
        'resultForm.subtopicVotes'(newVal) {this.activateStepTwoNextButton()},
        // for step 4 
        'resultForm.comments'(newVal) {
            this.nextButton.submit = false;
            if (this.resultForm.comments.trim() !== '') {
                this.nextButton.submit = true;
            }
        },
    },
    methods: {
        checkIfPassingScoreChange(){

            let score = this.resultForm.score;
            let isPassed = this.resultForm.isPassed;
            
            clearTimeout(this.debounceTimer)
            this.nextButton.step1 = false;
            if (score && isPassed != null) {

                this.debounceTimer = setTimeout(()=>{
                    // passing score is ussually 70
                    if ((isPassed == true && score < 70) || (isPassed == false && score >= 70)) {
                        // let the user confirm, that there is a changes on the passing score 
                        this.isPassingScoreChange = true;
                        if (!this.resultForm.isChangePassingScore) {
                            this.nextButton.step1 = false;
                        }
                    }else{
                        if (this.resultForm.examDate) {
                            const startDate = new Date('2020-01-01');
                            const currentDate = new Date();
                            const inputDate = new Date(this.resultForm.examDate);
                            this.nextButton.step1 = inputDate >= startDate && inputDate < currentDate;
                        }else{
                            this.nextButton.step1 = false;
                        }
                        this.isPassingScoreChange = false;
                        this.isUserConfirmChangesOfPassingScore = false;
                    }
                    
                    this.debounceTimer = null
    
                },500)                
            }else{
                this.isPassingScoreChange = false;
                this.isUserConfirmChangesOfPassingScore = false;

            }
        },
        activateStepOneNextButton(){

            this.nextButton.step1 = false;
            if (this.resultForm.examDate && this.resultForm.isPassed !== null && this.resultForm.score ) {

                // Check if the input date is between current date and jan 1, 2020
                const startDate = new Date('2020-01-01');
                const currentDate = new Date();
                const inputDate = new Date(this.resultForm.examDate);
                if (inputDate >= startDate && inputDate < currentDate) {
                    if (this.isPassingScoreChange) {
                        if (this.resultForm.isChangePassingScore) {
                            this.nextButton.step1 = true;
                        }
                    }else{
                        this.nextButton.step1 = true;
                    }
                }
            }
            
        },

        activateStepTwoNextButton(){

            this.nextButton.step2 = false;
            if(this.resultForm.numItemsInExam && this.resultForm.numItemsFromApp){               
                this.errorNumItemsFromApp = false;
                // check if the number of item is equal or greater than number of items from app
                if(this.resultForm.numItemsInExam >= this.resultForm.numItemsFromApp){
                    // now check if user selected at least one subtopic
                    if (this.resultForm.subtopicVotes.length > 0) {
                        if (!(this.isNumItemsInExamChange && !this.resultForm.isChangeItemCount)) {
                            this.nextButton.step2 = true;
                        }


                    }
                }else{
                    this.errorNumItemsFromApp = true;
                }
            }
        },

        closeNewCaapExamResultModal() {
            
            // clear all data
            this.numItemsInExamSelection = null;
            this.resultForm.subjectId = '';
            this.resultForm.userId = '';
            this.resultForm.email = '';
            this.resultForm.userDisplayName = '';
            this.resultForm.takeCount = 1;
            this.resultForm.examDate = null;
            this.resultForm.isPassed = null;
            this.resultForm.score = null;
            this.resultForm.numItemsInExam = null;
            this.resultForm.numItemsFromApp = null;
            this.resultForm.subtopicVotes = [];
            this.resultForm.addedQuestion = [];
            this.resultForm.comments = '';
            this.isChangePassingScore = false;
            this.isChangeItemCount = false;

            this.showAddQuestionForm = false;

            this.resetNewQuestionForm();

            this.step = 1;
            this.$emit("closeNewCaapExamResultModal");
        },
        truncateText(text, maxLength) {
            if (text.length <= maxLength) {
                return text;
            } else {
                return text.slice(0, maxLength) + '...';
            }
        },
        convertToProperName(str){
            // Split the string by hyphen and capitalize each word
            const capitalizedWords = str.split('-').map(word => word.toUpperCase());
            // Join the capitalized words with spaces
            return capitalizedWords.join(' ');
        },
        canProceedToPage2(){
            // check if to enable first page next button
            console.log('test')
            this.nextButton.step1 = false;
            if (this.resultForm.examDate && this.resultForm.isPassed && this.resultForm.score) {
                this.nextButton.step1 = true;
            }
        },
        addChoice(){
            this.newQuestionForm.data.choices.push('');
        },
        removeChoice(){
            this.newQuestionForm.data.choices.pop();
        },
        saveQuestion(){
            this.errorSaveQuestion = null;

            // verify first, check if the user entered the question and added choices at least 2
            if (!this.newQuestionForm.data.question || this.newQuestionForm.data.choices.length < 2) {
                this.errorSaveQuestion = 'Please ensure that you fill out the question and add at least two choices.'
                return;
            }

            // make sure no blank choices
            let blankChoices = this.newQuestionForm.data.choices.filter((c) => c.trim() === '');
            if (blankChoices.length > 0) {
                this.errorSaveQuestion = 'Choices could not be blank.'
                return;
            }

            // check if to save new question or update question
            if (this.newQuestionForm.fromIndex !== null) {
                // update
                this.resultForm.addedQuestion[this.newQuestionForm.fromIndex] = {...this.newQuestionForm.data};
            }else{
                // add new
                this.resultForm.addedQuestion.push({...this.newQuestionForm.data})
            }

            this.resetNewQuestionForm();
            
        },
        updateQuestion(i){
            this.showAddQuestionForm = true;
            this.newQuestionForm.fromIndex = i;
            this.newQuestionForm.data.question = this.resultForm.addedQuestion[i].question;
            this.newQuestionForm.data.choices = [...this.resultForm.addedQuestion[i].choices];
        },
        deleteQuestion(i){
            this.resultForm.addedQuestion.splice(i,1);
            this.resetNewQuestionForm();
        },
        resetNewQuestionForm(){
            this.newQuestionForm.fromIndex = null;
            this.newQuestionForm.data.question = '';
            this.newQuestionForm.data.choices = ['','']  
        },
        submitResult(){
            this.isSubmitting = true;
            this.resultForm.subjectId = this.openedSubject.subjectId;
            this.resultForm.userId = this.currentUser.uid;
            this.resultForm.userDisplayName = this.currentUser.displayName;
            this.resultForm.email = this.currentUser.email;
            this.resultForm.takeCount = this.openedSubject.results.length + 1;

            // convert date to date object before saving to firebase
            this.resultForm.examDate = new  Date(this.resultForm.examDate);
            this.resultForm.submittedDate = new Date();

           db.collection('exam_results').add(this.resultForm)
            .then((doc)=>{
                console.log('doc written with ID:', doc);
                this.$emit('fetchExamResults');
                this.closeNewCaapExamResultModal();
                this.isSubmitting = false;
            })
            .catch((err)=>{
                console.log('Error adding document:', err);
                alert('An error occurred during the submission of your result. Please try refreshing the browser and submitting again.');
                this.isSubmitting = false;
            })
        }

    },
  };
  </script>
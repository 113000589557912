<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p class="my-4">These questions are designed to measure your vocabulary, specifically your understanding of word
            meanings. They often use synonyms and antonyms. </p>
        <p class="my-4">Another type of word meaning question uses words which sound similar but have different
            meanings. These are called homophones.</p>

        <h3>💡 Commonly Confused Words</h3>
        <section>
            <p>The list below gives definitions of the most commonly confused homophones. Only the definition that often
                causes confusion is given.</p>
        </section>

        <div class="tip-wrapper">
            <p><strong>advice</strong> - somebody’s opinion about what another person should do.</p>
            <p><strong>advise</strong> - to suggest or recommend a course of action to somebody.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>aide</strong> - an assistant to somebody providing a professional service.</p>
            <p><strong>aid</strong> - to provide somebody or something with help.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>assent</strong> - to agree to something or express agreement.</p>
            <p><strong>ascent</strong> - an upward vertical movement.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>aural</strong> - relating to the ear.</p>
            <p><strong>oral</strong> - relating to the mouth.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>allude</strong> - to refer to indirectly.</p>
            <p><strong>elude</strong> - to escape from or avoid.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>alternate</strong> - to follow an interchanging pattern.</p>
            <p><strong>alternative</strong> - another possibility..</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>eager</strong> - enthusiastic and excited about something.</p>
            <p><strong>anxious</strong> - worried or afraid about something that is going to happen.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>bizarre</strong> - amusingly or grotesquely strange or unusual.</p>
            <p><strong>bazaar</strong> - a sale of goods.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>canvas</strong> - heavy closely woven fabric of cotton.</p>
            <p><strong>canvass</strong> - to visit somebody to solicit something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>capitol</strong> - building for law making body.</p>
            <p><strong>capital</strong> - seat of government, centre of activity, cash for investment.</p>
        </div>


        <div class="tip-wrapper">
            <p><strong>censure</strong> - to subject somebody or something to severe criticism.</p>
            <p><strong>censor</strong> - somebody or something that exercises suppressive control.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>climactic</strong> - extremely exciting or decisive.</p>
            <p><strong>climatic</strong> - involving climate.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>coarse</strong> - rough, vulgar or unrefined.</p>
            <p><strong>course</strong> - sequence, period of time, direction, action, program, etc.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>compliment</strong> - a statement of praise.</p>
            <p><strong>complement</strong> - a completing part.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>confident</strong> - self-assured or convinced.</p>
            <p><strong>confidant</strong> - somebody to whom secrets are told.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>dissent</strong> - to disagree with a widely held or majority opinion.</p>
            <p><strong>decent</strong> - conforming to accepted standards of moral behaviour.</p>
            <p><strong>descent</strong> - an act of going from a higher position to a lower position.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>conscious</strong> - awake, aware or conscious and deliberate.</p>
            <p><strong>conscience</strong> - the internal sense of what is right and wrong.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>counsel</strong> - somebody whose advice is sought, or who acts as an official adviser.</p>
            <p><strong>council</strong> - an appointed or elected body with a representative function.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>credible</strong> - believable or trustworthy.</p>
            <p><strong>creditable</strong> - praiseworthy.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>criterion</strong> - an accepted standard used in making decisions or judgments.</p>
            <p><strong>criteria</strong> - the plural of criterion.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>currently</strong> - at the present time.</p>
            <p><strong>presently</strong> - not at this exact moment but in a short while.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>demure</strong> - looking or behaving in a modest manner.</p>
            <p><strong>demur</strong> - to show reluctance to do something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>devise</strong> - to conceive of the idea for something.</p>
            <p><strong>device</strong> - a tool, machine or ploy.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>discreet</strong> - careful to avoid offence, circumspect, careful, etc.</p>
            <p><strong>discrete</strong> - completely separate and unconnected.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>disinterested</strong> - impartial and free from bias.</p>
            <p><strong>uninterested</strong> - not interested.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>effect</strong> - a result or power to influence.</p>
            <p><strong>affect</strong> - to give the appearance or pretence of something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>elicit</strong> - to provoke a reaction.</p>
            <p><strong>illicit</strong> - illegal or unacceptable.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>eminent</strong> - of high standing.</p>
            <p><strong>imminent</strong> - about to happen.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>empathy</strong> - understanding of another’s feelings.</p>
            <p><strong>sympathy</strong> - capacity to share feelings.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>ensure</strong> - to make something certain.</p>
            <p><strong>insure</strong> - to cover something with insurance.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>explicit</strong> - expressing all details in a clear and obvious way.</p>
            <p><strong>implicit</strong> - not stated, but understood in what is expressed.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>extemporaneous</strong> - prepared in advance but delivered without notes.</p>
            <p><strong>impromptu</strong> - not prepared or planned in advance.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>faze</strong> - to disconcert or disturb somebody.</p>
            <p><strong>phase</strong> - a clearly distinguishable period or stage in a process.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>foreboding</strong> - a feeling that something bad is going to happen.</p>
            <p><strong>forbidding</strong> - presenting an appearance that seems hostile or stern.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>farther</strong> - to a greater distance or to a greater extent.</p>
            <p><strong>further</strong> - that is more than or adds to the quantity or extent of something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>historical</strong> - existing, happening, or relating to the past.</p>
            <p><strong>historic</strong> - important in or affecting the course of history.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>illusion</strong> - something that deceives the senses or mind.</p>
            <p><strong>allusion</strong> - a reference that is made indirectly.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>imply</strong> - to make something understood without expressing it directly.</p>
            <p><strong>infer</strong> - to conclude something on the basis of evidence or reasoning.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>inflict</strong> - to impose a burden on another.</p>
            <p><strong>afflict</strong> - to cause severe mental or physical distress to somebody.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>ingenuous</strong> - showing innocence and a lack of worldly experience.</p>
            <p><strong>ingenious</strong> - possessing cleverness and imagination.</p>
        </div>


        <div class="tip-wrapper">
            <p><strong>intense</strong> - great, strong, or extreme in a way that can be felt.</p>
            <p><strong>intensive</strong> - involving concentrated effort.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>intensely</strong> - very much.</p>
            <p><strong>intently</strong> - something planned or the purpose that accompanies a plan.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>moral</strong> - relating to issues of right and wrong.</p>
            <p><strong>morale</strong> - the general level of confidence or optimism felt by a person or group.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>palette</strong> - a board or tray on which an artist arranges and mixes paints.</p>
            <p><strong>palate</strong> - a personal sense of taste and flavour.</p>
            <p><strong>pallet</strong> - a standardized platform or open-ended box.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>peak</strong> - the pointed summit of a mountain.</p>
            <p><strong>peek</strong> - to take a quick look at something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>prosecute</strong> - to take legal action against someone.</p>
            <p><strong>persecute</strong> - to make somebody the victim of continual pestering or harassment.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>personnel</strong> - the department of an organization that deals with employing staff.</p>
            <p><strong>personal</strong> - relating to the parts of somebody’s life that are private.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>principal</strong> - first or among the first in importance or rank.</p>
            <p><strong>principle</strong> - an important underlying law or assumption required in a system of thought.
            </p>
        </div>

        <div class="tip-wrapper">
            <p><strong>proceed</strong> - to go on to do something.</p>
            <p><strong>precede</strong> - to come, go, be, or happen before somebody or something else.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>racist</strong> - based on notions and stereotypes related to race.</p>
            <p><strong>racial</strong> - relating to or characteristic of races.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>reign</strong> - the period of time during which somebody rules a nation.</p>
            <p><strong>rein</strong> - any means of guiding, controlling, or restraining somebody or something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>respectfully</strong> - showing appropriate deference and respect.</p>
            <p><strong>respectively</strong> - matching one list with another in the order given for both.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>simple</strong> - easy to do, understand, or work out because not complicated.</p>
            <p><strong>simplistic</strong> - tending to oversimplify something.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>stationery</strong> - paper, envelopes, pens, pencils, and other things used in writing.</p>
            <p><strong>stationary</strong> - not moving, especially at a standstill after being in motion.</p>
        </div>

        <div class="tip-wrapper">
            <p><strong>trooper</strong> - a member of a cavalry unit.</p>
            <p><strong>trouper</strong> - a member of a group of travelling entertainers.</p>
        </div>

        <h2>Sample Questions</h2>
        <p><strong>Strategy: </strong>The best way to approach these kinds of items is to choose the oddest one among
            the choices. To put it simply, spot-the-not.</p>

        <div class="tip-wrapper">
            <p>1. Which word does not have a similar meaning to - <strong>site</strong></p>
            <div class="question-grid">
                <span>a] situation</span>
                <span>b] location</span>
                <span>c] place</span>
                <span>d] cite</span>
            </div>
            <hr />
            <p><strong>⭐️ site</strong> - an area of ground on which a building or monument is constructed.</p>
            <p><strong>situation</strong> - the location and surroundings of a place</p>
            <p><strong>location</strong> - a particular place or position.</p>
            <p><strong>place</strong> - a particular position or point in space.</p>
            <p class="font-bold"><strong>✅ cite</strong> - mention as an example. (not similar)</p>
        </div>

        <div class="tip-wrapper">
            <p>2. Choose the word most similar in meaning to - condescend</p>
            <div class="question-grid">
                <span>a] criticize</span>
                <span>b] usurp</span>
                <span>c] patronize</span>
                <span>d] contribute</span>
            </div>
            <hr />
            <p><strong>⭐️ condescend</strong> - show feelings of superiority; be patronizing</p>
            <p><strong>criticize</strong> - to find and point out faults or problems.</p>
            <p><strong>usurp</strong> - take a position of power or importance illegally or by force.</p>
            <p class="font-bold"><strong>✅ patronize</strong> - to adopt an air of condescension toward. (most similar)
            </p>
            <p><strong>contribute</strong> - help to cause or bring about.</p>
        </div>

        <div class="tip-wrapper">
            <p>3. Choose the word most nearly opposite in meaning to - ambiguous</p>
            <div class="question-grid">
                <span>a] tentative</span>
                <span>b] obvious</span>
                <span>c] vague</span>
                <span>d] uncertain</span>
            </div>
            <hr />
            <p><strong>⭐️ ambiguous</strong>- unclear or inexact; having a double meaning.</p>
            <p><strong>tentative</strong> - not fully worked out or developed.</p>
            <p class="font-bold"><strong>✅ obvious</strong> - easily perceived or understood; clear. (nearly opposite)
            </p>
            <p><strong>vague</strong> - not having a precise meaning</p>
            <p><strong>uncertain</strong> - not able to be relied on; not known or definite.</p>
        </div>



    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

.tip-wrapper {
    @apply my-4 py-2 px-4 border border-warm-gray-200 dark:border-warm-gray-700 rounded-lg
}

.tip-wrapper p {
    @apply my-4
}

.question-grid {
    @apply grid grid-cols-2
}

img {
    @apply h-16 mx-auto my-2
}

hr {
    @apply h-px my-6 bg-warm-gray-200 border-0 dark:bg-warm-gray-700
}
</style>
<script setup>

import ROUTER from "@/router"
import { ref, computed } from "vue"
import firebase from "@/firebase/config"
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, CheckCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon, LightBulbIcon } from "@heroicons/vue/solid"

import PreExamModal from "../components/PreExamModal.vue"

import Announcement from "@/modules/caap-101/components/Announcement.vue";

// Import other dependencies
import moment from "moment";

const isWaiting = ref(false)
const subtopics = ref([])
const tags = ref([])
const user = ref(null)

const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId)
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName)
const sortedSubtopics = computed(() => subtopics.value.sort((a, b) => a.order - b.order))

const isPreExamModalOpen = ref(false)
const selectedSubtopic = ref(null)
const closePreExamModal = () => {
    isPreExamModalOpen.value = false
}

const openPreExamModal = (subtopic) => {
    selectedSubtopic.value = subtopic;
    // console.log(subtopic)
    isPreExamModalOpen.value = true
}

const addOrderToSubtopics = (subjectId) => {

    var order = []

    switch (subjectId) {
        case 'mtp-verbal-reasoning':
            order = [
                'mtp-spelling',
                'mtp-word-meaning',
                'mtp-word-relationship',
                'mtp-comprehension',
                'mtp-critical-thinking',
            ]
            break;
        case 'mtp-numerical-ability':
            order = [
                'mtp-computation',
                'mtp-estimation',
                'mtp-numerical-reasoning',
                'mtp-data-interpretation',
            ]
            break;
        case 'mtp-abstract-reasoning':
            order = [
                'mtp-abstract-ability',
                'mtp-diagrammatic-ability',
                'mtp-concentration',
                'mtp-spatial-ability',
            ]
            break;
        case 'mtp-mechanical-aptitude':
            order = [
                'mtp-mechanical-reasoning',
                'mtp-fault-diagnosis',
            ]
            break;
        default:
            break;
    }

    order.forEach((subtopicId, orderIndex) => {
        let foundIndex = subtopics.value.findIndex(e => e.id == subtopicId)
        subtopics.value[foundIndex].order = orderIndex
    });
}

/**
 * Fetch user info from database 
 */
 const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let uid = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc(uid).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}


/**
 * Check if a user milestone exists for a certain subtopic
 */
const checkMilestone = (subtopic_id, milestone) => {
    if (user.value) {
        let subtopic = user.value.mtp_subtopics ? user.value.mtp_subtopics.filter((e) => e.id == subtopic_id)[0] : null;
        return subtopic && subtopic.milestones.includes(milestone) ? true : false;
    }
}

/**
 * Get the user's best time for a certain subtopic
 */
const getTime = (subtopic_id) => {
    if (user.value) {
        let subtopic = user.value.mtp_subtopics ? user.value.mtp_subtopics.filter((e) => e.id == subtopic_id)[0] : null;
        return subtopic ? formatTime(subtopic.time) : formatTime(0);
    }
}

/**
 * Get the user's best score for a certain subtopic
 */
const getScore = (subtopic_id) => {
    if (user.value) {
    let subtopic = user.value.mtp_subtopics ? user.value.mtp_subtopics.filter((e) => e.id == subtopic_id)[0] : null;
    return subtopic ? subtopic.score : 0;
    }
}

/**
 * Format's a timestamp for UI display
 * @NOTE: Utility function.
 * @TODO: Convert into reusable library
 */
const formatTime = (time) => {
    let momentedTime = moment("2015-01-01").startOf("day").seconds(time);
    return moment(momentedTime).format("HH:mm:ss");
}


const fetchSubtopicsBySubject = (subjectId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("mtp").collection("subtopics").where("subjects", "array-contains", subjectId).get()
            // firebase.firestore().collection("reviewers").doc("mtp").collection("subtopics").get()
            .then((querySnapshot) => {
                var subtopics = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subtopics.push(data)
                });
                resolve(subtopics)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })
}


const fetchTags = () => {
    return new Promise(async (resolve, reject) => {
        // console.log(subjectId.value)
        firebase.firestore().collection("tags").get()
            .then((querySnapshot) => {
                var tags = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    tags.push(data)
                });
                // console.log(tags)
                resolve(tags)
            })
            .catch((error) => {
                console.error("Error getting tags: ", error);
                reject(error)
            })
    })
}

const getNumberOfSetsBySubtopic = (subtopicId) => tags.value.filter(e => e.shorthand.includes(`${subtopicId}-set`)).length

const handlePracticeClick = (subtopic) => {
    ROUTER.push({ path: `/mtp/sets`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name, subjectId: subjectId.value, subjectName: subjectName.value } })
}

const handleTipsClick = (subtopic) => {
    /**
     * @todo @junrey ✅
     * Please add a 'scroll to top' here to make sure the Tip window is not stuck in the middle when view is changed.
     * -- Completed.Added at onMOunted to MtpTips View 
     */
    ROUTER.push({ path: `/mtp/tips`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name, subjectId: subjectId.value, subjectName: subjectName.value } })
}

// this has been moved to the pre exam modal
// const handleMockExamClick = (subtopic) => {
//     ROUTER.push({ path: `/mtp/exam`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name } })
// }

const initialize = async () => {

    isWaiting.value = true

    user.value = await fetchUser();

    if (subjectId.value) {
        subtopics.value = await fetchSubtopicsBySubject(subjectId.value)
        if (subtopics.value.length > 0) {
            addOrderToSubtopics(subjectId.value)
            tags.value = await fetchTags()
            isWaiting.value = false
        } else {
            console.error("No results found. Please make sure you have correct Subject Id.")
        }
    } else {
        console.error("There is no subject being passed. You will be redirected")
    }


}
initialize()
</script>
<template>
    <div class="mx-auto">
        <div class="space-y-6 mb-20 mx-0 lg:mx-6">
            <Announcement />
            
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/mtp/subjects"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">{{ subjectName }}</span>
                    <span class="block md:hidden">{{ subjectName }}</span>
                </div>
                <div class="w-6 h-6"></div>
                <!-- <button class="opacity-20 disabled:cursor-default" disabled>
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600"
                        aria-hidden="true" />
                </button> -->
            </nav>
            <div v-if="!isWaiting" class=" gap-6 grid grid-cols-1 lg:grid-cols-2">
                <!-- <ul>
                    <li v-for="subtopic in sortedSubtopics" :key="subtopic.id" class="my-8 border border-white rounded-md px-8 py-4">
                        <h1 class="my-2 text-lg font-bold">{{ subtopic.name }}</h1>
                        <p>{{ subtopic }}</p>
                        <div class="flex justify-between text-yellow-400 my-2">
                            <button @click="handlePracticeClick(subtopic)">Start Practice</button>
                        </div>
                    </li>
                </ul> -->
                <div v-for="subtopic in sortedSubtopics" :key="subtopic.id"
                    class="relative flex flex-col rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                    <div class="grid grid-cols-1 gap-4 p-6">
                        <div class="focus:outline-none">
                            <div class="text-base font-medium text-warm-gray-400 mb-4">
                                <div class="flex justify-between items-center">
                                    <!-- <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" /> -->
                                    <h1 class="text-xl font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                        {{ subtopic.name }}
                                    </h1>
                                    <button @click="handleTipsClick(subtopic)" class=" flex justify-center items-center text-xs rounded-lg px-2 py-1 border border-yellow-700 text-yellow-700 bg-warm-gray-200 hover:bg-warm-gray-300 dark:border-yellow-600 dark:text-yellow-600 dark:bg-warm-gray-900 dark:hover:bg-warm-gray-800"> 
                                        <LightBulbIcon class="w-4 h-4"/>
                                        <span>Tips</span>
                                        <!-- <QuestionMarkCircleIcon class="flex-shrink-0 h-5 w-5 text-warm-gray-600 hover:text-warm-gray-700" aria-hidden="true" /> -->
                                    </button>
                                </div>
                            </div>
                            <p class="text-sm text-warm-gray-500 mt-2">{{ subtopic.description }}</p>
                            <!-- <p class="text-sm text-warm-gray-500 mt-2">This subtopic contains {{ getNumberOfSetsBySubtopic(subtopic.id) }} sets.</p> -->
                        </div>
                        <!-- Mock Exam Badges -->
                        <div v-if="true" class="col-span-1">
                            <div class="flex flex-inline flex-row-reverse justify-between">
                                <div class="flex flex-inline space-x-2">
                                    <!-- Milestones -->
                                    <div v-if="checkMilestone(subtopic.id, 'passer')" class="bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <PaperClipIcon class="text-blue-500 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <PaperClipIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                    <div v-if="checkMilestone(subtopic.id, 'speedrunner')" class="bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <LightningBoltIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                    <div v-if="checkMilestone(subtopic.id, 'master')" class="bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <FireIcon class="text-yellow-600 w-6 h-6" />
                                    </div>
                                    <div v-else
                                        class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                        <FireIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                    </div>
                                </div>
                                <!-- Exam Data -->
                                <div class="focus:outline-none text-left">
                                    <div class="flex text-md items-center">
                                        <ClockIcon class="w-5 h-5 dark:text-warm-gray-500 text-warm-gray-300" />
                                        <p class="ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-300">{{ getTime(subtopic.id) }}</p>
                                    </div>
                                    <div class="flex dark:text-warm-gray-400 text-warm-gray-800 text-md items-center ">
                                        <ChartSquareBarIcon
                                            class="w-5 h-5 dark:text-warm-gray-500 text-warm-gray-300" />
                                        <p class="ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-300">{{ getScore(subtopic.id)}}%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl">
                        <div class="text-sm flex justify-between">
                            <button @click="handlePracticeClick(subtopic)"
                                class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                View Question Sets
                            </button>
                            
                            <!-- <button @click="handleMockExamClick(subtopic)"
                                class="disabled:cursor-default disabled:opacity-25 font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                                Start Exam
                            </button> -->
                            <button @click="openPreExamModal(subtopic)"
                                class="disabled:cursor-default disabled:opacity-25 font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                                Start Exam
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="flex justify-center mt-8">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                </div>
            </div>
        </div>
        <PreExamModal :isOpen="isPreExamModalOpen" :subtopic="selectedSubtopic" @closeModal="closePreExamModal()" />
    </div>
</template>
<template>
    <div class="text-sm text-warm-gray-600 dark:text-warm-gray-400 my-4 mb-12">
        <p class="my-4">
            These questions assess your ability to <strong>identify the relationship between words</strong> and to then
            apply this verbal
            analogy. To answer these questions, you need to understand the meaning of the words in the question and
            establish what exactly the relationship is between them; then choose <strong>whichever is the most
                appropriate</strong>.
        </p>

        <h2>Sample Questions</h2>
        <p><strong>Strategy: </strong>The critical point in making the right choice greatly depends on your
            understanding of the given relationship. Focus more on the given example of word relationship before going
            through the choices.</p>

        
        <div class="tip-wrapper">
            <p>1. degree is to temperature as current is to -------</p>
            <div class="question-grid">
                <span>a] Ohm</span>
                <span class="correct-answer">b] Amp</span>
                <span>c] Wire</span>
                <span>d] Volt</span>
            </div>
            <p>💡 Temperature is measured in degrees. Current is measured in amps.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>2. volume is to litre as time is to -------</p>
            <div class="question-grid">
                <span class="correct-answer">a] Minute</span>
                <span>b] Period</span>
                <span>c] Instant</span>
                <span>d] Point</span>
            </div>
            <p>💡 Litre is a unit of volume. Minute is a unit of time.</p>
        </div>

        
        <div class="tip-wrapper">
            <p>3. storm is to calm as clear is to -------</p>
            <div class="question-grid">
                <span>a] Quiet</span>
                <span class="correct-answer">b] Cloudy</span>
                <span>c] Lucid</span>
                <span>d] Cool</span>
            </div>
            <p>💡 Storm and calm are opposites. Clear and cloudy are opposites.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>4. sculptor is to marble as painter is to -------</p>
            <div class="question-grid">
                <span>a] Easel</span>
                <span>b] Artist</span>
                <span class="correct-answer">c] Canvas</span>
                <span>d] Brush</span>
            </div>
            <p>💡 A sculptor works with marble. A painter works with canvas.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>5. chapter is to book as color is to -------</p>
            <div class="question-grid">
                <span>a] Hue</span>
                <span>b] Artist</span>
                <span>c] Palette</span>
                <span class="correct-answer">d] Spectrum</span>
            </div>
            <p>💡 A book is divided into chapters. A spectrum is divided into colors.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>6. anaesthetic : numb</p>
            <div class="question-grid">
                <span>a] vaccine : virus</span>
                <span>b] disease : drug</span>
                <span class="correct-answer">c] sedative : drowsy</span>
                <span>d] action : lunacy</span>
            </div>
            <p>💡 An anaesthetic makes something numb. A sedative makes something drowsy.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>7. star : astronomy</p>
            <div class="question-grid">
                <span>a] religion : deity</span>
                <span class="correct-answer">b] event : history</span>
                <span>c] ice : geology</span>
                <span>d] vase : pottery</span>
            </div>
            <p>💡 Stars are studied as part of astronomy. Events are studied as part of history.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>8. courtroom : lawyer</p>
            <div class="question-grid">
                <span class="correct-answer">a] arena : gladiator</span>
                <span>b] contest : team</span>
                <span>c] teacher : class</span>
                <span>d] commuter : train</span>
            </div>
            <p>💡 A lawyer performs in a courtroom. A gladiator performs in an arena.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>9. copper, tin, zinc, -------</p>
            <div class="question-grid">
                <span class="correct-answer">a] Oxygen</span>
                <span>b] Brass</span>
                <span>c] Salt</span>
                <span>d] Bronze</span>
            </div>
            <p>💡 These are all elements.</p>
        </div>
        
        <div class="tip-wrapper">
            <p>10. arrow, spear, javelin, -------</p>
            <div class="question-grid">
                <span class="correct-answer">a] Pilum</span>
                <span>b] Shield</span>
                <span>c] Sword</span>
                <span>d] Armor</span>
            </div>
            <p>💡 These are all ranged weapons.</p>
        </div>

    </div>
</template>
<style scoped>
h2 {
    @apply mt-8 mb-2 text-lg text-warm-gray-800 dark:text-yellow-400 font-bold flex items-center space-x-2
}

h3 {
    @apply mt-6 mb-2 text-base text-warm-gray-800 dark:text-warm-gray-300 flex items-center space-x-2
}

.tip-wrapper {
    @apply my-4 py-2 px-4 border border-warm-gray-200 dark:border-warm-gray-700 rounded-lg
}

.tip-wrapper p {
    @apply my-4
}

.question-grid {
    @apply grid grid-cols-2
}

.correct-answer{
    @apply font-bold text-warm-gray-800 dark:text-warm-gray-300
}
</style>
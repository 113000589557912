<template>
    <div
        class="pointer-events-auto overflow-hidden rounded-lg bg-gradient-to-r from-indigo-800 to-indigo-500 shadow-lg ring-1 ring-black ring-opacity-5 mb-6 w-full">
        <div class="p-4">
            <div class="flex items-start">
                <div class="flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="h-6 w-6 text-white">
                        <path fill-rule="evenodd"
                            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                            clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-2 w-0 flex-1 pt-0.5 items-center">
                    <p class="text-sm font-bold text-white">IMPORTANT:</p>
                    <p class="text-sm text-indigo-100">This reviewer is in BETA mode! Some features are still under
                        development.</p>
                </div>
            </div>
        </div>
    </div>
</template>
/**
 * NOTE: Connects To Firebase
 * fetch all of the subjects under a certain course from the database
 */
function fetchMockExam(db, exam_id){
  return db.collection("mock").doc(exam_id).get()
    .then(doc => {
      let data = doc.data()
      data.id = doc.id
      return data
    })
    .catch((error) => {
      console.error("Error getting mock document:", error);
      throw `Error getting mock document: ${error}`;
    });
}


exports.examService = {
  fetchMockExam
}
<template>
  <LoadingScreen v-if="isLoading" />
  <main v-else>
    <header class="mt-0 sm:mt-4">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-4 md:flex md:items-center md:justify-between align-middle">
        <div class="my-5 sm:my-0">
          <h1 class="text-xl font-bold leading-7 text-black sm:text-2xl sm:truncate">
            🏆 Leaderboard Challenge - Season 2
          </h1>
          <p class="mt-1 text-gray-900 max-w-2xl">
            Reach the Top 10 and win the most amazing prizes as you master your CAAP Licensure exams. Increase your rank by earning Skillpoints and become the next Skilltech Champion!
          </p>
          <p class="mt-1 text-gray-900 max-w-2xl">
            Season ends on May 31, 2023 @6pm. Winners will be announced right after.
          </p>
        </div>
        <div class="flex items-center space-x-2">
          <button type="button" @click="isPrizeModalOpen = true" class="border-2 border-black inline-flex items-center rounded-md px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
            See Top 10 Prizes
          </button>
          <button type="button" @click="userView = !userView" class="border-2 border-black inline-flex items-center rounded-md  px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
            Switch to {{ userView ? 'Schools' : 'Users' }} View
          </button>
        </div>
      </div>
    </header>
    <div class="mt-6 sm:mt-0 flex flex-col sm:py-6 sm:px-6 max-w-full mx-auto lg:max-w-7xl space-y-6">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">

        <!-- Desktop View -->
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="hidden sm:block shadow overflow-hidden border-b border-yellow-800 sm:rounded-lg">
            <table v-if="userView" class="min-w-full divide-y divide-yellow-900">
              <thead class="bg-yellow-900">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-yellow-100 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-yellow-100 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs hidden lg:block font-medium text-yellow-100 uppercase tracking-wider">
                    School
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-yellow-100 uppercase tracking-wider">
                    Skillpoints
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                <tr v-for="user in leaderboard" :key="user.rank" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                    {{ this.getOrdinal(user.rank) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full" :src="user.photo" alt="" />
                        <DefaultUser v-else class="block mx-auto h-10 w-10" />
                      </div>
                      <div class="ml-4">
                        <div class="text-sm font-medium text-true-gray-900 dark:text-true-gray-100 flex flex-inline items-center space-x-1">
                          <span>{{ user.firstname }} {{ user.lastname }} </span>
                          <BadgeCheckIcon v-if="user.isVerified" class="w-4 h-4 text-blue-500" />
                        </div>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap hidden lg:block ">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <router-link :to="`/school/${getSchoolById(schools, user.school).shorthand.toLowerCase()}`" class="hover:text-md">
                          <img v-if="user.school" class="object-cover mx-auto h-10 w-10 rounded-md" :src="getSchoolById(schools, user.school).logoUrl" alt="" />
                        </router-link>
                      </div>
                      <div class="ml-4">
                        <div class="hover:text-md">
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100">{{ getSchoolById(schools, user.school).name }} ({{getSchoolById(schools, user.school).shorthand}})</div>
                          <div class="text-sm text-true-gray-500 dark:text-true-gray-400">{{ user.course }}</div>
                        </div>
                      </div>
                    </div>
                    
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ user.skillpoints }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-else class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
              <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Rank
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    School
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs hidden lg:block font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Top Users
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                    Skillpoints
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                <tr v-for="(school, index) in schools" :key="school.id" :class="(user.id === this.user.id) ? 'bg-warm-gray-100 dark:bg-true-gray-600' : ''">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-true-gray-500 dark:text-true-gray-400">
                    {{ this.getOrdinal(index+1) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <div class="hover:text-md">
                          <img class="object-cover mx-auto h-10 w-10 rounded-md" :src="school.logoUrl" alt="" />
                        </div>
                      </div>
                      <div class="ml-4">
                        <div>
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100 hidden lg:inline ">{{ school.name }} ({{ school.shorthand}})</div>
                          <div class="text-sm text-true-gray-900 dark:text-true-gray-100 inline lg:hidden">{{ school.shorthand}}</div>
                        </div>
                        <div class="text-sm text-true-gray-500 dark:text-true-gray-400 flex flex-inline">
                          <UserGroupIcon class="text-true-gray-500 dark:text-true-gray-400 h-4 w-4 mr-2 mt-0.5"/>
                          <span>{{school.users.length}} Users</span>
                          </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-3 text-sm text-gray-500 font-medium hidden lg:inline">
                    <div v-if="school.users.length > 0" class="flex items-center space-x-2">
                      <div class="flex flex-shrink-0 -space-x-1">

                        <div v-for="(user, index) in school.users.slice(0,5)" :key="index">
                          <img v-if="user.photo" class="max-w-none h-7 w-7 object-cover rounded-full ring-2 ring-warm-gray-100 dark:ring-true-gray-600" :src="user.photo" alt="" />
                          <DefaultUser v-else class="block mx-auto h-7 w-7 max-w-none h-6 w-6 rounded-full ring-2 ring-warm-gray-100 dark:ring-true-gray-600" />
                        </div>
                      </div>
                      <span v-if="school.users.length > 5" class="flex-shrink-0 text-xs leading-5 font-medium text-true-gray-500 dark:text-true-gray-400">+{{school.users.length - 5}}</span>
                    </div>
                    <div v-else class="flex items-center space-x-2 text-xs leading-5 font-medium text-true-gray-500 dark:text-true-gray-400">
                      This school has no users yet.
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ school.skillpoints }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <!-- Mobile View -->
          <div v-if="userView" class="block sm:hidden">
            <div class="grid grid-cols-1 gap-2">
              <div v-for="user in leaderboard" :key="user.rank" 
                :class="
                  [(user.id === this.user.id) ? 'bg-true-gray-700 border-2 border-gray-800' : 'bg-true-gray-800 border border-gray-900', 
                  'relative rounded-lg px-4 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400']">
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <div :class="[this.getRankClass(user.rank), 'w-14']">
                      {{ this.getRankEmoji(user.rank) }} {{ this.getOrdinal(user.rank) }}
                    </div>
                    <div class="flex-shrink-0 ml-2">
                      <img v-if="user.photo" class="object-cover h-10 w-10 rounded-full" :src="user.photo" alt="" />
                        <DefaultUser v-else class="block mx-auto h-10 w-10" />
                    </div>
                    <div class="min-w-0 flex-1 ml-2">
                      <div class="focus:outline-none">
                        <p class="text-sm font-medium text-white">{{ user.firstname }} {{ user.lastname }} <BadgeCheckIcon v-if="user.isVerified" class="w-4 h-4 text-blue-500" /></p>
                        <p class="truncate text-sm text-gray-400">{{ user.course.substring(user.course.length-1, user.course.length-4) }} at {{getSchoolById(schools, user.school).shorthand  }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col items-center space-x-3">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ user.skillpoints }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="block sm:hidden">
            <div class="grid grid-cols-1 gap-2">
              <div v-for="(school, index) in schools" :key="school.id" 
                  class='bg-true-gray-800 border border-gray-900 relative rounded-lg px-4 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400'>
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <div class="w-10 text-gray-400">
                      {{ this.getOrdinal(index+1) }}
                    </div>
                    <div class="flex-shrink-0 ml-2">
                      <img v-if="school.logoUrl" class="object-cover mx-auto h-10 w-10 rounded-md" :src="school.logoUrl" alt="" />
                      <DefaultUser v-else class="block mx-auto h-10 w-10" />
                    </div>
                    <div class="min-w-0 flex-1 ml-2">
                      <div class="focus:outline-none">
                        <div class="text-sm text-true-gray-900 dark:text-true-gray-100 inline lg:hidden">{{ school.shorthand}}</div>
                        <div class="flex space-x-1 text-gray-400 text-sm items-center ">
                          <UserGroupIcon class="text-true-gray-500 dark:text-true-gray-400 h-4 w-4 "/>
                          <span>{{school.users.length}} Users</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col items-center space-x-3">
                    <span class="ml-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                        <Logo class="w-3.5 h-3.5 mr-1" />
                        {{ school.skillpoints }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </main>

  <S2PrizeModal 
        :isPrizeModalOpen="isPrizeModalOpen" 
        @closePrizeModal="isPrizeModalOpen = false" 
      />

  
</template>

<script>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";

import Logo from '@/components/Logo'
import DefaultUser from '@/components/DefaultUser'
import LoadingScreen from "@/components/appshell/LoadingScreen"

import { leaderboardService } from "@/utils/s2leaderboard"
import { userService } from "@/utils/user"
import { schoolService } from "@/utils/school"

import { BadgeCheckIcon } from '@heroicons/vue/solid'
import { UserGroupIcon } from '@heroicons/vue/outline';

import S2PrizeModal from "@/components/modals/S2PrizeModal"
import { readlink } from "fs";

export default {
  components: { 
    Logo,
    DefaultUser,
    LoadingScreen,
    BadgeCheckIcon,
    UserGroupIcon,
    S2PrizeModal
  },
  data(){
    return {
      isLoading: true,
      user: null,
      leaderboard: [],
      schools: [],
      userView: true,
      isPrizeModalOpen: false
    }
  },
  
  methods: {

    getSchoolById: schoolService.getSchoolById,
    
    /**
     * Get the ordinal of a number for ranking system
     * i.e. getOrdinal(1) returns String "1st"
     * @NOTE: Duplicate over /dashboard
     */
    getOrdinal(i) { 
      var j = i % 10, k = i % 100;
      if (j == 1 && k != 11) { return i + "st"; }
      if (j == 2 && k != 12) { return i + "nd"; }
      if (j == 3 && k != 13) { return i + "rd"; }
      return i + "th";
    },

    getRankEmoji(rank){
      if(rank == 1){
        return '🏆';
      }else if(rank == 2){
        return '🥈';
      }else if(rank === 3){
        return '🥉';
      }else if(rank < 11){
        return  '🏅';
      }else{
        return '';
      }
    },

    getRankClass(rank){
      if(rank == 1){
        return 'text-yellow-400 font-bold'
      }else if(rank == 2){
        return 'text-white font-bold'
      }else if(rank === 3){
        return 'text-yellow-600 font-bold'
      }else{
        return 'text-gray-200';
      }
      r
    }
  },
  async created(){
    this.isLoading = true

    /* CLAIMS */
    // let claims = await userService.fetchUserClaims(firebase.auth())
    // if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    
    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    console.log("this.user", this.user)


    /**
     * 🏆 Season 2 Edits.
     * Make sure that the user is already initialized in the S2 Leaderboard.
     * Otherwise, initialize the user with its own data
     */
    var docRef = firebase.firestore().collection('s2leaderboard').doc(this.user.id)
    await docRef.get().then(async (doc) => {
        // Document was found in the cache. If no cached document exists,
        // an error will be returned to the 'catch' block below.
        console.log("Cached document data:", doc.data());
        if(doc.data()){
          console.log(`$[🏆S2:] User is already in the S2 leaderboard.`)
          // do nothing
        }else{
          console.log(`$[🏆S2:] User is not yet in the S2 leaderboard. Initializing...`)
          
          // let result = await leaderboardService.initializeUserToS2Leaderboard(
          //   this.user, 
          //   firebase.functions().httpsCallable('initUserToS2Leaderboard')
          // )

          let result = await leaderboardService.initializeUserToS2LeaderboardV2(
            this.user, 
            firebase.firestore()
          )

          console.log({result})

          if(result.success){
            console.log("Successful init to s2 leaderboard")
          }
        }

    }).catch((error) => {
        console.log("Error getting cached document:", error);
    });

    
    var all_leaderboard
    all_leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());

    all_leaderboard = leaderboardService.sortLeaderboard(all_leaderboard) // sort the leaderboard    
    all_leaderboard = leaderboardService.adjustLeaderboardForUser(this.user, all_leaderboard) // adjust the leaderboard for current user

    var lboard_user_rank = all_leaderboard.filter( e => { return e.id == this.user.id }) // get the user from leaderboard
    all_leaderboard.splice(10) // splice the all_leaderboard array to only top 20
    if(lboard_user_rank[0].rank < 10){
      this.leaderboard = all_leaderboard // if the user is within the top 20, display the spliced all_leaderboard
    }else{
      this.leaderboard = [...all_leaderboard, ...lboard_user_rank] // else, append the user into the all_leaderboard at the very end
    }
    
    /* SCHOOLS */
    let sch_all_leaderboard = await leaderboardService.fetchLeaderboard(firebase.firestore());
    this.schools = await schoolService.fetchAllSchools(firebase.firestore())
    this.schools = schoolService.sortSchoolLeaderboard(this.schools, sch_all_leaderboard)

    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");

    this.isLoading = false

  }
}
</script>
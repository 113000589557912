<template>
    <div
      class="
        min-h-screen
        bg-warm-gray-50
        flex flex-col
        justify-center
        py-12
        sm:px-6
        lg:px-8
      "
    >
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="https://skilltech.ph/">
          <Logo class="text-warm-gray-900 w-12 h-12 mx-auto" />
        </a>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-warm-gray-900">
          Sign up for a free account
        </h2>
        <p class="mt-2 text-center text-sm text-warm-gray-600">
          You're just a few minutes away from improving your Aviation career
        </p>
      </div>
  
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form @submit.prevent="pressed" class="space-y-6">
            <div>
              <label
                for="email"
                class="block text-sm font-medium text-warm-gray-700"
              >
                Email address
              </label>
              <div class="mt-1">
                <input
                  v-model="email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-warm-gray-400
                    focus:outline-none
                    focus:ring-yellow-500
                    focus:border-yellow-500
                    sm:text-sm
                  "
                />
              </div>
            </div>
  
            <div>
              <div class="flex justify-between align-center">
                <label for="password" class="block text-sm font-medium text-warm-gray-700">
                  Password
                </label>
                <div class="text-gray-600 hover:text-gray-500 dark:hover:text-gray-500 dark:text-gray-400 cursor-pointer" @click="toggleSeeingEye()">
                  <svg v-if="!isVisible" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </div>
              </div>
              <div class="mt-1">
                <input
                  v-model="password"
                  id="password"
                  name="password"
                  :type="(isVisible) ? 'text' : 'password'"
                  autocomplete="current-password"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-warm-gray-400
                    focus:outline-none
                    focus:ring-yellow-500
                    focus:border-yellow-500
                    sm:text-sm
                  "
                />
              </div>
            </div>
  
            <div>
              <div class="flex justify-between align-center">
                <label for="repassword" class="block text-sm font-medium text-warm-gray-700">
                  Confirm Password
                </label>
                <div class="text-gray-600 hover:text-gray-500 dark:hover:text-gray-500 dark:text-gray-400 cursor-pointer" @click="toggleSeeingEye()">
                  <svg v-if="!isVisible" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                  </svg>
                </div>
              </div>
              <div class="mt-1">
                <input
                  v-model="repassword"
                  id="repassword"
                  name="repassword"
                  :type="(isVisible) ? 'text' : 'password'"
                  autocomplete="repassword"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-warm-gray-400
                    focus:outline-none
                    focus:ring-yellow-500
                    focus:border-yellow-500
                    sm:text-sm
                  "
                />
              </div>
            </div>
  
            <!-- <div>
              <label
                for="code"
                class="block text-sm font-medium text-warm-gray-700"
              >
                Endorser Code
              </label>
              <div class="mt-1">
                <input
                  v-model="code"
                  id="code"
                  name="code"
                  autocomplete="code"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    px-3
                    py-2
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-warm-gray-400
                    focus:outline-none
                    focus:ring-yellow-500
                    focus:border-yellow-500
                    sm:text-sm
                  "
                />
              </div>
            </div> -->
  
            <div class="flex items-center justify-between">
              <!-- <div class="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-yellow-600 focus:ring-yellow-500 border-warm-gray-300 rounded" />
                <label for="remember-me" class="ml-2 block text-sm text-warm-gray-900">
                  Remember me
                </label>
              </div> -->
  
              <div class="text-sm">
                Already have an account? 
                <a
                  href="/login"
                  class="font-medium text-yellow-600 hover:text-yellow-500"
                >
                  Login here
                </a>
              </div>
            </div>
  
            <div>
              <button
                v-if="!isWaiting"
                type="submit"
                class="
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-warm-gray-600
                  hover:bg-warm-gray-500
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
                "
              >
                Register
              </button>
              <button
                v-else
                disabled
                class="
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-warm-gray-600
                  hover:bg-warm-gray-500
                  focus:outline-none
                  focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
                  disabled:opacity-50
                  disabled:cursor-auto
                "
              >
                <svg
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Signing up...
              </button>
            </div>
  
            <!-- <div class="error" v-if="error">{{error.message}}</div> -->
            <div v-if="error" class="rounded-md bg-yellow-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <ExclamationIcon
                    class="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div class="ml-3">
                  <p class="text-sm font-medium text-yellow-800">
                    {{ error.message }}
                  </p>
                </div>
                <div class="ml-auto pl-3">
                  <div class="-mx-1.5 -my-1.5">
                    <button
                      @click="error = ''"
                      type="button"
                      class="
                        inline-flex
                        bg-yellow-50
                        rounded-md
                        p-1.5
                        text-yellow-500
                        hover:bg-yellow-100
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-offset-yelow-50
                        focus:ring-yelow-600
                      "
                    >
                      <span class="sr-only">Dismiss</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
  
          <!-- <div class="mt-6">
            <div class="relative">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-warm-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="px-2 bg-white text-warm-gray-500">
                  Or continue with
                </span>
              </div>
            </div>
  
            <div class="mt-6 grid grid-cols-3 gap-3">
              <div>
                <a
                  href="#"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    bg-white
                    text-sm
                    font-medium
                    text-warm-gray-500
                    hover:bg-warm-gray-50
                  "
                >
                  <span class="sr-only">Sign in with Google</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 30 30"
                  >
                    >
                    <path
                      d="M 15.003906 3 C 8.3749062 3 3 8.373 3 15 C 3 21.627 8.3749062 27 15.003906 27 C 25.013906 27 27.269078 17.707 26.330078 13 L 25 13 L 22.732422 13 L 15 13 L 15 17 L 22.738281 17 C 21.848702 20.448251 18.725955 23 15 23 C 10.582 23 7 19.418 7 15 C 7 10.582 10.582 7 15 7 C 17.009 7 18.839141 7.74575 20.244141 8.96875 L 23.085938 6.1289062 C 20.951937 4.1849063 18.116906 3 15.003906 3 z"
                    ></path>
                  </svg>
                </a>
              </div>
  
              <div>
                <a
                  href="#"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    bg-white
                    text-sm
                    font-medium
                    text-warm-gray-500
                    hover:bg-warm-gray-50
                  "
                >
                  <span class="sr-only">Sign in with Facebook</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
  
              <div>
                <a
                  href="#"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    py-2
                    px-4
                    border border-warm-gray-300
                    rounded-md
                    shadow-sm
                    bg-white
                    text-sm
                    font-medium
                    text-warm-gray-500
                    hover:bg-warm-gray-50
                  "
                >
                  <span class="sr-only">Sign in with Twitter</span>
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div> -->
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import firebase from "@/firebase/config";
  import "firebase/auth";
  import "firebase/functions";
  import "firebase/firestore";
  const db = firebase.firestore();

  import { ExclamationIcon, XIcon } from "@heroicons/vue/solid";
  import Logo from "@/components/Logo";
  const NAME = "Login";
  
  export default {
    name: NAME,
    site_title: NAME,
    data() {
      return {
        isWaiting: false,
        email: "",
        password: "",
        repassword: "",
        error: "",
        code: "",
        isVisible: false,
      };
    },
    components: {
      ExclamationIcon,
      XIcon,
      Logo,
    },
  
    methods: {
      toggleSeeingEye(){
        console.log("Seeing eye toggled")
        this.isVisible = !this.isVisible
      },
      pressed() {
        this.isWaiting = true;
  
        if(this.password != this.repassword){
          let error = {
            message: 'Passwords did not match'
          }
          this.error = error;
          this.isWaiting = false;
        }else{
          console.log("User passed code: ", this.code);
          console.log("Checking endorser code");
  
            console.log("Signing up for a new account...");
            
            firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then((userCredential) => {
              console.log(`Successfully registered user ${this.email}`, userCredential)
              // location.reload()
              if(this.$route.query && this.$route.query.referrer){
                this.$router.push({ path: '/onboarding', query: { referrer: this.$route.query.referrer } })
              }else{
                this.$router.push({ path: '/onboarding' })
              }
              
            })
            .catch((error) => {
            this.error = error;
            this.isWaiting = false;
            });
            
  
        }
  
        
      },
    },
    created(){
      console.log("Removing overflow-hidden")
      document.body.classList.remove("overflow-hidden");

      console.log(this.$route.query.referrer)
    }
  };
  </script>
  
  <style scoped>
  </style>
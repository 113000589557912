<script setup>

import ROUTER from "@/router"
import { ref, computed } from "vue"
import firebase from "@/firebase/config"
import { Menu, MenuButton, MenuItem, MenuItems, TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { 
    SwitchHorizontalIcon, XIcon, 
    UploadIcon, DownloadIcon, 
    CheckCircleIcon, XCircleIcon, 
    ChevronDoubleLeftIcon, ChevronDoubleRightIcon, 
    LogoutIcon, FlagIcon, 
    ExclamationIcon
} from "@heroicons/vue/outline"
import { 
    VolumeUpIcon, VolumeOffIcon, 
    QuestionMarkCircleIcon, LightBulbIcon 
} from "@heroicons/vue/solid"

// import sound assets
import correctSound from '@/assets/audio/correct.mp3'
import wrongSound from '@/assets/audio/wrong.mp3'
import passedSound from '@/assets/audio/passed.mp3'
import failedSound from '@/assets/audio/failed.mp3'

const correctAudio = new Audio(correctSound)
const wrongAudio = new Audio(wrongSound)
const passedAudio = new Audio(passedSound)
const failedAudio = new Audio(failedSound)

// @TODO move this somewhere global
const tips = [
    "The CAAP Knowledge Test are given in various exam sets. Meaning, your exam may differ from what your friend is taking, even if you’re doing the test for the same subject.",
    "CAAP exams have time limits. Typically, the number of items in a subject is also the number of minutes allotted to complete the exam. For example, 30 items means 30 minutes. ",
    "One of the creators of this app has managed to pass all the CAAP Knowledge Test for AMT subjects in only one day. 7 subjects in 1 day. It’s possible.",
    "The passing score for the actual exam is 70% across all subjects. Want to know if you’re ready? Our Mock Exams follows the same passing rates so always give it a try.",
    "Skilltech Reviewer’s concepts and features are the results of in-depth research into real life experiences of Filipino AMT and AVT exam takers. ",
    "This reviewer has been created with the inputs of actual CAAP exam passers. Through hundreds of interviews, surveys, and statistics, we’ve managed to collect the best ideas from the Filipino aviation community. ",
    "In the Philippines, your CAAP Airmen License will be required first before a company promotes you to a Certifying Staff. No license, no promotion. That’s why it’s always better to have it ready.",
    "The CAAP Airmen License is one of the most frequently required credentials when applying for jobs abroad. Job agencies in the Philippines usually include your license in their qualification list.",
    "Our survey shows that the majority of previous exam takers consider Air Law and Airworthiness to be the most difficult subject and Human Performance to be the easiest.",
    "We are BSAMT but we started our OJT right after we got our Associate’s degree. After completing that, we took the CAAP exams. By the time we’re in 3rd year in college, we already have our CAAP AMT licenses.",
]

// firestore data
const tags = ref([]) // all the tags that are fetched from firestore
const questionnaire = ref(null) // the questionnaire that is fetched from firestore
const tagsQuestionsLinks = ref([]) // all the question==tag links that are fetched from firestore

// practice data
const isWaiting = ref(false) // ui status
const isSoundOff = ref(false) // ui sound
const isOnCompleteOpen = ref(false) // flag for the onComplete modal
const isOnQuitOpen = ref(false) // flag for the OnQuit modal. when user quits
const questions = ref(null) // an array that will be autogenerated according to the question's set and context
const exam = ref(null) // the exam data created 

// computed properties 
const reviewerId = 'mtp' // constant reviewer id
const setId = computed( () => ROUTER.currentRoute.value.query.setId ) // set id taken from the query params
const setName = computed( () => ROUTER.currentRoute.value.query.setName ) // set name taken from the query params
const subtopicId = computed( () => ROUTER.currentRoute.value.query.subtopicId ) // subtopic id taken from the query params
const subtopicName = computed( () => ROUTER.currentRoute.value.query.subtopicName ) // subtopic name taken from the query params
const subjectId = computed( () => ROUTER.currentRoute.value.query.subjectId ) // subject id taken from the query params
const subjectName = computed( () => ROUTER.currentRoute.value.query.subjectName ) // subject name taken from the query params


// getting all contexts of this specific subtopic
const currentSubtopicContexts = computed( () => {
    let filteredContexts = tags.value.sort().filter( e => e.shorthand.includes(`${subtopicId.value}-context`) ) // find contexts in tags collection
    return filteredContexts.sort((a, b) => a.name.localeCompare(b.name)) // sort alphabetically by name
})

// convert the above value into just an array of context IDs
const currentSubtopicContextsIds = computed( () => {
    return currentSubtopicContexts.value.map( e => e.id)
})

// convert the tagsQuestionsLinks array into just an array of ids
const tagsQuestionsLinksIds = computed( () => {
    return tagsQuestionsLinks.value.map( e => e.id)
})

// get the context based on the context id passed. This will return the HTML description for ui display
const getContextById = (contextId) => {
    return currentSubtopicContexts.value.filter( e => e.id == contextId)[0].description
}

const getSetInstructionsByQuestion = (questionId) => {

    // get all the tags that has this question from the tags-question links
    let filteredTagsQuestionLinks = tagsQuestionsLinks.value.filter(e => e.questionId == questionId )
    
    // loop through all the linked tags and retrieve them from the tag collection
    let filteredTags = []
    filteredTagsQuestionLinks.forEach( tagQuestionLink => {
        filteredTags = [...filteredTags, ...tags.value.filter( e => e.id == tagQuestionLink.tagId)]
    })

    // filter only the 'question-types' tags
    let qtypes = filteredTags.filter( e => e.shorthand.includes('question-type'))

    // each question will only have 1 question type. therefore, if all went well, we only get the first result
    return qtypes[0].description
}

// CONNECTS TO FIRESTORE: retreive the questionnaire collection of this specific subtopic via subtopic id
const fetchQuestionnaireBySubtopicId = (subtopicId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("questionnaires").doc(subtopicId).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })   
}

// CONNECTS TO FIRESTORE: retreive all the mtp reviewer's tags from the tags collection
const fetchTagsByReviewer = (reviewerId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags").where("reviewerId", "==", reviewerId).get()
            .then((querySnapshot) => {
                var tags = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    tags.push(data)
                });
                resolve(tags)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })   
}

// CONNECTS TO FIRESTORE: retreive all the tags==questions links of this subtopic from firestore
const fetchTagsQuestionsLink = (subtopicId) => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("tags_questions").where("questionnaireId", "==", subtopicId).get()
            .then((querySnapshot) => {
                var tags = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    tags.push(data)
                });
                resolve(tags)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })

    })   
}

// IMPORTANT: This will retreive all of the items from that questionnaire and arrange them depending on 
// what set they are in and if they have contexts, it will group them accordingly. 
const arrangeQuestionnaire = () => {
    
    // from the question_tags link, we will get the questions associated to this set. grab their IDs
    let questionsUnderThisSet = tagsQuestionsLinks.value.filter( e => e.tagId == setId.value)
    let questionIdsUnderThisSet = questionsUnderThisSet.map( e => e.questionId )

    // from here, we will check all contexts in this subtopic. we find in questions_tags if there are any associated questions for each context
    let itemsWithContext = [];
    currentSubtopicContextsIds.value.forEach( contextId => {
        let questionsUnderThisContext = tagsQuestionsLinks.value.filter( e => e.tagId == contextId)
        let questionIdsUnderThisContext = questionsUnderThisContext.map( e => e.questionId )
        itemsWithContext = itemsWithContext.concat(questionIdsUnderThisContext)
    })
    
    // here we get the remaining questions Ids that doesn't have any context
    let itemsWithoutContext = questionIdsUnderThisSet.filter( e => (!itemsWithContext.includes(e)) )
    
    // we merge these 2 arrays. we display the items without context first
    let finalItems = itemsWithoutContext.concat(itemsWithContext)

    let finalQuestions = []
    finalItems.forEach( itemId => {
        let question = questionnaire.value.questions.filter( e => e.id == itemId)[0]
        let i = itemsWithContext.findIndex( e => e == question.id)
        
        if(i > -1){
            let filtered = tagsQuestionsLinks.value.filter( e => (e.questionId == itemsWithContext[i]))
            let tagId = filtered.filter( e => { if(currentSubtopicContextsIds.value.includes(e.tagId)) return e.tagId })[0].tagId
            question.contextId = tagId
        }else{
            question.contextId = null
        }
        finalQuestions.push(question)
    });

    // this will now contain an array of questions for this particular practice session
    // @TODO: randomize it if needed, but only on v2
    console.log('finalQuestions', finalQuestions)
    finalQuestions.sort((a, b) => a.id - b.id);
    console.log('finalQuestions', finalQuestions)
    // return finalQuestions
    
    /**
     * August 17, 2024: 
     * Fixing the finalQuestions showing all items and not the ones that are in context
     */
    // Extract questionIds to an array for easier checking
    let questionIdsToFilter = questionsUnderThisSet.map(item => item.questionId);
    // Filter out the questions whose id is in questionIdsToFilter
    let filteredQuestions = finalQuestions.filter(question => questionIdsToFilter.includes(question.id));
    // shuffle the choices
    const finalFilteredQuestions = filteredQuestions.map(question => ({
                    ...question,
                    choices: shuffle([...question.choices])
                }));
    return finalFilteredQuestions;
    
}

const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// this will generate the exam data that will be saved in the database
const generateExamData = () => {
    // Create an 'answers' array with null values that matches the questionnaire size
    let answers = [];
    questions.value.forEach(() => answers.push({ answer: null }));

    // Create the exam data model
    return {
        user: firebase.auth().currentUser.uid,
        questionnaire: questions.value,
        result: null,           // result of the exam. Can be passed or failed
        score: null,            // user score
        status: "started",      // status of the exam. Can be either started, timedout, cancelled or completed
        subtopic: subtopicId.value,
        time: {
            start: Date.now(),  // date timestamp of when the exam was started
            end: 0,             // date timestamp of when the exam was finished
        },
        index: 0,               // current index of where the user is in the exam
        progress: 0,            // user progress
        answers: answers,       // the answer array relative to the questionnaire.
        url: `/mtp/practice?setId=${setId.value}&setName=${setName.value}&subtopicName=${subtopicName.value}&subtopicId=${subtopicId.value}&reviewerId=${reviewerId}` // NEW: Quick store a URL
        
    };
}

const countCorrectAnswers = () => {
    var count = 0
    exam.value.answers.forEach((e, i) => {
        if (e.answer != null && e.answer == questions.value[i].answer) count++
    });
    return count
}

/**
 * Returns the number of running incorrect answers
 */
const countIncorrectAnswers = () => {
    var count = 0
    exam.value.answers.forEach((e, i) => {
        if (e.answer != null && e.answer != questions.value[i].answer) count++
    });
    return count
}

/**
 * Fires when a choice is selected. In this function, we do any tasks required 
 * before the user can go to the next question (or complete the practice).
 * The Next button only increments the index to move to next question.
 */
const reveal = (i) => {

    // Update the answer chosen
    exam.value.answers[exam.value.index].answer = questions.value[exam.value.index].choices[i].id;

    // console.log('correctAnswer:', questions.value[exam.value.index].answer)
    // console.log('userAnswered:', exam.value.answers[exam.value.index].answer)
    if(!isSoundOff.value){
        // make sure to pause and reload any audio playing
        resetAudio();
        if(questions.value[exam.value.index].answer == exam.value.answers[exam.value.index].answer){
            // user answered correctly
            correctAudio.play()
        }else{
            wrongAudio.play()
        }
    }


    // Update the progress
    exam.value.progress = ((exam.value.index + 1) / questions.value.length) * 100;
    exam.value.progress = exam.value.progress.toFixed(2);
    // Update the score
    exam.value.score = (countCorrectAnswers()/questions.value.length)*100
}
const resetAudio = () => {
    correctAudio.pause()
    correctAudio.currentTime = 0
    wrongAudio.pause()
    wrongAudio.currentTime = 0
}
/**
 * Returns the corresponding HTML classes for showing correct and incorrect choices
 * @NOTE: Utility function for UI
 */
const showAnswers = (i) => {
    if (exam.value.answers[exam.value.index].answer == null) {
    return "";
    } else {
    return questions.value[exam.value.index].answer ==
        questions.value[exam.value.index].choices[i].id
        ? "text-green-500 font-bold"
        : "text-red-500";
    }
}

/**
 * Returns the corresponding HTML classes for the selected choice or otherwise
 * @NOTE: Utility function for UI
 * @TODO: Check if can be converted into reusable library. Also used in Mock Exam.
 * -- Issue for TODO: Using questions.value (Practice) instead of exam.value.questionnaire (Mock)
 */
const showSelected = (i) => {
    return exam.value.answers[exam.value.index].answer ==
    questions.value[exam.value.index].choices[i].id
    ? "bg-true-gray-100 dark:bg-true-gray-700"
    : "bg-white dark:bg-true-gray-800 hover:bg-true-gray-100 dark:hover:bg-true-gray-700";
}
// : "bg-white dark:bg-true-gray-800 hover:bg-true-gray-100 dark:hover:bg-true-gray-700";

/**
 * Returns the HTML classes for the border of the choices container.
 * If first choice, set the top border as curved
 * If last choice, set the bottom border as curved
 * @NOTE: Utility function for UI
 * @TODO: Check if can be converted into reusable library. Also used in Practice Exam.
 * -- Issue for TODO: Using questions.value (Practice) instead of exam.value.questionnaire (Mock)
 */
const setBorder = (i) => {
    if (i == 0) {
    return "rounded-tl-md rounded-tr-md";
    } else if (i == questions.value[exam.value.index].choices.length - 1) {
    return "rounded-bl-md rounded-br-md";
    }
}

const isOnlyOneUnansweredLeft = () => {
    let filteredArray = exam.value.answers.filter(e => e.answer == null)
    // console.log(filteredArray)
    if(filteredArray.length == 1){
        return true
    }else{
        return false
    }
}

const isTheRemainingQuestion = () => {
    // let i = exam.value.answers.findIndex(e => e.answer == null)
    let f = exam.value.answers.filter(e => e.answer == null)
    if(f.length == 0){
    console.log('this is the last remaining question')
        return true
    }else{
        return false
    }
}

const skip = (toIndex) => {

    // scroll to the top of the reviewer container
    document.getElementById("review-container").scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the scroll container
        inline: 'nearest' // Align to the nearest edge (in case of horizontal scrolling)
    });

    // find all indeces of unanswered questions
    let unansweredIndeces = []
    exam.value.answers.forEach( (e,i) => {
    if(e.answer == null){
        unansweredIndeces.push(i)
    }
    })

    // if there is only 1 left, do nothing.
    // if(unansweredIndeces.length == 1) return

    // find an index that has greater than the toIndex. 
    // if found, jump to there
    let i = unansweredIndeces.findIndex( e => e > toIndex)
    if(i == -1){
        // if nothing was found, get the first unanswered index
        // and return it
        exam.value.index = unansweredIndeces[0]
    }else{
        exam.value.index = unansweredIndeces[i]
    }

}

/**
 * This will check the practice exam results and status.
 * Once complete, user and leaderboard data in the database is updated.
 * @NOTE: Connects to Firebase.
 */
const completePractice = () => {
    // @TODO: 75% is the default passing but we must not hardcode this in the future
    exam.value.result = countCorrectAnswers() > (questions.value.length * 0.75) ? "passed" : "failed"
    exam.value.status = "completed"
    exam.value.time.end = Date.now()

    // play pass or fail audio

    if(!isSoundOff.value){
        if(exam.value.result == 'passed'){
            passedAudio.pause()
            passedAudio.currentTime = 0
            passedAudio.play()
        }else if(exam.value.result == 'failed'){
            failedAudio.pause()
            failedAudio.currentTime = 0
            failedAudio.play()
        }
    }

    // Open Complete Modal. Once the user Continues to exit, practice exam data will be saved to DB
    isOnCompleteOpen.value = true
}

/**
 * Wraps up the user's practice exam data as he/she exits the practice exam page and saves it to DB.
 * @NOTE: Connects to Firebase.
 */
const exitPractice = () => {
    // @TODO For now we won't save any progress. This reviewer is purely a compendium of topics
    ROUTER.push({ path: `/mtp/sets`, query: { subtopicName: subtopicName.value, subtopicId: subtopicId.value, subjectName: subjectName.value, subjectId: subjectId.value, reviewerId: reviewerId } })
}

// initialize this page
const initialize = async () => {
    isWaiting.value = true
    if(subtopicId.value){
        tags.value = await fetchTagsByReviewer(reviewerId)
        questionnaire.value = await fetchQuestionnaireBySubtopicId(subtopicId.value)
        tagsQuestionsLinks.value = await fetchTagsQuestionsLink(subtopicId.value)
        questions.value = await arrangeQuestionnaire()
        exam.value = await generateExamData()

        /* setup audio */
        wrongAudio.volume = 0.2
        correctAudio.volume = 0.2
        passedAudio.volume = 0.2
        failedAudio.volume = 0.2
            
        isWaiting.value = false
    }else{
        console.error("There is no subtopic being passed. You will be redirected")
    }
}
initialize()

const isHtmlEmpty = (html) => {
    return (html == '<p><br></p>') ? true : false
}
</script>

<template>
    <div id="review-container" class="w-full mx-auto py-6 px-3">
        <!-- The page has been initialized here -->
        <div v-if="!isWaiting">

            <!-- Breadcrumbs -->
            <nav id='practice-nav' class="flex px-1 lg:px-7 justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                <div @click="isOnQuitOpen = true" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center cursor-pointer" aria-current="page">
                    <LogoutIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                </div>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                <span>Practice Exam for {{ subtopicName }}</span>
                </div>
                <!-- FAQ Menu -->
                <button @click="isSoundOff = !isSoundOff" class="text-warm-gray-500">
                    <VolumeOffIcon v-if="isSoundOff" class="h-5 w-5" aria-hidden="true" />
                    <VolumeUpIcon v-else class="h-5 w-5" aria-hidden="true" />
                </button>
            </nav>

            <!-- Main Content. Shown if there are questions available -->
            <main v-if="questions.length > 0" class="max-w-full mt-6 mx-auto px-1 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8 space-y-4 mb-28">
                
                <!-- Stats -->
                <div id="stats" class="dark:border-true-gray-700 min-h-60 rounded-md mb-4 flex justify-between">
                    <div class="flex dark:text-warm-gray-500 text-warm-gray-700  text-md items-center">
                        <FlagIcon class="w-5 h-5 mr-1" />
                        <p class="hidden sm:block ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-700">
                        Question {{ exam.index + 1 }} out of {{ questions.length }} under {{ setName }}
                        </p>
                        <p class="block sm:hidden ml-2 font-medium dark:text-warm-gray-500 text-warm-gray-700">
                            {{ exam.index + 1 }} of {{ questions.length }}
                        </p>
                    </div>
                    <div class="flex text-warm-gray-800 text-md items-center">
                        <div class="ml-2 font-medium text-warm-gray-800 flex items-center">
                        <div class="flex items-center mr-3 text-green-500">
                            <CheckCircleIcon class="ml-1 w-5 h-5 mr-1" />
                            {{ countCorrectAnswers() }}
                            <span class="hidden sm:block ml-1">Correct</span>
                        </div>
                        <div class="flex items-center text-red-500">
                            <XCircleIcon class="ml-1 w-5 h-5 mr-1" />
                            {{ countIncorrectAnswers() }}
                            <span class="hidden sm:block ml-1"> Wrong</span>
                        </div>
                        </div>
                    </div>
                </div>

                <!-- Set Instructions -->
                <div v-if="!isHtmlEmpty(getSetInstructionsByQuestion(questions[exam.index].id))" class="select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line" >
                    <p class="mb-4 font-bold">Instructions: </p><p v-html="getSetInstructionsByQuestion(questions[exam.index].id)"></p>
                </div>

                <!-- Context -->
                <div v-if="questions[exam.index].contextId" class="select-none text-left text-blue-500 dark:text-blue-400 rounded-md -space-y-px p-6 bg-blue-50 dark:bg-blue-900 dark:bg-opacity-25 whitespace-pre-line" >
                    <span v-html="getContextById(questions[exam.index].contextId)"></span>
                </div>

                <!-- Question -->
                <transition name="fade">
                    <div v-if="!isHtmlEmpty(questions[exam.index].questionHtml)" class="select-none text-left text-warm-gray-900 dark:text-warm-gray-300 rounded-md -space-y-px p-6 border-2 border-dashed border-warm-gray-300 dark:border-true-gray-700 whitespace-pre-line" >
                        <span v-html="questions[exam.index].questionHtml"></span>
                    </div>
                </transition>

                <!-- Choices -->
                <div id="choices" class="select-none mt-4">
                    <ul class="text-warm-gray-800 dark:text-warm-gray-400 text-left rounded-md shadow-sm -space-y-px">
                        <li v-for="(choice, idX) in questions[exam.index].choices" :key="`choice-${idX}`">
                        <button
                            :disabled="exam.answers[exam.index].answer != null"
                            @click="reveal(idX)"
                            :class="[ showAnswers(idX), showSelected(idX), setBorder(idX), 
                            'flex justify-between border border-warm-gray-300 dark:border-true-gray-700 relative shadow-sm px-5 py-4 cursor-pointer text-left focus:outline-0 w-full disabled:cursor-auto' ]"
                        >
                            <span v-html="choice.choiceHtml"></span>
                            <div v-if="exam.answers[exam.index].answer != null">
                            <span v-if="choice.id == questions[exam.index].answer">
                                <CheckCircleIcon class="h-5 w-5" />
                            </span>
                            <span v-else>
                                <XCircleIcon class="h-5 w-5" />
                            </span>
                            </div>
                        </button>
                        </li>
                    </ul>
                </div>

                <!-- Action Buttons -->
                <nav class="mt-6 flex flex-col sm:flex-row justify-between">
                    <button
                        :disabled="exam.index <= 0"
                        v-on:click="exam.index > 0 ? exam.index-- : null"
                        class="order-last sm:order-first my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <ChevronDoubleLeftIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 mr-1"/>
                        Previous
                    </button>
                    <button
                        v-if="!isOnlyOneUnansweredLeft()"
                        :disabled="exam.answers[exam.index].answer != null"
                        v-on:click="skip(exam.index)"
                        class="my-2 disabled:opacity-50 w-full sm:w-auto space-x-2 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Skip<span class="ml-1 hidden sm:block"> This</span>
                        <SwitchHorizontalIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                    </button>
                    <!-- <button
                        
                        v-on:click="this.isQuestionExplorerShown = true"
                        class="my-2 disabled:opacity-50 w-full sm:w-auto space-x-2 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Jump<span class="ml-1 hidden sm:block">To</span>
                        <UploadIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                    </button> -->
                    <button
                        v-if="!isTheRemainingQuestion()"
                        :disabled="exam.answers[exam.index].answer == null"
                        v-on:click="skip(exam.index)"
                        class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-warm-gray-700 dark:text-warm-gray-400 bg-white dark:bg-true-gray-800 hover:bg-warm-gray-100 dark:hover:bg-true-gray-600 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Next
                        <ChevronDoubleRightIcon class="h-5 w-5 dark:text-warm-gray-400 text-warm-gray-500 ml-1"/>
                    </button>
                    <button
                        v-if="isTheRemainingQuestion()"
                        :disabled="exam.answers[exam.index].answer == null"
                        v-on:click="completePractice()"
                        class="order-first sm:order-last my-2 disabled:opacity-50 w-full sm:w-auto space-x-4 flex flex-inline justify-center items-center disabled:cursor-auto p-4 sm:px-5 shadow-sm border border-warm-gray-300 dark:border-true-gray-700 shadow-sm font-medium rounded-md text-white bg-purple-700 hover:bg-purple-800 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Complete Practice Exam
                    </button>
                </nav>



            </main>
            <div v-else class="text-warm-gray-400 w-full text-center mt-14">
                Questionnaire seems to be empty.
            </div>




        </div>
        <!-- Otherwise, show the loading animation for now -->
        <div v-else class="flex justify-center mt-8">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div class="text-true-gray-700">Preparing practice session...</div>
        </div>
    </div>

    <!--  -->

    <!-- Popup: On Complete -->
    <TransitionRoot appear :show="isOnCompleteOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  <DialogTitle as="h3" class=" text-sm uppercase font-semibold leading-6 text-warm-gray-500 dark:text-warm-gray-200 text-center">
                    Practice Exam Results
                  </DialogTitle>
                  <div class="mt-2">
                    <div class="text-3xl font-bold text-warm-gray-700 dark:text-warm-gray-200 mt-8">
                      <p v-if="exam.score == 100">Highly impressive! 🥳🔥</p>
                      <p v-else-if="exam.result == 'passed'">Wow, you’re good! 😲👍🏼</p>
                      <p v-else>Not quite there yet. 😉👌🏼</p>
                    </div>
                    <p class="text-lg dark:text-warm-gray-200 text-warm-gray-700 mt-1 mb-8">
                      <!-- You got {{this.countCorrectAnswers()}} correct answers out of {{questions.length}} items in {{ showTime('hour') }} {{ showTime('minute') }} {{ showTime('second') }} -->
                      You got {{ countCorrectAnswers() }} correct answers out of {{ questions.length }} items.
                    </p>
                    <div class="text-sm dark:text-warm-gray-400 text-warm-gray-500 max-w-sm mx-auto">
                      <p v-if="exam.score == 100">
                        You’ve accomplished something that most people can’t even attain. Congratulations and well done!
                      </p>
                      <p v-else-if="exam.result == 'passed'">
                        You’re doing really well. Your score shows that you’re on the right track. Keep it up and don’t stop. Great job!
                      </p>
                      <p v-else>
                        We know the feeling. But practice makes perfect, right? Don’t worry, you’ll get there. Now try again. 
                      </p>
                    </div>
                    <div class="bg-gray-50 overflow-hidden rounded-lg text-sm text-warm-gray-500 text-left mt-8 max-w-lg">
                      <div class="px-4 py-5 sm:p-5 flex space-x-2 items-start">
                        <div>
                          <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                        </div>
                        <p class="text-yellow-700">
                          <strong>Did You Know?</strong> {{tips[Math.floor(Math.random() * tips.length)]}}
                        </p>
                      </div>
                    </div>
                    <div class="mt-8 sm:mt-6">
                      <button @click="exitPractice()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-0 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>

    <!-- Popup: are you sure? -->
    <TransitionRoot appear :show="isOnQuitOpen" as="template">
        <Dialog as="div">
          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
              </TransitionChild>
              <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                <div class="text-center inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-true-gray-800 shadow-xl rounded-2xl">
                  
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-warm-gray-400 text-gray-900">Quit Practice Exam</DialogTitle>
                    <div class="mt-2">
                        <p class="text-sm dark:text-warm-gray-500 text-gray-500">Are you sure you want to quit your practice session? All of your progress will note be saved and you will start over next time.</p>
                    </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="exitPractice()">Quit Practice</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="isOnQuitOpen = false" ref="cancelButtonRef">Cancel</button>
                </div>
                  
                  
                  
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
    </TransitionRoot>


</template>
<style scooped>
button{
    @apply px-2 py-1 bg-true-gray-600
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>
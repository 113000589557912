/**
 * NOTE: Connects To Firebase
 * fetch all of the subjects under a certain course from the database
 */
function fetchSubjectsByCourse(db, reviewer_id, course_id){
    return db.collection("reviewers").doc(reviewer_id).collection("subjects").where("course", "array-contains", course_id).get()
      .then((querySnapshot) => {
        var subjects = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          data.id = doc.id
          subjects.push(data)
        });
        return subjects;
      })
      .catch((error) => {
        console.error("Error getting subject data: ", error);
        throw "Error getting subject data"
      })
}

/**
 * Retreive all of the subtopics under a certain Subject from the database.
 * @NOTE: Connects to Firebase.
 */
function fetchSubtopicsBySubject(db, reviewer_id, subject_id) {

    return db.collection("reviewers").doc(reviewer_id).collection("subtopics").where("subjects", "array-contains", subject_id).get()
      .then((querySnapshot) => {
        var subtopics = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data()
          data.id = doc.id
          subtopics.push(data)
        });
        return subtopics;
      })
      .catch((error) => {
        console.error("Error getting subtopic data: ", error);
        throw "Error getting subject data"
      })
}

/**
 * Retreive all of the subjects from the database.
 * @NOTE: Connects to Firebase.
 */
 function fetchAllSubjects(db, reviewer_id) {

  return db.collection("reviewers").doc(reviewer_id).collection("subjects").get()
    .then((querySnapshot) => {
      var subjects = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        subjects.push(data)
      });
      return subjects;
    })
    .catch((error) => {
      console.error("Error getting subtopic data: ", error);
      throw "Error getting subject data"
    })
}

/**
 * Retreive all of the subjects from the database.
 * @NOTE: Connects to Firebase.
 */
function fetchAllSubtopics(db, reviewer_id) {

  return db.collection("reviewers").doc(reviewer_id).collection("subtopics").get()
    .then((querySnapshot) => {
      var subjects = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        data.id = doc.id
        subjects.push(data)
      });
      return subjects;
    })
    .catch((error) => {
      console.error("Error getting subtopic data: ", error);
      throw "Error getting subject data"
    })
}


exports.reviewerService = {
  fetchSubjectsByCourse, fetchSubtopicsBySubject, fetchAllSubjects, fetchAllSubtopics
}
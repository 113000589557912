function formatDate(timestampInSeconds){

    const timestampInMilliseconds = timestampInSeconds * 1000;
    const date = new Date(timestampInMilliseconds);

    // Define an array to map month numbers to month names
    const months = [
    "Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    // Get the month, day, and year from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    return `${month} ${day}, ${year}`;

}


function formatDateInString(dateString){
    // Create a Date object from the string
    const date = new Date(dateString);
    // Get the individual components of the date
    const options = { 
      month: 'short', 
      day: '2-digit', 
      year: 'numeric', 
    //   hour: '2-digit', 
    //   minute: '2-digit', 
    //   second: '2-digit', 
    //   hour12: true 
    };
    // Format the date to the desired format
    const formattedDate = date.toLocaleString('en-US', options).replace(',', '');
    return formattedDate;
  }

export {formatDate, formatDateInString}
<script setup>

import ROUTER from "@/router";
import { ref, computed, onMounted } from "vue";
import firebase from "@/firebase/config";
import moment from "moment";
import PreExamModal from "../components/PreExamModal.vue";

/**
 * Component for the "This is Beta" announcement.
 */
import Announcement from "@/modules/caap-101/components/Announcement.vue";

import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon } from "@heroicons/vue/solid";

import Thinking1 from "../assets/Thinking1.vue";
import Thinking2 from "../assets/Thinking2.vue";
import Thinking3 from "../assets/Thinking3.vue";
import Thinking4 from "../assets/Thinking4.vue";


const isWaiting = ref(true);
const user = ref(null);
const subjects = ref([]);
const selectedSubject = ref(null)
const isPreExamModalOpen = ref(false)

const sortedFilteredSubjects = computed( () => { 
    const order = [
        'identification-general',
        'identification-aircraft',
        'explanation-of-process',
        'hands-on-performance'
    ]

    const noExam = [
        'explanation-of-process',
        'hands-on-performance'
    ];

    let newSubjectsArray = [];
    
    order.forEach((sId)=>{
        let subjectFound = subjects.value.find(s => s.id == sId)
        // check if the subject has no exam then add a flag value
        if (noExam.includes(subjectFound.id)) {
            subjectFound.hasExam = false;
        }else{
            subjectFound.hasExam = true;
        }
        
        if (subjectFound) {
            newSubjectsArray.push(subjectFound);
        }    
    });

    console.log(newSubjectsArray);

    return newSubjectsArray;

})

const icons = [Thinking1, Thinking2, Thinking3, Thinking4]

const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let id = firebase.auth().currentUser.uid
        firebase.firestore().collection("users").doc(id).get()
            .then(doc => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting user document:", error)
                reject(error)
            });
    })
}

const fetchSubjects = () => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("plus").collection("subjects").get()
            .then((querySnapshot) => {
                var subjects = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subjects.push(data)
                });
                resolve(subjects)
                // console.log(subjects)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}

const handleBeginPracticeClick = (subject) => {
    ROUTER.push({ path: `/plus/subtopics`, query: { subjectId: subject.id, subjectName: subject.name } })
}

/**
 *  Get the user's best time for a certain subtopic
 */
const getTime = (subject_id) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject ? formatTime(subject.time) : formatTime(0);
    }
}

/**
 * Get the user's best score for a certain subtopic
 */
 const getScore = (subject_id) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject ? subject.score : 0;
    }
}

/**
 * Check if a user milestone exists for a certain subtopic
 */
 const checkMilestone = (subject_id, milestone) => {
    if (user.value) {
        let subject = user.value.subjects ? user.value.subjects.filter((e) => e.id == subject_id)[0] : null;
        return subject && subject.milestones.includes(milestone) ? true : false;
    }
}

/**
 * Format's a timestamp for UI display
 * @NOTE: Utility function.
 * @TODO: Convert into reusable library
 */
 const formatTime = (time) => {
    let momentedTime = moment("2015-01-01").startOf("day").seconds(time);
    return moment(momentedTime).format("HH:mm:ss");
}

const closePreExamModal = () => {
    isPreExamModalOpen.value = false
    // selectedSubject.value = null
}

const openPreExamModal = (subject) => {
    isPreExamModalOpen.value = true
    selectedSubject.value = subject
}

onMounted(async () => {

    isWaiting.value = true;

    const element = document.getElementById('target-div');
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    [user.value, subjects.value] = await Promise.all([
        fetchUser(),
        fetchSubjects()
    ]);

    // addOrderToSubjects()
    isWaiting.value = false;
});
</script>
<template>    
    <div class="mx-auto" id="target-div">
        <div class="space-y-6 mb-20 mx-0 lg:mx-6">
            <!-- <Announcement /> -->
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/reviewers" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">CAAP Plus Subjects</span>
                    <span class="block md:hidden">CAAP Plus Subjects</span>
                </div>
                <button class="opacity-20 disabled:cursor-default" disabled>
                    <QuestionMarkCircleIcon class="flex-shrink-0 h-6 w-6 text-warm-gray-500 hover:text-warm-gray-600" aria-hidden="true"/>
                </button>
            </nav>

        
            <div v-if="!isWaiting">
                <div class=" gap-6 grid grid-cols-1 lg:grid-cols-2">
                    <div v-for="(subject, index) in sortedFilteredSubjects" :key="subject.id" class="relative flex flex-col rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                        
                        <div class="flex-1 min-w-0 col-span-1 p-6 flex flex-col justify-between">
                            <!-- Subject Data -->
                            <div class="focus:outline-none text-left">
                                <div class="flex dark:text-warm-gray-400 text-warm-gray-800 items-center">
                                    <!-- <LightningBoltIcon class="text-text-gray-500 w-6 h-6" /> -->
                                    <component :is="icons[index]" class="text-text-gray-500 w-7 h-7" />
                                    <h1 class="ml-2 text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                        {{subject.name}}
                                    </h1>
                                </div>
                                <p class="text-sm text-warm-gray-500 mt-2">
                                    {{subject.description}}
                                </p>
                            </div>
    
                            <!-- Mock Exam Badges -->
                            <div v-if="subject.hasExam" class="col-span-1 mt-4">
                                <div class="flex flex-inline flex-row-reverse justify-between">
                                    <div class="flex flex-inline space-x-2">
                                        <!-- Milestones -->
                                        <div v-if="checkMilestone(subject.id, 'passer')" class="bg-blue-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <PaperClipIcon class="text-blue-500 w-6 h-6" />
                                        </div>
                                        <div v-else
                                            class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <PaperClipIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                        </div>
                                        <div v-if="checkMilestone(subject.id, 'speedrunner')" class="bg-purple-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <LightningBoltIcon class="text-purple-500 w-6 h-6" />
                                        </div>
                                        <div v-else
                                            class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <LightningBoltIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                        </div>
                                        <div v-if="checkMilestone(subject.id, 'master')" class="bg-yellow-300 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <FireIcon class="text-yellow-600 w-6 h-6" />
                                        </div>
                                        <div v-else
                                            class="dark:bg-true-gray-700 bg-warm-gray-200 flex-grow-0 rounded-full w-12 h-12 p-3">
                                            <FireIcon class="dark:text-true-gray-600 text-warm-gray-300 w-6 h-6" />
                                        </div>
                                    </div>
                                    <!-- Exam Data -->
                                    <div class="focus:outline-none text-left">
                                        <div class="flex text-md items-center">
                                            <ClockIcon class="w-5 h-5 dark:text-warm-gray-700 text-warm-gray-300" />
                                            <p class="ml-2 font-medium dark:text-warm-gray-700 text-warm-gray-300">{{ getTime(subject.id) }}</p>
                                        </div>
                                        <div class="flex dark:text-warm-gray-400 text-warm-gray-800 text-md items-center ">
                                            <ChartSquareBarIcon
                                                class="w-5 h-5 dark:text-warm-gray-700 text-warm-gray-300" />
                                            <p class="ml-2 font-medium dark:text-warm-gray-700 text-warm-gray-300">{{ getScore(subject.id)}}%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
    
                        <!-- Action Buttons -->
                        <div class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 min-w-0 px-6 py-3">
                            <!-- <div class="text-sm flex justify-between">
                                <button @click="handleStudyClick(subject)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                    View Subtopics
                                </button>
                            </div> -->
                            <div class="text-sm flex justify-between">
                                <button @click="handleBeginPracticeClick(subject)" class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                View Subtopics
                                </button>
                                <button v-if="subject.hasExam" @click="openPreExamModal(subject)" class="font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                                Start Mock Exam
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Survey Notification card -->
                <div class=" bg-true-gray-100 dark:bg-true-gray-700 border-l-4 border-yellow-600 p-4 mx-auto rounded-md shadow-md mt-8">
                    <div class="flex">
                        <div class="flex-shrink-0">
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-medium dark:text-true-gray-100">
                                Done with your CAAP Practical Exam?
                            </h3>
                            <div class="mt-2 text-sm text-warm-gray-500 dark:text-true-gray-400">
                                <p>
                                    Take a quick survey and make a big difference. Help your fellow airmen in just a few clicks ♥️ ✈️
                                </p>
                            </div>
                            <div class="mt-4">
                                <router-link to="/plus/survey" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                                Take the Survey
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-else class=" gap-6 grid grid-cols-1 lg:grid-cols-2">
                <div v-for="i in 4" :key="i" class="relative animate-pulse rounded-lg overflow-hidden dark:bg-true-gray-600 dark:bg-opacity-50 bg-white shadow-sm dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
                    <div class="flex-1 min-w-0 col-span-1 p-6">
                        
                        <div class="focus:outline-none text-left">
                            <div class="flex dark:text-warm-gray-400 text-warm-gray-800 items-center">
                                <div class=" bg-warm-gray-300 dark:bg-warm-gray-500 w-7 h-7 rounded-md opacity-60"></div>
                                <div class=" w-32 h-6 rounded-md bg-warm-gray-300 dark:bg-warm-gray-500 ml-1 opacity-60"></div>

                            </div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-full h-4 mt-2"></div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-full h-4 mt-1"></div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-full h-4 mt-1"></div>
                            <div class=" rounded-md opacity-60 bg-warm-gray-300 dark:bg-warm-gray-500 w-3/4 h-4 mt-1"></div>
                        </div>
                    </div>
                    <div class="dark:bg-true-gray-400 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-5">
                    </div>
                </div>
            </div>


        </div>

        <PreExamModal :isOpen="isPreExamModalOpen" :subject="selectedSubject" @closeModal="closePreExamModal()" />

    </div>
</template>
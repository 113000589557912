<template>
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-20">
        <div
          class="
            flex-1 flex flex-col
            min-h-0
            overflow-y-auto
            dark:bg-true-gray-800
            bg-warm-gray-600
          "
        >
          <div class="flex-1 flex flex-col">
            <div
              class="
                flex-shrink-0
                dark:bg-true-gray-700 dark:bg-opacity-25
                bg-warm-gray-700
                py-4
                flex
                items-center
                justify-center
              "
            >
            <router-link to="/">
              <Logo
                class="h-8 w-auto dark:text-yellow-400 text-warm-gray-200"
              />
              </router-link>
            </div>
            <nav
              aria-label="Sidebar"
              class="py-6 flex flex-col items-center space-y-1"
            >
              
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.name == $route.name
                    ? 'bg-warm-gray-700 dark:bg-true-gray-700'
                    : 'hover:bg-warm-gray-700 dark:hover:bg-true-gray-700',
                  'flex items-center p-4 rounded-lg text-warm-gray-400',
                ]"
              >

                <div v-if="item.new" class="absolute ml-5 -mt-7 flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                </div>

                <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">{{ item.name }}</span>
              </router-link>

              <!-- Season 2 Menu Option -->
              <!-- <router-link 
                to="/season2"
                :class="[
                  'Season 2' == $route.name
                    ? 'bg-warm-gray-700 dark:bg-true-gray-700'
                    : 'hover:bg-warm-gray-700 dark:hover:bg-true-gray-700',
                  'flex items-center p-4 rounded-lg text-warm-gray-400',
                ]">
                
                <div class="absolute ml-5 -mt-7 flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
                </div>
                
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                </svg>  
              </router-link> -->
              
                <!-- Dark/Light Mode Toggle -->
                <button
                    @click="this.toggleDarkMode()"
                    class="hover:bg-warm-gray-700 dark:hover:bg-true-gray-700 flex items-center p-4 rounded-lg text-warm-gray-400"
                >
                    <SunIcon v-if="isDarkMode" class="h-6 w-6" aria-hidden="true" />
                    <MoonIcon v-else class="h-6 w-6" aria-hidden="true" />
                    <span class="sr-only">Dark Mode</span>
                </button>

              <router-link
                to="/logout"
                class="
                  hover:bg-warm-gray-700
                  dark:hover:bg-true-gray-700
                  flex
                  items-center
                  p-4
                  rounded-lg
                  text-warm-gray-400
                "
              >
                <LogoutIcon class="h-6 w-6" aria-hidden="true" />
                <span class="sr-only">Logout</span>
              </router-link>
            </nav>
          </div>

          <div class="flex-shrink-0 flex pb-5">
            <router-link
              to="/account"
              @click="this.closeMobileMenu()"
              class="flex-shrink-0 w-full"
            >
              
              <img v-if="user.photo"
                class="block mx-auto h-10 w-10 rounded-full object-cover"
                :src="user.photo"
                alt=""
              />
              <DefaultUser class="block mx-auto h-12 w-12" v-else />
            </router-link>
          </div>

          <footer class="text-warm-gray-400 text-xs text-center mb-3">
            v1.2.39
          </footer>
        </div>
      </div>
    </div>
</template>
<script>

import {
  HomeIcon, BookmarkAltIcon, PresentationChartLineIcon, LogoutIcon,
  SunIcon, MoonIcon, 
} from "@heroicons/vue/outline";

import Logo from "@/components/Logo";
import DefaultUser from "@/components/DefaultUser";

export default {
  props: [
    "isMobileMenuOpen", "isDarkMode", 
    "navigation", "user"
  ],
  components: {
    HomeIcon, BookmarkAltIcon, PresentationChartLineIcon, LogoutIcon, // Main Menu Items
    SunIcon, MoonIcon, // Dark/Light Mode Toggle
    Logo, DefaultUser
  },
  methods: {
    
    closeMobileMenu() {
        this.$emit("closeMobileMenu");
    },
    toggleDarkMode(){
        this.$emit("toggleDarkMode");
    }
  },
};
</script>
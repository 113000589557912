<script setup>

import ROUTER from "@/router";
import { ref, computed, onMounted } from "vue";
import firebase from "@/firebase/config";
import { formatDate } from '@/utils/dateHelper.js'

import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon, CheckCircleIcon, PaperClipIcon, LightningBoltIcon, FireIcon, ClockIcon, ChartSquareBarIcon, CalendarIcon } from "@heroicons/vue/solid";

/**
 * Component for the "This is Beta" announcement.
 */
import Announcement from "@/modules/caap-101/components/Announcement.vue";


// Import other dependencies
import moment from "moment";

const isWaiting = ref(false);
const subtopics = ref([]);
const user = ref(null);
const studySessions = ref([]);

const subjectId = computed(() => ROUTER.currentRoute.value.query.subjectId);
const subjectName = computed(() => ROUTER.currentRoute.value.query.subjectName);
const sortedSubtopics = computed(() => subtopics.value.sort((a, b) => a.order - b.order));

const orderToSubtopics = (subjectId) => {

    var order = []

    switch (subjectId) {
        case 'hands-on-performance':
            order = [
                'vernier-caliper-reading',
                'micrometer-reading',
                'multitester-reading',
            ]
            break;
        case 'explanation-of-process':
            order = [
                'reciprocating-engine-principles',
                'turbine-engine-propulsion',
                'four-stroke-five-events-cycle',
            ]
            break;
        default:
            break;
    }

    order.forEach((subtopicId, orderIndex) => {
        let foundIndex = subtopics.value.findIndex(e => e.id == subtopicId)
        subtopics.value[foundIndex].order = orderIndex
    });
}

/**
 * Fetch user info from database 
 */
const fetchUser = () => {
    return new Promise(async (resolve, reject) => {
        let uid = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc(uid).get()
            .then((doc) => {
                let data = doc.data()
                data.id = doc.id
                resolve(data)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })
    })
}


const fetchSubtopicsBySubject = (subjectId) => {

    return new Promise(async (resolve, reject) => {

        firebase.firestore().collection("reviewers").doc("plus").collection("subtopics").where("subjects", "array-contains", subjectId).get()
            .then((querySnapshot) => {
                var subtopics = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subtopics.push(data)
                });
                resolve(subtopics)
            })
            .catch((error) => {
                console.error("Error getting subtopic data: ", error);
                reject(error)
            })


    })
}

const fetchStudySession = (sId, uId) => {
    return new Promise(async (resolve, reject) => {

        firebase.firestore().collection("plus_study")
            .where('subjectId', '==', sId)
            .where('user', '==', uId).get()
            .then((querySnapshot) => {
                var sessions = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                });
                resolve(sessions)
            })
            .catch((error) => {
                console.error("Error getting sessions data: ", error);
                reject(error)
            })


    })
}

const getStudyProgress = (subtId) => {
    return studySessions.value.filter((session) => session.subtopicId == subtId)
}


const handleStudyClick = (subtopic) => {
    ROUTER.push({ path: `/plus/study`, query: { subtopicId: subtopic.id, subtopicName: subtopic.name, subjectId: subjectId.value, subjectName: subjectName.value } })
}

onMounted(async () => {

    isWaiting.value = true

    user.value = await fetchUser();

    if (subjectId.value) {

        // fetch subtopic and study session for this subject
        [subtopics.value, studySessions.value] = await Promise.all([
            fetchSubtopicsBySubject(subjectId.value),
            fetchStudySession(subjectId.value, user.value.id)
        ]);

        if (subtopics.value.length > 0) {
            // commenting this, cause it causes the error in production. The subtopic id is different
            // orderToSubtopics(subjectId.value)
        } else {
            console.error("No results found. Please make sure you have correct Subject Id.")
        }


        isWaiting.value = false

    } else {
        console.error("There is no subject being passed. You will be redirected")
        isWaiting.value = false
    }


});

</script>
<template>
    <div class="max-w-md mx-auto py-2">
        <div class="space-y-6 mb-20 px-4">
            <Announcement />
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/plus/subjects/study"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    <span class="hidden md:block">{{ subjectName }}</span>
                    <span class="block md:hidden">{{ subjectName }}</span>
                </div>
                <div class="w-6 h-6"></div>
            </nav>
            <div v-if="!isWaiting" class="space-y-6">
                <div v-for="subtopic in sortedSubtopics" :key="subtopic.id"
                    class="text-base rounded-2xl border border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white border-opacity-50">
                    <div class="grid grid-cols-1 gap-4 p-6">
                        <div class="focus:outline-none">
                            <div class="text-base font-medium text-warm-gray-400 mb-4">
                                <div class="flex justify-between items-center">
                                    <!-- <CheckCircleIcon class="text-warm-gray-600 w-6 h-6 mr-1 flex-shrink-0" /> -->
                                    <h1 class="text-xl font-medium dark:text-warm-gray-400 text-warm-gray-800">
                                        {{ subtopic.name }}
                                    </h1>
                                    <!-- <button @click="handleTipsClick(subtopic)"> 
                                        <QuestionMarkCircleIcon class="flex-shrink-0 h-5 w-5 text-warm-gray-600 hover:text-warm-gray-700" aria-hidden="true" />
                                    </button> -->
                                </div>
                            </div>
                            <p class="text-sm text-warm-gray-500 mt-2">{{ subtopic.description }}</p>

                            <div v-if="getStudyProgress(subtopic.id)[0] && getStudyProgress(subtopic.id)[0].isComplete == true"
                                class="relative pt-1 mt-4">
                                <div class="flex mb-2 items-center justify-between">
                                    <div>
                                        <span
                                            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                                            Completed
                                        </span>
                                    </div>
                                    <div class="text-right">
                                        <span class="text-xs font-semibold inline-block text-green-600">
                                            100%
                                        </span>
                                    </div>
                                </div>
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-green-200">
                                    <div style="width:100%"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="getStudyProgress(subtopic.id)[0] && getStudyProgress(subtopic.id)[0].isComplete == false"
                                class="relative pt-1 mt-4">
                                <div class="flex mb-2 items-center justify-between">
                                    <div>
                                        <span
                                            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                                            {{ getStudyProgress(subtopic.id)[0].isComplete == false ? 'In progress' :
                        'Completed' }}
                                        </span>
                                    </div>
                                    <div class="text-right">
                                        <span class="text-xs font-semibold inline-block text-indigo-600">
                                            {{ getStudyProgress(subtopic.id)[0] && (
                        getStudyProgress(subtopic.id)[0].progress == 1 ? 99 :
                            (getStudyProgress(subtopic.id)[0].progress * 100).toFixed(2)) }}%
                                        </span>
                                    </div>
                                </div>
                                <div class="overflow-hidden h-2 text-xs flex rounded bg-indigo-200">
                                    <div :style="`width:${getStudyProgress(subtopic.id)[0] && (getStudyProgress(subtopic.id)[0].progress == 1 ? 99 : getStudyProgress(subtopic.id)[0].progress * 100)}%`"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500">
                                    </div>
                                </div>
                            </div>

                            <div v-else class="relative pt-1 mt-4">
                                <div class="flex mb-2 items-center justify-between">
                                    <div>
                                        <span
                                            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-warm-gray-600 bg-warm-gray-200 dark:text-warm-gray-400 dark:bg-true-gray-700">
                                            Not Yet Started
                                        </span>
                                    </div>
                                    <div class="text-right">
                                        <span
                                            class="text-xs font-semibold inline-block text-warm-gray-600 dark:text-true-gray-400">
                                            0%
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="overflow-hidden h-2 text-xs flex rounded dark:bg-true-gray-700 bg-warm-gray-200">
                                    <div style="width:0%"
                                        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center dark:bg-true-gray-700 bg-gray-500">
                                    </div>
                                </div>
                            </div>

                            <div class=" flex justify-start items-center text-warm-gray-600 text-xs mt-2">
                                <CalendarIcon class=" w-4 h-4 mr-1" /> <span>Last Read: {{
                                    getStudyProgress(subtopic.id)[0] &&
                                    formatDate(getStudyProgress(subtopic.id)[0].lastDateStudy.seconds) }}</span>
                            </div>

                        </div>
                    </div>

                    <div
                        class="dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl">
                        <div class="text-sm flex justify-between">
                            <button @click="handleStudyClick(subtopic)"
                                class="font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700">
                                Start reading
                            </button>
                            <!-- <button @click="handleMockExamClick(subtopic)"
                                class="disabled:cursor-default disabled:opacity-25 font-medium dark:text-indigo-400 dark:hover:text-indigo-300 text-indigo-700 hover:text-indigo-900">
                                Start Exam
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="flex justify-center mt-8">
                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Layer_1" viewBox="0 0 511.999 511.999" xml:space="preserve">
<g>
	<g>
		<path d="M490.932,193.104c-3.544-75.095-64.901-135.312-140.453-137.03V40.056h-15.724v6.142C303.67,16.683,262.288,0,219.091,0    C126.866,0,51.765,74.725,51.144,166.806L8.472,311.888l42.668,0.003v50.755c0,33.804,27.5,61.304,61.305,61.304h90.779    l0.001,88.049h183.817V338.277c57.301-16.548,100.016-67.819,103.777-129.449h12.708v-15.724H490.932z M350.479,119.724v-15.888    c49.222,1.677,89.175,40.508,92.63,89.267H422.81v15.724h20.135c-4.388,47.82-43.917,85.619-92.466,87.274V280.11h-15.724v15.257    c-44.317-5.731-79.267-41.74-83.378-86.539h12.095v-15.724h-12.26c3.24-45.72,38.569-82.716,83.542-88.532v15.153H350.479z     M334.755,311.198v16.382c-61.992-5.98-111.21-56.288-115.496-118.753h16.332C239.799,262.303,281.757,305.326,334.755,311.198z     M334.755,88.741c-53.655,5.945-95.988,49.967-99.297,104.363h-16.326c3.361-63.386,52.971-114.702,115.621-120.745v16.382    H334.755z M371.318,496.276h-152.37l-0.001-88.049H112.445c-25.133,0-45.581-20.448-45.581-45.58v-66.479l-37.377-0.001    l37.377-127.083v-1.132c0-83.938,68.289-152.227,152.227-152.227c39.288,0,76.922,15.225,105.131,42.15    c-66.298,10.664-117.6,66.756-120.83,135.229h-20.636v15.724h20.75c4.342,71.139,60.582,128.476,131.25,134.539v16.413h15.724    v-15.915c7.083-0.161,14.041-0.838,20.839-1.992V496.276z M350.479,328.14v-16.304c57.223-1.675,103.806-46.51,108.252-103.009    h16.333C470.572,274.316,416.688,326.449,350.479,328.14z M458.865,193.104c-3.493-57.434-50.491-103.307-108.385-105.001V71.798    c66.881,1.708,121.19,54.882,124.711,121.305H458.865z"/>
	</g>
</g>
<g>
	<g>
		<polygon points="386.681,171.569 375.561,160.45 347.161,188.85 318.762,160.45 307.642,171.569 336.042,199.969 307.642,228.369     318.762,239.489 347.161,211.089 375.561,239.489 386.681,228.369 358.282,199.969   "/>
	</g>
</g>
</svg>
</template>
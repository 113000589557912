<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1"
		id="Layer_1" viewBox="0 0 511.999 511.999" xml:space="preserve">
		<g>
			<g>
				<path
					d="M486.433,137.321c-2.113-3.584-6.483-7.857-15.291-7.857h-24.177V80.051h-70.651c-0.932-1.514-1.882-3.016-2.853-4.487    C342.24,28.249,289.8,0,233.184,0C140.958,0,65.857,74.717,65.244,166.801L22.577,311.872l42.663,0.003v50.779    c0,33.794,27.495,61.289,61.29,61.289h90.805l0.001,88.055h181.597v-72.046h40.025v-79.38h32.182    c8.341,0,18.282-4.254,18.282-24.524V151.934C489.423,147.215,489.068,141.791,486.433,137.321z M358.909,95.737h72.37v72.369    h-72.37V95.737z M287.187,256.161v0.001v57.354h-21.554v-73.764c25.18-1.367,45.245-22.28,45.245-47.794    s-20.066-46.426-45.245-47.794v-31.433h77.589v71.06h35.352v72.37h-11.337v88.726h-8.328v-88.726H287.187z M343.222,271.848    v152.42h-40.349v-152.42H343.222z M263.01,224.141c-17.745,0-32.182-14.438-32.182-32.183s14.437-32.182,32.182-32.182    s32.182,14.438,32.182,32.182C295.192,209.704,280.755,224.141,263.01,224.141z M233.023,496.314l-0.001-88.055H126.53    c-25.146,0-45.604-20.458-45.604-45.603V296.19l-37.385-0.001l37.385-127.116v-1.129c0-83.956,68.303-152.258,152.259-152.258    c49.649,0,95.756,23.967,124.34,64.365h-14.304v16.995h-93.275v48.871c-20.064,5.701-34.806,24.176-34.806,46.042    s14.742,40.341,34.806,46.042v91.201h37.24v110.752h71.721v-79.38h8.328v79.38h16.01v56.359H233.023z M423.273,424.268h-40.349    v-152.42h40.349V424.268z M473.737,336.051c0,7.428-1.391,8.664-1.399,8.672c-0.07,0.043-0.441,0.165-1.197,0.165h-32.182v-88.726    H394.26v-72.37h52.704v-38.641h24.177c1.078,0,1.618,0.138,1.823,0.208c0.219,0.401,0.774,1.88,0.774,6.575V336.051z" />
			</g>
		</g>
	</svg>
</template>
<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 314.478 314.478"
    fill="currentColor"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M268.783,2.995c-3.107-3.106-8.348-2.899-11.708,0.461L204.854,55.68C198.547,23.335,180.822,0,159.893,0
			c-21.888,0-40.268,25.53-45.739,60.198L57.407,3.451c-3.36-3.361-8.601-3.568-11.708-0.461c-3.107,3.106-2.9,8.352,0.456,11.713
			l66.309,66.306c0,0.202-0.018,0.404-0.018,0.605c0,20.902,4.608,39.911,12.122,54.35l-78.413,78.41
			c-3.361,3.355-3.568,8.596-0.461,11.702c3.107,3.107,8.347,2.9,11.708-0.455l76.142-76.147c3.221,3.703,6.729,6.678,10.437,8.945
			v105.82l-47.722,32.192v18.046l50.197-22.308h26.88l50.186,22.308v-18.041L175.8,264.244v-105.82
			c2.744-1.68,5.369-3.759,7.845-6.235l73.431,73.432c3.36,3.365,8.601,3.572,11.708,0.466c3.106-3.107,2.899-8.348-0.461-11.713
			l-74.943-74.948c8.622-14.776,13.96-35.219,13.96-57.812c0-1.946-0.088-3.85-0.17-5.758l61.158-61.153
			C271.683,11.342,271.89,6.097,268.783,2.995z"
        ></path>
      </g>
    </g>
  </svg>
</template>
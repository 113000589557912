<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 491.3 491.3"
    fill="currentColor"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M410.795,36.353c-4.601-4.6-12.358-4.292-17.336,0.684L289.499,140.991c-2.364-10.582-4.315-24.742-6.479-44.172
			C272.555,2.621,241.728,0,241.728,0s-29.769,2.615-40.232,96.819c-1.799,16.194-3.446,28.701-5.29,38.569l-98.36-98.36
			c-4.975-4.975-12.734-5.282-17.334-0.683c-4.601,4.601-4.294,12.367,0.675,17.342l106.968,106.968
			c-10.331,17.078-29.065,12.578-73.666,29.724c0,0-9.396,22.9,0.246,39.252c0,0,45.725,8.397,67.644,18.478L81.187,349.301
			c-4.976,4.978-5.284,12.736-0.684,17.337c4.601,4.6,12.359,4.291,17.334-0.682l101.891-101.881
			c1.727,0.89,3.863,1.535,6.618,1.535l22.271,153.732l-54.285,10.464l-5.229-12.428c0,0-7.851,45.14,0.659,59.53l13.086,14.391
			l5.889-13.088l-6.541-11.773l50.698,9.814c0,0,0.982,11.773,8.832,11.773c7.849,0,9.889-11.773,9.889-11.773l51.764-9.814
			l-6.54,11.773l5.887,13.088l13.089-14.391c8.504-14.391,0.653-59.53,0.653-59.53l-5.238,12.428l-54.301-10.464l22.241-153.732
			c4.966,0,8.071-2.008,9.942-3.996L393.459,365.96c4.978,4.978,12.735,5.286,17.336,0.686s4.292-12.359-0.685-17.336
			L307.144,246.328c23.014-9.279,63.434-16.705,63.434-16.705c9.646-16.353-0.017-39.253-0.017-39.253
			c-40.892-15.715-60.203-13.267-71.22-25.904L410.119,53.688C415.087,48.712,415.396,40.946,410.795,36.353z"
        ></path>
      </g>
    </g>
  </svg>
</template>
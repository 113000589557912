<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue"
import firebase from "@/firebase/config"
import ROUTER from "@/router"

const { isOpen, subtopic, subjectId, subjectName, tip } = defineProps(['isOpen', 'subtopic', 'subjectId', 'subjectName', 'tip'])
const emit = defineEmits(['closeModal'])

const handleCloseClick = () => { emit('closeModal') }

/**
 * Proceed to continue a specific practice test.
 */
const continuePracticeTest = (subtopic) => {
  let practiceId = subtopic.pausedPractice.id
  ROUTER.push({ path: `/plus/practice/`, query: { subtopicId: subtopic.id, subjectId: subjectId, subjectName:subjectName, practiceId: practiceId } })
}

/**
 * Proceed to start a new practice test.
 * @NOTE: Connects to Firebase.
 */
const startNewPracticeTest = (subtopic) => {
  let practiceId = subtopic.pausedPractice.id
  // cancel the existing practice exam of a subtopic first
  firebase.firestore().collection("plus_practice").doc(practiceId).update({ status: "cancelled" })
    .then(() => {
      // once cancelled, proceed to practice
      ROUTER.push({ path: `/plus/practice/`, query: { subtopicId: subtopic.id, subjectId: subjectId, subjectName:subjectName } })
    })
    .catch((error) => { console.error("Error updating document: ", error) }); // The document probably doesn't exist.
}

</script>
<template>
    <!--  Popup: Subtopic -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="handleCloseClick()">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-true-gray-900 bg-opacity-75" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div class="inline-block w-full max-w-xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform bg-true-gray-800 shadow-xl rounded-2xl">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-true-gray-300">
                  {{ subtopic.name }}
                </DialogTitle>
                <span class="rounded-full bg-purple-500 text-purple-50 text-xs font-normal py-0.5 px-1.5">{{ subtopic.questionItemsCount }} Items</span>
                <button type="button" @click="handleCloseClick()" class="absolute top-0 right-0 mr-4 mt-4 ">
                  <svg class="w-6 h-6 text-true-gray-400 hover:text-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>
                <div class="mt-2">
                  
                  <!-- More Info -->
                  <p class="text-sm text-true-gray-400 text-left mt-8">
                    Before you begin, please take note:
                  </p>
                  <p class="text-sm text-true-gray-400 text-left">
                    <ol class="list-decimal ml-4 space-y-2 mt-2">
                      <li class="pl-1.5">There's no time limit for this Practice Exam.</li>
                      <li class="pl-1.5">A counter will tally your correct and wrong answers.</li>
                      <li class="pl-1.5">The right answer will be revealed after you pick a choice.</li>
                      <li class="pl-1.5">Click 'Next' or 'Previous' to see other questions.</li>
                      <li class="pl-1.5">Click 'Quit' icon to exit and save your progress for later.</li>
                    </ol>
                  </p>
                  
                  <!-- Tip Section -->
                  <div class="rounded-md bg-yellow-50 bg-yellow-50 overflow-hidden text-sm  text-left mt-8 max-w-lg p-2 sm:p-4">
                    <div v-if="subtopic.keys.includes(true)" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Key Subtopic: </strong>Based on our latest statistics, there's a high chance that a significant number of items from this topic will be included in the actual exam.</p>
                    </div>
                    <div v-if="subtopic.subjects.length > 1" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Duplicate Subtopic: </strong>This topic is also covered in other subject/s. Your saved progress here will also be synced in those subjects for a much easier tracking.</p>
                    </div>
                    <div v-if="subtopic.subjects.length <= 1 && !subtopic.isKey" class="p-2 sm:p-4 flex space-x-2 items-start">
                      <div><svg class="w-5 h-5 mt-px text-yellow-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg></div>
                      <p class="text-yellow-700"><strong class="text-yellow-800">Tip: </strong>{{ tip }}</p>
                    </div>
                  </div>

                  <!-- Action Buttons -->
                  <div v-if="subtopic.pausedPractice != null" class="mt-8 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button @click="continuePracticeTest(subtopic)" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                        Continue Practice Test
                    </button>
                    <button @click="startNewPracticeTest(subtopic)" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-warm-gray-500 shadow-sm px-4 py-2 bg-warm-gray-700 text-base font-medium text-warm-gray-200 hover:bg-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                        Start New Practice
                    </button>
                  </div>
                  <div v-else class="mt-8 sm:mt-6 w-full">
                    <button @click="ROUTER.push({ path: `/plus/practice/`, query: { subtopicId: subtopic.id, subjectId: subjectId, subjectName:subjectName } })" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                      Begin Practice Test
                    </button>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
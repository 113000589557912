<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="h-100">
    <LoadingScreen v-if="isLoading" />

    <main v-else-if="!isSubmitted">

      <!-- PAGE 1 -->
      <div v-if="currentPage == 1" id="page-1" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
        <h1 class="tracking-tight font-extrabold dark:text-gray-300 text-black text-3xl md:text-4xl mt-8">
          <!-- @TODO: "Hello, {name}!" -->
          Hello there!
        </h1>
        <p>Thank you for choosing our platform as your go-to resource for preparing for your Aircraft Mechanic License
          Exam.
          Our primary mission is to <strong class="font-bold text-black dark:text-white">help you succeed in getting your
            license</strong> by offering an updated and cost-effective study solution.</p>
        <p>
        <h3>Since your account is more than six months old, here's what you can do:</h3>
        <ul class="list-disc ml-4 space-y-4 my-4">
          <li><strong class="font-bold text-black dark:text-white">If You've Already Passed the Exam:</strong> We would
            appreciate it if you could share your exam results with us! As a token of our gratitude for your continued
            trust
            in our platform, you'll be eligible to claim an exclusive special reward.
          </li>
          <li><strong class="font-bold text-black dark:text-white">If You Haven't Taken the Exam Yet:</strong> Should you
            not have taken the exam yet all this time, don't worry as we will do everything to make sure you'll get your
            license. 
          </li>
        </ul>
        </p>
        <p>We want to ensure that our platform remains a high-quality, up-to-date, and affordable resource for everyone.
          Your feedback is essential for the sustained quality and affordability of our platform for all users.
        </p>
        <p>With that said, tell us your current status:
        </p>

        <div>
          <RadioGroup v-model="selected">
            <RadioGroupLabel class="sr-only">Account Status</RadioGroupLabel>
            <div class="-space-y-px rounded-md bg-white">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in options" :key="setting.name"
                :value="setting" v-slot="{ checked, active }">
                <div
                  :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'z-10 border-yellow-200 bg-yellow-50' : 'border-gray-200', 'relative flex cursor-pointer border p-4 focus:outline-none']">
                  <span
                    :class="[checked ? 'bg-yellow-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-yellow-600' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']"
                    aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <span class="ml-3 flex flex-col -mt-0.5">
                    <RadioGroupLabel as="span"
                      :class="[checked ? 'text-yellow-900' : 'text-gray-900', 'block text-base font-medium']">{{
                        setting.name }}</RadioGroupLabel>
                    <RadioGroupDescription as="span"
                      :class="[checked ? 'text-yellow-700' : 'text-gray-500', 'block text-sm']">{{ setting.description }}
                    </RadioGroupDescription>
                  </span>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div class="flex flex-col">
          <button type="button" @click="handlePageOneContinueButton()"
            class="my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
            Continue
          </button>
        </div>
      </div>

      <!-- PAGE 2 -->
      <div v-else-if="currentPage == 2" id="page-2" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">

        <p  v-if="softBlockUserInfo.data && softBlockUserInfo.data.postExamSurveyStatus == 'waiting'" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">
          We're excited to learn about your CAAP License Exam experience!
        </p>
        <div v-else>
          
          <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8" role="alert">
            <p class="font-bold">Your submission has been rejected!</p>
            <p>It seems like that there was a problem with the information you submitted. </p>
            <br>
            <p class="text-sm font-semibold">Possible causes of rejection:</p>
            <ul class=" list-disc ml-5 text-sm">
              <li>Unclear or improper profile picture uploaded.</li>
              <li>Incomplete or incorrect details.</li>
            </ul>
            <p class="mt-6 text-sm">Please review and make sure that you fill out all fields correctly. If you have any specific question, reach out to us via email at support@skilltech.ph or Facebook Messenger.</p>
          </div>

        </div>
        <!-- Test Results -->
        <label for="results" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Attached below is my Test Results (certificate)...</label>
        <div class="mt-4 shadow-sm bg-white  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg">
            <input id="test_results" @change="someHandler" name="test_results" type="file" accept="application/pdf,image/jpeg,image/png" class="p-4 inset-0 opacity-0 w-full bg-red-200 cursor-pointer border-gray-300 rounded-md" />
            <label for="test_results" class="flex justify-between text-sm text-gray-500 pointer-events-none w-full p-4 -mt-14">
              <span v-if="!test_results">Upload your Test Results here (accepts PDF, JPEG, PNG; file size below 2MB)</span>
              <span v-else>Selected: {{test_results}}  </span>
              <span v-if="test_results" class="right-0 text-sm font-medium text-red-700 dark:text-red-400">Change</span>
            </label>
        </div>
        <!-- PEL -->
        <div>
          <label for="pelNum" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">What is your PEL number? We will engrave this on your exclusive reward (more on this on next page).</label>
          <div class="mt-4">
            <input v-model="pelNum" name="pelNum" id="pelNum" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your unique PEL number" />
          </div>
        </div>
        <!-- Feedback -->
        <div class="my-4">
          <label for="feedback" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Give 2-3 sentences about how we helped you acheive your license and anything that you want to share to the Philippine Aircraft Mechanic community. We appreciate your positive feedback.</label>
          <div class="mt-2">
            <textarea v-model="feedback" id="feedback" name="feedback" rows="3"
              class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
          </div>
        </div>
        <!-- Inspiring Comment -->
        <div class="my-4">
          <label for="inspiringComment" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Give 2-3 sentences if you have any constructive criticism for Skilltech and its platform. We want to improve on ourselves, please let us know.</label>
          <div class="mt-2">
            <textarea v-model="inspiringComment" id="inspiringComment" name="inspiringComment" rows="3"
              class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <!-- Alert! -->
        <div class="my-4">
          <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-8" role="alert">
            <div class="flex">
              <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
              <div>
                <p class="font-bold">We will use your uploaded Skilltech profile picture.</p>
                <p class="text-sm">Please make sure you upload a profile picture that we can use. If not, your submission for claiming your reward might not be approved.</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Checkbox -->
        <div class="my-4 pb-4">
          <div class="flex items-center my-8">
            <!-- <input v-model="canPostResults" @click="canPostResults = !canPostResults" id="canPostResults" name="canPostResults" type="checkbox" class="cursor-pointer border-2 focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" /> -->
            <!-- <p class="ml-3 text-xl dark:text-gray-400 text-gray-600 leading-8">I'm okay sharing my results on social media so others will be inspired. <button @click="showSample = !showSample" class="text-yellow-400 hover:opacity-80"> {{ showSample ? 'Hide' : 'See'}} sample</button>.</p> -->
            <p class="text-xl dark:text-gray-400 text-gray-600 leading-8">By proceeding, you are agreeing to post your exam results to our social media. </p>
            <!-- Click <button @click="showSample = !showSample" class="text-yellow-400 hover:opacity-80">here</button> to {{ (!showSample) ? 'see' : 'hide' }} the sample below. -->
          </div>

          <div v-if="showSample" class="flex justify-center">
              <img class=" w-full md:w-96" src="https://scontent.fdar7-1.fna.fbcdn.net/v/t39.30808-6/387820391_855840439576162_3858669466049857091_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFPuzgaTV_Zc1ElLriWDx5xbzwDKNEtLnFvPAMo0S0ucQew4mZHndCaH5zktZBVaIJsmL5om72mTfIK0zWKHG_2&_nc_ohc=RAm3BHeTJ0wAX-Y85pJ&_nc_ht=scontent.fdar7-1.fna&oh=00_AfCXl-dC_3Hlvj7sGRLT_eZj7YDVgZacPA76HkedwKsnZA&oe=65675B95" alt="sample image">
          </div>
        </div>

        <div class="flex flex-col">

          <template v-if="softBlockUserInfo.data && softBlockUserInfo.data.postExamSurveyStatus == 'waiting'">
            <button type="button" @click="currentPage = 3"
              :disabled="!canProceedToPage2"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              Continue
            </button>
            <button type="button" @click="currentPage = 1"
              class="my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Go back
            </button>

          </template>
          <template v-else>
            <button type="button"
              @click="resubmitPostExamSurveyForm()"
              :disabled="!canProceedToPage2 || isResubmittingPostExamSurveyForm"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              <span v-if="!isResubmittingPostExamSurveyForm">Submit</span>
              <span v-else>Submitting...</span>
            </button>
            <button type="button" @click="isSubmitted = true"
              :disabled="isResubmittingPostExamSurveyForm"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Cancel
            </button>
          </template>
          
        </div>
      </div>

      <!-- PAGE 3 -->
      <div v-else-if="currentPage == 3" id="page-3" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
        <p v-if="softBlockUserInfo.data && softBlockUserInfo.data.achievementBoxStatus == 'waiting'" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">
          Lastly, we'd like to know your shipping address so we know where to send your Acheivement Box.
        </p>
        <div v-else>
          
          <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8" role="alert">
            <p class="font-bold">Your submission has been rejected!</p>
            <p>It seems like that there was a problem with the information you submitted. </p>
            <br>
            <p class="text-sm font-semibold">Possible causes of rejection:</p>
            <ul class=" list-disc ml-5 text-sm">
              <li>Unclear or incomplete details.</li>
            </ul>
            <p class="mt-6 text-sm">Please review and make sure that you fill out all fields correctly. If you have any specific question, reach out to us via email at support@skilltech.ph or Facebook Messenger.</p>
          </div>

        </div>
        <!-- Country -->
        <!-- <div>
          <label for="country" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Country:</label>
          <div class="mt-4">
            <input v-model="country" name="country" id="country" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your country" />
          </div>
        </div> -->
        <!-- address line 2 (Optional)-->
        <div>
          <label for="addressline2" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Your Complete Delivery Address:</label>
          <div class="mt-4">
            <input v-model="addressline2" name="addressline2" id="addressline2" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your complete delivery address" />
          </div>
        </div>
        <!-- State / Province -->
        <div>
          <label for="province" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">State/Province:</label>
          <div class="mt-4">
            <input v-model="province" name="province" id="province" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your province" />
          </div>
        </div>
        <!-- City / Municipality -->
        <div>
          <label for="city" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">City/Municipality:</label>
          <div class="mt-4">
            <input v-model="city" name="city" id="city" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your city/municipality" />
          </div>
        </div>
        <!-- Barangay -->
        <div>
          <label for="barangay" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Barangay:</label>
          <div class="mt-4">
            <input v-model="barangay" name="barangay" id="barangay" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your barangay" />
          </div>
        </div>
        <!-- address line 1 (House No./Bldg./Street)-->
        <div>
          <label for="addressline1" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">House No./Bldg./Street:</label>
          <div class="mt-4">
            <input v-model="addressline1" name="addressline1" id="addressline1" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your House No./Bldg./Street" />
          </div>
        </div>
        
        
        <div>
          <label for="zip" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Zip Code:</label>
          <div class="mt-4">
            <input v-model="zip" name="zip" id="zip" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your zip code" />
          </div>
        </div>

        <div>
          <label for="phone" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">For the Delivery Person, they can contact me at this number:</label>
          <div class="mt-4">
            <input v-model="phone" name="phone" id="phone" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input 11 Digit Phone Number" />
          </div>
        </div>

        <div class="flex flex-col">
          
          <template v-if="softBlockUserInfo.data && softBlockUserInfo.data.achievementBoxStatus == 'waiting'">
            <button type="button" @click="submitResults()"
              :disabled="!canSubmitResults || isSubmitting"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              <span v-if="!isSubmitting">Submit Results</span>
              <span v-else>Submitting Results...</span>
            </button>
            <button type="button" @click="currentPage = 2"
              :disabled="isSubmitting"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Go back
            </button>
            
          </template>
          <template v-else>
            <button type="button"
              @click="resubmitAchievementBoxForm()"
              :disabled="!canSubmitResults || isResubmittingAchievementBoxForm"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
              <span v-if="!isResubmittingAchievementBoxForm">Submit</span>
              <span v-else>Submitting...</span>
            </button>
            <button type="button" @click="isSubmitted = true"
              :disabled="isResubmittingAchievementBoxForm"
              class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Cancel
            </button>
          </template>

        </div>
      </div>

      <!-- PAGE 4 (Request to extend subscription instruction!) -->
      <div v-else-if="currentPage == 4" id="page-3" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
        
        <div>
          <h2 class=" font-bold text-3xl">We might extend your subscription.</h2>
          <br>
          <p>Please be aware that each request is subject to an approval process. To consider your appeal for an extension, you are required to provide a detailed explanation regarding the reasons for not completing the exam within the allocated time frame.</p>
          <br>
          <p >Additionally, it is imperative to include relevant documentation or evidence supporting your appeal. This may include but is not limited to medical certificates, official notices, proof of employment (if employed), or other verifiable documents that substantiate your claim.</p>
          <br/>
          <p>Please send your detailed appeal to <a href="mailto:support@skilltech.ph" class=" text-yellow-600">support@skilltech.ph</a> using your Skilltech account email address with the following subject: <em>"Appeal for Extension"</em>. Our team will review your request and respond accordingly. Note that the decision to grant an extension is at the discretion of our review team and is not guaranteed.</p>
          <br/>
          <p>We will respond within 48 hours. We appreciate your cooperation; we will make sure that you'll stay committed to getting your CAAP License.</p>
        </div>

        <div class="flex flex-col">
          <button type="button" @click="currentPage = 1"
            class="my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
            Go back
          </button>
        </div>

      </div>

    </main>

    <!-- THANK YOU VIEW -->
    <main v-else>
      <div id="submitted-page" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
        <h2 class=" font-bold text-xl">Thank you for your submission!</h2>
        <p>Please wait while we are reviewing the data you've submitted.</p>

        <div class="flex flex-col md:flex-row gap-4">
          <div class=" rounded-md border border-warm-gray-400 dark:border-warm-gray-600 p-2 md:w-1/2">
            <div class="border-b border-warm-gray-400 dark:border-warm-gray-600 p-1 font-semibold text-yellow-500 mb-2">
              Post Exam Sruvey
            </div>
            <div v-if="softBlockUserInfo.data && softBlockUserInfo.data.postExamSurveyStatus == 'submitted'" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
              <p class=" font-bold flex justify-start">
                <svg class="fill-current h-6 w-6 text-yellow-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                Submitted
              </p>
            </div>
            <div v-if="softBlockUserInfo.data && softBlockUserInfo.data.postExamSurveyStatus == 'rejected'" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
              <p class="font-bold">⚠ Rejected</p>
              <p>Seems that there was a problem on your submission. Please make sure that you fill out all fields correctly. <span @click="populatePostExamSurveyForm(); isSubmitted = false; currentPage = 2" class=" text-blue-400 underline cursor-pointer">Fill out form again</span></p>
            </div>
            <div v-if="softBlockUserInfo.data && softBlockUserInfo.data.postExamSurveyStatus == 'approved'" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
              <p class="font-bold">✓ Approved</p>
            </div>

            <div class="p-1">
              <p>Date submitted: <span class=" text-warm-gray-400">{{ postExamSurveyInfo && formatDate(postExamSurveyInfo.metadata.submittedOn) }}</span> </p>
              <p>Date updated: <span class=" text-warm-gray-400">{{ postExamSurveyInfo && formatDate(postExamSurveyInfo.metadata.updatedOn) }}</span></p>
            </div>
          </div>

          <div class=" rounded-md border border-warm-gray-400 dark:border-warm-gray-600 p-2 md:w-1/2">
            <div class="border-b border-warm-gray-400 dark:border-warm-gray-600 p-1 font-semibold text-yellow-500 mb-2">
              Achievement Box
            </div>
            
            <div v-if="softBlockUserInfo.data && softBlockUserInfo.data.achievementBoxStatus == 'submitted'" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
              <p class=" font-bold flex justify-start">
                <svg class="fill-current h-6 w-6 text-yellow-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                Submitted
              </p>
            </div>
            <div v-else-if="softBlockUserInfo.data && softBlockUserInfo.data.achievementBoxStatus == 'rejected'" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
              <p class="font-bold">⚠ Rejected</p>
              <p>Seems that there was a problem on your submission. Please make sure that you fill out all fields correctly. <span @click="populateAchievementBoxForm(); isSubmitted = false; currentPage = 3" class=" text-blue-400 underline cursor-pointer">Fill out form again</span></p>
            </div>
            <div v-else-if="softBlockUserInfo.data.achievementBoxStatus == 'approved'" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
              <p class="font-bold">✓ Approved</p>
            </div>
            <div v-else class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">

              <div class="flex">
                <div class=""><svg class="fill-current h-6 w-6 text-blue-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                <div v-if="achievementBoxInfo">
                  <p class="font-bold capitalize">{{ softBlockUserInfo.data.achievementBoxStatus }}</p>
                </div>
              </div>

            </div>
            <div class="p-1">
              <p>Date submitted: <span class=" text-warm-gray-400">{{ achievementBoxInfo && formatDate(achievementBoxInfo.metadata.submittedOn) }}</span> </p>
              <p>Date updated: <span class=" text-warm-gray-400"> {{ achievementBoxInfo && formatDate(achievementBoxInfo.metadata.updatedOn) }} </span></p>
            </div>
          </div>

        </div>

        <div v-if="softBlockUserInfo.data.achievementBoxStatus == 'shipped' || softBlockUserInfo.data.achievementBoxStatus == 'received'">
          <div v-if="achievementBoxInfo" class="mt-10">
            <h2 class=" font-bold text-xl mb-4">Your achievement box has been shipped!</h2>
            <p>Your achievement box has been shipped to the address you have provided. Please find the shipment details below.</p>
            
            <p class="text-sm mt-3">
              <span class="font-semibold text-warm-gray-50">Courier:</span> {{ achievementBoxInfo.courierName }}
            </p>
            <p class="text-sm mt-1">
              <span class="font-semibold text-warm-gray-50">Tracking #:</span> {{ achievementBoxInfo.trackingNum }}
            </p>
            <p class="text-sm mt-1">
              <span class="font-semibold text-warm-gray-50">Tracking URL:</span> <a :href="achievementBoxInfo.trackingUrl" target="_blank" class=" underline "> {{ achievementBoxInfo.trackingUrl }} </a>
            </p>
            <p class="text-sm mt-1">
              <span class="font-semibold text-warm-gray-50">Shipped by:</span> {{ achievementBoxInfo.metadata.shippedBy }}
            </p>
            <p class="text-sm mt-1">
              <span class="font-semibold text-warm-gray-50">Shipped date:</span> {{ formatDate(achievementBoxInfo.metadata.shippedOn) }}
            </p>

            <p v-if="softBlockUserInfo.data.achievementBoxStatus == 'received'" class="text-sm mt-5">
              <span class="font-semibold text-warm-gray-50">Date Received:</span> {{ formatDate(achievementBoxInfo.metadata.receivedOn) }}
            </p>

            <button 
              v-else
              @click="itemReceived()"
              type="button" 
              :disabled="isChangingStatusToReceiving"
              class="disabled:cursor-default disabled:opacity-25 px-2 py-1 border border-yellow-800 rounded mt-5 bg-yellow-700 w-full lg:w-52">
              {{ isChangingStatusToReceiving ? 'Updating status...' : 'I have received the item' }} 
            </button>
            
          </div>
        </div>
    
      </div>
    </main>

  </div>
</template>

<script setup>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const db = firebase.firestore();

import moment from "moment";

import { useRouter } from 'vue-router';
const router = useRouter()

import { computed, ref, onMounted } from 'vue'
import LoadingScreen from "@/components/appshell/LoadingScreen.vue"
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const options = [
  { id: 1, name: 'I already passed my CAAP License Exam.', description: 'I want to share my results and claim my exclusive reward.' },
  { id: 2, name: 'I haven\'t taken or finished my CAAP License Exam yet.', description: 'I want to submit the proof of my upcoming license exam.' },
]

const currentPage = ref(1);

const selected = ref(options[0])
const isLoading = ref(false)
const isSubmitting = ref(false)
const isSubmitted = ref(false)
const isChangingStatusToReceiving = ref(false)
const isResubmittingPostExamSurveyForm = ref(false)
const isResubmittingAchievementBoxForm = ref(false)

const authUserId = ref(null);
const softBlockUserInfo = ref({
  docId: null,
  data: null
});
const postExamSurveyInfo = ref(null);
const achievementBoxInfo = ref(null);

// variables for page 2 (Post Exam Survey)
// const canPostResults = ref(true)
const test_results = ref(null)
const tmpFile = ref(null)
const pelNum = ref('')
const feedback = ref('')
const inspiringComment = ref('')

// variables for page 3 (achievement box)
const name = "John"
// const country = ref(null)
const province = ref(null)
const city = ref(null)
const barangay = ref(null)
const addressline1 = ref(null)
const addressline2 = ref(null)
const zip = ref(null)
const phone = ref(null)

// ui flags
const showSample = ref(false)

const canProceedToPage2 = computed( () => {
  if(tmpFile.value == null || test_results.value == null || feedback.value == '' ||  inspiringComment.value == '' || pelNum.value == ''){
    return false
  }else{
    return true
  }
})

const canSubmitResults = computed( () => {
  if(
    !province.value || 
    !city.value || 
    !barangay.value || 
    !addressline1.value ||
    !zip.value ||
    !phone.value 
  ){
    return false
  }else{
    // check if a number is exactly 11 digits long
    return String(phone.value).length === 11 && /^\d+$/.test(String(phone.value));
  }
})

const handlePageOneContinueButton = () => {

  switch (selected.value.id) {
    case 1:
        // currentPage.value = 2;
        // reroute to reward/scorecard page
        router.push('/reward');
      break;
    case 2:
        // go to page 4: How to appeal for extension page..
        currentPage.value = 4;
      break;
  }

}

onMounted(async () => {
  
  isLoading.value = true;
  authUserId.value = firebase.auth().currentUser.uid;

  await getSoftBlockUserInfo();

  // check if the user is in caap_blocks collection or already fill out the old assessment form (before scorecard implementation)
  if(softBlockUserInfo.value.data){
    // check if soft block user status, to know which page to show
    if (softBlockUserInfo.value.data.status == 'appealed' || softBlockUserInfo.value.data.status == 'unblocked') {
      isSubmitted.value = true;
    }else if (softBlockUserInfo.value.data.status == 'blocked' && softBlockUserInfo.value.data.postExamSurveyStatus != 'waiting' && softBlockUserInfo.value.data.achievementBoxStatus != 'waiting') {
      // meaning, admin rejected one or both of the form submitted
      isSubmitted.value = true;
    }
  }

  isLoading.value = false;
})

const getClaimantsInfo = async (docId) => {

  return await db.collection("claimants").doc(docId).get()
      .then((doc) => {
        if (doc.exists) {
          return {
            id: docId,
            data: doc.data()
          }
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        return null;
      });

}

const getSoftBlockUserInfo = async () => {

  const query = db.collection('caap_blocks').where('userId', '==', authUserId.value);
  await query.get()
    .then((querySnapshot) => {
      querySnapshot.forEach(async (doc) => {

        softBlockUserInfo.value = {
          docId: doc.id,
          data: doc.data()
        }

        await Promise.all([
          getPostExamSurveyInfo(softBlockUserInfo.value.data.userId),
          getAchievementBoxInfo(softBlockUserInfo.value.data.userId)
        ]);

      });
    })
    .catch((error) => {
      console.log('Error getting documents from soft blocks collections: ', error);
    });

}

const getPostExamSurveyInfo = async (docId) => {
  
    await db.collection("post_exam_survey").doc(docId).get()
      .then((doc) => {
        if (doc.exists) {
          postExamSurveyInfo.value = doc.data();
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
    
}
  
const getAchievementBoxInfo = async (docId) => {
  
  await db.collection("achievement_box").doc(docId).get()
  .then((doc) => {
    if (doc.exists) {
      achievementBoxInfo.value = doc.data();
    }
  })
  .catch((error) => {
    console.error('Error getting document:', error);
  });

}

const submitResults = async () => {

  isSubmitting.value = true

  const [saveAchievementBoxSuccess,saveTestResultSuccess] = await Promise.all([
      saveAchievementBox(authUserId.value),
      saveTestResult(authUserId.value)
  ]);

  if (saveAchievementBoxSuccess || saveTestResultSuccess) {
      // update status for achievement box and post exam result in soft block collection
      var docRef = firebase.firestore().collection("caap_blocks").doc(softBlockUserInfo.value.docId);
      await docRef.update({
        achievementBoxStatus: saveAchievementBoxSuccess ? 'submitted' : 'waiting',
        postExamSurveyStatus: saveTestResultSuccess ? 'submitted' : 'waiting', 
        status: 'appealed'
      })

      await getSoftBlockUserInfo();

      console.log('Result submitted!')
  }

  isSubmitting.value = false
  isSubmitted.value = true

}

const saveTestResult = async (uid) => {

  let storageRef = firebase.storage().ref();
  let testResultImagesRef = storageRef.child(`testresults/${uid}.jpg`);
  let toRet = false;


  if(tmpFile.value){

      await testResultImagesRef.put(tmpFile.value).then(async (snapshot) => {
         await snapshot.ref.getDownloadURL().then( async (downloadURL) => {
              // Save Post Exam Survey data
              await saveTestResultData(uid, downloadURL);
              toRet = true;
          })
      })
  }

  return toRet;

}

const saveTestResultData = async (uid, resultImageUrl) => {

  await db.collection("post_exam_survey").doc(uid).set({
        userId: uid,
        status: 'submitted',
        shareOptOut: true,
        resultsUrl: resultImageUrl,
        inspiringComment: inspiringComment.value,
        platformFeedback: feedback.value,
        metadata:{
          submittedOn: moment().valueOf().toString(),
          updatedOn: moment().valueOf().toString(),
          approvedOn: null,
          approvedBy: null,
        }
    })
  .then(() => { 
      console.log('Post Exam Survey Results submitted!');
  })
  .catch((error) => { 
    console.error("Error submitting Post Exam Survey: ", error)
    isSubmitting.value = false
  });

}

const saveAchievementBox = (uid) => {

  // Create and return a Promise
  return new Promise((resolve, reject) => {

      db.collection("achievement_box").doc(uid).set({

          userId: uid,
          status: 'submitted',
          pelNum: pelNum.value,

          shippingAddress: {
            province: province.value,
            city: city.value,
            barangay: barangay.value,
            addressLine1: addressline1.value,
            addressLine2: addressline2.value,
            zip: zip.value,
          },
          phone: phone.value,
          trackingNum: null,
          trackingUrl: null,
          courierName: null,
          metadata:{
            submittedOn: moment().valueOf().toString(),
            updatedOn: moment().valueOf().toString(),
            approvedOn: null,
            approvedBy: null,
            shippedOn: null,
            shippedBy: null,
            receivedOn: null,
          }

      })
    .then(() => { 
      console.log('Achievement Box form submitted!')
      resolve(true);
    })
    .catch((error) => { 

      console.error("Error submitting Achievement Box: ", error)
      reject(false);
    });

  });
  
}

const itemReceived = async () => {

  isChangingStatusToReceiving.value = true;

  // update achievement_box collection
  await db.collection('achievement_box').doc(achievementBoxInfo.value.userId).update({
    status: 'received',
    'metadata.receivedOn': moment().valueOf().toString()
  })

  // update soft_block collection
  await db.collection('caap_blocks').doc(softBlockUserInfo.value.docId).update({
    achievementBoxStatus: 'received',
  })

  await getSoftBlockUserInfo();
  isChangingStatusToReceiving.value = false;

}

const populatePostExamSurveyForm = () => {
  pelNum.value = achievementBoxInfo.value.pelNum
  feedback.value = postExamSurveyInfo.value.platformFeedback
  inspiringComment.value = postExamSurveyInfo.value.inspiringComment
}

const populateAchievementBoxForm = () => {

  // country.value = achievementBoxInfo.value.shippingAddress.country
  province.value = achievementBoxInfo.value.shippingAddress.province
  city.value = achievementBoxInfo.value.shippingAddress.city
  barangay.value = achievementBoxInfo.value.shippingAddress.barangay
  addressline1.value = achievementBoxInfo.value.shippingAddress.addressLine1
  addressline2.value = achievementBoxInfo.value.shippingAddress.addressLine2
  zip.value = achievementBoxInfo.value.shippingAddress.zip
  phone.value = achievementBoxInfo.value.phone

}

const resubmitPostExamSurveyForm = async () => {

  isResubmittingPostExamSurveyForm.value = true;

  // save uploaded file result 
  let storageRef = firebase.storage().ref();
  let testResultImagesRef = storageRef.child(`testresults/${postExamSurveyInfo.value.userId}.jpg`);

  if(tmpFile.value){

      await testResultImagesRef.put(tmpFile.value).then(async (snapshot) => {
         await snapshot.ref.getDownloadURL().then( async (downloadURL) => {
              
            // update post_exam_survey collection
            await db.collection('post_exam_survey').doc(postExamSurveyInfo.value.userId).update({
              status: 'submitted',
              resultsUrl: downloadURL,
              inspiringComment: inspiringComment.value,
              platformFeedback: feedback.value,
              'metadata.updatedOn': moment().valueOf().toString(),
            })

            // update achievement_box collection for PEL number
            await db.collection('achievement_box').doc(postExamSurveyInfo.value.userId).update({
              pelNum: pelNum.value,
              'metadata.updatedOn': moment().valueOf().toString(),
            })

            // update soft_block collection
            await db.collection('caap_blocks').doc(softBlockUserInfo.value.docId).update({
              postExamSurveyStatus: 'submitted',
              status: 'appealed'
            })

            await getSoftBlockUserInfo();
              
          })
      })
  }

  isSubmitted.value = true
  isResubmittingPostExamSurveyForm.value = false

}

const resubmitAchievementBoxForm = async () => {

  isResubmittingAchievementBoxForm.value = true;

  // update achievement_box collection
  await db.collection('achievement_box').doc(achievementBoxInfo.value.userId).update({
    status: 'submitted',
    // 'shippingAddress.country': country.value,
    'shippingAddress.province': province.value,
    'shippingAddress.city': city.value,
    'shippingAddress.barangay': barangay.value,
    'shippingAddress.addressLine1': addressline1.value,
    'shippingAddress.addressLine2': addressline2.value,
    'shippingAddress.zip': zip.value,
    phone: phone.value,
    'metadata.updatedOn': moment().valueOf().toString(),
  })

  // update soft_block collection
  await db.collection('caap_blocks').doc(softBlockUserInfo.value.docId).update({
    achievementBoxStatus: 'submitted',
    status: 'appealed'
  })

  await getSoftBlockUserInfo();

  isSubmitted.value = true
  isResubmittingAchievementBoxForm.value = false;

}


// helper functions

const someHandler = (event) => {
  console.log("User updated the file")
  // tempFileName
  // console.log('Selected file: ' + event.target.files.item(0).name);
  // console.log('Selected file: ' + event.target.files.item(0).size);
  // console.log('Selected file: ' + event.target.files.item(0).type);
  
  var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
  
  console.log(`File size: ${filesize}MB`)
  
  if(filesize > 2){
      alert("File size of 2MB exceeded")
      // this.emptyFile()
      test_results.value = null 
  }else{
      test_results.value = event.target.files.item(0).name
      tmpFile.value = event.target.files.item(0)
      // console.log(event)
  }
  

}

const formatDate = (timestamp) => {

  if (timestamp) {
    const dateObject = new Date(parseInt(timestamp));
    return moment(dateObject).format("MMM. D, YYYY");
  }else{
    return '-'
  }

}


</script>

<style scoped>
  
  table, th, td {
    @apply border border-warm-gray-500 text-center;
  }

</style>
<script setup>
import firebase from '@/firebase/config';
import { onMounted, ref, computed } from 'vue';
import ROUTER from '@/router';
import LoadingCircle from '../components/LoadingCircle.vue';
import Announcement from '../components/Announcement.vue';
import { ChevronDoubleLeftIcon } from "@heroicons/vue/solid";

const isInitializing = ref(true); // if the page is being loaded, we set this to true. purpose: skeleton loader, etc.
const subjects = ref([]); // list of subjects. this is returned from the database without order.
const sortedSubjects = computed(() => subjects.value.sort((a, b) => a.order - b.order)); // this is a computed property wherein we sort the subjects according to an order that we set.

/**
 * Function that interfaces to the DB to fetch the subjects.
 */
const fetchCaap101Subjects = () => {
    return new Promise(async (resolve, reject) => {
        firebase.firestore().collection("reviewers").doc("caap-101").collection("subjects").get()
            .then((querySnapshot) => {
                var subjects = [];
                querySnapshot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    subjects.push(data)
                });
                resolve(subjects)
            })
            .catch((error) => {
                console.error("Error getting subjects data: ", error);
                reject(error)
            })
    })
}

/**
 * When the component or view is mounted, we fetch the caap 101 subjects
 * and create order on how they will be displayed in the UI.
 */
onMounted(async () => {
    subjects.value = await fetchCaap101Subjects();
    addOrderToSubjects();
    isInitializing.value = false;
})

/**
 * Since the subjects array returned from the database does not have order,
 * we can explicitly tell how the subjects should be arranged here.
 * 
 * @hack : we know exactly what the subject ids are and place them in an array
 * the way we want them to be ordered.
 * 
 */
const addOrderToSubjects = () => {
    const orderedSubjects = [
        'caap-101-general-information',
        'caap-101-the-license-exam',
        'caap-101-exam-study-tips',
    ]
    orderedSubjects.forEach((subjectId, orderIndex) => {
        let foundIndex = subjects.value.findIndex(e => e.id == subjectId)
        subjects.value[foundIndex].order = orderIndex

        /**
         * @hack @todo @junrey
         * I will be hard coding the number of subtopics here.
         * Let's refactor for later.
         */
        let numberOfSubtopics;
        let isHighlighted = false;
        switch (orderIndex) {
            case 0:
                numberOfSubtopics = 5;
                break;
            case 1:
                numberOfSubtopics = 6;
                isHighlighted = true;
                break;
            case 2:
                numberOfSubtopics = 5;
                break;
            default:
                break;
        }
        subjects.value[foundIndex].numberOfSubtopics = numberOfSubtopics;
        subjects.value[foundIndex].isHighlighted = isHighlighted;

    });
}

const handleViewSubtopicsClick = (subject) => {
    ROUTER.push({ path: `/caap-101/subtopics`, query: { subjectId: subject.id, subjectName: subject.name } })
}

</script>

<template>
    <div class="max-w-md mx-auto py-2">
        <Announcement />
        <div class="space-y-6 mb-20 px-4">
            <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                <div class="flex items-center">
                    <router-link to="/reviewers"
                        class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center"
                        aria-current="page">
                        <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                    </router-link>
                </div>
                <div class="text-sm font-medium text-warm-gray-500">
                    CAAP 101
                </div>
                <div class="w-6 h-6"></div>
            </nav>
            <div class="caap-101">
                <div v-if="!isInitializing" class="card-list">
                    <ul v-for="subject in sortedSubjects" :key="subject.id">
                        <div class="card-base">
                            <div class="p-6">
                                <h1 class="card-heading">{{ subject.name }}</h1>
                                <p class="card-description">{{ subject.description }}</p>
                                <p class="card-tags">
                                    <span class="badge-base badge-base--indigo">
                                        {{ subject.numberOfSubtopics }} Subtopics
                                    </span>
                                    <span v-if="subject.isHighlighted" class="badge-base badge-base--yellow">
                                        🔥 Must Read
                                    </span>
                                </p>

                            </div>
                            <div class="card-footer">
                                <div class="text-sm flex justify-between">
                                    <button class="card-button" type="button"
                                        @click="handleViewSubtopicsClick(subject)">Browse
                                        Subtopics</button>
                                </div>

                            </div>
                        </div>

                    </ul>
                </div>
                <div v-else>
                    <LoadingCircle />
                </div>

            </div>
        </div>
    </div>


</template>

<style scoped>
.caap-101 {
    @apply text-warm-gray-700 dark:text-warm-gray-400
}

.caap-101 .card-list {
    @apply space-y-8
}

.caap-101 .card-base {
    @apply text-base rounded-2xl border border-true-gray-400 dark:border-true-gray-700 dark:bg-true-gray-800 dark:bg-opacity-50 bg-white border-opacity-50 dark:border-opacity-50
}

.caap-101 .card-heading {
    @apply text-xl font-medium dark:text-warm-gray-400 text-warm-gray-800
}

.caap-101 .card-description {
    @apply text-sm text-warm-gray-500 mt-4
}

/* .card-footer {
    @apply dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl
} */

/* .card-footer {
    @apply px-6 pb-5
} */

.card-footer {
    @apply dark:bg-true-gray-700 dark:bg-opacity-25 bg-gray-50 flex-1 min-w-0 px-6 py-3 rounded-b-2xl
}

/* .caap-101 .card-button {
    @apply border border-true-gray-300 dark:border-true-gray-700 px-4 dark:bg-true-gray-800 py-2 rounded-md hover:opacity-75 transition-all w-full
} */

.card-tags {
    @apply text-sm text-warm-gray-500 mt-4 space-x-3
}

.caap-101 .card-button {
    @apply font-medium dark:text-yellow-400 dark:hover:text-yellow-300 text-yellow-600 hover:text-yellow-700
}

.badge-base {
    @apply inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset
}

.badge-base--indigo {
    @apply bg-indigo-400 bg-opacity-10 text-indigo-400 ring-indigo-400 ring-opacity-30
}

.badge-base--yellow {
    @apply bg-yellow-400 bg-opacity-10 text-yellow-500 ring-yellow-400 ring-opacity-30 dark:text-yellow-400
}
</style>
<script setup>
import { ref, computed } from 'vue'
import Leaderboard from './Leaderboard.vue'
import S2PrizeModal from "@/components/modals/S2PrizeModal"
import S1PrizeModal from "@/components/modals/S1PrizeModal"

const tabs = [
    { name: 'All Time' },
    { name: 'Seasons' },
]

const seasons = [
    { name: 'Season 1' },
    { name: 'Season 2' },
]

const currentTabIndex = ref(0)

const users_season1 = [
    {
        id: 'user-1',
        name: 'Kristian Garcia',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '322,670',
        rank: '1st',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FvFfZxOi3LFR35XpJku3gM4HXdAk2.jpg?alt=media&token=b09dbc73-3b3c-45ba-b1e7-e44fc39c529f`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-2',
        name: 'Justerrini Kyle Cayanan',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '257,640',
        rank: '2nd',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FzeI1cREvvuYYzHpDRZW7qFcDzTB3.jpg?alt=media&token=c97d0c6f-113d-4736-b8d7-e3f5ccd12807`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-3',
        name: 'Jireh Pancovilla',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '201,650',
        rank: '3rd',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FRyZMSUJeZeV3WA6tolpsgrU8RFx2.jpg?alt=media&token=246ddc26-264b-4150-b89f-5ff3a0d3e83c`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-4',
        name: 'Syth Loiz Ochea',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '161,350',
        rank: '4th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2F5PzrZT8uxoRiL4IogSJ1k5CMk3k2.jpg?alt=media&token=e1121e78-829a-499c-9765-0572edbdac20`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
    {
        id: 'user-5',
        name: 'Jhon Lloyd Vien De luna',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '95,710',
        rank: '5th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FxMX7PqLZTbQTruGbhCFYlAxiNLs1.jpg?alt=media&token=5434abfc-75d2-4c7c-87b5-a3c3792fdc9e`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-6',
        name: 'Micah Joy Elora Mendoza',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '93,250',
        rank: '6th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2Ffh2NohShwFVx3Vl4MMPGWROKrI93.jpg?alt=media&token=45b38f31-19ea-473b-958d-62b92a3e47e1`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-7',
        name: 'John Christopher Golimlim',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '83,010',
        rank: '7th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2Fa6DD2OiGfDVLcj57x0oon8zlROJ2.jpg?alt=media&token=d3a97a5f-b8cf-4e46-bc26-d06276241b67`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-8',
        name: 'Florence Espalago',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '79,890',
        rank: '8th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2F4JAWaFiYFKaI8lnHapg1Z8Nn6Co2.jpg?alt=media&token=e7363d86-672a-47b1-91fb-6fcce38165f5`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-9',
        name: 'John Arild Guia',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '67,960',
        rank: '9th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FRj9bQZSrJIP3Zyc1LAZjwgxk1z03.jpg?alt=media&token=4cad9270-9b55-4e68-8367-c3855bab4465`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
    {
        id: 'user-10',
        name: 'Carl John Butil',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '63,220',
        rank: '10th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FvcvHwrvdabNvSNL8lrGAn1JTcqM2.jpg?alt=media&token=9b193346-f98e-4e0a-bd1a-efb64cf1d110`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
]

const users_season2 = [
    {
        id: 'user-1',
        name: 'Daniel Adrian Falaminiano',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '1,562,342',
        rank: '1st',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FKWSf9jWmE9fcAd3Yx8WE1S5qAow2.jpg?alt=media&token=e617ffe7-f476-463f-8a78-b7a12abda2eb`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
    {
        id: 'user-2',
        name: 'Arnulfo Quinio Jr.',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '1,473,319',
        rank: '2nd',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FGauBIzS3EkeGXxvjvQkf4D2gnQf1.jpg?alt=media&token=a35b2b86-d62e-4d91-9fc2-574b0e1cb2b8`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
    {
        id: 'user-3',
        name: 'David Martin Consul',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '980,230',
        rank: '3rd',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2F24Zno1Vsw2VIT1ZRw7Za6Bh0mcF3.jpg?alt=media&token=a3e333e6-34b0-4164-9e4c-bde7201611b1`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-4',
        name: 'Jason Mentoy',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '322,300',
        rank: '4th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FqwDwOrMDLUQV9bhW5xDJ5EPYde62.jpg?alt=media&token=f955ce62-a27c-4ab4-b89c-89874893648e`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-5',
        name: 'Jan Christian Bangoy',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'PATTS College of Aeronautics (PATTS)',
        skillpoints: '251,694',
        rank: '5th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FlP4e3bbNkMfPrb7zf0qkavYBF8P2.jpg?alt=media&token=8fe8540d-17f4-4332-937d-018ac1d6eba2`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2FBvQd8D4ecp0yM8oQzleD.jpg?alt=media&token=260113c8-9b5d-46f9-8797-933a6a222b7f'
    },
    {
        id: 'user-6',
        name: 'Marvic Tizon',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '105,064',
        rank: '6th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FQmAGbO4xJrfYtIddVznqXXTwuqe2.jpg?alt=media&token=b13a8c3b-9866-413a-9157-63581dff9012`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-7',
        name: 'Jack Lord Yu',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Indiana Aerospace University (IAU)',
        skillpoints: '101,325',
        rank: '7th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2F6MABDtHX0vUeGPiNWCTbtKQcI4H2.jpg?alt=media&token=d46d764d-ffdf-4887-b80a-7859f9e1e90e`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F7xqRwAqIBZ8GJXjTGBeN.jpg?alt=media&token=bea65ba0-1a8b-41fa-928a-20f46f5082d7'
    },
    {
        id: 'user-8',
        name: 'Kim Noel Castillo',
        course: 'Aircraft Avionics/Electrical (AVT)',
        school: 'Philippine State College of Aeronautics (PHILSCA)',
        skillpoints: '91,680',
        rank: '8th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FD1xwbpQQbVenmYswgaUh48pxkrG2.jpg?alt=media&token=e803b533-a347-42cd-bccd-b302e95c8567`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2F0s1UwTVMm37H4T2G1fjW.jpg?alt=media&token=da516f0a-164f-4f7e-b559-18c36868029e'
    },
    {
        id: 'user-9',
        name: 'Christopher Lim',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'Flight Dynamics School of Aeronautics (FD)',
        skillpoints: '87,593',
        rank: '9th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FZeDMP7SSaqS2R6hhekYeFnvtiRP2.jpg?alt=media&token=373b2c5d-fbb6-4096-9809-871320352e53`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2FWml7pw0RAF2wUfRIvHwX.jpg?alt=media&token=9d374b8a-21fb-446e-8865-f6d114e6af4a'
    },
    {
        id: 'user-10',
        name: 'Daryl Joseph Janda',
        course: 'Aircraft Maintenance Technology (AMT)',
        school: 'PATTS College of Aeronautics (PATTS)',
        skillpoints: '68,309',
        rank: '10th',
        userPhoto: `https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fusers%2FzUlR3OoOF6fwPvvijieaDQ89BvG2.jpg?alt=media&token=04184117-4e45-476f-ac0f-a076bf5b7275`,
        schoolPhoto: 'https://firebasestorage.googleapis.com/v0/b/skilltech-dev.appspot.com/o/images%2Fschools%2FBvQd8D4ecp0yM8oQzleD.jpg?alt=media&token=260113c8-9b5d-46f9-8797-933a6a222b7f'
    },
]


const selectedSeason = ref('Season 1')

const selectedUsers = computed( () => {
    if(selectedSeason.value == 'Season 1'){
        return users_season1
    }else{
        return users_season2
    }
})

const isPrizeModalOpen = ref(false)
const isPrizeModalOpenS1 = ref(false)

</script>
<template>
    <div class="w-full h-full bg-true-gray-900">
        <div class="text-white w-full mx-auto pt-4 pb-20">

            <div class="mb-8 w-full sm:w-1/2 mx-auto ">
                <div>
                    <nav class="isolate flex divide-x divide-true-gray-700 rounded-lg shadow" aria-label="Tabs">
                        <button 
                            v-for="(tab, tabIdx) in tabs" :key="tab.name"
                            @click="currentTabIndex = tabIdx"
                            :class="[currentTabIndex == tabIdx ? 'text-true-gray-100' : 'text-true-gray-500 hover:text-true-gray-400', tabIdx === 0 ? 'rounded-l-lg' : '', tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium bg-true-gray-800 hover:bg-true-gray-700 focus:z-10']"
                            :aria-current="currentTabIndex == tabIdx ? 'page' : undefined">
                            <span>{{ tab.name }}</span>
                            <span aria-hidden="true"
                                :class="[currentTabIndex == tabIdx ? 'bg-yellow-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']" />
                        </button>
                    </nav>
                </div>
            </div>

            <div v-if="currentTabIndex == 0">
                <Leaderboard />
            </div>

            <div v-if="currentTabIndex == 1" class="p-4">
                <div class="mb-4">
                <label for="seasons" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select 
                    v-model="selectedSeason"
                    id="seasons" name="seasons" class="text-sm font-medium text-true-gray-500 block w-full rounded-md border-true-gray-700 bg-true-gray-800 px-4 focus:ring-gray-200 border-transparent focus:border-transparent focus:ring-0">
                    <option v-for="(season, tabIdx) in seasons" :key="season.name" :selected="(currentTabIndex == tabIdx) ? true : false">{{ season.name }}</option>
                </select>
            </div>

            <div v-if="selectedSeason == 'Season 1'" class="my-6 text-sm font-medium text-true-gray-500">
                <h1 class="text-xl font-bold leading-7 text-warm-gray-200 sm:text-2xl sm:truncate"> Season 1 Leaderboard Challenge 🏆 </h1>
                <p class="mt-1 text-warm-gray-400 ">
                    Season 1 started November 16, 2022 and endded in December 31, 2022. See complete list of prizes by clicking <a href="#" @click="isPrizeModalOpenS1 = true" class="text-indigo-500 hover:text-indigo-400">here</a>.
                </p>
            </div>
            <div v-else-if="selectedSeason == 'Season 2'" class="my-6 text-sm font-medium text-true-gray-500">
                <h1 class="text-xl font-bold leading-7 text-warm-gray-200 sm:text-2xl sm:truncate"> Season 2 Leaderboard Challenge 🏆 </h1>
                <p class="mt-1 text-warm-gray-400 ">
                    Season 2 was in collaboration with <span class="text-true-gray-400 font-bold">PTC Aviation Training Center</span> and <span class="text-true-gray-400 font-bold">Airmen Clothing</span>. It started March 1, 2023 and endded in May 31, 2023. See complete list of prizes by clicking <a href="#" @click="isPrizeModalOpen = true" class="text-indigo-500 hover:text-indigo-400">here</a>.
                </p>
            </div>

            <div v-for="user in selectedUsers" :key="user.id" class="bg-true-gray-800 w-full flex px-8 py-4 rounded-lg my-4">

                <!-- Image -->
                <div class="relative h-28 w-20">
                    <div class="flex-shrink-0 h-20 w-20 rounded-full bg-white">
                        <img class="object-cover h-20 w-20 border-4 border-white rounded-full" :src="user.userPhoto" alt="">
                    </div>
                    <div class="absolute flex-shrink-0 rounded-full bg-white h-12 w-12 bottom-4 -right-4">
                        <img class="object-cover border-4 h-12 w-12 border-white rounded-full" :src="user.schoolPhoto"
                            alt="">
                    </div>
                </div>

                <!-- Description -->
                <div class="ml-8 text-sm text-true-gray-500 w-full">
                    <h1 class="text-true-gray-400 text-sm font-semibold"> {{ user.name }}</h1>
                    <p class="flex items-start space-x-1 mt-1">
                        <span class="flex-none"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mt-0.5 w-4 h-4">
                            <path
                                d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                            <path
                                d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                            <path
                                d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
                        </svg></span>

                        <span>{{ user.course }}</span>
                    </p>
                    <p class="flex items-start space-x-1 mt-1">
                        <span class="flex-none">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="mt-0.5 w-4 h-4">
                            <path
                                d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                            <path fill-rule="evenodd"
                                d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z"
                                clip-rule="evenodd" />
                            <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
                        </svg>
                        </span>
                        <span>{{ user.school }}</span>
                    </p>
                    
                    <div class="flex items-center justify-between">

                        <div>
                            <span
                                class="mt-2 inline-flex items-center shadow-sm px-2.5 py-0.5 text-sm leading-5 font-medium rounded-full text-true-gray-700 bg-yellow-400 hover:bg-yellow-500">
                                <svg xmlns="http://www.w3.org/2000/svg" width="365" height="408" viewBox="0 0 365 408"
                                    fill="none" class="w-3.5 h-3.5 mr-1">
                                    <path
                                        d="M160.178 407.26H102.759V96.7148L128.962 119.472C148.791 136.693 160.178 161.666 160.178 187.928V407.26Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M205.519 407.26H262.937V96.7148L236.734 119.472C216.906 136.693 205.519 161.666 205.519 187.928V407.26Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M0 368.725H57.4244V217.608L29.9388 254.497C10.5 280.586 0 312.252 0 344.786V368.725Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M364.605 368.725H307.181V217.608L334.667 254.497C354.105 280.586 364.605 312.252 364.605 344.786V368.725Z"
                                        fill="currentColor"></path>
                                    <path
                                        d="M143.561 46.8462L182.851 0L222.142 46.8462C215.593 51.3797 198.265 60.4467 181.34 60.4467C164.415 60.4467 149.102 51.3797 143.561 46.8462Z"
                                        fill="currentColor"></path>
                                </svg>
                                {{ user.skillpoints }}
                            </span>
                        </div>

                        <h3
                            v-if="user.rank == '1st'"
                            class="mt-4 text-transparent text-2xl font-extrabold space-x-1">
                            <span class="bg-clip-text text-gray-200">🦄</span>
                            <span class="bg-clip-text bg-gradient-to-r from-pink-400 to-purple-600 animate-pulse">{{ user.rank }}</span>
                        </h3>
                        <h3
                            v-else-if="user.rank == '2nd'"
                            class="mt-4 text-transparent text-2xl font-extrabold space-x-1">
                            <span class="bg-clip-text text-gray-200">🥈</span>
                            <span class="bg-clip-text bg-gradient-to-r from-white to-gray-400 ">{{ user.rank }}</span>
                        </h3>
                        <h3
                            v-else-if="user.rank == '3rd'"
                            class="mt-4 text-transparent text-2xl font-extrabold space-x-1">
                            <span class="bg-clip-text text-gray-200">🥉</span>
                            <span class="bg-clip-text bg-gradient-to-r from-yellow-600 to-yellow-900 ">{{ user.rank }}</span>
                        </h3>
                        <h3
                            v-else
                            class="mt-4 text-transparent text-2xl font-extrabold space-x-1">
                            <span class="bg-clip-text text-gray-200">🏅</span>
                            <span class="bg-clip-text text-true-gray-500">{{ user.rank }}</span>
                        </h3>
                    </div>
                </div>
            </div>

            </div>

        </div>
    <S2PrizeModal 
        :isPrizeModalOpen="isPrizeModalOpen" 
        @closePrizeModal="isPrizeModalOpen = false" 
      />
    <S1PrizeModal 
    :isPrizeModalOpen="isPrizeModalOpenS1" 
    @closePrizeModal="isPrizeModalOpenS1 = false" 
    />

</div></template>
<script setup>
import moment from "moment"
import ROUTER from "@/router"
import { ref } from "vue"
import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import { ArrowRightIcon, ChevronDoubleLeftIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid";

const exams = ref([])
const isWaiting = ref(true)

const computeRating = (score, time_running, time_limit) => {
      /*
        Ratings are computed by following a 50:50 ratio between your recorded Score and Time Left Percentage.

        For Example:
        Username: Tony Stark
        Subject: Spelling
        Total No. of Items: 75 Items
        Time Limit: 15 minutes (900 seconds)

        Mock Exam Score: 69 out of 75 [ 92% ]
        Time Consumed: 7 minutes (420 seconds)
        Time Left: 8 minutes (480 seconds) [ 53.33% of the time limit ]			  

        Rating 	= [ 50% of Score Percentage ] + [ 50% of Time Left Percentage ]
                = [ 50% x 92% ] + [ 50% x 53.33% ]
                = [ 46% ] + [ 26.66% ]
                = 72.66%
      */

      let time_consumed = time_running;
      let time_left = time_limit - time_consumed;
      let time_left_percent = (time_left/time_limit)*100
      var rating = ( (score/2) + (time_left_percent/2) ).toFixed(2)
      return rating
}

const formatTime = (time) => {
    let momentedTime = moment("2015-01-01").startOf("day").seconds(time);
    return moment(momentedTime).format("HH:mm:ss");
}

const fetchMockExams = () => {
    return new Promise(async (resolve, reject) => {
        let userId = firebase.auth().currentUser.uid
        firebase.firestore().collection("lite_mock")
            .where("user", "==", userId)
            // .where("status", "==", "completed")
            .orderBy("time.start", "desc")
            // .limit(20)
            .get()
            .then((querySnapshot) => { 
            var mock_exams = [];
            if(querySnapshot.size > 0){ // only check if there are existing practice exams started
                
                querySnapshot.forEach((doc) => {
                let data = doc.data()
                data.id = doc.id
                mock_exams.push(data)
                });
                console.log(mock_exams)
            }
            resolve(mock_exams);
            })
            .catch((error) => {
                console.error("Error getting document => ", error);
                reject(error)
            })

    })   
}

const initilize = async () => { 
    isWaiting.value = true
    exams.value = await fetchMockExams() 
    isWaiting.value = false
}
initilize()

const navigateBackToExamHistory = () => {
    ROUTER.push({ path: `/lite` })
}

</script>
<template>
    <div class="max-w-md mx-auto py-6 px-4 sm:px-6 text-white">
            
        <!-- Breadcrumbs -->
        <nav class="flex justify-between items-center" aria-label="Breadcrumb">
            <div class="flex items-center">
            <button @click="navigateBackToExamHistory()" class="text-sm font-medium text-warm-gray-500 hover:text-warm-gray-400" aria-current="page">
                <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
            </button>
            </div>
            <div class="text-sm font-medium text-warm-gray-500">
            <span class="hidden md:block">Mock Exam History</span>
            <span class="block md:hidden">Mock Exam History</span>
            </div>
            <!-- FAQ Menu -->
            <QuestionMarkCircleIcon class="h-5 w-5 text-warm-gray-500 hover:text-warm-gray-400" aria-hidden="true" />
        </nav>
            
        <!--  -->

        <div v-if="!isWaiting">
            <ul v-if="exams.length > 0" class="flex flex-col space-y-5 mt-6 mb-20">
                <li v-for="exam in exams" :key="exam.id" class="card">
                    <router-link v-if="exam.status == 'completed'" :to="`/lite/review?examId=${exam.id}`" class="hover:opacity-75 flex flex-col text-warm-gray-300">

                        <div class="flex items-start justify-between px-4 sm:px-5 pt-5 pb-3">
                            <div class="flex flex-col">
                                <span class="text-sm">{{ moment(exam.time.start).format("MMMM Do YYYY - hh:mm:ss A").toString().replace("-", "at") }}</span>
                                <span class="text-xs text-true-gray-500">Started {{ moment(exam.time.start).startOf('minutes').fromNow() }}</span>
                            </div>
                            <span 
                                :class="[
                                    (exam.result == 'passed') ? 'text-green-600 bg-green-200' : '',
                                    (exam.result == 'failed') ? 'text-red-600 bg-red-200' : '',
                                    'text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full']">
                                {{ exam.result }}
                            </span>
                        </div>

                        <div class="w-full flex px-4 sm:px-5">
                            <div class="flex items-center text-yellow-400 text-sm">
                                <span>Review Exam</span>
                                <ArrowRightIcon class="w-4 h-4 ml-1" />
                            </div>
                        </div>
                        
                        
                        <div class="mt-4 grid grid-cols-4">
                            <span class="pl-3 sm:pl-5 pr-2 text-xs sm:text-sm py-3 border-t border-true-gray-700">Score: <span :class="[(exam.result == 'passed') ? 'text-green-400' : 'text-red-400', 'text-sm']">{{ exam.score }}%</span></span>
                            <span class="px-3 sm:px-3 py-3 text-xs sm:text-sm border-t border-true-gray-700 border-l">Time: <span class="text-sm">{{ formatTime(exam.time.running) }}</span></span>
                            <span class="px-3 sm:px-3 py-3 text-xs sm:text-sm border-t border-true-gray-700 border-l">Rating: <span class="text-sm">{{ computeRating(exam.score, exam.time.running, exam.time.limit) }}%</span></span>
                            <span class="pr-2 sm:pr-5 pl-3 text-xs sm:text-sm sm:pl-3 py-3 border-t border-true-gray-700 border-l">Skillpoints: <span :class="[(exam.skillpoints > 0) ? 'text-yellow-400' : '', 'text-sm']">+{{ exam.skillpoints }}</span></span>
                        </div>
                        
                    </router-link>
                    <div v-else class="opacity-50 text-warm-gray-400 flex flex-col px-5 py-5">
                        
                        <div class="flex items-start justify-between">
                            <div class="flex flex-col">
                                <span class="text-sm">{{ moment(exam.time.start).format("MMMM Do YYYY - hh:mm:ss A").toString().replace("-", "at") }}</span>
                                <span class="text-xs">Started {{ moment(exam.time.start).startOf('minutes').fromNow() }}</span>
                            </div>
                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-true-gray-800 bg-true-gray-400">
                                {{ exam.status }}
                            </span>
                        </div>

                        <!-- <span>{{  exam.id }} | {{ exam.status }} | Score: {{ exam.score }} | Time: {{ formatTime(exam.time.running) }} | Rating: {{ computeRating(exam.score, exam.time.running, exam.time.limit) }}%</span> -->
                        <!-- <span>Skillpoints: +{{ exam.skillpoints }}</span> -->
                    </div>
                </li>
            </ul>
            <div v-else class="text-warm-gray-400 w-full text-center mt-8">
                No Exam History Yet
            </div>
        </div>
        <div v-else>
            <div class="flex justify-center mt-8">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-true-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            </div>
        </div>

    </div>
</template>
<style>
.card{
    @apply text-sm rounded-2xl bg-true-gray-800 border border-true-gray-700 bg-opacity-50 border-opacity-50
}
</style>


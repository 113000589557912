<template>
    <div class=" text-white">
      <h1>{{ title }}</h1>
      <p>{{ response }}</p>
      <button @click="fetchOpenAIResponse">Get AI Response</button>
    </div>
  </template>
  
  <script>
  // import axios from "axios";
  // Import OpenAI SDK
//   import OpenAI from "openai";

// import OpenAI from "openai";
// const openai = new OpenAI({
//     apiKey: process.env.VUE_APP_OPENAI_API_KEY,
//     dangerouslyAllowBrowser: true
// });

// // const ai_cardoId = 'asst_G1gBfD2rV4SCRv0ZCLIcNrDT'; // Jay's ID
// // const ai_messages = ref(null);
// // const ai_thread = ref(null);

// const apiKey = process.env.VUE_APP_OPENAI_API_KEY;
// const client = axios.create({
//     headers: {
//       Authorization: "Bearer " + apiKey,
//       'Content-Type': 'application/json'
//     }
// });

  
  export default {
    data() {
      return {
        title: "OpenAI Integration Example",
        response: "Waiting for AI response...",
      };
    },
    methods: {
      async fetchApi(){
        
        // const params = {
        //   prompt: `Question: "${q}". Answer: "${ans}". Explain why in detail, with solution if possible.`, // we should have different prompts for different topics
        //   model: "text-davinci-003",
        //   max_tokens: 2000,
        //   n: 2,
        //   temperature: 0.5,
        // };

        let question = "Calibration and repair of instruments is an example of (Part IS: 5.1.1.2)?";
        let choice_strings = "(1)Appliance major alterations, (2)Appliance major repair, (3)Components major repair";
        let selectedChoice = "Components major repair";
        let answer = "Appliance major repair";

        const paramsNew = {
            model: 'gpt-4o',
            messages: [{ 
                role: 'user', 
                content: `
                  You are an Aircraft Mechanic in the Philippines. 
                  The question: ${question}
                  The choices are: ${choice_strings}.
                  The choice I selected was "${selectedChoice}".
                  The correct answer is: ${answer}.
                  Explain why in detail, with solution if possible.
                `
            }],
            max_tokens: 100
        };

        try {
          let result = await client.post("https://api.openai.com/v1/completions", paramsNew)

          console.log(result);

          // console.log(result)
          // if(this.isWaitingForExplanation){
          //     return {
          //       isSuccess: true,
          //       data: result.data.choices[0].text
          //     }
          //   }
        } catch (error) {
          console.error(error);
            // this.isWaitingForExplanation = false;
            // return {
            //   isSuccess: false
            // }
        }
      },

      async fetchOpenAIResponse() {


        let question = "Calibration and repair of instruments is an example of (Part IS: 5.1.1.2)?";
        // let choice_strings = "-Appliance major alterations\n -Appliance major repair\n -Components major repair";
        let choice_strings = "-Appliance major alterations\n -Appliance major repair\n -Components major repair";
        let selectedChoice = "Components major repair";
        let answer = "Appliance major repair";

        const paramsNew = {
            model: 'gpt-4o',
            messages: [{ 
                role: 'user', 
                content: `
                  You are an Aircraft Mechanic in the Philippines. 
                  The question: **${question}**
                  The choices are: ${choice_strings}
                  The choice I selected was: "${selectedChoice}".
                  The correct answer is: ${answer}.
                  Explain why this is the correct answer in detail, with a solution if possible.
                `
            }],
            max_tokens: 100
        };


        console.log('Test 1')
        await fetch('https://api.openai.com/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(paramsNew)
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));

        console.log('Test 2')

        

        // console.log('===>',process.env.VUE_APP_OPENAI_API_KEY)

        // fetch('https://api.openai.com/v1/completions', {
        // method: 'POST',
        // headers: {
        //     'Content-Type': 'application/json',
        //     Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`, // Store the key securely
        // },
        // body: JSON.stringify({
        //     model: 'asst_G1gBfD2rV4SCRv0ZCLIcNrDT',
        //     prompt: "Explain how to integrate OpenAI in Vue.js",
        //     max_tokens: 100,
        // }),
        // })
        // .then((response) => {
        //     console.log(response.json());
        //     return response.json();
        // }
        // )
        // .then((data) => console.log(data));

        // const openai = new OpenAI({
        //     apiKey: "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",
        //     dangerouslyAllowBrowser: true
        // });
  
        // try {
        //   const completion = await openai.completions.create({
        //     model: "asst_G1gBfD2rV4SCRv0ZCLIcNrDT",
        //     prompt: "Explain how to integrate OpenAI in Vue.js",
        //     max_tokens: 100,
        //   });
  
        //   this.response = completion.choices[0].text;
        // } catch (error) {
        //   console.error("Error fetching response from OpenAI:", error);
        //   this.response = "Error fetching AI response.";
        // }
      },
    },
  };
  </script>
  
<template>
  <div class="caap">
    <!-- Breadcrumbs -->
    <nav class="flex px-2 lg:px-12 justify-between items-center" aria-label="Breadcrumb">
      <div class="flex items-center">
        <router-link to="/reviewers" class="ml-2 text-sm font-medium text-warm-gray-500 hover:text-warm-gray-600 flex items-center" aria-current="page">
          <ChevronDoubleLeftIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true"/>
        </router-link>
      </div>
      <div class="text-sm font-medium text-warm-gray-500">
        <span class="hidden md:block">Info about CAAP </span>
        <span class="block md:hidden">CAAP</span>
      </div>
      <!-- FAQ Menu -->
      <Menu as="div" class="ml-3 relative inline-block text-left">
        <MenuButton class="p-1 rounded-full flex items-center text-warm-gray-500 hover:text-warm-gray-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          <span class="sr-only">Open options</span>
          <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <MenuItems class="z-50 origin-top-right absolute right-0 mt-3 w-72 -mr-2 rounded-md shadow-lg dark:bg-true-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <button type="button" :class="[ active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What am I seeing in this page?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What is a Practice/Mock Exam?</span>
                </button>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <button :class="[active ? 'bg-warm-gray-100 text-warm-gray-900 dark:bg-true-gray-600 dark:text-true-gray-300' : 'text-warm-gray-700 dark:text-warm-gray-300', 'w-full flex justify-between px-4 py-2 text-sm']">
                  <span>What are Achievements?</span>
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
    </nav>

    <!-- Main Content -->
    <main class="max-w-full mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8 mb-4">
      
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0 mt-6">
            <h2 class="text-2xl font-extrabold tracking-tight text-warm-gray-900 dark:text-true-gray-300">
              {{ headline[0].title }}
            </h2>
            <p class="mt-4 text-base text-warm-gray-500">
              {{ headline[0].description }}
            </p>
          </div>
        </div>
        <div class="md:col-span-2">
          <router-link :to="menu[0].href" class="cursor-pointer hover:shadow-md grid grid-cols-1 sm:grid-cols-5 mt-4 rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
            <div class="bg-yellow-400 mt-8 col-span-1 rounded-lg w-24 h-24 p-6 m-6 my-auto">
              <img :src="menu[0].image" class="flex-grow-0 w-18 w-18">
            </div>
            <div class="focus:outline-none col-span-1 sm:col-span-4 mx-8 xl:-ml-5 text-left py-8 mb-4">
              <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                <h1 class="text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                  {{menu[0].title}}
                </h1>
              </div>
              <p class="text-sm text-warm-gray-500 mt-2 w-full">
                {{menu[0].description}}
              </p>
            </div>
          </router-link>
          <router-link :to="menu[1].href" class="cursor-pointer hover:shadow-md grid grid-cols-1 sm:grid-cols-5 mt-4 rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
            <div class="bg-yellow-400 mt-8 col-span-1 rounded-lg w-24 h-24 p-6 m-6 my-auto">
              <img :src="menu[1].image" class="flex-grow-0 w-18 w-18">
            </div>
            <div class="focus:outline-none col-span-1 sm:col-span-4 mx-8 xl:-ml-5 text-left py-8 mb-4">
              <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                <h1 class="text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                  {{menu[1].title}}
                </h1>
              </div>
              <p class="text-sm text-warm-gray-500 mt-2 w-full">
                {{menu[1].description}}
              </p>
            </div>
          </router-link>
        </div>
      </div>

      <hr class="border-t border-warm-gray-300 dark:border-true-gray-700 mt-8 mb-5"/> 

      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0 mt-6">
            <h2 class="text-2xl font-extrabold tracking-tight text-warm-gray-900 dark:text-true-gray-300">
              {{headline[1].title}}
            </h2>
            <p class="mt-4 text-base text-warm-gray-500">
              {{headline[1].description}}
            </p>
          </div>
        </div>
        <div class="md:col-span-2">
          <router-link :to="menu[2].href" class="cursor-pointer hover:shadow-md grid grid-cols-1 sm:grid-cols-5 mt-4 rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
            <div class="bg-yellow-400 mt-8 col-span-1 rounded-lg w-24 h-24 p-6 m-6 my-auto">
              <img :src="menu[2].image" class="flex-grow-0 w-18 w-18">
            </div>
            <div class="focus:outline-none col-span-1 sm:col-span-4 mx-8 xl:-ml-5 text-left py-8 mb-4">
              <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                <h1 class="text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                  {{menu[2].title}}
                </h1>
              </div>
              <p class="text-sm text-warm-gray-500 mt-2 w-full">
                {{menu[2].description}}
              </p>
            </div>
          </router-link>
          <router-link :to="menu[3].href" class="cursor-pointer hover:shadow-md grid grid-cols-1 sm:grid-cols-5 mt-4 rounded-lg overflow-hidden border border-warm-gray-300 dark:border-true-gray-700 dark:bg-true-gray-800 bg-white shadow-sm hover:border-warm-gray-400 dark:hover:border-true-gray-500 dark:focus-within:ring-true-gray-800 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-yellow-500">
            <div class="bg-yellow-400 mt-8 col-span-1 rounded-lg w-24 h-24 p-6 m-6 my-auto">
              <img :src="menu[3].image" class="flex-grow-0 w-18 w-18">
            </div>
            <div class="focus:outline-none col-span-1 sm:col-span-4 mx-8 xl:-ml-5 text-left py-8 mb-4">
              <div class="flex dark:text-warm-gray-400 text-warm-gray-800">
                <h1 class="text-lg font-medium dark:text-warm-gray-400 text-warm-gray-800">
                  {{menu[3].title}}
                </h1>
              </div>
              <p class="text-sm text-warm-gray-500 mt-2 w-full">
                {{menu[3].description}}
              </p>
            </div>
          </router-link>
        </div>
      </div>

    </main>
  </div>
</template>

<script>
// Import firebase dependencies
import firebase from "@/firebase/config";
import "firebase/auth";

// Import UI dependencies
import { ChevronDoubleLeftIcon, QuestionMarkCircleIcon } from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import { reviewerData } from "@/data/reviewer.data"
import { userService } from "@/utils/user"
import { expirationGuard } from "@/utils/helper"

export default {
  components: {
    ChevronDoubleLeftIcon, QuestionMarkCircleIcon,
    Menu, MenuButton, MenuItem, MenuItems,
  },
  name: "Caap",
  setup() {
    return {
      headline: reviewerData.caap_headline,
      menu: reviewerData.caap_menu,
    };
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async created(){
    this.isLoading = true

    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });

    /* CLAIMS - Guard Clause */
    let claims = await userService.fetchUserClaims(firebase.auth())
    if(!claims.isOnboarded) this.$router.push({ path: '/onboarding' })  // reroute non-onboarded users
    if(!claims.caap) this.$router.push({ path: '/reviewers' })  // reroute non-caap members

    /* USER */
    this.user = await userService.fetchUserData(firebase.firestore(), firebase.auth().currentUser.uid);
    if(!this.user.license || !this.user.license.exam_date) this.$router.push({ path: '/onboarding-caap' });
    expirationGuard(this.user, this.$router) // Check if access already expired. Added November 17, 2023
   
    this.isLoading = false
  }
  
};
</script>

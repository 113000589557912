/**
 * sort the schools alphabetically
 */
function sortSchoolsAlpha(schools){
  return schools.sort(function (a, b) {
    if (a.name > b.name) return 1
    if (b.name > a.name) return -1
    return 0;
  });
}

/**
 * sort by skillpoints
 */
 function sortBySkillpoints(data){
  return data.sort(function (a, b) {
    if (a.skillpoints > b.skillpoints) return -1
    if (b.skillpoints > a.skillpoints) return 1
    return 0;
  });
}

/**
 * sort the schools by users
 */
 function sortSchoolsByUsers(schools){
  return schools.sort(function (a, b) {
    if (a.users.length > b.users.length) return -1
    if (b.users.length > a.users.length) return 1
    return 0;
  });
}


function getSchoolById(schools, school_id){
  // console.log('getSchoolById')
  // console.log(schools)
  // console.log(school_id)
  let school = schools.filter( e => {return (e.id == school_id) })
  if(school.length > 0) return school[0]
  return ''
}

/**
 * NOTE: Connects To Firebase
 * fetch the schools
 */
function fetchAllSchools(db){
  return db.collection("schools").get()
    .then((querySnapshot) => {
      var schools = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data = doc.data();
        data.id = doc.id;
        schools.push(data)
      });
      return schools;          
    })
    .catch((error) => {
      console.error("Error getting school data: ", error);
      throw "Error getting school data"
    })
}

function sortSchoolLeaderboard(schools, leaderboard){

  schools.forEach( (school, index) => {
    var usersInSchool = leaderboard.filter( user => { return (school.id == user.school) })
    
    // Sort users according to skillpoints
    schools[index].users = usersInSchool;
    schools[index].users = sortBySkillpoints(schools[index].users)

    // compute the total score
    let skillpoints = 0;
    usersInSchool.forEach( user => { skillpoints += user.skillpoints });
    schools[index].skillpoints = skillpoints;

  });

  schools = sortSchoolsByUsers(schools)  // sort first by number of users. so schools will be sorted with more users at the top
  schools = sortBySkillpoints(schools) // then sort by skillpoints

  return schools

}

exports.schoolService = {
  sortSchoolsAlpha, fetchAllSchools, getSchoolById, sortSchoolLeaderboard
}
<template>
    <LoadingScreen v-if="isLoading" />
    
    <main v-if="getUserAccessType() == 'active' || getUserAccessType() == 'expired'">

        <!-- <div class="bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 w-44 h-44"  style="background-image: url('https://firebasestorage.googleapis.com/v0/b/skilltech-team.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-1b.png?alt=media&token=0c9e8d46-4c52-46b2-ad89-ee9696fc13e1')">
        </div> -->
        <!-- <img src="https://firebasestorage.googleapis.com/v0/b/skilltech-team.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-1b.png?alt=media&token=0c9e8d46-4c52-46b2-ad89-ee9696fc13e1" alt="achievement box"> -->

        <header class="mt-0 sm:mt-4">

            <div class="max-w-7xl mx-auto px-4 lg:px-8">
                <div class="">
                    <h1 class="text-xl font-bold leading-7 dark:text-gray-200 text-gray-600 sm:text-2xl sm:truncate mb-5">
                        Achievement Box Reward 🎁
                    </h1>
                    <p class="mb-2 dark:text-gray-400 text-gray-600">
                        Start unlocking your customized and free-of-charge Achievement Box by simply updating the scorecards found below! <br/>
                        Once completed, you can then proceed to the next step.
                    </p>
                    <p class="dark:text-gray-400 text-gray-600">
                        To learn how it works, you can click <a href="#" @click="showInstruction = true" class="text-gray-600 dark:text-gray-200 underline">here</a>.
                    </p>
                </div>
            </div>
        </header>

        <div class="flex flex-col p-4 lg:p-8 max-w-full mx-auto lg:max-w-7xl mb-16">

            <!-- Type of exam taken -->
            <div class="flex flex-col mb-10">
                <label class="text-true-gray-500 dark:text-true-gray-400 font-medium text-sm tracking-wider mb-2" for="exam-type">TYPE OF EXAM TAKEN:</label>
                <select v-model="typeOfExam" class="w-full md:w-1/2 dark:bg-true-gray-700 border border-gray-400 dark:border-gray-500 rounded-md text-gray-500 dark:text-gray-300 focus:ring-0 focus:border-blue-700" name="exam-type" id="exam-type" :disabled="assessmentIsCompleted">
                    <option class="dark:bg-warm-gray-800" value="amt">Aviation Maintenance Technician (AMT)</option>
                    <option class="dark:bg-warm-gray-800" value="avt">Aviation Maintenance Specialist (AMS/AVT)</option>
                </select>
            </div>

            <!-- PC -->
            <div class=" hidden md:block overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 dark:border-gray-800 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-warm-gray-800">
                    <thead class="bg-warm-gray-300 dark:bg-true-gray-800">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                Subjects
                            </th>
                            <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                Rating
                            </th>
                            <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider">
                                Add result
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-true-gray-700 divide-y divide-gray-200 dark:divide-true-gray-800 dark:divide-opacity-75">
                        <template v-for="subject in subjects" :key="subject.id">
                            <tr v-if="(typeOfExam == 'amt' && subject.subjectId != 'avionics-rating') || (typeOfExam == 'avt' && (subject.subjectId != 'powerplant-rating' && subject.subjectId != 'airframe-rating'))">
                                <td class="px-6 py-4 whitespace-nowrap text-left text-sm text-true-gray-500 dark:text-true-gray-400 flex justify-start" >
                                    <CheckCircleIcon :class="['w-5 h-5 mr-1', subject.isComplete ? 'opacity-100 text-green-500':'opacity-30']"/>
                                    <span>{{subject.subjectName}}</span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center font-semibold"> 
                                    <span v-if="subject.rating" :class="subject.isComplete ? 'text-green-600':'text-red-700'">{{subject.rating}}%</span>
                                    <span v-else class=" text-warm-gray-600">---</span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-center text-sm"> 
                                    <span v-if="subject.examDate" class=" text-warm-gray-400">{{ formatDate(subject.examDate.seconds) }}</span>
                                <span v-else class=" text-warm-gray-600">---</span>
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap text-right flex justify-end">
                                    <PlusCircleIcon @click="openListResultModal(subject)" class=" w-5 h-5 text-yellow-600 mr-6 cursor-pointer"/>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>

            <!-- mobile -->
            <div class=" md:hidden">

                <!-- <h3 class="text-xs font-medium text-warm-gray-600 dark:text-warm-gray-400 uppercase tracking-wider mb-4">Subjects</h3> -->


                <div class="flex flex-col space-y-6">
                    <template v-for="subject in subjects" :key="subject.id">
                        <div v-if="(typeOfExam == 'amt' && subject.subjectId != 'avionics-rating') || (typeOfExam == 'avt' && (subject.subjectId != 'powerplant-rating' && subject.subjectId != 'airframe-rating'))" class="rounded border border-true-gray-700 bg-true-gray-100 dark:bg-true-gray-700 p-2">
    
                            <p class="whitespace-nowrap text-true-gray-500 dark:text-true-gray-400 flex justify-start items-center">
                                <CheckCircleIcon :class="['w-5 h-5 mt-0.5 mr-1', subject.isComplete ? 'opacity-100 text-green-500':'opacity-30']"/>
                                <span class="truncate">{{subject.subjectName}}</span>
                            </p>
                            
                            <p class="text-sm mt-2">
                                <span class="text-true-gray-500 mr-1">Score: </span>
                                <span v-if="subject.rating" :class="subject.isComplete ? 'text-green-600':'text-red-700'">{{subject.rating}}%</span>
                                <span v-else class=" text-warm-gray-600">---</span>
                            </p>
    
                            <p class="text-sm">
                                <span class="text-true-gray-500 mr-1">Date: </span>
                                <span v-if="subject.examDate" class=" text-warm-gray-400">{{ formatDate(subject.examDate.seconds) }}</span>
                                <span v-else class=" text-warm-gray-600">---</span>
                            </p>
    
                            <button @click="openListResultModal(subject)" class="border border-true-gray-600 bg-true-gray-600 dark:bg-true-gray-800 text-yellow-600 rounded flex justify-center items-center text-sm p-2 mt-4 w-full">
                                <PlusCircleIcon class=" w-5 h-5 text-yellow-600 cursor-pointer mr-1"/>
                                Add result  
                            </button>
                        </div>
                    </template>
                </div>

            </div>


            <template v-if="assessmentIsCompleted">
                <button
                    @click="goToAssessmentPage()"
                    type="button"
                    class="py-4 px-5 flex justify-center tracking-wider text-warm-gray-100 bg-yellow-700 rounded-md mt-6"
                >
                    Check submission status
                </button>
            </template>
            <template v-else>

                <div class=" flex justify-start items-start lg:items-center dark:text-gray-500 text-gray-600 mt-6">
                    <input v-model="isAcknowledge" type="checkbox" name="certify" id="certify" class="mt-1 lg:mt-0">
                    <p class="ml-2">
                        I hereby declare that the information provided is true and correct to the best of my knowledge and belief.
                    </p>
                </div>
                <button
                    @click="goToAssessmentPage()"
                    type="button"
                    class="py-3 px-5 flex justify-center tracking-wider text-warm-gray-100 bg-yellow-700 rounded-md disabled:opacity-40 disabled:cursor-not-allowed mt-6"
                    :disabled="!(isCompleteAll && isAcknowledge)"
                >
                    Claim your reward now!
                </button>

            </template>


        </div>

        <RewardUpdateCaapExamResultModal
            :isUpdateCaapExamResultModalOpen="isUpdateCaapExamResultModalOpen"
            :openedSubject="openedSubject"
            :openedResult="openedResult"
            @closeUpdateCaapExamResultModal="closeUpdateCaapExamResultModal"
            @fetchExamResults="fetchExamResults"
        />

        <RewardNewCaapExamResultModal
            :isNewCaapExamResultModalOpen="isNewCaapExamResultModalOpen"
            :openedSubject="openedSubject"
            @closeNewCaapExamResultModal="closeNewCaapExamResultModal"
            @fetchExamResults="fetchExamResults"
        />

        <RewardListResultModal
            :isListResultModalOpen="isListResultModalOpen"
            :openedSubject="openedSubject"
            @closeListResultModal="isListResultModalOpen = false"
            @openNewExamResultModal="openNewExamResultModal"
            @updateResult="updateResult"
            @fetchExamResults="fetchExamResults"
        />


        <!-- Instruction Modal -->
        <TransitionRoot appear :show="showInstruction" as="template">
        <Dialog as="div" @close="closeAnnounceModal">
            <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="min-h-screen px-4 text-center">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
                </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
            </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
            >
                <div class="inline-block w-full max-w-3xl p-8 my-8 overflow-hidden text-left align-middle transition-all transform dark:bg-true-gray-800 bg-white shadow-xl rounded-2xl">
                    <!-- <div class="flex text-warm-gray-800 dark:text-true-gray-200 justify-center">
                        <DialogTitle as="h3" class="ml-1 text-lg font-medium text-warm-gray-800 dark:text-true-gray-200">
                            Filling Up the CAAP Scorecard
                        </DialogTitle>
                    </div> -->
                        
                    <button type="button" @click="showInstruction = false" class="absolute top-0 right-0 mr-4 mt-4">
                        <svg class="w-6 h-6 dark:text-true-gray-400 text-warm-gray-400 hover:text-warm-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>

                    <div class="mt-2">

                        <h2 class="font-bold dark:text-true-gray-300 text-warm-gray-800 text-left mt-6">Filling Up the CAAP Scorecard</h2>
                        <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6 leading-loose">
                            <ol class=" list-decimal pl-5 mt-2">
                                <li>Once you’ve taken a CAAP exam, choose a subject to update.</li>
                                <li>Based on your actual exam result, update your scorecard.</li>
                                <li>Follow the steps and fill-up the field to the best of your knowledge.</li>
                                <li>Submit your result, and save your progress.</li>
                                <li>Complete all the subjects and claim your reward.</li>
                            </ol>
                        </p>
                        <p class="text-sm dark:text-true-gray-400 text-warm-gray-700 text-left mt-6 leading-loose">
                            <b class="dark:text-warm-gray-300">Developer’s Message:</b> It would be best if you can update your scorecard the same day you’ve taken an exam. Even if you passed or failed, every information we get is vital to the growth of the app. All we can ask for is your full honesty and support. Together, let us all help our fellow airmen reach their licensure goals. Thanks in advance, airman!
                        </p>
                    </div>
                </div>
            </TransitionChild>
            </div>
            </div>
        </Dialog>
        </TransitionRoot>
    </main>
    <div v-else class="text-true-gray-400 flex justify-center items-center">
        <div class="flex flex-col items-center justify-center w-full md:w-1/2 mt-10">
                <h1 class=" text-yellow-600 font-bold text-3xl mb-7">Almost there!</h1>
                <p class="text-center sm:w-2/3">
                    To claim your Achievement Box, you need to have the CAAP reviewer activated on your account. If you haven't got it yet, let's fix that right away so you can start working towards your reward.
                </p>
                <RouterLink to="reviewers" tag="button" class="rounded-lg bg-gradient-to-r from-yellow-800 to-yellow-500 shadow-lg p-2 font-semibold tracking-wider text-white w-3/4 mt-5 text-center">Purchase now!</RouterLink>
        </div>

    </div>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import firebase from "@/firebase/config";
import 'firebase/functions'; // Import the Firebase Functions module
const db = firebase.firestore()

import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/vue/outline';
import RewardNewCaapExamResultModal from "../components/modals/RewardNewCaapExamResultModal";
import RewardUpdateCaapExamResultModal from "../components/modals/RewardUpdateCaapExamResultModal";
import RewardListResultModal from "../components/modals/RewardListResultModal";
import LoadingScreen from "@/components/appshell/LoadingScreen" 

import { reviewerService } from "@/utils/reviewer"
import { formatDate } from '@/utils/dateHelper.js'

import { mapGetters } from 'vuex';
import { RouterLink } from "vue-router";

import aBoxImage1 from '@/assets/images/achievement-box-feat-1.jpeg';

/**
 * 
 * This page should be accessible by:
 * - 1. users who are subscribed (i.e. ACTIVE)
 * - 2. users who are not subscribed (i.e. INACTIVE) but 
 *      had a previous subscription (i.e. expired or data.isBlocked)
 */
export default {
    data(){
        return{
            isFirstLoad: true,
            isNewCaapExamResultModalOpen: false,
            isUpdateCaapExamResultModalOpen: false,
            isListResultModalOpen: false,
            isLoading: false,
            subjects:[],
            openedSubject: null,
            openedResult: null,
            isCompleteAll: false,
            isAcknowledge: false,
            assessmentIsCompleted: true,
            typeOfExam: 'amt',
            showInstruction: false
        }
    },
    components: { 
        CheckCircleIcon,
        RewardNewCaapExamResultModal,
        RewardUpdateCaapExamResultModal,
        RewardListResultModal,
        PlusCircleIcon,
        LoadingScreen,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
        DialogTitle,
    },
    computed:{
        ...mapGetters({
            user: 'user'
        })
    },
    watch:{
        typeOfExam: function(newVal, oldVal){
            if (!this.isFirstLoad) {
                this.fetchExamResults();
            }
        }
    },
    async created(){
        this.isLoading = false

        // fetch all subjects and it's subtopics to display
        let subjects = await reviewerService.fetchAllSubjects(db,'caap');

        // create a new array for each subject for display
        subjects.forEach(subject => {

            // calculate for how many items
            let itemCount = subject.exam.items.reduce((total, current) => total + current, 0)

            this.subjects.push({
                subjectId: subject.id,
                subjectName: subject.name,
                itemCount: itemCount,
                subtopics: subject.exam.subtopics,                
                isComplete: false,
                results: [],
            })
        });

        // load the type of exam user is taken
        if (this.user.info.examTaken) {
            this.typeOfExam = this.user.info.examTaken
        }

        // now populate the results for each subject if already have
        // fetch user caap exam results data to compare
        await Promise.all([
            this.fetchExamResults(),
            this.getClaimantsInfo()
        ])
        // this.computeData();

        this.isFirstLoad = false;

    },
    methods:{
        getUserAccessType(){

            /**
             * This will get the type of access the user has. IE:
             * 1. user has an active subscription
             * 2. user has an inactivate subscription
             * 3. user has an inactive subscription BUT had a previous subscription (i.e. expired or data.isBlocked)
             */
            if(this.user.info.reviewers.caap == 'active'){
                return 'active'
            }else if(this.user.info.reviewers.caap == 'inactive' && this.user.info.isCaapBlocked){
                return 'expired'
            }else if(this.user.info.reviewers.caap == 'inactive' && !this.user.info.isCaapBlocked){
                return 'inactive'
            }

        },
        formatDate,
        async computeData(){
            // Get a reference to your Cloud Function
            const myFunction = firebase.functions().httpsCallable('computeScoreCard');

            // Call the Cloud Function with any necessary data
            myFunction({ /* Data to send to the function */ })
            .then((result) => {
                // Handle the result of the function call
                console.log(result.data);
            })
            .catch((error) => {
                // Handle any errors that occurred during the function call
                console.error('Error calling Cloud Function:', error);
            });

        },
        async fetchExamResults(){

            console.log('Fetching exam results...');

            // clear results array, and other additional fields for UI
            this.subjects.forEach((s)=>{
                s.results = [];
                s.isComplete = false;
                delete s.rating;
                delete s.examDate;
            });

            const query = db.collection('exam_results').where('userId','==', firebase.auth().currentUser.uid).orderBy("takeCount","asc");
            await query.get()
                .then((querySnapshot)=>{
                    querySnapshot.forEach((doc)=>{

                        let data = doc.data();

                        // find the subject object to update
                        let toUpdateSubject = this.subjects.find((sub) => sub.subjectId == data.subjectId)

                        // check if this subject is completed
                        if (toUpdateSubject.isComplete == false && data.isPassed) {
                            toUpdateSubject.isComplete = true;
                        }

                        toUpdateSubject.rating = data.score;
                        toUpdateSubject.examDate = data.examDate

                        toUpdateSubject.results.push({
                            id: doc.id,
                            data: data
                        })
                        
                    })
                })


            // Check if all required subjects are completed 
            let inComplete = this.subjects.filter((s) => {
                // check type of exam taken, and required subject to complete
                if (this.typeOfExam == 'amt' && s.subjectId != 'avionics-rating') { // if AMT, and subject ID is not avionics-rating
                    return s.isComplete == false
                }else if(this.typeOfExam == 'avt' && (s.subjectId != 'powerplant-rating' && s.subjectId != 'airframe-rating')){ // if AVT, and subject ID is not powerplant-rating and airframe-rating
                    return s.isComplete == false
                }else{ // subject is not required to check
                    return false;
                }
            });

            // rearrange the subject to display based on the test result template
            const order = [
            'air-law',
            'nat-sci',
            'aircraft-engineering',
            'aircraft-maintenance',
            'human-performance',
            'airframe-rating',
            'powerplant-rating',
            'avionics-rating'
            ];
            
            this.subjects.sort((a, b) => {
                let indexA = order.indexOf(a.subjectId);
                let indexB = order.indexOf(b.subjectId);
                return indexA - indexB;
            });

            
            // update user exam result information
            // check if inComplete, and what type of exam taken, then update user info  
            this.isCompleteAll = inComplete.length == 0;
            let dataToUpdate = {
                        isExamResultCompleted: this.isCompleteAll,
                        examTaken: this.typeOfExam
                    }
            var userRef = db.collection("users").doc(this.user.info.id);
            return userRef.update(dataToUpdate)
            .then(() => {
                // update the user state info in store
                this.$store.dispatch('updateUserExamResultInfo', dataToUpdate);
                console.log("Update user exam result information.")
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
            });

        },
        async getClaimantsInfo(){
            
            this.assessmentIsCompleted = false;

            await db.collection("claimants").doc(firebase.auth().currentUser.uid).get()
            .then((doc) => {
                if (doc.exists) {
                    this.assessmentIsCompleted = true;
                }
            })
            .catch((error) => {
                console.error('Error getting document:', error);
            });

        },
        openListResultModal(subject){
            this.openedSubject = subject;
            this.isListResultModalOpen = true;
        },
        openNewExamResultModal(){
            this.isListResultModalOpen = false
            this.isNewCaapExamResultModalOpen = true
        },
        closeNewCaapExamResultModal(){
            this.isNewCaapExamResultModalOpen = false
            this.isListResultModalOpen = true
        },
        closeUpdateCaapExamResultModal(){
            this.openedResult = null
            this.isUpdateCaapExamResultModalOpen = false
            this.isListResultModalOpen = true
        },
        goToAssessmentPage(){
            this.$router.push('/reward/assessment')
        },
        updateResult(result){
            this.openedResult = result
            this.isListResultModalOpen = false
            this.isUpdateCaapExamResultModalOpen = true
        }
    }
}

</script>

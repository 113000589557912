<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="h-100 w-full flex justify-center">

    <div class="lg:w-3/4">
      
      <LoadingScreen v-if="isLoading" />

      <main v-else-if="!isSubmitted">
  
  
        <!-- PAGE 1 -->
        <div v-if="currentPage == 1" id="page-2" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
  
          <p  class="block text-3xl font-semibold dark:text-gray-200 text-gray-600 mt-8 mb-2">
            We are very proud of you, Airman!
          </p>
          <p class="block text-xl dark:text-gray-200 text-gray-600 mt-8 mb-2">
            Congratulations on passing your CAAP Knowledge Test! Thank you for sharing your insights and results with us. With this, you can help a lot of your fellow AMTs and AVTs with their exams, and propel our aviation community into greater heights!
          </p>

          <br>

          <!-- Test Results -->
          <!-- <label for="results" class="block text-xl dark:text-gray-400 text-gray-600 mt-8"> <span @click="isSampleModalOpen = true" class="underline text-base text-blue-700 cursor-pointer">Click here to see sample</span> </label> -->
          <div class="flex flex-col space-y-2 text-xl dark:text-gray-400 text-gray-600 mt-8">
            <p>At this stage, we will need you to upload a clear copy/picture of your official CAAP Knowledge Test Report. This will help us verify the validity of your entry, and will act as your proof of passing the exam.</p>
            <p>Before you upload, make sure that the “Rating” (grades) found in your certificate matches the entries you’ve made in your personal CAAP Exam Checklist. Failing to do so will invalidate this whole submission.</p>
            <span @click="isSampleModalOpen = true" class="underline text-base text-blue-700 cursor-pointer">Click here to see sample</span>
          </div>
          <div class="mt-4 shadow-sm bg-white  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg">
              <input id="test_results" @change="someHandler" name="test_results" type="file" accept="application/pdf,image/jpeg,image/png" class="p-4 inset-0 opacity-0 w-full bg-red-200 cursor-pointer border-gray-300 rounded-md" />
              <label for="test_results" class="flex justify-between text-sm text-gray-500 pointer-events-none w-full p-4 -mt-14">
                <span v-if="!test_results">Upload your Test Results here (accepts PDF, JPEG, PNG; file size below 2MB)</span>
                <span v-else>Selected: {{test_results}}  </span>
                <span v-if="test_results" class="right-0 text-sm font-medium text-red-700 dark:text-red-400">Change</span>
              </label>
          </div>

          <!-- PEL -->
          <div>
            <label for="pelNum" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">What is your PEL number? We will engrave this on your exclusive reward (more of this on the next page).</label>
            <div class="mt-4">
              <input v-model="pelNum" name="pelNum" id="pelNum" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your unique PEL number" />
            </div>
          </div>

          <!-- Feedback -->
          <div class="my-4">
            <label for="feedback" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Let’s inspire others! We would like to request a short comment (2-3 sentences will do) about your experience with our app and the exam. We'll post it together with your result and profile pic after some final editing.</label>
            <div class="mt-2">
              <textarea v-model="inspiringComment" id="feedback" name="feedback" rows="3"
                class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <!-- Inspiring Comment -->
          <div class="my-4">
            <label for="inspiringComment" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Any feedback or criticism towards Skilltech and its platform? We always want to improve ourselves. Please feel free to let us know.</label>
            <div class="mt-2">
              <textarea v-model="feedback" id="inspiringComment" name="inspiringComment" rows="3"
                class="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <!-- Photo -->
          <div class="my-4">
            <label for="upload_photo" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">We need a clear profile pic for our social media post. If you can kindly provide us with one, that would be really great.</label>
            <div class="mt-4 shadow-sm bg-white  focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg">
                <input id="upload_photo" @change="uploadPhotoHandler" name="upload_photo" type="file" accept="image/jpeg,image/png" class="p-4 inset-0 opacity-0 w-full bg-red-200 cursor-pointer border-gray-300 rounded-md" />
                <label for="upload_photo" class="flex justify-between text-sm text-gray-500 pointer-events-none w-full p-4 -mt-14">
                  <span v-if="!upload_photo">Upload your photo here (accepts JPEG and PNG; file size below 2MB)</span>
                  <span v-else>Selected: {{upload_photo}}  </span>
                  <span v-if="upload_photo" class="right-0 text-sm font-medium text-red-700 dark:text-red-400">Change</span>
                </label>
            </div>
          </div>
  
          
          <!-- Checkbox -->
          <div class="my-4 pb-4">
            
            <!-- Alert! -->
            <div class="my-4">
              <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mt-8" role="alert">
                <div class="flex">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                  <div>
                    <!-- <p class="font-bold">We will use your uploaded Skilltech profile picture.</p> -->
                    <p class="text-sm">By proceeding, you are agreeing to have your exam results posted on our social media. If you do not wish for your results to be posted, please email us at <a href="mailto:support@skilltech.ph" class=" text-yellow-700">support@skilltech.ph</a></p>
                  </div>
                </div>
              </div>
            </div>


            <!-- <div class="flex items-center my-8">
              <input v-model="canPostResults" @click="canPostResults = !canPostResults" id="canPostResults" name="canPostResults" type="checkbox" class="cursor-pointer border-2 focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300 rounded" />
              <p class="ml-3 text-xl dark:text-gray-400 text-gray-600 leading-8">I'm okay sharing my results on social media so others will be inspired. <button @click="showSample = !showSample" class="text-yellow-400 hover:opacity-80"> {{ showSample ? 'Hide' : 'See'}} sample</button>.</p>
              <p class="text-xl dark:text-gray-400 text-gray-600 leading-8">By proceeding, you are agreeing to post your exam results to our social media. </p>
              Click <button @click="showSample = !showSample" class="text-yellow-400 hover:opacity-80">here</button> to {{ (!showSample) ? 'see' : 'hide' }} the sample below.
            </div> -->

            <div v-if="showSample" class="flex justify-center">
                <img class=" w-full md:w-96" src="https://scontent.fdar7-1.fna.fbcdn.net/v/t39.30808-6/387820391_855840439576162_3858669466049857091_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeFPuzgaTV_Zc1ElLriWDx5xbzwDKNEtLnFvPAMo0S0ucQew4mZHndCaH5zktZBVaIJsmL5om72mTfIK0zWKHG_2&_nc_ohc=RAm3BHeTJ0wAX-Y85pJ&_nc_ht=scontent.fdar7-1.fna&oh=00_AfCXl-dC_3Hlvj7sGRLT_eZj7YDVgZacPA76HkedwKsnZA&oe=65675B95" alt="sample image">
            </div>
          </div>
  
          <div class="flex flex-col">

            <template v-if="postExamSurveyInfo == null">
              <button type="button" @click="currentPage = 2"
                :disabled="!canProceedToPage2"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                Continue
              </button>
              <router-link 
                :to="'/reward'" 
                type="button"
                class="my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 text-center">
                Cancel
              </router-link>
            </template>
            
            <template v-else>

              <button type="button"
                @click="resubmitPostExamSurveyForm()"
                :disabled="!canProceedToPage2 || isResubmittingPostExamSurveyForm"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                  <span v-if="!isResubmittingPostExamSurveyForm">Submit</span>
                  <span v-else>Submitting...</span>
              </button>
              <button type="button" @click="isSubmitted = true"
                :disabled="isResubmittingPostExamSurveyForm"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                  Cancel
              </button>

            </template>
            
          </div>
          <br><br>
        </div>
  
        <!-- PAGE 2 -->
        <div v-else-if="currentPage == 2" id="page-3" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
          <!-- <p v-if="softBlockUserInfo.data && softBlockUserInfo.data.achievementBoxStatus == 'waiting'" class="block text-xl dark:text-gray-400 text-gray-600 mt-8"> -->
          <p class="block text-3xl font-semibold dark:text-gray-200 text-gray-600 mt-8 mb-8">
            Lastly, we’d like to know where to deliver your well-deserved reward!
          </p>
          <!-- address line 2 (Optional)-->
          <div>
            <label for="addressline2" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Your Complete Delivery Address:</label>
            <div class="mt-4">
              <input v-model="addressline2" name="addressline2" id="addressline2" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your complete delivery address" />
            </div>
          </div>
          <!-- State / Province -->
          <div>
            <label for="province" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">State/Province:</label>
            <div class="mt-4">
              <input v-model="province" name="province" id="province" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your province" />
            </div>
          </div>
          <!-- City / Municipality -->
          <div>
            <label for="city" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">City/Municipality:</label>
            <div class="mt-4">
              <input v-model="city" name="city" id="city" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your city/municipality" />
            </div>
          </div>
          <!-- Barangay -->
          <div>
            <label for="barangay" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Barangay:</label>
            <div class="mt-4">
              <input v-model="barangay" name="barangay" id="barangay" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your barangay" />
            </div>
          </div>
          <!-- address line 1 (House No./Bldg./Street)-->
          <div>
            <label for="addressline1" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">House No./Bldg./Street:</label>
            <div class="mt-4">
              <input v-model="addressline1" name="addressline1" id="addressline1" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your House No./Bldg./Street" />
            </div>
          </div>
          
          <div>
            <label for="zip" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">Zip Code:</label>
            <div class="mt-4">
              <input v-model="zip" name="zip" id="zip" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input your zip code" />
            </div>
          </div>
  
          <div>
            <label for="phone" class="block text-xl dark:text-gray-400 text-gray-600 mt-8">For the Delivery Person, they can contact me at this number:</label>
            <div class="mt-4">
              <input v-model="phone" name="phone" id="phone" class="text-gray-600 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Input 11 Digit Phone Number" />
            </div>
          </div>
  
          <div class="flex flex-col">
            
            <template v-if="achievementBoxInfo === null">
              <button type="button" @click="submitResults()"
                :disabled="!canSubmitResults || isSubmitting"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                <span v-if="!isSubmitting">Submit Results</span>
                <span v-else>Submitting Results...</span>
              </button>
              <button type="button" @click="currentPage = 1"
                :disabled="isSubmitting"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                Go back
              </button>
            </template>

            <template v-else>
              <button type="button"
                @click="resubmitAchievementBoxForm()"
                :disabled="!canSubmitResults || isResubmittingAchievementBoxForm"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-yellow-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600">
                <span v-if="!isResubmittingAchievementBoxForm">Submit</span>
                <span v-else>Submitting...</span>
              </button>
              <button type="button" @click="isSubmitted = true"
                :disabled="isResubmittingAchievementBoxForm"
                class="disabled:cursor-default disabled:opacity-25 my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                Cancel
              </button>
            </template>
  
          </div>
          <br><br>
        </div>
  
        <!-- PAGE 3 (Request to extend subscription instruction!) -->
        <div v-else-if="currentPage == 3" id="page-3" class="mt-4 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4">
          
          <div>
            <h2 class=" font-bold text-3xl">We might extend your subscription.</h2>
            <br>
            <p>Please be aware that each request is subject to an approval process. To consider your appeal for an extension, you are required to provide a detailed explanation regarding the reasons for not completing the exam within the allocated time frame.</p>
            <br>
            <p >Additionally, it is imperative to include relevant documentation or evidence supporting your appeal. This may include but is not limited to medical certificates, official notices, proof of employment (if employed), or other verifiable documents that substantiate your claim.</p>
            <br/>
            <p>Please send your detailed appeal to support@skilltech.ph using your Skilltech account email address with the following subject: <em>"Appeal for Extension"</em>. Our team will review your request and respond accordingly. Note that the decision to grant an extension is at the discretion of our review team and is not guaranteed.</p>
            <br/>
            <p>We will respond within 48 hours. We appreciate your cooperation; we will make sure that you'll stay committed to getting your CAAP License.</p>
          </div>
  
          <div class="flex flex-col">
            <button type="button" @click="currentPage = 1"
              class="my-2 rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Go back
            </button>
          </div>
  
        </div>
  
      </main>
  
      <!-- THANK YOU VIEW -->
      <main v-else>

        <div id="submitted-page" class="mt-4 pt-10 sm:mt-8 pb-8 text-gray-800 dark:text-gray-300 space-y-4 flex flex-col lg:items-center lg:justify-center">

          <h2 class=" font-bold text-xl text-center">Thank you for your submission! 🤗</h2>
          <p class=" text-center w-full lg:w-3/4">
            We will verify your entry now. Please visit this page in the coming days to get an update on your Achievement Box status. Congratulations!<br/><br/>
            For any issues, inquiries, and requests, kindly email us at <a href="mailto:support@skilltech.ph" class=" text-yellow-700">support@skilltech.ph</a>
          </p>


          <div class="flex flex-col space-y-8 w-full lg:w-3/4 pt-5">

            <!-- POST EXAM SURVEY -->
            <div class="rounded-md border border-warm-gray-300 bg-warm-gray-200 dark:border-warm-gray-700 dark:bg-warm-gray-800 p-4">

              <div class="border-b border-warm-gray-400 dark:border-warm-gray-600 p-1 font-semibold text-yellow-600 dark:text-yellow-500 mb-2">
                Post Exam Sruvey
              </div>
              <div v-if="postExamSurveyInfo && postExamSurveyInfo.status == 'submitted'" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                <p class=" font-bold flex justify-start">
                  <svg class="fill-current h-6 w-6 text-yellow-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                  Submitted
                </p>
              </div>

              <div v-else-if="postExamSurveyInfo && postExamSurveyInfo.status == 'rejected'" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p class="font-bold">⚠ Rejected</p>
                <p>
                  Seems that there was a problem on your submission.<br/> 
                  {{ postExamSurveyInfo.reasonsForRejection[postExamSurveyInfo.reasonsForRejection.length - 1] }}<br/> 
                  <span @click="populatePostExamSurveyForm(); isSubmitted = false; currentPage = 1" class=" text-blue-400 underline cursor-pointer">Fill out form again</span>
                </p>
              </div>
              <div v-else-if="postExamSurveyInfo && postExamSurveyInfo.status == 'approved'" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                <p class="font-bold">✓ Approved</p>
              </div>
              
              <div class="p-1">
                <p>Date submitted: <span class=" text-warm-gray-400">{{ postExamSurveyInfo && formatDate(postExamSurveyInfo.metadata.submittedOn) }}</span> </p>
                <p>Date updated: <span class=" text-warm-gray-400">{{ postExamSurveyInfo && formatDate(postExamSurveyInfo.metadata.updatedOn) }}</span></p>
              </div>
            </div>

            <!-- ACHIEVEMENT BOX -->
            <div class=" rounded-md border border-warm-gray-300 bg-warm-gray-200 dark:border-warm-gray-700 dark:bg-warm-gray-800 p-4">

              <div class="border-b border-warm-gray-400 dark:border-warm-gray-600 p-1 font-semibold text-yellow-600 dark:text-yellow-500 mb-2">
                Achievement Box
              </div>
              
              <div v-if="achievementBoxInfo && achievementBoxInfo.status == 'submitted'" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                <p class=" font-bold flex justify-start">
                  <svg class="fill-current h-6 w-6 text-yellow-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                  Submitted
                </p>
              </div>

              <div v-else-if="achievementBoxInfo && achievementBoxInfo.status == 'rejected'" class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p class="font-bold">⚠ Rejected</p>
                <p>
                  Seems that there was a problem on your submission.<br/>
                  {{ achievementBoxInfo.reasonsForRejection[achievementBoxInfo.reasonsForRejection.length - 1] }}<br/>
                  <span @click="populateAchievementBoxForm(); isSubmitted = false; currentPage = 2" class=" text-blue-400 underline cursor-pointer">Fill out form again</span>
                </p>
              </div>

              <div v-else-if="achievementBoxInfo && achievementBoxInfo.status == 'approved'" class="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                <p class="font-bold">✓ Approved</p>
              </div>
              <div v-else class="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">

                <div class="flex">
                  <div class=""><svg class="fill-current h-6 w-6 text-blue-500 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                  <div v-if="achievementBoxInfo">
                    <p class="font-bold capitalize">{{ achievementBoxInfo && achievementBoxInfo.status }}</p>
                  </div>
                </div>

              </div>

              <div class="p-1">
                <p>Date submitted: <span class=" text-warm-gray-400">{{ achievementBoxInfo && formatDate(achievementBoxInfo.metadata.submittedOn) }}</span> </p>
                <p>Date updated: <span class=" text-warm-gray-400"> {{ achievementBoxInfo && formatDate(achievementBoxInfo.metadata.updatedOn) }} </span></p>
              </div>
            </div>

          </div>

          <div v-if="achievementBoxInfo && (achievementBoxInfo.status == 'shipped' || achievementBoxInfo.status == 'received')" class="w-full lg:w-3/4">

            <div class="mt-10">
              <h2 class=" font-bold text-xl mb-4">Your achievement box has been shipped!</h2>
              <p>Your achievement box has been shipped to the address you have provided. Please find the shipment details below.</p>

              <p class="text-sm mt-3">
                <span class="font-semibold dark:text-warm-gray-50">Courier:</span> {{ achievementBoxInfo.courierName }}
              </p>
              <p class="text-sm mt-1">
                <span class="font-semibold dark:text-warm-gray-50">Tracking #:</span> {{ achievementBoxInfo.trackingNum }}
              </p>
              <p class="text-sm mt-1">
                <span class="font-semibold dark:text-warm-gray-50">Tracking URL:</span> <a :href="achievementBoxInfo.trackingUrl" target="_blank" class=" underline "> {{ achievementBoxInfo.trackingUrl }} </a>
              </p>
              <p class="text-sm mt-1">
                <span class="font-semibold dark:text-warm-gray-50">Shipped by:</span> {{ achievementBoxInfo.metadata.shippedBy }}
              </p>
              <p class="text-sm mt-1">
                <span class="font-semibold dark:text-warm-gray-50">Shipped date:</span> {{ formatDate(achievementBoxInfo.metadata.shippedOn) }}
              </p>

              <p v-if="achievementBoxInfo.status == 'received'" class="text-sm mt-5">
                <span class="font-semibold dark:text-warm-gray-50">Date Received:</span> {{ formatDate(achievementBoxInfo.metadata.receivedOn) }}
              </p>
              <button 
                v-else
                @click="itemReceived()"
                type="button" 
                :disabled="isChangingStatusToReceiving"
                class="disabled:cursor-default disabled:opacity-25 px-2 py-1 border border-yellow-800 rounded mt-5 bg-yellow-700 w-full lg:w-52">
                {{ isChangingStatusToReceiving ? 'Updating status...' : 'I have received the item' }} 
              </button>
              
            </div>

          </div>

        </div>

      </main>

    </div>


    <TransitionRoot as="template" id="test2" :show="isSampleModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="setIsSampleModalOpen(false)">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="setIsSampleModalOpen(false)">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <img class="w-full mt-7" src="https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fmockups%2Fsample-caap-test-result.jpg?alt=media&token=9bf0dfec-b7eb-4656-ab6d-9c65cb261e3d" alt="CAAP Result Sample">
                </div>
                

              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>


  </div>
</template>

<script setup>

import firebase from "@/firebase/config";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const db = firebase.firestore();

import moment from "moment";


import { computed, ref, onMounted } from 'vue'
import LoadingScreen from "@/components/appshell/LoadingScreen.vue"

import { XIcon } from "@heroicons/vue/outline";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";

const isSampleModalOpen = ref(false)
function setIsSampleModalOpen(value) {
    isSampleModalOpen.value = value
  }

const options = [
  { id: 1, name: 'I already passed my CAAP License Exam.', description: 'I want to share my results and claim my exclusive reward.' },
  { id: 2, name: 'I haven\'t taken or finished my CAAP License Exam yet.', description: 'I want to submit the proof of my upcoming license exam.' },
]

const currentPage = ref(1);

const selected = ref(options[0])
const isLoading = ref(false)
const isSubmitting = ref(false)
const isSubmitted = ref(false)
const isChangingStatusToReceiving = ref(false)
const isResubmittingPostExamSurveyForm = ref(false)
const isResubmittingAchievementBoxForm = ref(false)

const authUserId = ref(null);
const authUserEmail = ref(null);

const softBlockUserInfo = ref({
  docId: null,
  data: null
});

const postExamSurveyInfo = ref(null);
const achievementBoxInfo = ref(null);

// variables for page 2 (Post Exam Survey)
// const canPostResults = ref(true)
const test_results = ref(null)
const tmpFile = ref(null)
const pelNum = ref('')
const feedback = ref('')
const inspiringComment = ref('')
const upload_photo = ref(null)
const tmpPhoto = ref(null)

// variables for page 3 (achievement box)
// const name = "John"
// const country = ref(null)
const province = ref(null)
const city = ref(null)
const barangay = ref(null)
const addressline1 = ref(null)
const addressline2 = ref(null)
const zip = ref(null)
const phone = ref(null)

// ui flags
const showSample = ref(false)

const canProceedToPage2 = computed( () => {

  if(tmpFile.value == null || test_results.value == null || feedback.value == '' ||  inspiringComment.value == '' || pelNum.value == '' || tmpPhoto.value == null || upload_photo.value == null){
    return false
  }else{
    return true
  }
})

const canSubmitResults = computed( () => {
  if(
    !addressline2.value ||
    !province.value || 
    !city.value || 
    !barangay.value || 
    !addressline1.value ||
    !zip.value ||
    !phone.value 
  ){
    return false
  }else{
    // check if a number is exactly 11 digits long
    return String(phone.value).length === 11 && /^\d+$/.test(String(phone.value));
  }
})

onMounted(async () => {
  
  isLoading.value = true;
  authUserId.value = firebase.auth().currentUser.uid;
  authUserEmail.value = firebase.auth().currentUser.email;

  await Promise.all([
    getPostExamSurveyInfo(authUserId.value),
    getAchievementBoxInfo(authUserId.value)
  ]);

  // console.log(achievementBoxInfo.value.status, postExamSurveyInfo.value.status)

  // check if both survey form has been submitted
  if (achievementBoxInfo.value && postExamSurveyInfo.value) {
    isSubmitted.value = true;
  }

  // await getSoftBlockUserInfo();

  // if(softBlockUserInfo.value.data){
  //   // check if soft block user status, to know which page to show
  //   if (softBlockUserInfo.value.data.status == 'appealed' || softBlockUserInfo.value.data.status == 'unblocked') {
  //     isSubmitted.value = true;
  //   }else if (softBlockUserInfo.value.data.status == 'blocked' && softBlockUserInfo.value.data.postExamSurveyStatus != 'waiting' && softBlockUserInfo.value.data.achievementBoxStatus != 'waiting') {
      
  //     // meaning, admin rejected one or both of the form submitted
  //     isSubmitted.value = true;

  //   }
  // }

  isLoading.value = false;

})

// const getSoftBlockUserInfo = async () => {

//   const query = db.collection('caap_blocks').where('userId', '==', authUserId.value);
//   await query.get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach(async (doc) => {

//         softBlockUserInfo.value = {
//           docId: doc.id,
//           data: doc.data()
//         }

//         await Promise.all([
//           getPostExamSurveyInfo(softBlockUserInfo.value.data.userId),
//           getAchievementBoxInfo(softBlockUserInfo.value.data.userId)
//         ]);

//       });
//     })
//     .catch((error) => {
//       console.log('Error getting documents from soft blocks collections: ', error);
//     });

// }

const getPostExamSurveyInfo = async (docId) => {
  
    await db.collection("post_exam_survey").doc(docId).get()
      .then((doc) => {
        if (doc.exists) {
          postExamSurveyInfo.value = doc.data();
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
    
}
  
const getAchievementBoxInfo = async (docId) => {
  
  await db.collection("achievement_box").doc(docId).get()
  .then((doc) => {
    if (doc.exists) {
      achievementBoxInfo.value = doc.data();
    }
  })
  .catch((error) => {
    console.error('Error getting document:', error);
  });

}

const submitResults = async () => {

  isSubmitting.value = true
  let uid = authUserId.value;
  let testResultUrl = null;
  let uploadPhotoUrl = null;

  try {

    // 1. Upload the file to Firebase Storage
    [testResultUrl, uploadPhotoUrl] = await uploadFiles(uid)

    // 2. If successfully uploaded files then perform the Firestore transaction
    if (testResultUrl && uploadPhotoUrl) {

      await db.runTransaction(async (transaction) => {

        // save achievement box information
        const docRefABox = db.collection('achievement_box').doc(uid);
        transaction.set(docRefABox, {
          userId: uid,
          status: 'submitted',
          pelNum: pelNum.value,

          shippingAddress: {
            province: province.value,
            city: city.value,
            barangay: barangay.value,
            addressLine1: addressline1.value,
            addressLine2: addressline2.value,
            zip: zip.value,
          },
          phone: phone.value,
          trackingNum: null,
          trackingUrl: null,
          courierName: null,
          metadata:{
            submittedOn: moment().valueOf().toString(),
            updatedOn: moment().valueOf().toString(),
            approvedOn: null,
            approvedBy: null,
            shippedOn: null,
            shippedBy: null,
            receivedOn: null,
            rejected: 0
          },
          reasonsForRejection: [] 
        });

        // save post exam survey
        const docRefPostSurvey = db.collection('post_exam_survey').doc(uid);
        transaction.set(docRefPostSurvey, {
            userId: uid,
            status: 'submitted',
            shareOptOut: true,
            resultsUrl: testResultUrl,
            userPhotoUrl: uploadPhotoUrl, 
            inspiringComment: inspiringComment.value,
            platformFeedback: feedback.value,
            metadata:{
              submittedOn: moment().valueOf().toString(),
              updatedOn: moment().valueOf().toString(),
              approvedOn: null,
              approvedBy: null,
              rejected: 0
            },
            reasonsForRejection: []
        });

        // save information to claimants collection
        const docRefClaimants = db.collection('claimants').doc(uid);
        transaction.set(docRefClaimants, {
          email: authUserEmail.value,
          postExamSurveyInfoStatus: 'submitted',
          achievementBoxInfoStatus: 'submitted',
          metadata:{
            submittedOn: moment().valueOf().toString(),
            updatedOn: moment().valueOf().toString(),
          }
        });

      });
      
    }else{
      throw new Error('Error while uploading files');
    }
   
    await Promise.all([
      getPostExamSurveyInfo(uid),
      getAchievementBoxInfo(uid)
    ]);

    isSubmitting.value = false;
    isSubmitted.value = true;

    console.log("Transaction successfully committed!");
  } catch (error) {
    
    console.error("Transaction failed: ", error);

    // 3. Rollback: Delete the file from Firebase Storage if the transaction fails
    if (testResultUrl) {
      let fileRefTestResult = firebase.storage().ref().child(`testresults/${uid}.jpg`);
      await fileRefTestResult.delete();
      console.log("Test result file deleted due to transaction failure.");
    }
    if (uploadPhotoUrl) {
      let fileRefUploadPhoto = firebase.storage().ref().child(`users-uploaded-photo/${uid}.jpg`);
      await fileRefUploadPhoto.delete();
      console.log("Upload photo file deleted due to transaction failure.");
    }

    alert("Error while submitting result, please try again later.")
    isSubmitting.value = false
  }

}

const submitResultsOld = async () => {

  isSubmitting.value = true

  const [saveAchievementBoxSuccess,savePostExamSurveySuccess] = await Promise.all([
      saveAchievementBox(authUserId.value),
      savePostExamSurvey(authUserId.value)
  ]);

  if (saveAchievementBoxSuccess || savePostExamSurveySuccess) {

    await Promise.all([
      getPostExamSurveyInfo(authUserId.value),
      getAchievementBoxInfo(authUserId.value)
    ]);

    // save information to claimants collection
    await db.collection("claimants").doc(authUserId.value).set({
      email: authUserEmail.value,
      postExamSurveyInfoStatus: 'submitted',
      achievementBoxInfoStatus: 'submitted',
      metadata:{
        submittedOn: moment().valueOf().toString(),
        updatedOn: moment().valueOf().toString(),
      }
    });

    console.log('Result submitted!')

  }

  isSubmitting.value = false
  isSubmitted.value = true

}

const uploadFiles  = async (uid) => {
  return await Promise.all([
    saveTestResult(uid),
    saveUploadPhoto(uid)
  ])
}

const savePostExamSurvey = async (uid) => {
    // save uploaded test result and photo first
    const [testResultUrl, uploadPhotoUrl] = await uploadFiles(uid)

    // then save the post exam survey data
    return await saveTestResultData(uid, testResultUrl, uploadPhotoUrl)
}

// save the uploaded test result
const saveTestResult = async (uid) => {

  

  let storageRef = firebase.storage().ref();
  let testResultImagesRef = storageRef.child(`testresults/${uid}.jpg`);
  let resultUrl = null;

  if(tmpFile.value){

      await testResultImagesRef.put(tmpFile.value).then(async (snapshot) => {
         await snapshot.ref.getDownloadURL().then( async (downloadURL) => {
          resultUrl = downloadURL;
          })
      })
  }

  return resultUrl;

}

// save the uploaded
const saveUploadPhoto = async (uid) => {

  let storageRef = firebase.storage().ref();
  let imagesRef = storageRef.child(`users-uploaded-photo/${uid}.jpg`);
  let uploadPhotoUrl = null;

  if(tmpPhoto.value){

      await imagesRef.put(tmpPhoto.value).then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then( async (downloadURL) => {
            uploadPhotoUrl = downloadURL;
          })
      })

  }

  return uploadPhotoUrl;

}

const saveTestResultData = async (uid, resultImageUrl, uploadPhotoUrl) => {
    return await db.collection("post_exam_survey").doc(uid).set({
        userId: uid,
        status: 'submitted',
        shareOptOut: true,
        resultsUrl: resultImageUrl,
        userPhotoUrl: uploadPhotoUrl, 
        inspiringComment: inspiringComment.value,
        platformFeedback: feedback.value,
        metadata:{
          submittedOn: moment().valueOf().toString(),
          updatedOn: moment().valueOf().toString(),
          approvedOn: null,
          approvedBy: null,
          rejected: 0
        },
        reasonsForRejection: []
    })
    .then(() => { 
        console.log('Post Exam Survey Results submitted!');
        return true;
    })
    .catch((error) => { 
      console.error("Error submitting Post Exam Survey: ", error)
      isSubmitting.value = false
      return false
    });
}

const saveAchievementBox = (uid) => {

  // Create and return a Promise
  return new Promise((resolve, reject) => {

      db.collection("achievement_box").doc(uid).set({

          userId: uid,
          status: 'submitted',
          pelNum: pelNum.value,

          shippingAddress: {
            province: province.value,
            city: city.value,
            barangay: barangay.value,
            addressLine1: addressline1.value,
            addressLine2: addressline2.value,
            zip: zip.value,
          },
          phone: phone.value,
          trackingNum: null,
          trackingUrl: null,
          courierName: null,
          metadata:{
            submittedOn: moment().valueOf().toString(),
            updatedOn: moment().valueOf().toString(),
            approvedOn: null,
            approvedBy: null,
            shippedOn: null,
            shippedBy: null,
            receivedOn: null,
            rejected: 0
          },
          reasonsForRejection: []

      })
    .then(() => { 
      console.log('Achievement Box form submitted!')
      resolve(true);
    })
    .catch((error) => { 

      console.error("Error submitting Achievement Box: ", error)
      reject(false);
    });

  });
  
}

const itemReceived = async () => {

  isChangingStatusToReceiving.value = true;

  // update achievement_box and claimants collections
  await Promise.all([
    db.collection('achievement_box').doc(achievementBoxInfo.value.userId).update({
      status: 'received',
      'metadata.receivedOn': moment().valueOf().toString()
    }),
    db.collection('claimants').doc(achievementBoxInfo.value.userId).update({
      achievementBoxInfoStatus: 'received',
      'metadata.updatedOn': moment().valueOf().toString()
    })
  ])

  await getAchievementBoxInfo(achievementBoxInfo.value.userId)

  isChangingStatusToReceiving.value = false;

}

const populatePostExamSurveyForm = () => {
  pelNum.value = achievementBoxInfo.value.pelNum
  feedback.value = postExamSurveyInfo.value.platformFeedback
  inspiringComment.value = postExamSurveyInfo.value.inspiringComment
}

const populateAchievementBoxForm = () => {

  // country.value = achievementBoxInfo.value.shippingAddress.country
  province.value = achievementBoxInfo.value.shippingAddress.province
  city.value = achievementBoxInfo.value.shippingAddress.city
  barangay.value = achievementBoxInfo.value.shippingAddress.barangay
  addressline1.value = achievementBoxInfo.value.shippingAddress.addressLine1
  addressline2.value = achievementBoxInfo.value.shippingAddress.addressLine2
  zip.value = achievementBoxInfo.value.shippingAddress.zip
  phone.value = achievementBoxInfo.value.phone

}

const resubmitPostExamSurveyForm = async () => {

  isResubmittingPostExamSurveyForm.value = true;

  let storageRef = firebase.storage().ref();
  let testResultImagesRef = storageRef.child(`testresults/${postExamSurveyInfo.value.userId}.jpg`);

  let storageRefPhoto = firebase.storage().ref();
  let uploadPhotoRef = storageRefPhoto.child(`users-uploaded-photo/${postExamSurveyInfo.value.userId}.jpg`);

  if(tmpFile.value && tmpPhoto.value){

      let resUrl = null;
      let photoUrl = null

      await testResultImagesRef.put(tmpFile.value).then(async (snapshot) => {
         await snapshot.ref.getDownloadURL().then( (downloadURL) => {
              resUrl = downloadURL;
          })
      })

      await uploadPhotoRef.put(tmpPhoto.value).then(async (snapshot) => {
         await snapshot.ref.getDownloadURL().then( (downloadURL) => {
            photoUrl = downloadURL;
          })
      })

      await Promise.all([
        // update post_exam_survey collection
        db.collection('post_exam_survey').doc(postExamSurveyInfo.value.userId).update({
          status: 'submitted',
          resultsUrl: resUrl,
          userPhotoUrl: photoUrl,
          inspiringComment: inspiringComment.value,
          platformFeedback: feedback.value,
          'metadata.updatedOn': moment().valueOf().toString(),
        }),
        // update achievement_box collection for PEL number
        db.collection('achievement_box').doc(postExamSurveyInfo.value.userId).update({
          pelNum: pelNum.value,
          'metadata.updatedOn': moment().valueOf().toString(),
        }),
        // update claimants collection info
        db.collection('claimants').doc(postExamSurveyInfo.value.userId).update({
          postExamSurveyInfoStatus: 'submitted',
          'metadata.updatedOn': moment().valueOf().toString(),
        })
      ])

      await Promise.all([
        getPostExamSurveyInfo(authUserId.value),
        getAchievementBoxInfo(authUserId.value)
      ]);

  }

  isSubmitted.value = true
  isResubmittingPostExamSurveyForm.value = false

}

const resubmitAchievementBoxForm = async () => {

  isResubmittingAchievementBoxForm.value = true;

  // update achievement_box collection
  await db.collection('achievement_box').doc(achievementBoxInfo.value.userId).update({
    status: 'submitted',
    // 'shippingAddress.country': country.value,
    'shippingAddress.province': province.value,
    'shippingAddress.city': city.value,
    'shippingAddress.barangay': barangay.value,
    'shippingAddress.addressLine1': addressline1.value,
    'shippingAddress.addressLine2': addressline2.value,
    'shippingAddress.zip': zip.value,
    phone: phone.value,
    'metadata.updatedOn': moment().valueOf().toString(),
  })

  await db.collection('claimants').doc(achievementBoxInfo.value.userId).update({
    achievementBoxInfoStatus: 'submitted',
    'metadata.updatedOn': moment().valueOf().toString(),
  })

  await getAchievementBoxInfo(authUserId.value);

  isSubmitted.value = true
  isResubmittingAchievementBoxForm.value = false;

}

// ===== helper functions

// check for the file size...
const someHandler = (event) => {
  console.log("User updated the file")
  // tempFileName
  // console.log('Selected file: ' + event.target.files.item(0).name);
  // console.log('Selected file: ' + event.target.files.item(0).size);
  // console.log('Selected file: ' + event.target.files.item(0).type);
  
  var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
  
  console.log(`File size: ${filesize}MB`)
  
  if(filesize > 2){
      alert("File size of 2MB exceeded")
      // this.emptyFile()
      test_results.value = null 
  }else{
      test_results.value = event.target.files.item(0).name
      tmpFile.value = event.target.files.item(0)
      // console.log(event)
  }
  
}


// check for the file when uploading the photo size...
const uploadPhotoHandler = (event) => {
  console.log("User updated the photo")
  
  var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
  
  console.log(`File size: ${filesize}MB`)
  
  if(filesize > 2){
      alert("File size of 2MB exceeded")
      // this.emptyFile()
      upload_photo.value = null 
  }else{
      upload_photo.value = event.target.files.item(0).name
      tmpPhoto.value = event.target.files.item(0)
      // console.log(event)
  }
  
}

const formatDate = (timestamp) => {

  if (timestamp) {
    const dateObject = new Date(parseInt(timestamp));
    return moment(dateObject).format("MMM. D, YYYY");
  }else{
    return '-'
  }

}


</script>

<style scoped>
  
  table, th, td {
    @apply border border-warm-gray-500 text-center;
  }

</style>
<template>
  <div>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-4clearxl mx-auto divide-y-12 divide-warm-gray-200 dark:divide-warm-gray-700">
        <!-- FAQs: Reviewers -->
        <h2 class="my-6 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl">Skilltech Changelog</h2>
        <dl class="space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700">
          <Disclosure as="div" v-for="version in versions.reverse()" :key="version.id" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                  See changes for {{ version.name }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <div class="text-base text-warm-gray-500">
                <ul>
                  <li v-for="change in version.changes" :key="change" class="py-1 list-disc dark:text-warm-gray-400 text-warm-gray-700 ml-6 relative focus-within:ring-2 focus-within:ring-yellow-500 text-sm">
                    {{ change }}
                  </li>
                </ul>
                <p class="dark:text-warm-gray-400 text-warm-gray-700 text-sm mt-4">
                  Released: {{version.released}}
                </p>
              </div>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

import { changelogData } from "@/data/changelog.data"

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  setup() {
    return {
      versions: changelogData.versions,
    }
  },
  created(){
    // console.log("Adding overflow-hidden")
    document.body.classList.add("overflow-hidden");
  }
}
</script>